import React from 'react';
import {Icon, Popover} from 'antd';
import {customerSupportNumber} from "../config";
export const CustomerSupportNumberIcon = ()=>{
	const phone = <span>For customer support, call:<a href={`tel:${customerSupportNumber}`}>{customerSupportNumber}</a></span>
	return <div style={{ textAlign:'end',position:'fixed',right:0,bottom:50,width:'fit-content' }}>
		<Popover title={phone} overlayStyle={{width:'250px'}} placement="topRight" overlayClassName={'customerSupportPopUp'}>
			<Icon type="question-circle" style={{fontSize:30,marginRight:50,marginBottom:50,cursor:'pointer' }}/>
		</Popover>
	</div>
}