import React, {useEffect, useState} from 'react';
import MakeOrderButtonWrapperContainer from './MakeOrderButtonWrapperContainer';
import '../style/index.scss';
import SubscriptionContainer from "./SubscriptionContainer";
import PaidOrderListContainer from "./PaidOrderListContainer";
import { getOrdersQuery,getSkuList,getSubscriptions} from "../../API/query/Order";
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setProductInfo } from '../../redux/actions/orderActions';
const PAGE_SIZE=10;
const OrderContainer = ()=>{
	const [ orders,setOrders ] = useState([]);
	const [ subscriptions,setSubscriptions ] = useState([]);
	const [ page,setPage ] = useState(1);
	const [ status,setStatus ] = useState('');
	const [ sort,setSort ] = useState({ sort:'created_At',order:'desc '});
	const [ totalCount,setTotalCount ] = useState(0);
	const {refresh} = useSelector(state=>state.orderReducer);
	const [ loading,setLoading ] = useState(false);
	const dispatch = useDispatch();
	const doFetch = async ()=>{
		try{
			let ordersResponse = await getOrdersQuery(page,PAGE_SIZE,status,sort);
			let subscriptionsResponse = await getSubscriptions();
			let totalSummary = get(ordersResponse,'data.summary.totalCount');
			let products = await getSkuList();
			if (products.data.data.length) {
				dispatch(setProductInfo(products.data.data[0]));
			}
			setTotalCount(totalSummary);
			setOrders(get(ordersResponse,'data.data'));
			setSubscriptions(get(subscriptionsResponse,'data.data'));
		}catch (e) {
			console.log(e);
		}
		finally {
			setLoading(false);
		}
	}
	useEffect(()=>{
		setLoading(true);
		doFetch()
	},[page,status,sort, refresh]);

	const renderSubscription = ()=>{
		return <SubscriptionContainer subscriptions={subscriptions}/>
	}
	const renderMakeOrderBtn=()=>{
		return <MakeOrderButtonWrapperContainer fetchOrders={doFetch} className={'createOrderBtn'} text={'Make an Order'}/>
	}
	// const { orders } = this.state;
	return <div className={'orderWrapper'}>
				<div className={'header'}>
					<span style={{ fontSize:24,lineHeight:'21px',fontWeight:900,color:'#2D3238' }}>Orders</span>
					{ renderMakeOrderBtn() }
				</div>
				<div className={'subscriptionWrapper'}>
					{ renderSubscription() }
				</div>
				<div>
					<PaidOrderListContainer orders={orders} page={page}  setStatus={setStatus} setLoading={setLoading} setSort={setSort}
					                        fetchOrders={doFetch} setPage={setPage} totalCount={totalCount} loading={loading}/>
				</div>
		   </div>
}
export default OrderContainer;
