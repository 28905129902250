import React from "react";
import { Icon } from 'antd';
const SignUpSucceedComponent = class extends React.Component{
	renderWrapper = ()=>{
		return <div style={{ marginLeft:'auto',display:'flex',justifyContent:'center',marginTop:100 }}>
				<div style={{ width:550,backgroundColor:'white',display:'flex',justifyContent:'center',padding:'50px 100px',color:'#2D3238',flexDirection:'column' }}>
					<Icon type="check-circle" theme="filled" style={{ fontSize:50,margin:50,color:'#3BA767' }} />
					<div style={{ fontWeight:'bold',fontSize:24,lineHeight:'28px',textAlign:'center' }}>Please check your email to verify your account</div>
					<div style={{ textAlign:'center',margin:'20px 0' }}>Almost done! We sent an email to <span style={{ fontWeight:'bold' }}> nicolewang@airbnb.com</span></div>
					<div style={{ textAlign:'center',marginBottom:'60px' }}>
						Please open your email and click the verification button. Once your email is verified, you can sign in to your account.
					</div>
				</div>
			   </div>
	}
	render() {
		return this.renderWrapper();

	}
}

export default SignUpSucceedComponent