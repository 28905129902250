import axios from 'axios';
import { host } from "../config";
import axiosRetry from 'axios-retry';
import { isRetryableError, exponentialDelay } from 'axios-retry';

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
let instance = axios.create({
	baseURL: host,
	timeout: 200000,
	// withCredentials:true,
});
axiosRetry(instance, {
	retries: 3,
	retryCondition: (error) => {
		return isRetryableError(error);
	},
	retryDelay: (retryCount) => {
		return exponentialDelay(retryCount);
	}
})
export {
	instance
}
