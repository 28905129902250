import React from 'react';
import {Row, Col, Divider} from 'antd';

const Footer = () => {
    return <footer id="footer">
        <div className="footerContainer">
        <Row type="flex" gutter={20}>
            <Col offset={2}>
                <h4>Company</h4>
                <ul>
                    <li><a href="https://ihealthlabs.com/pages/about-us">About iHealth</a></li>
                    <li><a href="https://ihealthlabs.com">Shop</a></li>
                </ul>
            </Col>
            <Col>
                <h4>iHealth Solutions</h4>
                <ul>
                    <li><a href="/">iHealth COVID Test Kit Solution</a></li>
                    <li><a href="https://ihealthunifiedcare.com">Unified Care Program</a></li>
                </ul>
            </Col>
        </Row>
        <Divider style={{borderTop:'1px solid #B5B9BE'}}/>
        <Row type="flex" gutter={20}>
            <Col offset={2}>
                <a href="https://ihealthlabs.com/pages/privacy-policy">Privacy Policy</a>
            </Col>
            <Col>
                <a href="https://ihealthlabs.com/pages/terms-and-conditions">Terms and Conditions</a>
            </Col>
        </Row>
        <Divider style={{borderTop:'1px solid #B5B9BE'}}/>
        <Row type="flex" justify='end'>
            <Col offset={15} xs={22} md={9}>
                <p >© 2022 iHealth Labs Inc. All rights reserved.  iHealth is a trademark of iHealth Labs Inc.</p>
            </Col>
        </Row>
        </div>
    </footer>
}

export default Footer;