let GOOD_NETWORK_STATUS = {
	color:'green',
	text: 'Good',
}
let MEDIUM_NETWORK_STATUS = {
	color:'yellow',
	text: 'Medium',
}
let WEAK_NETWORK_STATUS = {
	color: 'red',
	text: 'Poor'
}
export const networkStatusMap =(code)=>{
	return {
		0: {
			color:'gray',
			text:'not available'
		},
		1: GOOD_NETWORK_STATUS,
		2: GOOD_NETWORK_STATUS,
		3: MEDIUM_NETWORK_STATUS,
		4: MEDIUM_NETWORK_STATUS,
		5: WEAK_NETWORK_STATUS,
		6: WEAK_NETWORK_STATUS
	}[code]
}
