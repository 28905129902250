import React, {useEffect, useState} from "react";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getStripePK,getIntentId,getPaymentMethod } from '../../API/query/Stripe';
import { deleteCard } from '../../API/query/Order';
import SavedCreditCardComponent from "../../Order/component/SavedCreditCardComponent";
import { get} from 'lodash';
import '../style/index.scss';
import { CheckoutForm} from "./CheckoutForm";
import { message,Modal,Button,Spin,Icon } from 'antd';

export default function PaymentInputs(props) {
	let [stripePromise,setStripePromise] = useState(null);
	let [options, setOptions ] = useState(null);
	let [payment,setPayment] = useState([]);

	useEffect(()=>{
		const initialStripe = async()=>{
			try{
				const response = await getStripePK();
				const publicKey = get(response,'data.data.publicKey');
				const intentRes = await getIntentId();
				const clientSecret = get(intentRes,'data.data.setupIntentId');
				const paymentRes = await getPaymentMethod();
				const payment = get(paymentRes,'data.data');
				stripePromise = loadStripe(publicKey);
				setStripePromise(stripePromise);
				setOptions({clientSecret});
				setPayment(payment);
			}
			catch (e) {
				console.log(e);
			}
		}
		initialStripe();

	},[]);
	const popDeleteCardConfirm =(id,payment)=>{
		let { last4 } = payment;
		Modal.confirm({
			title:`Remove Card ending in ${last4} ?`,
			className:'removeCardModal',
			onOk: async ()=>{
				handleDelete(id);
			}
		})
	}

	const fetchPayment = async ()=>{
		const paymentRes = await getPaymentMethod();
		const payment = get(paymentRes,'data.data');
		setPayment(payment);

	}
	const handleDelete = async (id)=>{
		await deleteCard(id)
		message.info('Card Removed.');
		setPayment([]);
	}

	const cardFooter = ()=>{
		return <div style={{ display:'flex',marginTop:10 }}>
			<div><Icon type={'info-circle'}/></div>
			<div style={{ width: 400,marginLeft: 10 }}>We currently only support one payment card. To change or update payment card, please delete the current one and add a new one.</div>
		</div>
	}

	if(stripePromise&&options){
		if(!payment.length){
			return <Elements stripe={stripePromise} options={options}>
					<CheckoutForm fetchPayment={fetchPayment}/>
				   </Elements>
		}
		return <div>
				<SavedCreditCardComponent payment={payment}/>
				<Button onClick={()=>popDeleteCardConfirm(payment[0].id,payment[0])} className={'ihealth'}>Remove Card</Button>
				{ cardFooter() }
			   </div>

	}
	return <Spin/>

}