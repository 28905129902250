import { getRequest } from "../helper";
import { postRequest } from "../helper";
import {getLoginUser} from "../../auth/helper";

const HEADER =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})
const STRIPE_PUBLIC_KEY_URL = '/api/enterprise/payment/stripe/public-key';
const STRIPE_INTENT_ID = '/api/enterprise/payment/stripe/create-setup-intent';
const DEFAULT_PAYMENT_METHOD = '/api/enterprise/payment/stripe/payment-methods';
export const getStripePK = ()=>getRequest(STRIPE_PUBLIC_KEY_URL,{ headers: HEADER() })
export const getIntentId = ()=>postRequest(STRIPE_INTENT_ID,{ headers: HEADER() });
export const getPaymentMethod = ()=>getRequest(DEFAULT_PAYMENT_METHOD,{ headers:HEADER() });