import React, {useEffect} from 'react';
import AppointmentDetailComponent from './AppointmentDetailComponent';
import TechnicalRequirementsComponent from '../../Accessories/component/TechnicalRequirementsComponent';
import WhatToDoNextComponent from '../../Accessories/component/WhatToDoNextComponent';
import { website} from "../../API/config/index";

const AppointmentScheduledComponent = (props) => {
    const {code='',status} = props;
    const path = 'verifyTest/redeem'
    const link = `${website}/${path}/${code}`;
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[status]);
    return <div className="appointmentScheduledWrapper">
        <div className="header">
            <img src="/image/success.png"  alt="success"/>
            <h1>Appointment scheduled!</h1>
            <p>Please see the instruction below for next steps!  The instruction has been sent to your email as well, please check!</p>
            </div>
            <div className="appointmentInfoWrapper">
                <p>Below is the link to join the appointment. Please mark the date & time in your calendar and join on time. See you soon!</p>
                <a href={link} target="_blank">{link}</a>
                <AppointmentDetailComponent {...props} />
                <div style={{marginBottom: '75px'}}>
                    <h3>Technical Requirements</h3>
                    <TechnicalRequirementsComponent />
                </div>
                <WhatToDoNextComponent />
                <p style={{marginTop: '3rem'}}>Can’t join the appointment on time? <a href={`/verifyTest/schedule/${code}`}>Reschedule</a> or <a href={`/verifyTest/cancelApp/${code}`}>Cancel</a></p>          
            </div>
    </div>
}

export default AppointmentScheduledComponent;