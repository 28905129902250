import React from 'react';
import { Select, Row, Col, Form, Input,Button, message } from 'antd';
import { getUserInfo } from '../../auth/helper';
import axios from 'axios';

class CustomerSupportForm extends React.Component {
    state = {
        loading: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        this.props.form.validateFields(async (err, values) => {
          if (!err) {
            const user = getUserInfo();
            try {
                const data = {...values, 'your-email': user.Email, 'your-name': `${user.FirstName} ${user.LastName}`, 'your-tags': ["covid_test_kit_solution"] };
                const response = await axios.post('https://form-services.ihealth-eng.com/forms/customer', data);
                if(response.status === 200 && response.data.result===1 && response.data.message === 'success'){ 
                    this.props.onCancel();
                    this.props.setSubmit(true);
                } else {
                    console.log(err);
                    message.error('Something went wrong. Please try again later.');
                }
            }
            catch(err) {
                console.log(err);
                message.error('Something went wrong. Please try again later.');
            }finally{
                this.setState({ loading: false });
            }
          }
          else{
              console.log('error');
          }
        });
      };

      render(){
        const { getFieldDecorator } = this.props.form;
        return <div className="csFormContainer">
            <h2>Please leave us a message</h2>
            <Form layout={'vertical'} onSubmit={this.handleSubmit}>
            <Form.Item label="Message category">
            {getFieldDecorator('your-subject', {
                rules: [
                {
                    required: true,
                    message: 'Please select a cagegory.',
                },
                ],
            })(
                <Select>
                    <Select.Option value="Tech Support">Tech Support</Select.Option>
                    <Select.Option value="Group test management issue">Group test management issue</Select.Option>
                    <Select.Option value="Order issue">Order issue</Select.Option>
                    <Select.Option value="Payment issue">Payment issue</Select.Option>
                    <Select.Option value="Others">Others</Select.Option>
                </Select>)}
            </Form.Item>
            <Form.Item label="Message">
            {getFieldDecorator('your-message', {
                rules: [
                {
                    required: true,
                    message: 'Please input your message.',
                },
                ],
            })(<Input.TextArea placeholder="Please let us know the problem." />)}
            </Form.Item>
            <Form.Item>
                <Row type="flex" justify='center'>
                    {this.state.loading? 'loading...': 
                    <Col>
                        <Button onClick={()=>this.props.onCancel()}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Col>}
                </Row>
            </Form.Item>
            </Form>
        </div>
    }
}

const CustomerSupportFormWrapper = Form.create({ name: 'CustomerSupport' })(CustomerSupportForm);

export default CustomerSupportFormWrapper;