import React, { useState} from "react";
import { useElements, useStripe,} from "@stripe/react-stripe-js";
import {Button, Modal, Icon} from "antd";
import {checkoutDirectly, checkOutWithPaymentId,remakeOrderMutation, createSubscriptionMutation, makeOrderBasedOnSubscription} from '../../API/query/Order';
import { get,pick } from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import {setOrderDetails} from "../../redux/actions/orderActions";

const PayForOrderButtonComponent = (props)=> {
	const stripe = useStripe();
	const {clientSecret, saveCard, form, haveCC,orderType,creationOrderInfo,disabled,initialStripe,orderDetails={ },subscription, setActiveKey, setShowMakeOrder} = props;
	const [ paymentInProcess,setPaymentInProcess ] = useState(false);
	const elements = useElements();
	let orderId = creationOrderInfo[orderType];
	const dispatch = useDispatch();
	console.log(subscription);

	const {orderDetails:storeOrderDetails } = useSelector(state=>state.orderReducer);


	let checkOutOrder = async (id)=>{
		if(orderType==='order') {
			let res = await checkoutDirectly(id);
			return res;
		}else{
			let fieldsToCreateSub = [
				"contactGroupId",
				"name",
				"quantity",
				"date",
				"frequency",
				"frequencyUnit",
				"startDate",
			]
			let res = await createSubscriptionMutation(pick(subscription,fieldsToCreateSub));
			let subscriptionId = get(res,'data.data.id');
			let orderRes = await makeOrderBasedOnSubscription(subscriptionId);
			orderId = get(orderRes,'data.data.id');
			let checkoutRes = await checkoutDirectly(orderId);
			return checkoutRes;
		}
	}

	let onSubmitWithoutSavedCard = async ()=>{
		form.validateFields(async errors => {
			if(!errors) {
				let values = form.getFieldsValue();
				let processModal = Modal.info({
					title: 'Placing order',
					content: (
						<div>
							<p>Please wait and DO NOT refresh this page.</p>
						</div>
					),
					okButtonProps: { style: { display: 'none' } }
				});
				try {
					let res = false;
					if (!saveCard) {
						res = await createPaymentMethodWithoutSaveCard(values);

					} else {
						res = await createPaymentWithSavingCard(values);
					}
					if(res) {
						if(orderType=='subscription'){
							dispatch(setOrderDetails({ ...storeOrderDetails, orderId:res, orderType, status: 'PAID'}));
						}else{
							dispatch(setOrderDetails({ ...storeOrderDetails, orderId:res, orderType, status: 'PAID'}));
						}
						setActiveKey('3');
					} else {
						throw new Error(res.data.data.transactionStatus,{cause:'DECLINED'})
					}
				}catch (e) {
					initialStripe();
					console.log(e);
					const { cause } = e;
					if(cause === 'DECLINED') {
						let msg = <div style={{ display:'flex',flexDirection:'column' }}>
							<span className={'subHeader'} style={{maxWidth: 462}}>Oops! There is something wrong with your payment card. Please confirm your card information or try another card.</span>
						</div>
						Modal.error({
							title: msg,
							className: 'paymentStatus paymentError',
							icon: <Icon type="info-circle" theme="filled" style={{color:'#DA6453', margin:0}} />,
							okButtonProps: { style: { margin: '0 auto' } }
						})
					}else {
						setShowMakeOrder(false);
						let msg = <div style={{ display:'flex',flexDirection:'column', maxWidth:497 }}>
							<p className={'subHeader'}>Subscription is created!! However, there is something wrong with the payment card thus the first subscription order is failed.</p>
							<span>Please do as follows:</span>
							<ol style={{paddingLeft:19}}>
								<li>Go to "settings" and update your payment card.</li>
								<li>Go to your orders tab, re-order the failed order.</li>
							</ol>
						</div>
						if(orderType=='order') {
							msg = <div style={{ display:'flex',flexDirection:'column', maxWidth:497 }}>
							<p className={'subHeader'}>Oops! There is something wrong with the payment card thus the order is failed.</p>
							<span>Please do as follows:</span>
							<ol style={{paddingLeft:19}}>
								<li>Go to "settings" and update your payment card.</li>
								<li>Go to your orders tab, re-order the failed order.</li>
							</ol>
						</div>	
				}
						Modal.error({
							title:msg,
							className:'paymentStatus paymentError',
						})
					}
				}
				finally {
					processModal.destroy();
					setPaymentInProcess(false);
				}
			}
		});
	}

	let onSubmitWithSavedCard =  async ()=>{
		setPaymentInProcess(true);
		let processModal = Modal.info({
			title: 'Placing order',
			content: (
				<div>
					<p>Please wait and DO NOT refresh this page.</p>
				</div>
			),
			okButtonProps: { style: { display: 'none' } }
		});
		try {
			    let orderToCheckout = orderId;
				if(get(orderDetails,'status') =='FAILED') {
					let res = (await remakeOrderMutation(orderId));
					orderToCheckout = get(res,'data.data.id');
				}
				let res = await checkOutOrder(orderToCheckout);
				const s = get(res,'data.data.succeeded');
				if(s) {
					if(orderType=='subscription'){
						dispatch(setOrderDetails({ ...storeOrderDetails, orderId: orderToCheckout||orderId, orderType, status: 'PAID'}));
					}else{
						dispatch(setOrderDetails({ ...storeOrderDetails, orderId: orderToCheckout, orderType, status:res.data.data.succeeded?'PAID':'FAILED'}));
					}
					setActiveKey('3');
				}else{
					// saved card not abled to charge.
					throw new Error(res.data.data.transactionStatus,{cause:'DECLINED'});
				}
		}
		catch (e) {
			const { cause } = e;
			if(cause === 'DECLINED') {
				let msg = <div style={{ display:'flex',flexDirection:'column', maxWidth:497 }}>
					<p className={'subHeader'}>Subscription is created!! However, there is something wrong with the payment card thus the first subscription order is failed.</p>
					<span>Please do as follows:</span>
					<ol style={{paddingLeft:19}}>
						<li>Go to "settings" and update your payment card.</li>
						<li>Go to your orders tab, re-order the failed order.</li>
					</ol>
				</div>
				if(orderType=='order') {
					msg = <div style={{ display:'flex',flexDirection:'column', maxWidth:497 }}>
					<p className={'subHeader'}>Oops! There is something wrong with the payment card thus the order is failed.</p>
					<span>Please do as follows:</span>
					<ol style={{paddingLeft:19}}>
						<li>Go to "settings" and update your payment card.</li>
						<li>Go to your orders tab, re-order the failed order.</li>
					</ol>
				</div>	
				}
				setShowMakeOrder(false);
				Modal.error({
					title: msg,
					className: 'paymentStatus paymentError',
					icon: null
				})
			}else {
				let message  = get(e,'response.data.Message','Please verify your payment card information or start with a new order.');
				setShowMakeOrder(false);
				Modal.error({
					title:message,
					className:'paymentStatus paymentError',
					icon: null
				})
			}
			
		}finally {
			processModal.destroy();
			setPaymentInProcess(false);
		}

	}


	const createPaymentMethodWithoutSaveCard = ({ firstName, lastName, country, street, apt, city, state, zipcode }) => {
		let cardElement = elements.getElement('card');
		return new Promise((resolve,reject)=>{
			stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					address: {
						"city": city,
						"country": 'US',
						"line1": street,
						"line2": apt,
						"postal_code": zipcode,
						"state": state
					},
				}
			})
				.then(function (result) {
					if (!result.error) {
						try {
							(async () => {
								const res = await checkOutWithPaymentId(orderId, result.paymentMethod.id);
								// orderPaidSucceed();
								console.log(res);
								resolve(res);
							})();
						} catch (e) {
							reject(e)
						}
					} else {
						console.log(result.error);
					}
				})
		})

	}
	const createPaymentWithSavingCard = ({ firstName, lastName, country, street, apt, city, state, zipcode }) => {
		return new Promise((resolve,reject) =>{
			let cardElement = elements.getElement('card');
			stripe.confirmCardSetup(clientSecret, {
				payment_method: {
					card: cardElement,
					billing_details: {
						address: {
							"city": city,
							"country": 'US',
							"line1": street,
							"line2": apt,
							"postal_code": zipcode,
							"state": state
						},
					},
				}
			}).catch(error=>{
					initialStripe();
					reject(error)
			})
			.then(function (result) {
					if (!result.error) {
						checkOutOrder(orderId)
							.then(res =>{
								if(res.data.data.succeeded) {
									resolve(orderId);
								}
								else {
									// able to save, but not able to charge
									reject(new Error(res.data.data.transactionStatus));
								}})
							.catch(error=>{
								initialStripe();
							});
					}else{
						// bad card, not able to saved nor charge
						reject(new Error(result.error.mesage,{cause:'DECLINED'}))
					}
				})
			});
	}
	const onClick = ()=>{
		setPaymentInProcess(true);
		form.validateFields((res,errors) => {
			let cb = !haveCC ? onSubmitWithoutSavedCard : onSubmitWithSavedCard;
			cb();
		});

	}

	let btnText = orderType =='order' ? 'Place Order' : 'Confirm Subscription';
	return <div><Button className='ihealth confirmOrder' onClick={ onClick } disabled={disabled || paymentInProcess} loading={paymentInProcess}>{btnText}</Button></div>

}
export default PayForOrderButtonComponent;