import React from "react";
import AppointmentDetailComponent from "./AppointmentDetailComponent";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
const CancelFinishedScheduledComponent = (props)=>{
	const { visit={},tester={} } = props;
	const { ScheduledAt,TimezoneLabel,Timezone } = visit||{};
	const { code } = tester;
	const navigate = useNavigate();
	const renderButton = ()=>{
		return <Button className={'ihealth'} onClick={()=>navigate(`/verifyTest/schedule/${code}`)} style={{ fontWeight: 700,width:230,height:44,fontSize:20 }}>Check my test report</Button>
	}
	return <div className="appointmentScheduledWrapper" style={{ paddingTop: '3em' }}>
			<div style={{ maxWidth:480, marginBottom:20 }} className={'note'}>
				Sorry, your appointment has been finished and thus can't be canceled.
				To check your test report, please click the button below.
			</div>
			<div>
				<AppointmentDetailComponent {...tester} selectedSlot={{timeSlot:ScheduledAt}} selectedTimezone={{label:TimezoneLabel,offset:Timezone}}/>
			</div>
			<div>
				{renderButton()}
			</div>
	</div>
}
export default CancelFinishedScheduledComponent;