import React, {useState} from "react";
import {Row, Col, Input, Button, Form, Tooltip, Icon} from 'antd';
import '../style/index.scss';
import DateFormattedInput from "../../Accessories/component/DateFormattedInput";
import {testerGetReportMutation} from "../../API/query/tester";
import moment from "moment";
import AppointmentFinishedComponent from "../../schedule/component/AppointmentFinishedComponent";
import TesterCheckReportComponent from "../component/TesterCheckReportComponent";
let TesterCheckReportContainer = class extends React.Component{
	constructor() {
		super();
		this.state = {
			code:'',
			birthday:'',
			error:false,
			verified: false,
			loading: false
		}
	}

	setCode = (e)=>{
		this.setState({
			code:(e.target.value).trim()
		})
		this.setError();

	}

	setBirthday = (birthday)=>{
		this.setState({
			birthday
		})
		this.setError();

	}
	setError= (error)=>{
		this.setState({
			error
		})
	}
	setLoading = (loading)=>{
		this.setState({
			loading
		})
	}
	header = ()=> <div className={'title'}>Check My Test Report</div>;

	getReport = async ()=>{
		const { setBirthday,setCode,props,state,setLoading } = this;
		const { birthday,code } = state;
		const [month,day,year] = birthday.split('/');
		try {
			setLoading(true);
			await testerGetReportMutation({code, birthday: `${year}-${month}-${day}`});
			this.setState({
				verified: true
			})
		}catch (e) {
			this.setError(true)
		}
		finally {
			setLoading(false);
		}
	}

	dobValidator = (rule, value, callback) => {
		let disabledDate=(current)=> {
			return current && current > moment().endOf('day');
		}
		if (!!value && (moment(value).format('MM/DD/yyyy') !== value || disabledDate(moment(value)))){
			return callback('Please input a valid birthday.');
		}
		callback()
	};

	form = ()=> {
		const { setBirthday,setCode,props,state } = this;
		const { birthday,code } = state;
		const {  getFieldDecorator } = props.form;
		return <div>
			<Form>
				<Form.Item label={'Ticket Number'}>
					{getFieldDecorator('code',{ value: code,rules:[{required: true,message:'Ticket Number is required!'}] })(
						<div style={{display:'flex'}}>
							<Input onChange={setCode}/>
							<div style={{marginLeft: 10, height: 44, display:'flex', alignItems:'center'}}>
								<Tooltip title={'The ticket number is the code we \n' +
								'sent to you in your service purchase \n' +
								'confirmation email. '} overlayClassName={'codeTooltip'}>
									<Icon type="question-circle"  theme='filled' style={{ fontSize:20 }}/>
								</Tooltip>
							</div>
						</div>)}

				</Form.Item>
				<Form.Item label={'DOB'}>
					{getFieldDecorator('birthday',{value:birthday,rules:[{ validator: this.dobValidator},{ required:true,message: 'DOB is Required!' }]})(<DateFormattedInput onChange={setBirthday}/>)}
				</Form.Item>
			</Form>
		</div>
	}
	showError = ()=>{
		return <div className={'errorBox'}>
				<img src={'/image/red_info.png'} width={14} style={{marginRight:10,alignSelf:'flex-start',marginTop:5 }}/>
				<span style={{lineHeight:'20px'}}>  Sorry, the ticket number or DOB is incorrect. Please try again.</span>
		</div>
	}
	button = ()=>{
		const { setBirthday,setCode,props,state,getReport } = this;
		const { birthday,code,loading } = state;
		const { getFieldError } = props.form;
		const birthdayError = getFieldError('birthday');
		let disabled = !code || !birthday || birthdayError;
		return <Button className={'ihealth'} disabled={disabled}  loading={loading} onClick={getReport}>Confirm</Button>
	}
	renderForm = ()=>{
		let { header,form,button,state,showError } = this;
		const { error } = state;
		return  <div className={'checkReportForm'}>
			{header()}
			{form()}
			{error && showError()}
			{button()}
		</div>
	}

	render() {
		const { renderForm,state } = this;
		const { verified,code } = state;
		if(verified){
			return <TesterCheckReportComponent code={code}/>
		}
		return renderForm();
	}
}

TesterCheckReportContainer = Form.create()(TesterCheckReportContainer);
export default TesterCheckReportContainer;