import { actionTypes, filterTypes} from '../actionTypes/testResultActionTypes';
import {getStartTimeEndTimeOfRecentNdays} from '../../lib/helpers';
const startEnd = getStartTimeEndTimeOfRecentNdays();
const initialState = {
	filter: filterTypes.ALL,
	groupStats: {},
	selectedTestGroup: {},
	testGroups: [],
	isLoading: false,
	startTime: startEnd[0],
	// startTime: 1,
	endTime: startEnd[1],
	userList: [],
	tableRowsData: {},
	currentPage: 1
}


const testResultReducer = (state = initialState, action) => {
	switch (action.type) {

		case actionTypes.SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.currentPage
			}

		case actionTypes.SET_TABLE_ROWS:
			return {
				...state,
				tableRowsData: action.data
			}
		
		case actionTypes.SET_TEST_GROUPS:
			const newState = {
				...state,
				testGroups: action.data
			}
			if(action.data.length > 0){
				newState.selectedTestGroup = action.data[0];
			}
			return newState;

		case actionTypes.SET_GROUP_TEST_STATS:
			return {
				...state,
				groupStats: action.data,
				isLoading: false
			}

		case actionTypes.SET_FILTER:
			return {
				...state,
				filter: action.filter,
				currentPage: 1
			}
		case actionTypes.SET_SELECTED_GROUP:
			return {
				...state,
				selectedTestGroup: action.data,
				currentPage:1
			}
		case actionTypes.SET_GROUP_USER_LIST:
			return {
				...state,
				isLoading: false,
				userList: action.data
			}

		case actionTypes.SET_START_END_TIME:
			return {
				...state,
				startTime: action.startTime,
				endTime: action.endTime,
				currentPage: 1
			}
		
		case actionTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading
			}
		default:
			return state
	}
}

export default testResultReducer;