import React from 'react';
import UserProfileDropdown from "./UserProfileDropdown";
import '../style/index.scss';
import { isLoggedIn } from "../../auth/helper";
import Header from './Header';
import { Button } from 'antd';
import SupportMenu from './SupportMenu';
import { useLocation } from 'react-router-dom'
import { HIDE_LOGIN, HIDE_SIGNUP, HIDE_SUPPORT_MENU, getThemeAndTitle } from '../constant/HeaderMap';
const BannerComponent = () => {

	const renderLoginButton = () => {
		return <Button className='login' href="/login">Sign in</Button>
	}

	const renderSignUpButton = () => {
		return <Button className='signup' href="/signup">Get started</Button>
	}

	const location = useLocation();
	let { theme, loginLink } = getThemeAndTitle(location.pathname);
	return <div className={`banner ${theme}`}>
		<Header location={location} />
		{
			isLoggedIn() ?
				<div>
					{HIDE_SUPPORT_MENU.some(v => location.pathname.toLowerCase().includes(v.toLowerCase())) ? '' : <SupportMenu />}
					<UserProfileDropdown loginLink={loginLink}/>
				</div> : <div>
					{HIDE_LOGIN.some(v => location.pathname.toLowerCase().includes(v.toLowerCase())) ? '' : renderLoginButton()}
					{HIDE_SIGNUP.some(v => location.pathname.toLowerCase().includes(v.toLowerCase())) ? '' : renderSignUpButton()}
				</div>
		}
	</div>
}

export default BannerComponent
