import { host } from "../../API/config";
import { getLoginToken } from "../../auth/helper";

export const createWS = () => {
	let ws;
	let url;
	// try {
	const wshost = host.replace('http', 'ws');
	let server = `${wshost}/api/proctoring/setup-connection`;
	let authToken = getLoginToken();
	url = server + `?authorization=${authToken}&force=true`;
	// 	ws = new WebSocket(server+`?authorization=${authToken}&force=true`);
	// 	console.log('ws created at',server);
	// }catch (e) {
	// 	console.log(e);
	// }
	return url;
}