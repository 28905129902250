import React from 'react';
import { centsToDollar } from '../../lib/helpers';

const ProductContentComponent = (props) => {
    const { product } = props;
    const { discountRate } = props;
    const discountedPrice = centsToDollar(product.price*(1-discountRate/1000/100));
    return <div className={'productWrapper'} style={{ display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <img src={'/image/test_kit.png'} alt="iHealth Covid Test Kit" width={200}/>
            <div style={{ fontWeight:'bold',fontSize:'16px',lineHeight:'26px',color: '#2D3238',textAlign:'start',paddingTop:10 }}>
                <div>{product.sku_name||product.name}</div>
                <div>Self-Test at Home. Result in 15 min.</div>
                <div> <span>{`${centsToDollar(product.price)}`}</span>{`/Pack (2 Tests)`}</div>
                { discountRate ? <div className={'discountDiv'}><span>{`${ -discountRate/1000}%  ${discountedPrice}`}</span></div>:'' }
            </div>
        </div>
}

export default ProductContentComponent;