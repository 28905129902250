import React from 'react';
import { formMap } from '../constant/signUpForm';
import {Form, Row, Col, Alert} from "antd";
import '../style/index.scss';
import 'antd/lib/col/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/css';
import 'antd/lib/button/style/index.css';
import TermAndPolicyComponent from '../component/TermAndPolicyComponent';
import SignUpButtonComponent from '../component/SingUpButtonComponent'
function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}
const errorMap = {
	'409':'Email already exists'
}
let SignUpFormComponent = class extends React.Component{
	constructor() {
		super();
		this.state ={
			confirmDirty: false,
			errors: null
		}
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
			}
			window.location = '/signupSuccees'
		});
	};

	formValidation = () => {
		return new Promise((resolve,reject) => {
			this.props.form.validateFields((err, values) => {
				console.log(err,values);
				if(err) reject(err);
				resolve(values);
			});
		})
	};

	setErrors = (errors)=>{
		this.setState({
			errors
		})
	}

	renderForm = ()=>{
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
		const { errors } = this.state;
		let formItemList = [];
		const disabled = hasErrors(getFieldsError());
		for(let [index,array] of Object.entries(formMap(this))) {
			let itemsInRow = [];
			if(Array.isArray(array)){
				for(let [colIndex,item] of Object.entries(array)) {
					let { rules,render,col,key,label,offset } = item;
					itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
						<Form.Item label={label} key={key}>{ getFieldDecorator(key,{ rules } )(render()) }</Form.Item>
					</Col>)
				}
			}
			formItemList.push(<Row key={index}>{itemsInRow}</Row>)
		}

		return <Form id={'singUpForm'} layout={'horizontal'} onSubmit={this.handleSubmit}>
				{formItemList}
				<Form.Item>
					{ !!errors && <Alert message={errorMap[errors]} type="error" showIcon  style={{ marginBottom: 15 }}/> }
					<SignUpButtonComponent formValidation={this.formValidation} setErrors={ this.setErrors}/>
				</Form.Item>
				<TermAndPolicyComponent/>
			  </Form>;

	}



	render() {
		const { renderForm } = this;
		return <div className={'singUpFormOutter'}>
					{renderForm()}
			   </div>
	}

}
SignUpFormComponent = Form.create({ name: 'singUpForm' })(SignUpFormComponent);

export default SignUpFormComponent;