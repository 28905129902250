import React, {useState} from 'react';
import {Tabs, Modal} from "antd";
import SendResetPwdCodeComponent from "../../Login/component/SendResetPwdCodeComponent";
import ConfirmChangePwdComponent from "../component/ConfirmChangePwdComponet";
const { TabPane } = Tabs;

export const ForgotPasswordContainer = (props)=>{
	const [ activeKey, setActiveKey ] = useState('1');
	const [ email,setEmail ] = useState('');
	const { setShowRestPwd } = props;
	return <Modal visible={true} onCancel={()=>setShowRestPwd(false)} footer={null} className={'sendResetPwd'} title={'Reset Password'}>
		  <Tabs defaultActiveKey={activeKey} activeKey={activeKey}>
				<TabPane tab={'1'} key={'1'}>
					<SendResetPwdCodeComponent {...props} setActiveKey={setActiveKey} email={email} setEmail={setEmail}/>
				</TabPane>
			    <TabPane tab={'2'} key={'2'}>
				   <ConfirmChangePwdComponent email={email} {...props}/>
			    </TabPane>
		  </Tabs>
	</Modal>
};