import React, { useState } from "react";
import UserInformationContainer from '../../BasicInfo/container/UserInformationContainer';
import { Input, Button, message } from "antd";
import '../style/index.scss';
import Validator from "../../utils/Validator";
import { useAuth } from "../../auth";
import { get } from "lodash";
import { setLoginToken } from "../../auth/helper";
import TesterStatusComponent from '../../Video/components/TesterStatusComponent';
import OutOfRangeComponent from '../../schedule/component/OutOfRangeComponent'
import * as Cookies from "js-cookie";
const EnterTicketNumberComponent = (props) => {
	const { tester, improperTimeVisit, processTesterCode, codeError, setCodeError, getSessionStatus, status, setStatus,
		summary, setSummary, timer, setTimer, code, operator, setOperator,serverTime,setServerTime } = props;
	const [ticketNum, setTicketNum] = useState(code || '');
	const [error, setError] = useState('');
	// const [ summary,setSummary ] = useState();
	// const [ timer,setTimer ] = useState();
	let auth = useAuth();
	const renderTitle = () => {
		return <div className={'title'}>
			<div style={{ textAlign: 'center' }}>
				<span >Welcome to iHealth</span>
				<p>Verified COVID-19 Test Service</p>
			</div>
		</div>
	}
	const validate = (val) => {
		setError('');
		if (Validator.isNonEmpty(val.trim())) {
			setError('Cannot be empty!');
			return false;
		}
		else if (Validator.minLength(val.trim(), 3)) {
			setError('No shorter than 3!');
			return false
		}

		return true;
	}
	const renderSubtitle = () => {
		return <div className={'subtitle'}>
			<span>Please enter the ticket number that we sent to you in the service purchase confirmation email.</span>
		</div>
	}
	const onChangeInput = (ticketNum) => {
		setTicketNum(ticketNum);
		validate(ticketNum);
	}
	const renderInput = () => {
		return <div >
			{/* Ticket number */}
			<Input value={ticketNum} onChange={(e) => onChangeInput(e.target.value)} className={codeError && 'hasError'} style={{ height: 44, marginTop: 5 }} />
			<div className={'codeError'}>{codeError}</div>
		</div>
	}

	const handleOnClick = async () => {
		processTesterCode(ticketNum);
	}

	const showError = () => {
		return <span>{error}</span>
	}
	const renderBtn = () => {
		const disabled = !ticketNum.length || error.length;

		return <div style={{ textAlign: 'center' }}>
			<Button className={'ihealth'} disabled={disabled} onClick={handleOnClick}>Confirm</Button>
		</div>
	}
	if (status) {
		return <TesterStatusComponent operator={operator} setOperator={setOperator} code={ticketNum} timer={timer} serverTime={serverTime} setServerTime={setServerTime}
		setTimer={setTimer} summary={summary} status={status} getSessionStatus={getSessionStatus} setStatus={setStatus} />
	}
	if (improperTimeVisit) {
		const { ScheduledAt,TimezoneLabel,Timezone } = improperTimeVisit||{};
		const params = {
			...tester,
			selectedTimezone : {
				offset : Timezone,
				label : TimezoneLabel,
			},
			selectedSlot : {
				timeSlot : ScheduledAt,
			},
			code : ticketNum,
		}
		return <OutOfRangeComponent {...params} />
	}

	return <div className={'ticketNumWrapper'}>
		<div className={'content'}>
			{renderTitle()}
			<div className={'optContainer'} style={{padding: '0 10px'}}>
				{renderSubtitle()}
				{renderInput()}
				{showError()}
				{renderBtn()}
			</div>
		</div>
	</div>
}
export default EnterTicketNumberComponent;