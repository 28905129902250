import React, {useState,useEffect} from "react";
import SingleOrderDetailComponent from "../component/SingleOrderDetailComponent";
import OrderInvoiceDetailComponent from '../component/OrderInvoiceDetailComponent';
import {useNavigate,useLocation} from "react-router-dom";
import { getSubOrderList,getOrderPriceDetails } from '../../API/query/Order';
import { get } from 'lodash';
import { Icon } from 'antd';
const SingleOrderDetailContainer = (props) =>{
	const { hideBackBtn, total=0 } = props;
	const [ subOrderDetails,setSubOrderDetails ] = useState();
	const [ bill,setBill ] = useState();
	const [page, setPage] = useState(1);
	const {state} = useLocation();
	let orderNumber = get(state,'orderNumber',get(props,'orderNumber'));
	const navigate = useNavigate();
	useEffect( ()=>{
		const doFetch = (async ()=>{
			try {
				const subOrderListRes = await getSubOrderList(orderNumber, page);
				const orderPriceRes = await getOrderPriceDetails({objectType:'order',id:orderNumber});
				const bill = get(orderPriceRes,'data.data.bill');
				setSubOrderDetails(get(subOrderListRes,'data', {}));
				setBill(bill);
			}catch (e) {
				console.log(e);
			}

		})
		doFetch();
	},[page]);
	const backBtn = ()=>{
		return <Icon type="left-square" onClick={()=>navigate('/orders')} style={{ fontSize:28 }}/>
	}

	return <div className={'singleOrderWrapper'}>
			  { !hideBackBtn&&backBtn() }
			  {/*{ renderOrderNumber() }*/}
			  <OrderInvoiceDetailComponent orderId={orderNumber}/>
			  <SingleOrderDetailComponent page={page} setPage={setPage} total={subOrderDetails?.summary?.totalCount || total} orderList={subOrderDetails?.data || props.subOrderList}/>
		   </div>
}

export default SingleOrderDetailContainer;