import {getRequest, postRequest} from "../helper";
import {getLoginUser} from "../../auth/helper";

const HEADER =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})

const FETCH_TIME_SLOT_URL = `/api/proctoring/admin/fetch-time-slots`;
const UPDATE_TIME_SLOT_URL =`/api/proctoring/admin/update-time-slots`;

export const getTimeSlots = (data)=>postRequest(FETCH_TIME_SLOT_URL,{ headers: HEADER(),data });
export const updateTimeSlot = (data)=>postRequest(UPDATE_TIME_SLOT_URL,{ headers:HEADER(),data });