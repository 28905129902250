import React from "react";
import * as Cookies from "js-cookie";
import "./meeting.scss";
import AgoraVideoCall from "../../Video/components";
import TimerContainer from "../../Video/container/TimerContainer";
import JoiningComponent from "../../Video/components/JoiningComponent";
import { changeProctorMutation } from '../../API/query/proctor';
import { HANGED_WAITING_MSG } from "../../Video/constant";
import AudioPlayerComponent from '../../Video/components/AudioPlayerComponent';
import {Call} from "../../Video/components/Testcall";
import '../../Video/style/index.scss';
import { get } from 'lodash';
class Meeting extends React.Component {
  constructor(props) {
    super(props);
    const { appId, uid, code, token, status } = props;
    this.videoProfile = "vp8";
    this.channel = code.toLowerCase();
    this.transcode = "vp8";
    this.attendeeMode = "video";
    this.baseMode = "avc";
    this.appId = appId;
    this.uid = uid;
    this.token = token;
    this.timeoutChangeProctor = null;
    this.timeoutUpdateStatus = null;
    if (!this.appId) {
      return alert("Get App ID first!");
    }
  }

  // componentDidMount() {
  //   document.getElementsByClassName("PHASE_ONE_ONGOING")[0].addEventListener('touchstart',()=>{
  //     let chatIcon = document.getElementsByClassName('chatbox')[0];
  //     if(chatIcon) {
  //       let chatClassList = get(chatIcon,'classList',[]);
  //       let iconShown = chatClassList.contains('show');
  //       let video = document.getElementById('ag-canvas');
  //       if (video&&!iconShown) {
  //         video.classList.add('withChat')
  //       }
  //     }
  //   })
  // }

  componentDidUpdate() {
    const { proctorStatus, testerStatus } = this.props;
    if (proctorStatus === 'OFFLINE' && testerStatus === 'ONLINE' && this.canChangeProctor()) {
      this.startTimeoutChangeProctor();
    }
    if (!!this.timeoutChangeProctor && (!this.canChangeProctor() || proctorStatus === 'ONLINE' 
      || proctorStatus === 'HUNGUP' || this.getProctorId() < 0 || testerStatus !== 'ONLINE')) {
      this.clearTimeoutChangeProctor();
    }
    if (this.needUpdateStatus()) {
      this.setTimeoutUpdateStatus();
    }
    if (!!this.timeoutUpdateStatus && !this.needUpdateStatus()) {
      this.clearTimeoutUpdateStatus();
    }
  }

  needUpdateStatus() {
    const { proctorStatus, status, operator=-1 } = this.props;
    const onlineUpdateStatus = ['PHASE_ONE_WAITING', 'PHASE_ONE_COMPLETED', 'PHASE_TWO_WAITING'];
    const offlineUpdateStatus = ['PHASE_ONE_ONGOING', 'PHASE_TWO_ONGOING', 'RESULT_SUBMITTED'];
    return (proctorStatus === 'ONLINE' && (operator < 0 || onlineUpdateStatus.some(field => field === status))) || 
      (proctorStatus === 'OFFLINE' && offlineUpdateStatus.some(field => field === status));
  }

  setTimeoutUpdateStatus() {
    if (!this.timeoutUpdateStatus) {
      this.timeoutUpdateStatus = setTimeout(async () => {
        if (this.needUpdateStatus()) {
          const { setStatus, getSessionStatus, setLoading, fetchAndSetStatus, setOperator, timer, setTimer, serverTime, setServerTime } = this.props;
          try {
            await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, false, { timer, setTimer, serverTime, setServerTime });
          } catch (e) {
            console.log(e);
          }
        }
      }, 1000);
    }
  }

  clearTimeoutUpdateStatus() {
    clearTimeout(this.timeoutUpdateStatus);
    this.timeoutUpdateStatus = null;
  }

  getProctorId() {
    const {operator=-1} = this.props;
    let proctorIdFromCookie = Cookies.get('proctorId');
    const proctorId = operator > 0 ? operator : proctorIdFromCookie;
    return proctorId || -1;
  }

  clearTimeoutChangeProctor() {
    clearTimeout(this.timeoutChangeProctor);
    this.timeoutChangeProctor = null;
  }

  startTimeoutChangeProctor() {
    if (!this.timeoutChangeProctor) {
      this.timeoutChangeProctor = setTimeout(async () => {
        const { setStatus, getSessionStatus, setLoading, fetchAndSetStatus, setOperator, setProctorStatus, timer, setTimer, serverTime, setServerTime } = this.props;
        const proctorId = this.getProctorId();
        if (this.canChangeProctor() &&  proctorId > 0) {
          setProctorStatus('CHANGING');
          try {
            await changeProctorMutation(proctorId);
            await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, true, { timer, setTimer, serverTime, setServerTime });
          } catch (e) {
            console.log(e);
            await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, true, { timer, setTimer, serverTime, setServerTime });
          }
        }
      }, 120000);
    }
  }

  componentWillUnmount() {
    if (!!this.timeoutChangeProctor) {
      this.clearTimeoutChangeProctor();
    }
    if (!!this.timeoutUpdateStatus) {
      this.clearTimeoutUpdateStatus();
    }
  }

  canChangeProctor = () => {
    const { status } = this.props;
    const statusChangeProctor = ['PHASE_ONE_ONGOING', 'PHASE_TWO_ONGOING', 'RESULT_SUBMITTED'];
    return statusChangeProctor.some(field => field === status);
  }

  waitingContent() {
    const { proctorStatus, operator = -1 } = this.props;
    let { waitingMsg } = this.props;
    waitingMsg = (proctorStatus === 'CHANGING' || proctorStatus === 'HUNGUP') ? HANGED_WAITING_MSG : waitingMsg;
    let shoWaitingMsg = operator === -1 ?
      <div style={{ maxWidth: '340px' }}>
        <span>{waitingMsg}</span>
        <img alt='loading...' src='/image/hourglass.gif' style={{width: '2em'}}/>
        <AudioPlayerComponent audioSrc={'/sound/waiting.m4a'}/>
      </div>
      : ' ';
    return <div style={{ height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 16 }}>
      {shoWaitingMsg}
    </div>;
  }

  rightContent = () => {
    const { status, timer, code, serverTime } = this.props;
    const statusMap = {
      PHASE_ONE_ONGOING: this.waitingContent(),
      PHASE_ONE_COMPLETED: timer && <TimerContainer completedAt={timer} status={status} code={code} serverTime={serverTime}/>,
      PHASE_TWO_WAITING: <JoiningComponent code={code} status={status} />,
      PHASE_TWO_ONGOING: this.waitingContent(),
      RESULT_SUBMITTED: this.waitingContent(),
    }
    return statusMap[status];
  }

  renderProctorIcon = () => {
    const { operator = -1, proctorStatus } = this.props;
    const proctorIconMap = {
      ONLINE: <img src={'/image/proctor.png'} alt='proctor online' />,
      OFFLINE: <img src={'/image/proctor_offline.png'} alt='proctor offline' />,
      CHANGING: <div />,
      HUNGUP: <div />,
    }
    return operator !== -1 && this.canChangeProctor() ? proctorIconMap[proctorStatus] : <div />;
  }

  render() {
    const { proctorStatus, setProctorStatus, testerStatus, setTesterStatus, wsclosed,status } = this.props;
    return (
      <div className="wrapper meeting">
        <div className="ag-main">
          <div className="ag-container">
            {this.props.appId ?
                <Call appId={this.appId}
                uid={this.uid}
                token={this.token}
                channel={this.channel}
                role={'TESTER'}
                status={status}
                setProctorStatus={setProctorStatus}
                proctorStatus={proctorStatus}
                setTesterStatus={setTesterStatus}
                testerStatus={testerStatus}
                wsclosed={wsclosed}
                />
            : ''

            }
          </div>
          <div className={'rightContainer'} id='rightContainer'>
            <div className={'timerContainer'}>
              {this.rightContent()}
            </div>
          </div>
        </div>
        <div id={'audioPlayer'}>
          {this.renderProctorIcon()}
        </div>
      </div>
    );
  }
}

export default Meeting;
