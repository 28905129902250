import React from "react";
import UploadFileComponent from './UploadFileComponent';
import PeopleListComponent from "./PeopleListComponent";
import { createOrderMutation,updateOrderMutation } from "../../API/query/Order";
import moment from "moment";
import {Input, Icon, Button,Select,Row,Col,message,Switch} from 'antd';
import '../style/index.scss';
import { get,isEmpty } from 'lodash';
import PaymentDetailsContainer from "../../payment/container/PaymentDetailsContainer";
import Step2Content from "./Step2";
import { connect } from "react-redux"
import { setSubscription, setOrderDetails } from '../../redux/actions/orderActions';
import StepFourComponent from "./CreateOrder/StepFourComponent";
const { Option } = Select;

const ImportPeopleComponent = class extends React.Component{
	constructor(props) {
		super(props);
		const { orderDetails,skuList } = props;
		const orderName = get(orderDetails,'name',null);
		const orderCount = get(orderDetails,'quantity',1);
		const peopleList = get(orderDetails,'peopleList',1);
		const contactGroupId = get(orderDetails,'contactGroupId',null);
		const sku = get(orderDetails,'sku',get(skuList,'0.sku'));
		const status = get(orderDetails,'status');
		this.state = {
			peopleList,
			orderName,
			orderCount,
			contactGroupId,
			freq: 1,
			file: null,
			unit:'week',
			sku,
			status,
			fileError: [],
			subscriptionStartDate: moment().add('4','day'),
		}
	}
	requiredFieldsFinished = ()=>{
		const { orderName,orderCount,contactGroupId } = this.state;
		let allFinished = orderName && orderCount &&contactGroupId
		return allFinished;
	}
	stepOne = ()=>{
		const { orderName } = this.state;
		return <div className={'step'}>
				<div><span className={'header'}>Step 1: Create a group order name</span></div>
				<div><span style={{ lineHeight:2 }}>Please give your group order an intuitive name for easier reference.</span></div>
				<div style={{ display:'flex', flexDirection:'column', maxWidth: 360,marginTop:10 }}>
					<span className={'label'}>Group order name</span>
					<Input onChange={(e)=>this.setState({ orderName:e.target.value })} value={orderName}/>
					{orderName? '': <span className={'exampleOrderName'}>For example, "Order for my employees"</span>}
				</div>
		</div>
	}
	setFile = (file)=>{
		this.setState({
			file
		})
	}

	setOrderCount = (orderCount)=>{
		this.setState({
			orderCount
		})
	}

	stepTwo = ()=>{
		const { setPeopleList,setFileError } = this;
		const { peopleList } = this.state;
		return <div className={'step'}>
			<div><span className={'header'}>Step 2: Add recipients</span></div>
			<div style={{ display:'flex',flexDirection:'column'}}>
				<Step2Content/>
				<div style={{display:'flex'}}>
					 <UploadFileComponent setPeopleList={setPeopleList} setOrderId={this.setOrderId} setFileError={setFileError}
					                      peopleList={ peopleList } setFile={this.setFile} file={this.state.file}/>
					<Button className={'downloadBtn'}><a href="/files/template.xlsx" download>Download Excel template</a></Button>
				</div>
			</div>
		</div>
	}

	renderCountDropDown = ()=>{
		let nums = [];
		for(let i=1;i<=5;i++){
			nums[i-1] = i;
		}
		return <Select style={{ width:100 }} onChange={(e)=>this.setState({ orderCount:e })}>
			{nums.map(n=><Option  key={n} value={n}>{n}</Option>)}
		</Select>
	}

	setOrderId = (contactGroupId)=>{
		this.setState({
			contactGroupId
		})
	}

	setPeopleList= (peopleList)=>{
		this.setState({
			peopleList:[...peopleList]
		})
	}


	setUnit = (unit)=>{
		this.setState({
			unit
		})
	}
	setFileError = (fileError)=>{
		this.setState({
			fileError
		})
	}
	setFreq = (freq)=>{
		this.setState({
			freq
		})
	}

	setStartDate = (subscriptionStartDate)=>{
		this.setState({
			subscriptionStartDate
		})
	}

	componentWillUnmount() {
		this.props.setSubscription({subscription:null});
	}

	stepFour = ()=>{
		const { setFreq,setUnit,setStartDate,setOrderCount } = this;
		const { contactGroupId,orderCount,freq,unit } = this.state;
		const { orderType,setOrderType,orderId,orderDetails } = this.props;
		return <div className={'step'}>
			<Row>
				<Col span={24}><span className={'header'}>Step 3: Select your plan</span></Col>
			</Row>
			<Row>
				<span>Please select your reorder quantity and interval that fits your organization's needs. <strong>(Note: 2 tests/pack)</strong></span>
			</Row>
			<Row style={{padding:'20px 0px'}}>
				<Switch defaultChecked onChange={setOrderType} checked={orderType=='subscription'} className={'orderTypeSwitch'}/>
				<span style={{marginLeft:20}}>Enjoy <strong>10% off</strong> and save time by subscribing and send test kits to recipients regularly.</span>
			</Row>
				<StepFourComponent orderType={orderType} setFreq={setFreq} orderCount={orderCount}  frequency={freq} frequencyUnit={unit}
				                   setUnit={setUnit} setOrderCount={setOrderCount} setStartDate={setStartDate}/>
			<Row>
				<Col>{   this.requiredFieldsFinished() ?
					<PaymentDetailsContainer
						contactGroupId={contactGroupId}
						setType={setOrderType}
						setFreq={setFreq}
						orderDetails={orderDetails}
						setUnit={setUnit}
						orderId={orderId}
						setStartDate={setStartDate}
						type={orderType}
						orderCount={orderCount}
					/> :''
				}
				</Col>
			</Row>
		</div>
	}


	renderFileErrors = ()=>{
		let { fileError } = this.state;
		if(!fileError.length) return <div></div>
		fileError = fileError.sort((a,b)=>a['lineNumber']-b['lineNumber']);
		return <div>
			<div className="fileError" style={{ color:'red',width:750,paddingLeft:50,marginBottom:20,display:'flex'}}>
				<div><Icon type={'info-circle'} style={{marginRight: 10}}/></div>
				In your uploaded file, there are several places that the format is invalid.
				Please refer to the list below,  modify the information and upload again.
			</div>
			{fileError.map(e=>{
			const { lineNumber, warnings } = e;
			return <Row style={{ paddingLeft: 50,marginBottom:10,color:'red' }}>
				<Col span={4}>
					line number:{lineNumber+1}
				</Col>
				<Col span={10}>
					{
						Object.keys(warnings).map(w=>{
							return <div>{`${w}: ${warnings[w].error}`}</div>
						})
					}
				</Col>
			</Row>
		})}</div>

	}

	renderTable = ()=>{
		const { peopleList } = this.state;
		return !!peopleList.length && <PeopleListComponent peopleList={peopleList} activeKey={this.props.activeKey}/>
	}

	createOrder = ()=>{
		const { orderName,orderCount,contactGroupId,unit,freq,subscriptionStartDate,peopleList } = this.state;
		const { setOrderId,setCreationOrderInfo,orderType } = this.props;
		const date = moment().format('YYYY-MM-DD');
		if (orderType == 'order') {
			const data = {contactGroupId: contactGroupId, name: orderName, quantity: parseInt(orderCount), date}
			createOrderMutation(data).then(res => {
				const orderId = get(res, 'data.data.id');
				setOrderId(orderId);
				setCreationOrderInfo({ 'order': orderId });
				message.info({content:'Draft order created', duration:5});
			});
		}

		if (orderType === 'subscription') {
			const data = {
				contactGroupId: contactGroupId,
				name: orderName,
				quantity: parseInt(orderCount),
				date,
				frequency: freq,
				frequencyUnit: unit,
				startDate: subscriptionStartDate.valueOf(),
				taxDetails: peopleList.map(p=>({ ...p,quantity:parseInt(orderCount)}))
			}

			this.props.setSubscription({subscription:data});
			// createSubscriptionMutation(data).then(res => {
			// 	const orderId = get(res, 'data.data.id');
			// 	setOrderId(orderId);
			// 	setCreationOrderInfo({ 'subscription':orderId });
			// 	message.info('Subscription Created')
			// });
		}
	}

	updateOrder = async (type,orderId)=>{
		const { orderName,orderCount,contactGroupId,unit,freq,subscriptionStartDate,peopleList,sku,status } = this.state;
		const { setActiveKey,setOrderId } = this.props;
		const date = moment().format('YYYY-MM-DD');
		if (type == 'order'&&status!=='FAILED') {
			const data = {contactGroupId: contactGroupId, name: orderName, quantity: parseInt(orderCount),sku}
			await updateOrderMutation(orderId,data)
			this.props.setSubscription(null);
		}
		if (type === 'subscription') {
			const data = {
				contactGroupId: contactGroupId,
				name: orderName,
				quantity: parseInt(orderCount),
				frequency: freq,
				frequencyUnit: unit,
				startDate: subscriptionStartDate.valueOf(),
				taxDetails: peopleList.map(p=>({ ...p,quantity:parseInt(orderCount)}))

		}
			this.props.setSubscription(data);
			// await updateSubscriptionMutation(orderId,data)
			message.info('Subscription Updated')
		}
	}


	handleMakePayment =async ()=>{
		const { setActiveKey,creationOrderInfo,orderType } = this.props;
		try {
			//if there is no creation yet.
			if(isEmpty(creationOrderInfo)) {
				this.createOrder();
			}else{
				if(creationOrderInfo[orderType]) {
					await this.updateOrder(orderType,creationOrderInfo[orderType]);
				}else{
					this.createOrder();
				}
			}
		}catch (e) {

		}finally {
			setActiveKey('2');

		}
	}

	renderFooter = ()=>{
		let { orderName, orderCount, contactGroupId } = this.state;
		let disabled = !this.requiredFieldsFinished();
		return <div className={'step'} style={{textAlign:'center'}} >
			<Button className={'makePaymentBtn'} disabled={disabled} onClick={ this.handleMakePayment }>
				{'Go to payment'}
			</Button>
		</div>
	}
	renderHeader = ()=>{
		return <div style={{ textAlign:'center',fontSize:24,lineHeight:'24px',fontWeight: 'bold' }}>
					<span>Make an Order</span>
			   </div>
	}
	render() {
		const { stepOne,stepTwo,stepFour,renderFooter,renderTable,renderFileErrors,renderHeader} = this;
		const { status } = this.state;
		return <div className={'importContent'+(status==='FAILED' ? ' disableEdit':'')}>
			<h2 style={{textAlign: 'center', marginTop: 35, fontSize: '24px'}}>Make an order</h2>
			{ stepOne() }
			{ stepTwo() }
			{ renderTable() }
			{ renderFileErrors() }
			{ stepFour() }
			{ renderFooter()}
		</div>;
	}
}
const mapToProps = (props)=>{
	return {
		subscription: get(props,'orderReducer.subscription'),
	}
}

export default connect(mapToProps,{ setSubscription, setOrderDetails })(ImportPeopleComponent);