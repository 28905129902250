import React, {useEffect, useState} from "react";
import {getSessionStatusQuery, getTesterFormQueryWithoutAuth, getVisitQuery} from "../../API/query/schedule";
import {get} from "lodash";
import AppointmentFinishedComponent from "../../schedule/component/AppointmentFinishedComponent";

const TesterCheckReportComponent = (props)=>{
	const { code } = props;
	const [ tester,setTester ] = useState({});
	const [ visit,setVisit ] = useState({});

	const getAdditionalInfo = async ()=>{
		let status = await getSessionStatusQuery(code);
		let visit = await getVisitQuery(code);
		let tester = await getTesterFormQueryWithoutAuth(code);
		let statusInfo = get(status,'data.data',{});
		let visitData = get(visit,'data.data',{});
		let testerInfo = get(tester,'data.data',{});
		if(statusInfo) {
			setTester(statusInfo);
		}
		if(testerInfo) {
			setTester({...statusInfo,...testerInfo});
		}
		if(visitData) {
			setVisit(visitData)
		}
	}

	useEffect(()=>{
		getAdditionalInfo();
	},[]);

	return <AppointmentFinishedComponent visit={visit} tester={tester} header={'My Test Report'}/>

}

export default TesterCheckReportComponent;