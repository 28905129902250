import React, {useEffect} from "react";
import AppointmentDetailComponent from "./AppointmentDetailComponent";
import moment from "moment";

const CancelAppFinishedComponent = (props)=>{
	const { visit={},tester={},header='',setStatus } = props;
	const { ScheduledAt,TimezoneLabel,Timezone } = visit||{};
	const { ExpiredAt } = tester;
	const expiredDateStr = moment(ExpiredAt).format('MM/DD/YYYY');
	return <div className="appointmentScheduledWrapper">
		<div className="header">
			<img src="/image/success.png"  alt="success"/>
			<h1>Cancellation Confirmed</h1>
		</div>
		<div>
			<AppointmentDetailComponent {...tester} selectedSlot={{timeSlot:ScheduledAt}} selectedTimezone={{label:TimezoneLabel,offset:Timezone}} className={'cancelled'}/>
		</div>
		<div style={{ maxWidth:610,fontSize:16 }}>
			Your iHealth Verified COVID Test Appointment has been canceled. Be sure to schedule a new appointment using the same ticket number before <strong>{expiredDateStr}</strong> (6 months since the purchase date).
			<p/>
			Please check your email for more information.
		</div>
	</div>
}
export default CancelAppFinishedComponent;