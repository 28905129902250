import {getRequest, postRequest} from '../helper/index';
import {getLoginUser} from "../../auth/helper";
import {host} from "../config";
const SIGN_UP_URL = '/api/proctoring/redeem-code/verify';
const CODE_LOGIN_URL = '/api/proctoring/redeem-code/login';
const SUBMIT_USER_FORM = '/api/proctoring/testers/form';
const GET_FORM_URL =(code)=>`/api/proctoring/testers/form?code=${code}`
const SESSION_STATUS_URL ='/api/proctoring/testers/status';
const START_VISIT_REQUEST_URL ='/api/proctoring/testers/start-visit'
const RESERVE_VISIT_URL = '/api/proctoring/appoint-visit';
const RESULT_REPORT_URL = (code)=>`${host}/api/proctoring/tester/report/${code}/report.png`
const RATE_URL = `/api/proctoring/testers/rating`;
const HEADER = {
	Accept: '*/*',
	'Content-Type': 'application/json'
}

const HEADER_WITH_AUTH =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})

export const redeemCodeMutation =(parameter)=> postRequest(SIGN_UP_URL,{ headers:HEADER, data:parameter });
export const redeemCodeLogInMutation = (parameter)=>postRequest(CODE_LOGIN_URL,{ headers:HEADER,data:parameter });
export const submitUserFormMutation = (parameter)=> postRequest(SUBMIT_USER_FORM,{ headers: HEADER_WITH_AUTH(),data: parameter});
export const getSessionStatusQuery = (parameter)=>getRequest(SESSION_STATUS_URL,{ headers:HEADER_WITH_AUTH() } );
export const getTesterFormQuery = (code)=>getRequest(GET_FORM_URL(code),{ headers:HEADER_WITH_AUTH() });
export const startVisitMutation = ()=>postRequest(START_VISIT_REQUEST_URL,{ headers: HEADER_WITH_AUTH() });
export const reserveVisitMutation = (data)=>postRequest(RESERVE_VISIT_URL,{ headers:HEADER_WITH_AUTH(),data })
export const getResultReportQuery = (code)=>RESULT_REPORT_URL(code);
export const rateServiceMutation = (data)=>postRequest(RATE_URL,{ headers:HEADER_WITH_AUTH(),data});
