
export const USERKEY_TO_FORMKEY = {
	CompanyName:'companyName',
	CompanyWebsite:'companyWebsite',
	Email:'email',
	FirstName:'firstName',
	LastName: 'lastName',
	PhoneNumber:'phoneNumber',
	Title:'title'
}

export const FORMKEY_TO_USERKEY = (()=>{
	let obj = {};
	for(let [key,value] of Object.entries(USERKEY_TO_FORMKEY)) {
		obj[value] = key ;
	}
	return obj;
})()

export const roleMap = {
	2001:'proctor',
	2002:'proctor',
}
