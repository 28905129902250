import React from "react";
import SubscriptionListComponent from '../component/SubscriptionListComponent';
const SubscriptionContainer = class extends React.Component{
	renderHeader = ()=>{
		return <div className={'header'}>My Subscriptions</div>
	}
	render() {
		const{ renderHeader,props } = this;
		const { subscriptions } = props;
		return <div>
				{renderHeader()}
				<SubscriptionListComponent subscriptions={subscriptions}/>
		</div>
	}
}
export default SubscriptionContainer;