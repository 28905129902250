import { useEffect, useLayoutEffect, useRef } from 'react'

function useInterval(callback: () => void, delay: number | null, firstExec=false) {
	const savedCallback = useRef(callback)
	let id =null;
	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return id;
		}
		if (firstExec){
			savedCallback.current()
		}
		id = setInterval(() => savedCallback.current(), delay)
		return () => clearInterval(id)
	}, [delay])
	return id;

}

export default useInterval