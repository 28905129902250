import { useState, useEffect } from 'react';
import AgoraRTC, {
	IAgoraRTCClient, IAgoraRTCRemoteUser, MicrophoneAudioTrackInitConfig, CameraVideoTrackInitConfig, IMicrophoneAudioTrack, ICameraVideoTrack, ILocalVideoTrack, ILocalAudioTrack } from 'agora-rtc-sdk-ng';
import {q} from "react-payment-inputs/es/utils/validator-0f41e23d";

export default function useAgora(client: IAgoraRTCClient | undefined,role)
	:
	{
		localAudioTrack: ILocalAudioTrack | undefined,
		localVideoTrack: ILocalVideoTrack | undefined,
		joinState: boolean,
		leave: Function,
		join: Function,
		remoteUsers: IAgoraRTCRemoteUser[],
	}
{
	const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
	const [localAudioTrack, setLocalAudioTrack] = useState(undefined);
	const [ downlinkNetworkQuality, setDownlinkNetworkQuality] = useState(0);
	const [uplinkNetworkQuality,setUplinkNetworkQuality] = useState(0);

	const [joinState, setJoinState] = useState(false);

	const [remoteUsers, setRemoteUsers] = useState([]);
	const [ muted,setMuted ] = useState(false);
	async function createLocalTracks(audioConfig?: MicrophoneAudioTrackInitConfig, videoConfig?: CameraVideoTrackInitConfig) {
		const createFn = async (role)=>{
			let microphoneTrack = null;
			let cameraTrack = null;
			if(role=='TESTER') {
				[microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(videoConfig, audioConfig);
			}
			if(role=='PROCTOR') {
				microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);
			}
			return [microphoneTrack,cameraTrack];
		}
		const [microphoneTrack, cameraTrack] = await createFn(role);
		setLocalAudioTrack(microphoneTrack);
		setLocalVideoTrack(cameraTrack);
		return [microphoneTrack, cameraTrack];
	}

	async function join(appid: string, channel: string, token?: string, uid?: string | number | null) {
		if (!client) return;
		const [microphoneTrack, cameraTrack] = await createLocalTracks({AEC:true});
		await client.join(appid, channel, token || null,uid);
		const publishFn = {
			'TESTER': (microphoneTrack,cameraTrack)=>client.publish([microphoneTrack, cameraTrack]),
			'PROCTOR': (microphoneTrack,cameraTrack)=>client.publish([microphoneTrack])
		}
		await publishFn[role](microphoneTrack,cameraTrack);

		window.client = client;
		window.videoTrack = cameraTrack;

		setJoinState(true);

	}
	async function controlAudio(){
		if(localAudioTrack) {
			if(localAudioTrack.muted) {
				await localAudioTrack.setMuted(false);
			}else{
				await localAudioTrack.setMuted(true);
			}
			setMuted(localAudioTrack.muted);
		}
	}
	async function leave() {
		if (localAudioTrack) {
			localAudioTrack.stop();
			localAudioTrack.close();
		}
		if (localVideoTrack) {
			localVideoTrack.stop();
			localVideoTrack.close();
		}
		setRemoteUsers([]);
		setJoinState(false);
		await client?.leave();
	}

	useEffect(() => {
		if (!client) return;
		setRemoteUsers(client.remoteUsers);

		const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
			await client.subscribe(user, mediaType);
			// toggle rerender while state of remoteUsers changed.
			setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
		}
		const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
			setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
		}
		const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
			setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
		}
		const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
			setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
		}
		const handleNetworkQualityChange = (state)=>{
			console.log(state);
		}
		client.on('user-published', handleUserPublished);
		client.on('user-unpublished', handleUserUnpublished);
		client.on('user-joined', handleUserJoined);
		client.on('user-left', handleUserLeft);
		client.on("network-quality", (stats) => {
			setUplinkNetworkQuality(stats.downlinkNetworkQuality);
			setDownlinkNetworkQuality(stats.uplinkNetworkQuality);
		});

		return () => {
			client.off('user-published', handleUserPublished);
			client.off('user-unpublished', handleUserUnpublished);
			client.off('user-joined', handleUserJoined);
			client.off('user-left', handleUserLeft);
			client.off('network-quality',handleNetworkQualityChange);
		};
	}, [client]);

	return {
		localAudioTrack,
		localVideoTrack,
		joinState,
		leave,
		join,
		remoteUsers,
		muted,
		controlAudio,
		downlinkNetworkQuality,
		uplinkNetworkQuality
	};
}