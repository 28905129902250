import React from "react";
import {Button, Col, Radio, Row, Form, message} from "antd";
import {REPORT_USER_FORM} from "../../BasicInfo/constant/userForm";
import {get} from 'lodash';
import RadioGroup from "antd/es/radio/group";
import {getReportHistoryByCode, sendReportMutation} from '../../API/query/report';
import ReportHistoryComponent from "./ReportHistoryComponent";
import StringFormat from "../../utils/StringFormat";
let SendReportContent = class extends React.Component{
  constructor(props) {
		super(props);
	    const result = get(props, 'testerInfo.result');
	    const { disableForm } = props;
	    this.state = {
	      result: disableForm ? result: null,
	      sending: false,
	      resultSubmitted: false,
		  list :[]
	    };
  }

  fetchReportHistory = async()=>{
	  if(!this.props.disableForm) {
		  const code = get(this.props, 'testerInfo.code');
		  try {
			  let res = await getReportHistoryByCode(code);
			  if (res.data.code == 200) {
				  this.setState({
					  list: [...get(res, 'data.data')]
				  })
			  }
		  } catch (e) {

		  }
	  }
  }

  componentDidMount() {
	  this.fetchReportHistory();
  }

	renderForm = ()=>{
		const { getFieldDecorator, getFieldError } = this.props.form;
    const { disableForm } = this.props;
		let formItemList = [];
		for(let [index,array] of Object.entries(REPORT_USER_FORM(this))) {
			let itemsInRow = [];
			if(Array.isArray(array)){
				for(let [colIndex,item] of Object.entries(array)) {
					let { rules,render,col,key,label,offset,dataTransfer } = item;
					const error = getFieldError(key);
					let data = get(this.props.testerInfo, key, '');
					let initialValue = dataTransfer ? dataTransfer(data) : data;
					itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
						<Form.Item validateStatus={error ? 'error':''} help={error || '' }label={label}>{ getFieldDecorator(key,{initialValue, rules } )
						(render({disabled: disableForm})) }
						</Form.Item>
					</Col>)
				}
			}
			formItemList.push(<Row key={index}>{itemsInRow}</Row>)
		}
		return <React.Fragment>
			<Form className={'testerInfoForm'}>
			{formItemList}
		</Form></React.Fragment>;
	}
  resultContent = () => {
    const {result} = this.state;
    const { disableForm } = this.props;
		const resultArray = [{
			key: 'NEGATIVE',
			value: 'NEGATIVE',
			label: 'Negative',
			style:{ background:'#3BA767',color:'#3BA767',border:'solid 1px #3BA767' }
		}, {
			key: 'POSITIVE',
			value: 'POSITIVE',
			label: 'Positive'
		}];
		return <div className={'buttonGroup'}>
				<RadioGroup value={result}  className={'resultRadio'} onChange={(e)=>{ if(!disableForm){
					this.setState({result:e.target.value})
				} } }>
				{ resultArray.map(({value,label,key,style})=><div className={value}><Radio className={value.key}value={value} key={key}>{label}</Radio></div>)}
			</RadioGroup>
		</div>
	}
  sendReport = () => {
    const { form } = this.props;
    form.validateFields(async (error, res) => {
    if (!error) {
      const code = get(this.props, 'testerInfo.code');
      const {result} = this.state;
      let birthday = StringFormat.parseDOB(res.birthday);
      let variables = {
        ...res,
        birthday,
        result,
      };
      try {
        this.setState({sending: true});
        await sendReportMutation(code, variables);
        this.setState({resultSubmitted: true});
        message.success('Report has been sent!');
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({sending: false});
      }
    }
  });
  }
  showReportHistory=()=>{
	  const {testerInfo,disableForm} = this.props;
	  const { list } = this.state;
	  return !disableForm&&<React.Fragment>
		        <span>Test report has already been sent to this tester:</span>
		            <ReportHistoryComponent res={list}/>
		        <div style={{marginBottom: 20 }}>If you want to send another report, please send below:</div>
	  </React.Fragment>
  }
  render(){
    const {testerInfo,disableForm} = this.props;
    const {result,sending,resultSubmitted} = this.state;
    const disabled = !result || sending;
    const buttonText = sending ? 'sending...' : resultSubmitted ? 'Resend' : 'Send report to email';
    return <div className={'sendReportContent'}>
      <h3>Tester</h3>
     { this.showReportHistory() }
      <div className={'detail'}>
        <div>Ticket #: {testerInfo.code}</div>
        <div>Phone: {testerInfo.phone}</div>
        <div>Time zone: {testerInfo.timezoneLabel}</div>
        <div>Shopify order #: {testerInfo.transactionId}</div>
      </div>
      <Row className={'form'}>
          <Col span={24}>{this.renderForm()}</Col>
      </Row>
      <div className={'result'}>
        <h3>Test result:</h3>
        <div className={'resultContent'}>
          {this.resultContent()}
	        {
		        !disableForm && <Button className="ihealth" disabled={disabled} onClick={this.sendReport}>
		            {buttonText}
	            </Button>
	        }
        </div>
      </div>
      
    </div>
  }
}
SendReportContent = Form.create()(SendReportContent);
export default SendReportContent;