import React, { useRef, useState } from "react";
import { Modal,Carousel,Result,Button, Row, Col } from 'antd';
import { POP_UP_CONFIG } from '../constant/index';
import 'antd/lib/carousel/style/index.css';
import '../style/index.scss';
import { setOnboardingStatus } from '../../API/query/Onboarding';
import { useDispatch } from "react-redux";
import { setRefresh } from "../../redux/actions/orderActions";

const OnboardingPopUpComponent = (props)=>{
	const {status, setStatus, visibility, setVisibility} = props;
	const carRef = useRef(null);
	const [step, setStep] = useState(0);
	const dispatch = useDispatch();
	const afterChange = (k)=>{
		setStep(k);
	}

	const onFinalStep = (k)=>{
		if(k<POP_UP_CONFIG.length){
			carRef.current.next();
		}else{
			setStep(0);
			setVisibility(false);
			if(status!=="1") {
				setStatus("1");
				setOnboardingStatus({Key:"onboarding", Value:"1"});
			}
		}
	}

	const afterClose = ()=>{
		if(status!=="1") {
			setStatus("1");
			setOnboardingStatus({Key:"onboarding", Value:"1"});
		}
		carRef.current.goTo(0);
		setVisibility(false);
	}

	const fetchOrders = ()=>{
		dispatch(setRefresh());
	}
	
	const showModal = ()=>{
		return <Modal afterClose={afterClose} mask={false} maskClosable={false} closable={step===4||status=="1"} visible={visibility} onCancel={ ()=>setVisibility(false)} footer={null} wrapClassName={'onboardingPopUp'} width={'100%'}>
					<Carousel ref={carRef} dots={false} afterChange={afterChange}>
						{
							POP_UP_CONFIG.map((v,k)=>{
								const { title,subTitle,prev, Next } = v;
								return <div key={k} ><Result key={k} className={'onboardingContent'} icon={<img src={`/image/onboarding_${k+1}.png`} alt="onboarding"/>} title={title} subTitle={subTitle}/>
									<Row type="flex" justify="center" className={'buttonGroup'}>
										<Col>
										{prev && <Button className={'goBack'} onClick={()=>carRef.current.prev()}>{prev}</Button>}
										</Col>
										<Col>
										{typeof Next==='string'?
										<Button className="next" onClick={()=>onFinalStep(k+1)}>{Next}</Button>
										:
										<Next fetchOrders={fetchOrders} callback={()=>onFinalStep(POP_UP_CONFIG.length)} >Click</Next>
										}
										</Col>
									</Row>
								</div>
							})
						}
					</Carousel>
			   </Modal>
	}
	return 	<React.Fragment>
				{ showModal() }
		    </React.Fragment>

}

export default OnboardingPopUpComponent;