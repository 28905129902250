import {useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { message,Modal,Button } from 'antd';
export const CheckoutForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();
	const { fetchPayment } = props;
	const onClick = (event)=>{
		event.preventDefault();
		stripe.confirmSetup({
			elements,
			confirmParams: {
				// Return URL where the customer should be redirected after the PaymentIntent is confirmed.
				// return_url: '/settings',
			},
			redirect:'if_required'
		})
			.then(function(result) {
				if (!result.error) {
					message.info('succeed added');
					fetchPayment()
				}
				else{
					const { type } = result.error;
					if(type=="card_error") {
						Modal.error({
							title: result.error.message
						});
					}
				}
			})
	}
	return (
		<form>
			<PaymentElement/>
			<Button onClick={onClick} className={'ihealth'}>Submit</Button>
		</form>
	);
};