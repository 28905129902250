import React, {useEffect} from 'react';
import TestResultBodyComponent from '../component/TestResultBodyComponent';
import '../style/index.scss';
import TestGroups from '../component/TestGroups';
import TestResultDatePickerController from '../component/TestResultDatePickerController';
import { useDispatch, useSelector } from 'react-redux';
import { getTestGroups } from '../../API/query/TestResults';
import { setTestGroups } from '../../redux/actions/testResultActions';
import { getGroupStats } from '../helper/index';

const TestResultsComponent = () => {
	const dispatch = useDispatch();
    const { testGroups, startTime, endTime } = useSelector(state => state.testResultReducer);

    const fetchTestGroups = async () => {
        const response = await getTestGroups();
        if (response.data.message === 'success' && response.data.data) {
            dispatch(setTestGroups(response.data.data));
			const GroupCode = response.data.data[0].GroupCode;
            getGroupStats(dispatch , {GroupCode, StartTime: startTime, EndTime: endTime});
        }
    };

    useEffect(() => {
        fetchTestGroups();
    }, []);

    if (testGroups && testGroups.length > 0) {
        return <div className={'testResultWrapper'}>
                    <TestResultDatePickerController />
                    <TestGroups />
                    <TestResultBodyComponent />
        </div>
    } else {
        return <div className={'testResultWrapper'}>
                    <p style={{textAlign: 'center'}}>No test group available.</p>
        </div>
    }
}


export default TestResultsComponent;

 