import { postRequest,getRequest } from '../helper/index';
import {getLoginUser} from "../../auth/helper";
const AVAILABLE_SLOTS_URL = '/api/proctoring/fetch-time-slots';
const GET_VISIT_URL = (code)=>`/api/proctoring/appointments?code=${code}`
const GET_TESTER_FORM_WITHOUT_AUTH_URL = (code)=>`/api/proctoring/redeem-code/form?code=${code}`;
const SESSION_STATUS_URL = (code)=>`/api/proctoring/redeem-code/status?code=${code}`;
const CANCEL_APP = `/api/proctoring/cancel-visit`
const HEADER = {
	Accept: '*/*',
	'Content-Type': 'application/json'
}

const HEADER_WITH_AUTH =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})
export const getAvailableSlots =(parameter)=> postRequest(AVAILABLE_SLOTS_URL,{ headers:HEADER, data:parameter });
export const getVisitQuery = (code)=>getRequest(GET_VISIT_URL(code), { headers:HEADER });
export const getTesterFormQueryWithoutAuth = (code)=>getRequest(GET_TESTER_FORM_WITHOUT_AUTH_URL(code), { headers:HEADER });
export const getSessionStatusQuery = (code)=>getRequest(SESSION_STATUS_URL(code),{ headers:HEADER } );
export const mutateCancelVisit = (parameter)=>postRequest(CANCEL_APP,{ headers:HEADER,data:parameter });