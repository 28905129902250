const regExArr = [/\d/, /[a-z]/, /[A-Z]/]; // Per Carmina remove special character requirement IH-1087

export const passwordValidator = (rule,value,cb) => {
	if (!value) {
		cb('Password is Required');
		return;
	}

	if (value.length < 8) {
		cb('Password should be at least 8 character.');
		return;
	}
	const combinations = regExArr.filter(reg => reg.test(value)).length;

	if (combinations !== regExArr.length) {
		cb('*Must contain a number\n' +
			'*Must contain at least 1 lowercase letter\n' +
			'*Must contain at least 1 UPPERCASE letter\n');
		return;
	}
	cb();
};