import * as React from "react";
import { useAuth } from '../../auth';
import { Button,message } from 'antd';
import 'antd/lib/message/style/index.css';
import '../style/index.scss';
import { get } from 'lodash';
import { getUserMutation} from '../../API/query/User';
import {setLoginToken,setUserInfo} from "../../auth/helper";

const logInSucceed = ()=>{
	window.location.href = '/';
}

export default function SingUpButtonComponent(props) {
	const { formValidation,disabled,setErrors } = props;
	let auth = useAuth();
	async function handleSubmit(e){
		e.preventDefault();
		try{
			let { companyName,companyWebsite,password,phoneNumber,title,firstName,lastName,email } = await formValidation();
			let res = await auth.signUp({email,password,companyWebsite,title,firstName,lastName,phoneNumber,companyName});
			let code = res.data.code;
			if(code === 200) {
				const auth = get(res,'data.data.token');
				const userRes = await getUserMutation(auth);
				let userCode = get(userRes,'data.code');
				if(userCode === 200) {
					setLoginToken(auth);
					setUserInfo(get(userRes,'data.data'));
					logInSucceed();
				}
			}else{
				throw res.data.sub_code;
			}
		}
		catch(e){
			const code = get(e,'response.status');
			setErrors(code);
			message.error(e);
		}
	}
	return <Button onClick={handleSubmit} type="primary" id={'singUpButton'} disabled={disabled}>Sign Up and Login</Button>
}