import React from "react";
import { Modal,Button,Tabs } from "antd";
import ImportPeopleContainer from "../container/ImportPeopleContainer";
import MakePaymentComponent from '../component/MakePaymentComponent';
import '../style/index.scss';
import {get} from "lodash";
import {getContactListByGroupId, getOrderById} from "../../API/query/Order";
import OrderConfirmationComponent from "./OrderConfirmationComponent";
import { setOrderDetails } from "../../redux/actions/orderActions";
import {connect} from "react-redux";

const initialState = {
	showExitModal: false,
	activeKey: '1',
	orderId: null,
	orderType:'order',
	creationOrderInfo: { },
}
const MakeOrderButtonComponent = class extends React.Component{
	constructor(props) {
		super(props);
		let { unpaidOrderId } = props
		this.state ={
			showExitModal: false,
			activeKey: '1',
			orderId: unpaidOrderId ? unpaidOrderId : null,
			orderDetails:null,
			orderType:unpaidOrderId ? 'order': 'subscription',
			creationOrderInfo: unpaidOrderId ? { order:unpaidOrderId } : { }
		}
	}
	async componentWillMount() {
		let { unpaidOrderId } = this.props;
		if(unpaidOrderId) {
			const orderDetails = get(await getOrderById(unpaidOrderId),'data', { });
			const peopleList = get(await getContactListByGroupId(orderDetails.contactGroupId, 1),'data.data',[]);
			this.setState({
				orderDetails:{
					...orderDetails,
					peopleList: peopleList
				}
			})
		}

	}
	setShowMakeOrder = (showMakeOrder)=>{
		let extra = !showMakeOrder ? initialState : { };
		this.props.setShowMakeOrder(false);
		this.props.fetchOrders();
	}

	handleExit = (showExitModal)=>{
		if (this.state.activeKey=='3') {
			this.setShowMakeOrder(false);
			this.props.setOrderDetails(null);
		}
		else {
			this.setState({
				showExitModal
			})
		}

	}

	closeButton = ()=>{
		const { handleExit,setShowMakeOrder } = this;
 		const { showExitModal ,activeKey } = this.state;
		const footer = ()=>{
			return <div style={{ display:'flex',justifyContent:'flex-end' }}>
				<Button className={'okButton'} onClick={()=>handleExit(false)}>Stay on the page</Button>
				<Button className={'cancelButton'} onClick={()=>{
					handleExit(false);
					setShowMakeOrder(false);
					this.props.setOrderDetails(null);

				}}>Yes, exit now</Button>
			</div>
		}
		const subTitle = {
			1:'By exiting the page, the current information won’t be saved.',
			2:'By exiting the page, the current information won’t be saved.',
			3:''
		}
		
		return <div className={'closeButtonWrapper'}>
				<Modal visible={showExitModal} title={'Are you sure to exit ?'} style={{ zIndex:1000 }}
				       className={'exitConfirm'}
				       footer={false}
				       keyboard={false}
				       onCancel={()=>this.handleExit(false)}
				       maskClosable={false}>

					<div style={{ height: 50,display:'flex',alignItems:'center',textAlign:'start' }}>{subTitle[activeKey]}</div>
					{footer()}
				</Modal>
				<Button onClick={()=>this.handleExit(true)}>Exit</Button>
			   </div>
	}

	setOrderId = (orderId)=>{
		this.setState({
			orderId
		})
	}

	setActiveKey =(activeKey)=>{
		this.setState({
			activeKey
		})
	}

	setCreationOrderInfo = (creationOrderInfo)=>{
		const curOrderCreationIfo = this.state.creationOrderInfo
		this.setState({
			creationOrderInfo:{
				...creationOrderInfo,
				...curOrderCreationIfo
			}
		})
	}
	setOrderType=(orderType)=>{
		if(typeof orderType =='string') {
			this.setState({
				orderType
			})
		}
		else if(typeof orderType =='boolean') {
			this.setState({
				orderType: !orderType ? 'order' : 'subscription'
			})
		}
		if(orderType=="true")
		{
			this.props.setOrderDetails(null);
		}
	}
	render() {
		const {callback=null} = this.props;
		if(callback) {
			callback();
		}
		const { activeKey,orderId,creationOrderInfo,orderType,orderDetails,peopleList } = this.state;
		const { showMakeOrder,unpaidOrderId } = this.props;
		const { setShowMakeOrder,setActiveKey,setOrderId,setCreationOrderInfo,setOrderType } = this;
		let showModal = showMakeOrder && (( unpaidOrderId&&orderDetails ) || !unpaidOrderId)
 		return <div>
			{showModal && <Modal visible={true}
			                         onCancel={() => setShowMakeOrder(false)}
			                         footer={null} className={'makeOrderModal'}
			                         maskClosable={false}
			                         closable={false}
									 mask={false}
			                         keyboard={false}
			                         width={'100vw'} bodyStyle={{top: 0, padding: 0}}
			                         style={{top: 0, padding: 0}}>
									{this.closeButton()}
								<div className={'content'}>
									<Tabs defaultActiveKey="2" activeKey={activeKey}
									      onChange={(activeKey) => this.setState({activeKey})}>
										<Tabs.TabPane key={'1'} tab={'1'}>
											<ImportPeopleContainer isActive={activeKey == '1'}
											                       creationOrderInfo={creationOrderInfo}
											                       setCreationOrderInfo={setCreationOrderInfo}
											                       setShowMakeOrder={setShowMakeOrder}
											                       setOrderType={setOrderType}
											                       orderType={orderType}
											                       orderDetails={orderDetails}
											                       peopleList={peopleList}
											                       orderId={orderId} setOrderId={setOrderId}
											                       setActiveKey={setActiveKey}
																   activeKey={activeKey}
																   />
										</Tabs.TabPane>
										<Tabs.TabPane key={'2'} tab={'2'}>
											<div className={'orderDetailsWrapper'}>
												<MakePaymentComponent isActive={activeKey == '2'} orderType={orderType}
												                      creationOrderInfo={creationOrderInfo}
												                      orderDetails={orderDetails}
												                      orderId={orderId} setShowMakeOrder={setShowMakeOrder}
												                      setActiveKey={setActiveKey}/>

											</div>
										</Tabs.TabPane>
										<Tabs.TabPane key={'3'} tab={'3'}>
											<OrderConfirmationComponent isActive={activeKey == '3'} />
										</Tabs.TabPane>
									</Tabs>
								</div>
							</Modal>
							}
			   </div>
	}
}

const mapToProps = (props)=>{
	return {
		// subscription: get(props,'orderReducer.subscription'),
	}
}

export default connect(mapToProps,{ setOrderDetails })(MakeOrderButtonComponent);