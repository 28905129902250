/* eslint-disable import/no-anonymous-default-export */
import React, {useEffect, useState} from "react";
import { Button} from "antd";
import MakeOrderButtonComponent from "../component/MakeOrderButtonComponent";


export default (props)=>{
	const {className,text,callback } = props;
	const [ showMakeOrder,setShowMakeOrder ] = useState(false);
	

	const showChild = ()=> showMakeOrder ?  <MakeOrderButtonComponent callback={callback} showMakeOrder={showMakeOrder} {...props} setShowMakeOrder={setShowMakeOrder}/>:<div></div>;
	return <div>
			 { showChild() }
			<Button onClick={()=>setShowMakeOrder(true)} className={className}>{text}</Button>
		   </div>
}