import React from "react";
import { Form, Icon, Input, Button,Alert } from 'antd';
// import 'antd/lib/form/style/index.css';
// import 'antd/lib/button/style/index.css';
import '../style/index.scss';
import { LogInButton } from './LoginButtonComponent'
function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}
let LoginFormComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {
			logInError: null
		}
	}


	componentDidMount() {
		// To disable submit button at the beginning.
		this.props.form.validateFields();
	}

	formValidation = () => {
		return new Promise((resolve,reject) => {
			this.props.form.validateFields((err, values) => {
				if(err) reject(err);
				resolve(values);
			});
		})
	};
	setLogInError = (logInError)=>{
		this.setState({
			logInError
		})
	}
	render() {
		const { logInError }  = this.state;
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
		// Only show error after a field is touched.
		const usernameError = isFieldTouched('username') && getFieldError('username');
		const passwordError = isFieldTouched('password') && getFieldError('password');
		const disabled = hasErrors(getFieldsError());
		return (
			<Form onSubmit={this.handleSubmit} id={'loginForm'} onChange={()=>{
				if(logInError){
					this.setState({
						logInError: null
					})
				}
			}}>
				<Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''} label="Email Address">
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your email!' },{type:'email', message: 'The input is not valid email!'}],
					})(
						<Input />,
					)}
				</Form.Item>
				<Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} label='Password'>
					{getFieldDecorator('password', {
						rules: [{ required: true, message: 'Please input your Password!' }],
					})(
						<Input type="password" />,
					)}
				</Form.Item>
				{ !!logInError && <Alert message={logInError} type="error" showIcon  style={{ marginBottom: 15 }}/> }
				<Form.Item>
					<LogInButton disabled={disabled} formValidation={this.formValidation} setLogInError={ this.setLogInError}/>
				</Form.Item>
			</Form>
		);
	}

}

LoginFormComponent = Form.create({ name: 'loginForm' })(LoginFormComponent);

export default LoginFormComponent