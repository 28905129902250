import React, {Fragment} from 'react';
import {centsToDollar} from "../../lib/helpers";

const OrderPriceDetailsComponent = (props)=>{
    const { subTotal, discount, serviceFee, serviceFeeDiscount, total_tax, total } = props;

    return <Fragment>
        <div className="totals-item">
				<label>Subtotal:</label>
				<div className="totals-value" id="cart-subtotal">{centsToDollar(subTotal)}</div>
			</div>
			<div className="totals-item">
				<label>Discount:</label>
				<div className="totals-value" id="cart-subtotal">-{centsToDollar(discount)}</div>
			</div>
			<div className="totals-item">
				<label>Shipping:</label>
				<div className="totals-value" id="cart-shipping">Free</div>
			</div>
			<div className="totals-item">
				<label>Tax:</label>
				<div className="totals-value" id="cart-tax">{centsToDollar(total_tax)}</div>
			</div>
			<div className="totals-item totals-item-total">
				<label>Total:</label>
				<div className="totals-value" id="cart-total">{centsToDollar(total)}</div>
			</div>
    </Fragment>

}

export default OrderPriceDetailsComponent;