import React, {useEffect, useState} from "react";
import ProctorDashboardComponent from "../component/ProctorDashboardComponent";
import {
  getProctorStatsQuery,
  getTesterStatsQuery,
  getAppointmentStatsQuery,
  getTestersQueueStats, getTestersQueueDetails
} from '../../API/query/proctor';
import moment from "moment-timezone";
import { get } from 'lodash';
import '../style/index.scss';
import useInterval from "../../lib/hook/useHook";


const REFRESH_INTERVAL = 30*1000;
const ProctorDashboardContainer = (props) => {
  const [ loading,setLoading ] = useState(false);
  const [proctorAvailable, setProctorAvailable] = useState(0);
  const [proctorInService, setProctorInService] = useState(0);
  const [testerWaiting, setTesterWaiting] = useState(0);
  const [ statInfo,setsStatInfo ] = useState({});
  const [ status,setStatus ] = useState(["PHASE_ONE_WAITING","PHASE_TWO_WAITING"]);
  const [testerInfo,setTesterInfo] = useState([]);
  const [servingProfiles,setServingProfiles] = useState([]);
	const [appointments, setAppointments] = useState([]);
  const fetchStatsData = async () => {
    setLoading(true);
    await getProctorStats();
    await getTesterStats();
    await getAppointmentStats();
    setLoading(false);
  }
  useInterval(fetchStatsData, REFRESH_INTERVAL, true);
  const getProctorStats = async () => {
    try {
      const proctorStats = await getProctorStatsQuery();
      const statInfo = get(proctorStats, 'data.data', {});
      const available = get(statInfo, 'availableNumber', 0);
      const inService = get(statInfo, 'servingNumber', 0);
      const servingProfiles = get(statInfo,'servingProfiles',[]);
      setProctorAvailable(available);
      setProctorInService(inService);
      setServingProfiles(servingProfiles);
    } catch (e) {
      console.log(e);
    }
  }

  const getTesterStats = async () => {
    try {
      // const testerStats = await getTestersQueueStats();
      const testerInfoRes = await getTestersQueueDetails();
      // const statInfo = get(testerStats, 'data.data', {});
      const testerInfo = get(testerInfoRes,'data.data',[]);
      // setsStatInfo(statInfo);
      setTesterInfo(testerInfo)
    } catch (e) {
      console.log(e);
    }
  }

  const getAppointmentStats = async () => {
		try {
			const now = moment();
			const startTimeStr = now.add(-1, 'hours').valueOf();
			const endTimeStr = now.add(24, 'hours').valueOf();
			const data = { startTime: startTimeStr, endTime: endTimeStr };
			const proctorStats = await getAppointmentStatsQuery(data);
			const appointments = get(proctorStats, 'data.data', []);
			setAppointments(appointments);
		} catch (e) {
			console.log(e);
		}
	}

  const renderHeader = () => <div className={'header'}>Dashboard</div>;
  const renderTimezone = ()=><div className={'timezone'}>{moment.tz.guess()}</div>
  return <div className='dashboardWrapper'>
    {renderHeader()}
    {renderTimezone()}
    <ProctorDashboardComponent fetchStatsData={fetchStatsData} proctorAvailable={proctorAvailable} statInfo={statInfo} setStatus={setStatus}
      proctorInService={proctorInService} testerWaiting={testerWaiting} testerInfo={testerInfo} loading={loading} servingProfiles={servingProfiles}
      appointments={appointments}/>
  </div>
}
export default ProctorDashboardContainer;
