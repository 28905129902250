import React from 'react';
import TestResultStatsFilters from './TestResultStatsFilters';
import TestResultTableComponent from './TestResultTableComponent';
import { useSelector } from 'react-redux';

const TestResultBodyComponent = () => {
    const { groupStats } = useSelector(state => state.testResultReducer);

    const renderTable = () => {
        if(groupStats.IsAdmin===0) {
            return <p style={{textAlign: 'center', marginTop: '40px'}}>You are not authorized to view this content.</p>
        }

        return <TestResultTableComponent />
    }
    
    return <div className={'testResultBodyWrapper'}>
            <TestResultStatsFilters />
            {renderTable()}
        </div>
}

export default TestResultBodyComponent;