import React from 'react';
import { Dropdown,Menu,Icon } from "antd";
import { getUserInfo } from '../../auth/helper/index';
import { useAuth } from '../../auth/index';
import { get,isEmpty } from 'lodash';
import 'antd/lib/dropdown/style/index.css';
const UserProfileDropdown = ({loginLink='/login'})=>{
	const user = getUserInfo()|| {};
	const auth  = useAuth();
	const handleLogOut = ()=>{
		auth.signout();
		window.location.href = loginLink;
	}
	const fullName = get(user,'FirstName') + ' '+get(user,'LastName');
	let menu =  <Menu>
		<Menu.Item>
			<a onClick={handleLogOut}>Log Out</a>
		</Menu.Item>
	</Menu>
	return isEmpty(user) ? <div></div> : <Dropdown overlay={menu}  className={'headerDropdown'}><a>{fullName}<Icon type="down"/></a></Dropdown>
}

export default UserProfileDropdown;