import React, {useState} from "react";
import {Button, Input, Popconfirm,Row,Col,message } from "antd";
import '../style/index.scss';
import { KEY_TO_LABEL } from '../constant/index';
import { isArray,isEmpty,first,get,trim,startsWith } from 'lodash';
import {getTesterByOrderId, sendPurchaseEmailMutation} from "../../API/query/customerService";

const CustomerServiceTesterComponent = ()=>{
	const [ order,setOrder ] = useState({});
	const [ error,setError ] = useState('');
	const [ email,setEmail ] = useState('');
	const formatOrderId = (orderId)=>{
		let newOrderId = trim(orderId);
		if (!newOrderId) return newOrderId;
		if (startsWith(newOrderId, 'shopify-')) {
			return newOrderId;
		}
		if (newOrderId.length > 7) {
			newOrderId = newOrderId.substring(2, newOrderId.length);
		}
		newOrderId = 'shopify-'+newOrderId;
		return newOrderId;
	}
	const searchOrder = async (orderId)=>{
		try {
			const formatId = formatOrderId(orderId);
			let res = await getTesterByOrderId(formatId);
			if(res.data.code === 200) {
				let codeArr = res.data.data.code;
				if(codeArr.length === 0) {
					setOrder({});
					setError('Sorry, this order number doesn’t exist. Please double check.');
					throw new Error('not found');
				}
				let code = first(res.data.data.code);
				setOrder({
					orderId,
					quantity: codeArr.length,
					tickets:codeArr,
				})
				setEmail(get(res,'data.data.email',''));
				setError('');

			}
		}catch (e) {
			console.log(e);
		}
	}
	const renderOrderDetails = ()=>{
			const keys = Object.keys(order);
			const renderKey=(key)=>{
				if(isArray(key)) {
					return key.map(k=><div style={{ marginBottom: 15 }}>{k}</div>)
				}
				return key;
			}
			return <div className={'orderDetails'}>
				{keys.map(k=><div style={{display:'flex',justifyContent:'space-between',marginBottom: 15 }}>
								<div className={'key'}>{KEY_TO_LABEL[k]}</div>
								<div className={'value'}>{renderKey(order[k])}</div>
						    </div>)}
			    { emailInput() }
				<div style={{ marginTop:'auto' }}>{ sendTicket() }</div>
			</div>

	}
	const emailInput = ()=>{
		return <div>
			<Input value={email} onChange={(e)=>setEmail(e.target.value)}/>
		</div>
	}
	const sendEmail = async ()=>{
		const { orderId } = order;
		try{
			if(orderId&&email.length) {
				await sendPurchaseEmailMutation({email, TransactionId:formatOrderId(orderId)});
				message.info('Email sent')
			}
		}catch (e) {
			console.log(e);
		}
	}
	const sendTicket = ()=>{
		const disabled = !order.quantity || email.length==0;
		return <Button className={'ihealth'} disabled={disabled} onClick={sendEmail}>Send ticket</Button>
	}

	const renderError = ()=>{
		return <div style={{ height:'inherit',marginTop:'20%' }} className={'error'}>
			<span>{error}</span>
		</div>
	}
	return <div style={{ backgroundColor:'#F4F6F8',padding:20,height:'100%' }}>
			<div className={'serverTesterInputWrapper'}>
				<div>
					<Input placeholder={'Enter Shopify order number to find verified test ticket(s)'} onPressEnter={(e)=>searchOrder(e.target.value)}/>
				</div>
				{ !isEmpty(order) && renderOrderDetails() }
				{ renderError() }
		   </div>
	</div>
}
export default CustomerServiceTesterComponent;