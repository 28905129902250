import './App.css';
import BannerComponent from "./Accessories/component/Banner";
import SiderComponent from './Accessories/component/Sider';
import { routes,teleServiceRoutes } from '../src/routes/index';
import { Layout } from 'antd';
import {AuthProvider} from "./auth";
import 'antd/lib/layout/style/index.css'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/reducer/store';


const { Header, Content } = Layout;
function App() {
  return (
      <Provider store={store}>
          <AuthProvider>
              <BrowserRouter>
              <div className="App">
                 <Layout>
                     <Header>
                         <BannerComponent/>
                     </Header>
                  <Layout>
                      <SiderComponent/>
                      <Content>
                          { routes }
                      </Content>
                  </Layout>
                 </Layout>
                </div>
                  {/*{teleServiceRoutes}*/}
              </BrowserRouter>
         </AuthProvider>
      </Provider>
  );
}

export default App;
