import React from "react";
import {Tabs,Table,Popover } from "antd";
import '../style/index.scss';
import {TESTER_STAT_TABLE_MAP} from "../constant/testerStat";
import { get } from 'lodash'
const TesterStateTableComponent = (props)=>{
	const { testerInfo,statInfo,setStatus,loading } = props;
	const { DROP_LINED, PHASE_ONE_COMPLETED, PHASE_ONE_WAITING, PHASE_TWO_WAITING } = statInfo;
	const getColumns = (key)=>{
		return TESTER_STAT_TABLE_MAP[key]
	}
	const keyToStatus = {
		WAITING:{
			status:["PHASE_ONE_WAITING","PHASE_TWO_WAITING"],
			label:(num=0)=>`Waiting to start: ${num}`,
			dataIndex:'waiting'
		},
		TIMER:{
			status:["PHASE_ONE_COMPLETED"],
			label:(num)=>`Waiting for result: ${num}`,
			dataIndex:'phaseOneCompleted'
		},
		DROPLINED:{
			status:["DROP_LINED"],
			label:(num)=>`Dropped connection: ${num}`,
			dataIndex:'dropLined'
		}
	}
	const keys = Object.keys(keyToStatus);
	return <Tabs defaultActiveKey={'WAITING'} onChange={(k)=>setStatus(keyToStatus[k].status)} className={'testerStatTab'}>
		{ keys.map(k=><Tabs.TabPane key={k} tab={keyToStatus[k].label(get(testerInfo,`${keyToStatus[k].dataIndex}`,[]).length)}>
			<Table style={{ width:'100%' }} loading={loading} dataSource={get(testerInfo,`${keyToStatus[k].dataIndex}`,[])} columns={getColumns(k)} pagination={false}/>
		</Tabs.TabPane>)}
	</Tabs>
}
export default TesterStateTableComponent;