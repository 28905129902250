import React from "react";
import { passwordValidator} from '../../lib/validator/formValidators'
import { Input} from 'antd';

const formItemLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 12 },
};
const regExArr = [/\d/, /[a-z]/, /[A-Z]/]; // Per Carmina remove special character requirement IH-1087

const requiredInput = (key,message,label,{ col,offset }={ col:24 })=>{
	return {
		key,
		col,
		label,
		offset,
		formItemLayout,
		rules:[{
			required: true,
			message: `Please input your ${message}`,
		}],
		render:(args={})=><Input disabled={args.disabled}/>
	}
}

export const formMap =(localThis)=> [[
	requiredInput('firstName','firstName','First Name',{ col: 11 }),
	requiredInput('lastName','lastName','Last Name',{ col: 11,offset:2 })],
	[ requiredInput('title','title','Your Title',{ col:24 })],
	[{
		key:'phoneNumber',
		label:'Phone Number',
		render:()=><Input/>,
		rules:[{
			required: true,
			message:'Please input phone number'
		}]

	}],
	[{
		key:'email',
		label:'Work Email',
		rules: [
			{
				type: 'email',
				message: 'The input is not valid E-mail!',
			},
			{
				required: true,
				message: 'Please input your E-mail!',
			},
		],
		render:()=><Input placeholder={'Email'} />
	}],
	[requiredInput('companyName','Organization Name','Organization Name',{ col:24 })],
	[{ key:'companyWebsite',  render:()=><Input/> ,label: 'Organization Website(optional)'}],
	[{
		key:'password',
		label: 'Password',
		rules: [{ validator: passwordValidator }],
		render:()=><Input.Password type={'password'}/>
	}],
	[{
		key:'confirm',
		label: 'Re-enter Password',
		rules: [
			{
				validator: (rule, value, callback) => {
					const pwd = localThis.props.form.getFieldValue('password');
					if (!value) {
						callback('Please confirm your password.');
						return;
					}
					if (value !== pwd) {
						callback('Must match the previous entry.');
						return;
					}
					callback();
				}
			}
		],
		render:()=><Input.Password type={'password'}/>
	}]
]

export const profileFormMap = (localThis)=> [[
		requiredInput('firstName','firstName','First Name',{ col: 11 }),
		requiredInput('lastName','lastName','Last Name',{ col: 11,offset:2 })],
		[{
			key:'phoneNumber',
			label:'Phone Number',
			render:({disabled})=><Input disabled={disabled}/>,

		}],
		[{
			key:'email',
			label:'Work Email',
			rules: [
				{
					type: 'email',
					message: 'The input is not valid E-mail!',
				},
				{
					required: true,
					message: 'Please input your E-mail!',
				},
			],
			render:()=><Input placeholder={'Email'} disabled={true}/>
		}],
		[ requiredInput('title','title','Your Title',{ col:24 })],
]

export const proctorProfileFormMap = (localThis)=> [[
	requiredInput('firstName','firstName','First Name',{ col: 11 }),
	requiredInput('lastName','lastName','Last Name',{ col: 11,offset:2 })],
	[{
		key:'email',
		label:'Account Email',
		rules: [
			{
				type: 'email',
				message: 'The input is not valid E-mail!',
			},
			{
				required: true,
				message: 'Please input your E-mail!',
			},
		],
		render:()=><Input placeholder={'Email'} disabled={true}/>
	}],
]

export const changePwdFormMap =(localThis)=>[
	[{
		key:'curPassword',
		label: 'Current Password',
		rules: [{ required: true,message:'Current Password is Required' }],
		render:()=><Input.Password type={'password'}/>
	}],
	[{
		key:'password',
		label: 'New Password',
		rules: [ { validator: (rule,value,cb) => {
			try {
				if (!value) {
					throw new Error('Password is Required');
				}

				if (value.length < 8) {
					throw new Error('Password should be at least 8 character.');
					// cb('Password should be at least 8 character.')
				}
				const combinations = regExArr.filter(reg => reg.test(value)).length;
				if (combinations !== regExArr.length) {
					throw new Error('*Must contain a number\n' +
						'*Must contain at least 1 lowercase letter\n' +
						'*Must contain at least 1 UPPERCASE letter\n');
					// cb('*Must contain a number\n' +
					// 	'*Must contain at least 1 lowercase letter\n' +
					// 	'*Must contain at least 1 UPPERCASE letter\n');
				}
			}
			catch (e) {
				cb(e);
			}
		   }
		}],
		render:()=><Input.Password type={'password'}/>
	}],
	[{
		key:'confirm',
		label: 'Re-enter New Password',
		rules: [{
				validator:(rule, value, callback) => {
					// if (!value) {
					// 	callback('Please confirm your password.');
					// }
					try {
						const pwd = localThis.props.form.getFieldValue('password');
						if (value !== pwd) {
							throw new Error('Must match the previous entry.');
							// callback('Must match the previous entry.');
						}
					}catch (e) {
						callback(e);
					}
				}
			}],
		render:()=><Input.Password type={'password'}/>
	}]
]

export const billingInfoFormMap = (localThis)=>[
	[
		requiredInput('firstName','firstName','First Name',{ col: 11 })
	],
	[
		requiredInput('lastName','lastName','Last Name',{ col: 11 })
	],
	[ { key:'companyWebsite',  render:()=><Input/> ,label: 'Organization Website(optional)',col:11 }],
	[ { key:'header',render:()=> <div className={'header'}>Billing Address</div> }],
	[
		{
			key:'address',
			label:'Street Address',
			rules: [{ required: true }],
			render:() => <Input/>,
			col:11
		}
	],
	[
		{
			key:'apt',
			label:'Apt, Suite, Building (Optional)',
			rules: [{ required: false }],
			render:() => <Input/>,
			col:11
		}
	],[
		{
			key:'city',
			label:'City',
			rules: [{ required: true }],
			render:() => <Input/>,
			col:7
		},
		{
			key:'state',
			label:'State',
			rules: [{ required: true }],
			render:() => <Input/>,
			col:7,
			offset:1
		},
		{
			key:'zipcode',
			label:'Zip Code',
			rules: [{ required: true }],
			render:() => <Input/>,
			col:7,
			offset:1
		}
	]
]

export const confirmChangePwdForm = (localThis)=>[
	[{
		key: 'verifyCode',
		label: 'Verify Code',
		rules: [{ required: true,message:'Verify code is required' }],
		render:()=><Input key={'verifyCode'}/>
	}],
	[{
		key:'password',
		label: 'New Password',
		rules: [
				{ validator: (rule,value,cb) => {
					if(!value){
						return cb('password required');

					}
					if (value.length < 8) {
						return cb('Password should be at least 8 character.');

						// cb('Password should be at least 8 character.')
					}
					const combinations = regExArr.filter(reg => reg.test(value)).length;
					if (combinations !== regExArr.length) {
						return cb('*Must contain a number\n' +
							'*Must contain at least 1 lowercase letter\n' +
							'*Must contain at least 1 UPPERCASE letter\n');
						// cb('*Must contain a number\n' +
						// 	'*Must contain at least 1 lowercase letter\n' +
						// 	'*Must contain at least 1 UPPERCASE letter\n');
					}
					cb();
				}
			},
			{ required: true}
		],
		render:()=><Input.Password type={'password'}/>
	}],
	[{
		key:'confirm',
		label: 'Re-enter New Password',
		rules: [{ required: true },{
			validator:(rule, value, callback) => {
				// if (!value) {
				// 	callback('Please confirm your password.');
				// }
				const pwd = localThis.props.form.getFieldValue('password');
				if (value !== pwd) {
					return callback('Must match the previous entry.');
				}
				callback()

			}
		}
		],
		render:()=><Input.Password type={'password'}/>
	}]
]
