import {getLoginUser} from "../../auth/helper";
import { host} from "../config";

export const uploadFile = (file)=> {
	let auth = getLoginUser();
	return new Promise((resolve)=> {
		var data = new FormData();
		data.append("file", file);
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				resolve(JSON.parse(this.response));
			}
		});
		xhr.open("POST", `${host}/api/enterprise/user/upload-contact-excel`);
		xhr.setRequestHeader("Authorization", `kit ${auth}`);
		xhr.send(data);
	})
}

export const uploadId = (file)=> {
	let auth = getLoginUser();
	return new Promise((resolve)=> {
		var data = new FormData();
		data.append("photo", file);
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				resolve(JSON.parse(this.response));
			}
		});
		xhr.open("POST", `${host}/api/proctoring/testers/form/upload-photo`);
		xhr.setRequestHeader("Authorization", `kit ${auth}`);
		xhr.send(data);
	})
}
export const associateContactsWithGroup=(file,id)=>{
	let auth = getLoginUser();
	return new Promise((resolve)=> {
		var data = new FormData();
		data.append("file", file);
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				resolve(JSON.parse(this.response));
			}
		});
		xhr.open("POST", `${host}/api/enterprise/user/contact-groups/${id}/associate`);
		xhr.setRequestHeader("Authorization", `kit ${auth}`);
		xhr.send(data);
	})
}