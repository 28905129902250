export const isLoggedIn = ()=>{
	return sessionStorage.getItem('auth') && JSON.parse(sessionStorage.getItem('auth'));
}

export const clearSession = ()=>{
	sessionStorage.clear();
}

export const setUserInfo = (user)=>{
	sessionStorage.setItem('user',JSON.stringify(user));
}
export const setLoginToken = (auth)=>{
	sessionStorage.setItem('auth',JSON.stringify(auth));
}

export const getLoginToken = ()=>{
	return JSON.parse(sessionStorage.getItem('auth'));
}

export const getLoginUser = ()=> {
	return sessionStorage.getItem('auth') && JSON.parse(sessionStorage.getItem('auth'));
}
export const getUserInfo = ()=>{
	return sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'));
}

export const showSideMenu = (params)=>{
	const pathname = params.toLowerCase();
	// const isCSPortal = pathname.includes('cs');
	const verifyTestNeedSider = ['testsession', 'settings', 'dashboard', 'manualreport','cs','timeslot'];
	const verifyTestWithoutTestSession = pathname.includes('verifytest') && !verifyTestNeedSider.some(v => pathname.includes(v));
	return isLoggedIn()&&  !verifyTestWithoutTestSession;
}

export const getUserRole = ()=>{
	return sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).UserApplicationID;

}