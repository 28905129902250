import React from "react";
import { Badge } from "antd";

export default (props)=>{
	const { status, record } = props;
	const statusToBadge = {
		Paid:'success',
		Unpaid:'default',
		Scheduled: 'processing',
		Draft: 'default',
		Error:'error',
		Locked:'warning',
		Failed:'error'
	};
	const value = 
	!record.subscriptionId && status=== 'Unpaid' ? 'Draft': record.subscriptionId && status=== 'Unpaid' ? 'Scheduled': status;
	return <div className={`orderStatus ${status}`}>
			<Badge status={ statusToBadge[value]} />
			<span>{value}</span>
	       </div>
}