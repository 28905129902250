import React, {useState} from "react";
import {upperFirst} from "lodash";
import {Button, Modal, Table} from "antd";
import SingleOrderDetailContainer from '../../Order/container/SingleOrderDetailContainer';
import ReshipModalComponent from "./ReshipModalComponent";
import { getUserRole } from '../../auth/helper/index';
import { RESHIP_ROLE } from '../constant/index';
const CustomerServiceHomeComponent = (props)=>{
	let [ showReshipModal,setShowReshipModal ] = useState(false);
	let [ orderRes,setOrderRes ] = useState(null);
	let { orderList,subOrderId,subOrderList,errorMsg, total } = props;

	const handleReship = (doc)=>{
		setOrderRes(doc);
		setShowReshipModal(true)
	}

	const renderTable = ()=>{
		const columns = [{
			key:'fullName',
			dataIndex:'fullName',
			title: 'Full Name',
			render:(val,doc)=>{
				return doc.firstName + ' ' + doc.lastName;
			}
		},{
			key:'email',
			dataIndex: 'email',
			title: 'Email'
		},{
			key:'address',
			dataIndex: 'address',
			title:'Address'
		},{
			key:'orderNumber',
			dataIndex: 'id',
			title: 'Individual Order Number'
		},{
			key:'status',
			dataIndex: 'status',
			title:'Status',
			render:(v)=> upperFirst(v.toLowerCase())
		},{
			key:'trackingCompany',
			dataIndex: 'trackingCompany',
			title:'Carrier'
		},{
			key:'trackingNumber',
			dataIndex: 'trackingNumber',
			title: 'Tracking Number',
			render:(v,doc)=>{
				return !!v.length && <a href={doc.trackingUrl} target="_blank">{v}</a>
			}
		},{
			key:'action',
			render:(v,doc)=>{
				const role = getUserRole();
				if(RESHIP_ROLE.includes(role)) {
					return <Button onClick={() => handleReship(doc)}>reship</Button>
				}
			}
		}]
		return <Table columns={columns} dataSource={orderList} rowKey={record => record.id}/>
	}

	const renderReshipModal = ()=>{
		return showReshipModal&&<Modal visible={true} onCancel={()=>setShowReshipModal(false)} footer={null} title={'Enter reship quantity'}
		                               maskClosable={false}>
				<ReshipModalComponent orderRes={orderRes} setShowReshipModal={setShowReshipModal}/>
		</Modal>
	}
	if(errorMsg.length) {
		return <div style={{ marginTop:200,textAlign:'center' }}>{ errorMsg }</div>;
	}
	return <div>
			<div style={{ padding:50 }}>{ !!orderList.length&&renderTable()}</div>
			<div>{renderReshipModal()}</div>
		   { subOrderId && <SingleOrderDetailContainer total={total} orderNumber={subOrderId} subOrderList={subOrderList} hideBackBtn = {true}/> }
		  </div>
}
export default CustomerServiceHomeComponent;

//POST ‘/support/sub-orders/:id/reship’ reship 一个suborder