import {getRequest, postRequest} from "../helper";
import {getLoginUser} from "../../auth/helper";

const HEADER =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})
const CUSTOMER_SERVICE_SUBORDER_URL = (id)=>`/api/enterprise/orders/sub-orders?orderId=${id}`;
const CUSTOMER_SERVICE_ORDER_URL = (id)=>`/api/enterprise/orders/sub-orders?id=${id}`;
const RESHIP_ORDER_URL = (id)=>`/api/enterprise/support/sub-orders/${id}/reship`;
const TESRER_BY_ORDRRID = (id)=>`api/proctoring/redeem-code?transactionId=${id}`;
const SEND_PURCHASE_EMAIL_URL = `/api/proctoring/redeem-code/resend-purchase-email`;
export const customerServiceSuborderQuery = (id)=>getRequest(CUSTOMER_SERVICE_SUBORDER_URL(id),{ headers:HEADER() });
export const customerServiceOrderQuery = (id)=>getRequest(CUSTOMER_SERVICE_ORDER_URL(id),{ headers:HEADER() });
export const reshipOrderMutation = (id,data)=>postRequest(RESHIP_ORDER_URL(id),{ headers: HEADER(),data });
export const getTesterByOrderId = (id)=>getRequest(TESRER_BY_ORDRRID(id),{ headers:HEADER() });
export const sendPurchaseEmailMutation = (data)=>postRequest(SEND_PURCHASE_EMAIL_URL,{ headers: HEADER(),data });