import { postLog } from '../API/query/utils';
export default {
  async error(msg) {
    try {
      await postLog({
        time: Date.now(),
        level: 'ERROR',
        msg,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async warn(msg) {
    try {
      await postLog({
        time: Date.now(),
        level: 'WARN',
        msg,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async info(msg) {
    try {
      await postLog({
        time: Date.now(),
        level: 'INFO',
        msg,
      });
    } catch (e) {
      console.log(e);
    }
  },
}
