export const questions = {
	'HAS_TEST_KIT': '1. Do you have an unopened iHealth COVID-19 Antigen Rapid Test Kit with you?',
	'ID_READY': '2. Do you have a photo ID with you? You will be asked to present your ID to the iHealth COVID test proctor. Valid IDs include but are not limited to driver‘s license, passport and military ID card.',
	'HAND_SANITIZED': '3. Are your hands sanitized and dry?',
	'FAMILIAR_WITH_TEST': '4. Are you familiar with using the iHealth COVID-19 Antigen Rapid test kit?',
}
export const questionsTip = {
	'HAS_TEST_KIT': 'The verified test can’d be finished if you don’t have a new iHealth test kit with you.',
	'ID_READY': 'Please prepare a valid photo ID before joining test session.',
	'HAND_SANITIZED': 'Please make sure your hands are sanitized and dry to ensure accurate test result.',
	'FAMILIAR_WITH_TEST': '',
}
