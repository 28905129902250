import React, {useEffect, useRef, useState} from "react";
import ChatComponent from "../component/ChatComponent";
import AgoraRTM from "agora-rtm-sdk";
import {useChat} from "../hooks/useChat";
import {Badge, notification} from 'antd';
import '../style/index.scss';
import Icon from "antd/es/icon";
import {get} from "lodash";
const ChatContainer = (props)=>{
	const { role,agora = {} } = props;
	const { uid, rtmToken,channel,appId } = agora;
	const [ showModal,setShowModal ] = useState(false);
	const [ hasUnread, setHasUnread ] = useState(false);
	const client = AgoraRTM.createInstance(appId,{ enableLogUpload: false });
	const { joinChannelAndSubscribe,sendMessage,chatStatus,logOut,messages } = useChat(client,rtmToken,role,uid.toString() );
	const startChat = async ()=>{
		try {
			await joinChannelAndSubscribe(channel);
		}catch (e) {
			console.log(e);
		}
	};
	useEffect(()=>{
		if(!chatStatus&&rtmToken&&uid&&channel) {
			(async () => await startChat())();
		}
	},[messages.length]);

	const showModalHandler = ()=>{
		setShowModal(true);
		setHasUnread(false);
		let video = document.getElementById('ag-canvas');
		if (video) {
			video.classList.add('withChat');
		}

	}

	const closeModalHandler = ()=>{
		setShowModal(false);
		let video = document.getElementById('ag-canvas');
		if (video) {
			video.classList.remove('withChat');
		}
	}
	if(!chatStatus)return '';
	return <React.Fragment>
			<div className={`chatIcon ${role}`}>
				<img src={`/image/${hasUnread ? 'chat_msg.png' : 'chat.png'}`} width={60} onClick={()=>showModalHandler()}/>
			</div>
			<ChatComponent sendMessage={sendMessage} closeModalHandler={closeModalHandler}
		                            messages={messages} showModal={showModal} role={role} setHasUnread={setHasUnread}/>
	</React.Fragment>
}
export default ChatContainer;