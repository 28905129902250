import { Navigate, Route, Routes} from "react-router-dom";
import LoginFormContainer from "../Login/container/LoginFormContainer";
import SignUpFormContainer from "../SignUp/container/SignUpFormContainer";
import SignUpSucceedComponent from "../SignUp/component/SignUpSucceedComponent";
import OrderContainer from "../Order/container/OrderContainer";
import PageNotFound from '../Accessories/container/PageNotFoundContainer';
import TestResultsComponent from "../TestResults/container/TestResultsComponent";

import SingleOrderDetailContainer from "../Order/container/SingleOrderDetailContainer";
import SettingContainer from "../Settings/container/SettingsContainer";
import { RequireAuth } from '../auth/index.js';
import { isLoggedIn } from "../auth/helper";
import React from "react";

import Home from '../Home/container/HomeContainer';
import SingleSubscriptionContainer from "../Order/container/SingleSubscriptionContainer";
import ImportPeopleContainer from '../Order/container/ImportPeopleContainer';
import CustomerServiceHomeContainer from '../CustomerSupport/container/CustomerServiceHomeContainer';
import ReshipModalComponent from '../CustomerSupport/component/ReshipModalComponent'

import Index from '../pages/index/index';
import Meeting from '../pages/meeting/index';
import EnterTicketNumberContainer from '../../src/main/container/EnterTicketNumberContainer';
import UserInformationContainer from "../BasicInfo/container/UserInformationContainer";
import ConnectingPageContainer from "../Video/container/ConnectingPageContainer";

import TestSessionContainer from "../TestSession/container/TestSessionContainer";
import ProctorLoginContainer from '../proctor/container/ProctorLoginContainer';
import ProctorDashboardContainer from '../proctor/container/ProctorDashboardContainer';

import RedeemAndScheduleContainer from "../schedule/container/RedeemAndScheduleContainer";
import TimerContainer from "../Video/container/TimerContainer";
import AppointmentFinishedComponent from '../schedule/component/AppointmentFinishedComponent';
import { Call } from '../Video/components/Testcall';
import CustomerServiceTesterContainer from "../CustomerSupport/container/CustomerServiceTesterContainer";
import ChatContainer from "../Chat/container/ChatContainer";
import TesterReportListContainer from "../testerReport/container/TesterReportListContainer";
import TimeSlotManagementContainer from "../admin/container/TimeSlotManagementContainer";
import TesterCheckReportContainer from "../testerReport/container/TesterCheckReportContainer";
import CancelAppContainer from "../schedule/container/CancelAppContainer";

export let routes =
		<Routes>
			<Route path='/' element={isLoggedIn() ?<Navigate replace to={'/orders'}/> : <Home />}/>
			<Route path='/login/' >
				<Route path={''} element={ isLoggedIn() ?<Navigate replace to={'/orders'}/> : <LoginFormContainer/> } />
				<Route path={':role'} element={ isLoggedIn() ?<Navigate replace to={'/orders'}/> : <LoginFormContainer/> } />
			</Route>
			<Route path='/cs/orders' element={<CustomerServiceHomeContainer/>}/>
			<Route path={'chat/:id'} element={<ChatContainer/>}/>
			<Route path='/cs/tickets' element={<CustomerServiceTesterContainer/>}/>
			<Route path='/signup' element={ isLoggedIn() ?<Navigate replace to={'/orders'}/> : <SignUpFormContainer/> }/>
			<Route path='/signupsuccess' element={<RequireAuth><SignUpSucceedComponent/></RequireAuth>} />
			<Route path='/results' element={<RequireAuth><TestResultsComponent/></RequireAuth>} />
			<Route path='/makeorder' element={<ImportPeopleContainer/>} />
			<Route path='/after' element={<ReshipModalComponent/>}/>
			<Route path='/orders' element={<RequireAuth><OrderContainer/></RequireAuth>} />
			<Route path='/subscription/:id' element={<RequireAuth><SingleSubscriptionContainer/></RequireAuth>}/>
			<Route path='/orders/:id' element={<RequireAuth><SingleOrderDetailContainer/></RequireAuth>} />
			<Route path='/settings' element={<RequireAuth><SettingContainer/></RequireAuth>} />
			<Route path='/verifyTest/settings' element={<RequireAuth path='/verifyTest/proctorLogin'><SettingContainer/></RequireAuth>} />
			<Route path='/verifyTest/dashboard' element={<RequireAuth path='/verifyTest/proctorLogin'><ProctorDashboardContainer/></RequireAuth>} />
			<Route path='/verifyTest/testSession' element={<RequireAuth path='/verifyTest/proctorLogin'><TestSessionContainer/></RequireAuth>} />
			<Route path='/verifyTest/manualReport' element={<RequireAuth path='/verifyTest/proctorLogin'><TesterReportListContainer/></RequireAuth>} />
			<Route path={'/verifyTest/video'} element={<Index/> }/>
			<Route path="/verifyTest/redeem/:code" element={<EnterTicketNumberContainer/>} />
			<Route path="/verifyTest/redeem/" element={<EnterTicketNumberContainer/>} />
			<Route path="/verifyTest/meeting" element={<Meeting/>} />
			<Route path='/verifyTest/userInfo/:code' element={<UserInformationContainer/>}/>
			<Route path='/verifyTest/waiting' element={<ConnectingPageContainer/> }/>
			<Route path={'/verifyTest/proctorLogin'} element={<ProctorLoginContainer/>} />
			<Route path={'/verifyTest/schedule'} element={<RedeemAndScheduleContainer/>} />
			<Route path={'/verifyTest/cancelApp/:code'} element={<CancelAppContainer/> }/>
			<Route path={'/verifyTest/previewSchedule'} element={<RedeemAndScheduleContainer preview={true}/>}/>
			<Route path={'/verifyTest/schedule/:code'} element={<RedeemAndScheduleContainer/>} />
			<Route path={'/verifyTest/checkmyreport'} element={<TesterCheckReportContainer/>} />
			<Route path={'timer'} element={<TimerContainer/>}/>
			<Route path={'/confirmed'} element={<AppointmentFinishedComponent/>}/>
			<Route path='*' element={<PageNotFound />}/>
			<Route path='/call' element={<Call/>} />
			<Route path={'/verifyTest/timeSlot'} element={<TimeSlotManagementContainer/>}/>
		</Routes>

export let teleServiceRoutes = <Routes>
		<Route path={'/video'} element={<Index/> }/>
		<Route exact path="/redeem" element={<EnterTicketNumberContainer/>} />
		<Route path="/meeting" element={<Meeting/>} />
		<Route path='/userInfo' element={<UserInformationContainer/>}/>
		<Route path='/waiting' element={<ConnectingPageContainer/> }/>
	</Routes>
