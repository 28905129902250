import React, {useEffect, useState} from "react";
import TimeSlotManagementComponent from "../component/TimeSlotManagementComponent";
import {getTimeSlots,updateTimeSlot} from "../../API/query/admin";
import moment from "moment-timezone";
import {get, isNumber} from 'lodash';
import Icon from "antd/es/icon";
import {message} from "antd";
import useInterval from "../../lib/hook/useHook";
const TimeSlotManagementContainer = ()=>{
	const [ startDay, setStartDay ] = useState(moment());
	const [ endDay, setEndDay ] = useState(moment().add(6,'day'));
	const [ slots,setSlots ] = useState([]);
	const [ loading,setLoading ] = useState(false);
	const [ editableRow,setEditableRow ] = useState();

	const fetData = async (refresh)=>{
		try {
			setLoading(true);
			let res = await getTimeSlots({
				startTime: startDay.startOf('day').valueOf(),
				endTime: endDay.endOf('day').valueOf(),
				refresh
			});
			if(res.data.code==200){
				setSlots(get(res,'data.data',[]));
			}
			setLoading(false);
		}catch (e) {

		}
	}

	useInterval(fetData,30000);

	useEffect(()=>{
		fetData();
	},[startDay,endDay])
	const updateSlots = async (timeSlots)=>{
		try{
			await updateTimeSlot({timeSlots})
			await fetData(true);
			message.success('Changes have been saved.');
		}catch (e) {
			console.log(e);
		}
	}
	const renderMonth = ()=>{
		let monthOne = startDay.format('MMM');
		let monthTwo = endDay.format('MMM');
		let str = '';
		if(monthOne==monthTwo) {
			str = monthTwo;
		}else{
			str = `${monthOne} - ${monthTwo}`;
		}
		return <div className={'monthTitle'}>{str} {renderYear()} { renderArrow() }</div>
	}
	const renderYear = ()=>{
		return startDay.format('YYYY');
	}
	const renderArrow = ()=>{
		const handleClick = {
			LEFT:()=>{
				setStartDay(moment(startDay).subtract(7,'day'));
				setEndDay(moment(endDay).subtract(7,'day'))
			},
			RIGHT:()=>{
				setStartDay(moment(startDay).add(7,'day'));
				setEndDay(moment(endDay).add(7,'day'))
			}
		};
		const disabled = isNumber(editableRow);
		const disableLeft = moment(startDay).isSameOrBefore(moment(),'day') || disabled;
		return <div style={{ marginLeft:20 }} className={'arrowWrapper'}>
				<a onClick={handleClick['LEFT']} disabled={disableLeft}><Icon type="left"/></a>
				<a onClick={handleClick['RIGHT']} disabled={disabled} className={'right'}><Icon type="right"/></a>
		</div>
	}
	const freshIcon = ()=>{
		return <div>Current scheduled appointments/Capacity
				<Icon type={'sync'} style={{ marginLeft:10,cursor:'pointer' }} onClick={()=>fetData(true)}/>
		</div>
	}
	const renderTitle = ()=><div className={'title'}>
		  <span>Slot management</span>
		  <div style={{ marginLeft: 10 }} className={'timezone'}>( <Icon type="global" style={{ marginLeft:5 }}/><span style={{ margin:'0 5px'}}>{moment.tz.guess()} )</span></div>
	</div>
	const renderHeader =()=> <div className={'headerWrapper'}>
		{ renderTitle() }
		<div className={'subtitle'}>
			{ renderMonth() }
			{ freshIcon() }
		</div>
	</div>
	return <div >
			<TimeSlotManagementComponent renderHeader={renderHeader}  editableRow={editableRow} setEditableRow={setEditableRow}
			                             slots={slots} startDay={startDay} endDay={endDay} updateSlots={updateSlots} loading={loading}/>
	</div>
}
export default TimeSlotManagementContainer;