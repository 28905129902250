import React from "react";
import { FormattedInput } from "@buttercup/react-formatted-input";
class DateFormattedInput extends React.Component {
  render () {
    const {className, onChange, disabled, value, style} = this.props;
    const DateFormat = [
      { char: /[01]/, repeat: 1 },
      { char: /\d/, repeat: 1 },
      { exactly: "/" },
      { char: /[0123]/, repeat: 1 },
      { char: /\d/, repeat: 1 },
      { exactly: "/" },
      { char: /[12]/, repeat: 1 },
      { char: /\d/, repeat: 3 }
    ];
    return <FormattedInput
    className={`ant-input ${className}`}
    style={style}
    format={DateFormat}
    onChange={onChange}
    disabled={disabled}
    placeholder="MM/DD/YYYY"
    value={value}
  />
  }
}

export default DateFormattedInput;