import { getRequest } from "../helper";
import { getLoginUser} from "../../auth/helper";

const USER_URL = '/api/enterprise/user/profile';
const PROCTOR_URL = '/api/proctoring/operators/profile';
const USER_HEADER =(auth)=>({
	    "Authorization": `kit ${auth || getLoginUser()}`,
		"Accept": "*/*",
})
export const getUserMutation =(auth)=> getRequest(USER_URL,{ headers:USER_HEADER(auth) });
export const getProctorQuery = (auth)=>getRequest(PROCTOR_URL,{ headers:USER_HEADER(auth )})