import React from 'react';
import LinkButton from './LinkButton';

const GetStartedNowSection = () => {
    return <div className="getStartedNowSection ">
        <div className="sectionWrapper">
            <div className='getStartedNowContent'>
                <div>
                    <img className='imgLeft' src={'/image/test_kit2.png'} alt="test_kit2" />
                </div>
                <div className='getStartedText'>
                    <div className='getStartedNowHeader'>
                        <h2>
                        Get started now and enjoy 10% off on subscription orders
                        </h2>
                    </div>
                </div>
                <div>
                    <img className='imgRight' src={'/image/delivery2.png'} alt="delivery2" />
                </div>
            </div>
            <div className='getStartedNowFooter'>
                <LinkButton to="/signup" text="Get started" className='button linkButton' />
            </div>
        </div>
    </div>

}

export default GetStartedNowSection;
