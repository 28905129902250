import React from "react";
import { Icon } from 'antd';
import moment from "moment";
const SingleMessageComponent = (props)=>{
	const { msg,index,uid } = props;
	const { text='',role,ts} = msg;
	const roleIconMap = {
		PROCTOR:"/image/proctorAvatar.png",
		TESTER:"/image/testerAvatar.png"
	}[role]

	return <div className={'singleMsg'}>
			<div style={{display:'flex'}}>
				<img src={roleIconMap} width={25} height={25} style={{marginRight:10}}/>
				<div className={'msgBody'}>{text}</div>
			</div>
			<div style={{marginLeft: 'auto',color:'#6B7178',fontSize: 12 }}>{moment(ts).format('h:mm A')}</div>
	</div>
}
export default SingleMessageComponent;