import React, {useEffect, useRef, useState} from "react";
import {InputNumber, Table} from "antd";
import moment from "moment-timezone";
import '../style/index.scss';
import { difference,groupBy,sortBy,set,isNumber } from 'lodash';
import Icon from "antd/es/icon";
const TimeSlotManagementComponent = (props)=>{
	const { slots,startDay,endDay,updateSlots,loading,renderHeader,editableRow,setEditableRow } = props;
	const [ expandedRows, setExpandedRows ] = useState([]);
	// const [ editableRow,setEditableRow ] = useState();
	const [ editedDates, setEditedDates ] = useState({});
	const [ timeSlotMap,setTimeSlotMap ] = useState({});
	const rowKeys = useRef();
	let tz = moment.tz.guess();
	let enumerateDaysBetweenDates = (startDate, endDate)=>{
		let dates = [];
		const currDate = moment(startDate).startOf('day');
		const lastDate = moment(endDate).endOf('day');
		while(currDate.diff(lastDate) <= 0) {
			dates.push(currDate.clone().toDate());
			currDate.add(1, 'days').diff(lastDate);
		}
		return dates;
	};
	const handleParseSlots = ()=>{
		let res = [];
		for (const [ k,v ] of Object.entries(editedDates)) {
			for(let i=0;i<=3;i++){
				if(timeSlotMap[k]) {
					res.push({
						timeSlotIndex: timeSlotMap[k] + i,
						capacity: Math.round(v / 4)
					})
				}
			}
		}
		return res;

	}
	const showEditSaveIcon = (isEditing,index)=>{
		const handler = isEditing ? async ()=> {
			let parsedSlot = handleParseSlots();
			if(parsedSlot.length) {
				await updateSlots(parsedSlot);
			}
			setEditableRow(null);
			setEditedDates({});
			setTimeSlotMap({});
		}: ()=>{
			setEditableRow(index);
			setExpandedRows([index]);
		};

		const text = isEditing ? 'Save' : 'Edit';
		return <a onClick={handler} className={'editIcon'} disabled={isNumber(editableRow)&&!isEditing}>{text}</a>
	}
	const showCancelBtn = ()=>{
		return <div className={'editIcon'} style={{ color:'#6B7178' }} onClick={()=>setEditableRow()}>Cancel</div>
	}
	const renderExpandIcon = ()=>{
		const len = rowKeys.current.length;
		const keys = [];

		const expandAll = ()=>{
			for(let i=0;i<len;i++) {
				keys.push(i);
			}
			if(expandedRows.length) {
				setExpandedRows([]);
			}else{
				setExpandedRows(keys);
			}
		}
		let dir = !expandedRows.length ? 'down' : 'up';
		return {
			key:'expand',
			title:<div style={{marginTop:45,cursor:'pointer'}} onClick={()=>expandAll()}>Expand All <Icon style={{ marginLeft:10 }} type={dir}/></div>,
			render:(c,d,i)=>{
				let newRows = expandedRows.includes(i) ?  difference(expandedRows,[i]) : [...expandedRows,i];
				let isEditing = i===editableRow;
				return <div key={i} className={'expandIconWrapper'}>
						{ showEditSaveIcon(isEditing,i) }
						{ isEditing ? showCancelBtn():'' }
						<Icon type="down" onClick={()=>setExpandedRows(newRows)}/>
				</div>
			}
		}
	}
	const onChangeInput = (num,key,day,map)=>{
		setTimeSlotMap(map);
		setEditedDates({
			...editedDates,
			[day]: num
		})
	}
	const getColumns = ()=>{
		let dates = enumerateDaysBetweenDates(startDay,endDay);
		let columns =  dates.map(d=>{
			const isToday = moment(d).isSame(moment(),'day');
			const formattedDate = moment.utc(d).tz(tz).format('D ddd');
			const [dayOfMonth,dayOfWeek] = formattedDate.split(' ');
			return {
				key: formattedDate,
				width:'160px',
				title:<div className={'dateTitleWrapper'}>
					<div className={`dayOfWeek`}>{dayOfWeek}</div>
					<div className={`dayOfMonth ${isToday ? 'isToday' :''}`}>{dayOfMonth}</div>
				</div>,
				render:(item,doc,index)=>{
					let capacity = 0;
					let seatsOccupied = 0;
					const res = Object.entries(item)[0];
					const [ key,value ] = res;
					let editing = index === editableRow;
					let slotsFromDate = item[key];
					let timeSlot = null;
					let map = { };
					let day = moment.utc(d).tz(tz).format('MM/DD/YYYY')

					slotsFromDate.forEach((cur,j)=>{
						if(!map[moment.utc(cur.timeSlot).tz(tz).format('MM/DD/YYYY')]) {
							map[moment.utc(cur.timeSlot).tz(tz).format('MM/DD/YYYY')] = cur.timeSlotIndex;
						}
						if(moment.utc(cur.timeSlot).tz(tz).isSame(moment(d),'day')) {
							capacity += cur.capacity;
							seatsOccupied += cur.seatsOccupied;

						}
					});
					let totalSeats = editing ? <InputNumber defaultValue={capacity}
					                                        onChange={(n)=>onChangeInput(n,key,day,map)}
					                                        step={4} min={0}/>
	                                         : <strong>{capacity}</strong>
					return <div>{seatsOccupied} / {totalSeats} </div>

				}
			}

		})
		columns.unshift({
			key:'timeTitle',
			width:'250px',
			render:(item,doc,index)=>{
				const res = Object.entries(item)[0];
				const [ key,value ] = res;
				return `${key}`;
			}
		})
		columns.push(renderExpandIcon());
		return columns;
	}
	const parseDateSource = ()=>{
		// let sortedSlots = slots.sort(s=>s.timeSlot);
		const groupedDate = groupBy(slots,(s)=>moment.utc(s.timeSlot).tz(tz).format('hh:00 A'));
		let set = new Set();
		let dates = [];
		for(const [key,value] of Object.entries(groupedDate)) {
			if(set.has(key)){
				continue;
			}else {
				dates.push({
					[key]:groupedDate[key]
				});
				set.add(key);
			}
		}
		dates.sort((a,b)=>{
			const aKey = Object.keys(a)[0];
			const bKey = Object.keys(b)[0];
			return moment(aKey,'hh:mm A').isBefore(moment(bKey,'hh:mm A')) ? -1 : 1;
		});
		let newArr = Array.from(set);
		newArr.sort((a,b)=>moment(a,'hh:mm A').isBefore(moment(b,'hh:mm A')) ? -1 : 1);
		rowKeys.current = newArr;
		return dates;
	}
	const expandedRowRender = (record,index,indent) => {
		const value = Object.values(record)[0];
		let dataSource = [];
		let dates = enumerateDaysBetweenDates(startDay,endDay);
		const groupedDate = groupBy(value,(s)=>moment.utc(s.timeSlot).tz(tz).format('hh:mm'));
		for(const [key,value] of Object.entries(groupedDate)) {
			let obj = { };
			obj.key=key;
			value.forEach(v=>{
				let day = moment.utc(v.timeSlot).tz(tz).format('MM/DD')
				set(obj,day,v)
			})
			dataSource.push(obj);
		}

		let columns = dates.map(d=>{
			const formattedDate = moment.utc(d).tz(tz).format('MM/DD');
			return {
				key: formattedDate,
				title:'',
				dataIndex:formattedDate,
				width:'160px',
				render:(slot=[],doc)=>{
					let { capacity=0,seatsOccupied=0 } = slot;
					return <div>{`${seatsOccupied} / ${capacity}`}</div>
				}
			}
		})
		columns.unshift({
			key: 'timeOfDay',
			title:'',
			dataIndex:'key',
			width:'230px',
			render:(item,doc)=>(item)
		})
		columns.push({
			key:'dummy'
		})
		return <Table dataSource={dataSource} columns={columns} pagination={false}/>
	}
	const renderTimeSlotTable = ()=>{
		return <Table dataSource={parseDateSource()} columns={getColumns()} className={'timeSlotTable'}
		              expandIcon={()=>''}
		              pagination={false}
		              loading={loading}
		              expandedRowKeys={expandedRows}
		              expandedRowRender= { expandedRowRender }
		/>
	}
	return <div className={'timeSlotTableWrapper'}>
			{ renderHeader() }
			{renderTimeSlotTable()}
	</div>
}
export default TimeSlotManagementComponent;