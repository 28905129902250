import React from "react";
import {Radio,Row,Col,DatePicker,Select,Divider,Spin} from "antd";
import '../style/index.scss';
import '../../Order/style/index.scss';
import { get } from 'lodash'
import { centsToDollar } from "../../lib/helpers";
import { useSelector } from "react-redux";
import ProductContentComponent from "./ProductContentComponent";

const PaymentDetailsComponent = props=>{
	const {product} = useSelector(state => state.orderReducer);
	const { priceDetails,setType,type,setUnit,setFreq,setStartDate,error,loadingPrice } = props;
	const productContent = ()=>{
		return <div className={'orderBottomWrapper'} style={{lineHeight: '30px', background: '#F4F6F8', padding: "40px" }}>
			<ProductContentComponent product={product} {...priceDetails} />
		</div>
	}


	const paymentSummarySub = (priceDetails,showContent)=>{
		const {subTotal,total,total_tax,items=[],discount,taxDetails=[] } = priceDetails;
		const isSubscription = type === 'subscription';
		let peopleCount = taxDetails.length;
		let qtPerPerson = get(taxDetails,'0.quantity',0);
		let totalCount = peopleCount * qtPerPerson;
		if(loadingPrice) {
			return <Spin/>
		}
		return <div className={`totals`}>
						<div className="totals-item">
							<label>Quantity</label>
							<div className="totals-value" id="cart-subtotal">{`${totalCount} packs (${peopleCount} recipients x ${qtPerPerson} packs/recipient)`}</div>
						</div>
						<div className="totals-item">
							<label>Subtotal</label>
							<div className="totals-value" id="cart-subtotal">{centsToDollar(subTotal)}</div>
						</div>
						<div className="totals-item">
							<label>Discount</label>
							<div className="totals-value" id="cart-subtotal">-{centsToDollar(discount)}</div>
						</div>
						<div className="totals-item">
							<label>Shipping</label>
							<div className="totals-value" id="cart-subtotal">Free</div>
						</div>
						<div className="totals-item">
							<label>Tax</label>
							<div className="totals-value" id="cart-tax">{centsToDollar(total_tax)}</div>
						</div>
						<Divider style={{color: '#E7EAED',widthL:550}}/>
						<div className="totals-item totals-item-total">
							<label>Total</label>
							<div className="totals-value" id="cart-total">{centsToDollar(total)}</div>
						</div>
				</div>
	}

	return <div>
			{ error ? <div style={{ color:'#f5222d'}}>{ error }</div>:'' }
			<Row style={{ display:'flex',marginTop: 10 }}>
				<div style={{ width:'fit-content'}}>{productContent()}</div>
			</Row>
			<div className={'paymentOrderGroup'}>{ paymentSummarySub(priceDetails,type =='subscription') }</div>
		   </div>
}
export default PaymentDetailsComponent;