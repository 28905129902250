import React from 'react';


const CollectTestResultGuidanceComponent = (props)=>{
    return <div className={'testGuidanceWrapper'}>
        <div className="serviceTypeLabel">Service type:</div>
        <div className="serviceType">Interpret and generate test report </div>
        <div className="instructions">
            <div className="instruction">
                <h4>1. Introduce yourself.</h4>
                <p><i>Script:  Thank you for joining the iHealth Verified COVID test appointment. My name is [Name], and I will be your proctor to interpret and verify you test result.</i></p>
            </div>
            <div className="instruction">
                <h4>2. Verify tester's identity.</h4>
                <p>Have the tester to place their photo ID in front of the camera. Check and make sure the <strong>name</strong>, <strong>date of birth</strong> and <strong>photo ID</strong> match this person.</p>
            </div>
            <div className="instruction">
                <h4>3. Log &amp; send test result report.</h4>
                <p>a) Have the tester to place the test card in front of the camera.</p>
                <p>b) Interpret the result and log the result on the screen.</p>
                <p>c) Confirm email with the tester before sending the report.</p>
            </div>
        </div>

    </div>
}

export default CollectTestResultGuidanceComponent;