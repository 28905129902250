import React, {useEffect, useState,useCallback} from 'react';
import {Switch, Row, Col, Card, DatePicker,InputNumber} from "antd";
import { SUBSCRIPTION_PLAN_MAP,ORDER_PLAN_MAP } from '../../constant/order';
import debounce from 'lodash.debounce';

import '../../style/index.scss';
import moment from 'moment-business-days';
const StepFourComponent = (props)=>{
	const { setOrderType,orderType,setFreq,setUnit,setOrderCount,setStartDate,orderCount,frequency,frequencyUnit } = props;
	const [ selectedCard,setSelectedCard ] = useState(2);
	useEffect(()=>{
		if(orderType==='order') {
			setFreq(1);
			setUnit('week');
			setSelectedCard(6);
		}else if(orderType=='subscription') {
			setSelectedCard(2);
			setOrderCount(1)
		}
	},[orderType])
	const debouncedChangeHandler = useCallback(
		debounce(setOrderCount, 300)
		, []);
	const onSelectCard = (key,frequency,frequencyUnit,orderCount)=>{
		if(selectedCard!==key) {
			setSelectedCard(key);
			setFreq(frequency);
			setUnit(frequencyUnit);
			setOrderCount(orderCount)
		}
	}

	const renderOrderOption = ()=>{
		let keys = Object.keys(ORDER_PLAN_MAP);
		return <div>
				{keys.map(k=><div key={k}>{orderPlanCard(ORDER_PLAN_MAP[k])}</div>)}
				<div style={{ marginTop:10 }}><img src={'/assess/fast-delivery-icon.png'}/><span style={{ marginLeft: 15 }}>Estimated ship within 1 business day</span></div>
			   </div>
	}

	const orderPlanCard = (p)=>{
		const { label,subTitle,extra,key,frequency, frequencyUnit,count,className} = p;
		return <Card className={`orderPlanCard ${ selectedCard===key ?' selected':'' } ${className ? className :''}`} key={key} title={label} extra={extra}
		             onClick={()=>onSelectCard(key,frequency,frequencyUnit,count)}>
			{subTitle}
		</Card>
	}
	const nextShipDate = ()=>{
		const disabledDate = (curDate)=>{
			if(curDate &&!moment(curDate).isBusinessDay()){
				return true;
			}
			// Can not select days before today and today
			return ((moment().add('1','day') > curDate || curDate > moment().add('3','month')));
		}
		return <Col span={9}>
			<div>First ship date</div>
			<div><DatePicker onChange={(m)=>setStartDate(m)} allowClear={false}
			            format={'MM/DD/YYYY'}
			            showToday={false}
			            disabledDate={disabledDate}
			            defaultValue={moment().add('1','day')}/>
			</div>
		</Col>
	}
	const renderSubscriptionOpts = ()=>{
		let keys = Object.keys(SUBSCRIPTION_PLAN_MAP({setUnit,setFreq,setOrderCount,frequency, frequencyUnit,orderCount,selectedCard}));
		return keys.map((k, key)=><Col key={key}>{orderPlanCard(SUBSCRIPTION_PLAN_MAP({setUnit,setFreq,setOrderCount,frequency, frequencyUnit,orderCount,selectedCard})[k])}</Col>)
	}
	const packEachPerson = ()=>{
		const max = 5;
		return <Col>
				<span style={{ marginTop: 30 }}>Select the number of packs for each recipient</span>
						<Row type="flex" align="middle" gutter={10}>
							<Col>
								<InputNumber min={1} max={max} onChange={(e)=>setOrderCount(e)} value={orderCount}/>
							</Col>
							<Col>
			 					{orderCount>max? <span style={{color: 'red'}}>Limit {max} packs/recipient</span> : ''}
			 				</Col>
						</Row>
			</Col>
	}
	const renderOptions = ()=>{
		return orderType === 'order' ? renderOrderOption() : renderSubscriptionOpts();
	}
	const extraContent = orderType === 'order' ? packEachPerson() : nextShipDate();
	return <Row>
			<Col span={24} style={{ display:'flex',marginBottom:20 }}><Row type="flex" gutter={[0, 20]}>{renderOptions()}</Row></Col>
			{ extraContent }
		   </Row>
}
export default StepFourComponent;