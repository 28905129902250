import React, { useEffect, useState } from 'react';
import CancelAppComponent from "../component/CancelAppComponent";
import { getSessionStatusQuery, getTesterFormQueryWithoutAuth, getVisitQuery } from "../../API/query/schedule";
import { get, isEmpty } from 'lodash';
import { useParams } from "react-router-dom";
import { Tabs, Spin } from "antd";
import CancelAppFinishedComponent from "../component/CancelAppFinishedComponent";
import CancelFinishedScheduledComponent from "../component/CancelFinishedScheduleComponent";
import {isCompleted} from "../../schedule/constant/CheckStatus";
const CancelAppContainer = (props) => {
	const { code } = useParams();
	const [tester, setTester] = useState({});
	const [visit, setVisit] = useState({});
	const [status, setStatus] = useState('INIT');
	const getAdditionalInfo = async () => {
		let status = await getSessionStatusQuery(code);
		let visit = await getVisitQuery(code);
		let tester = await getTesterFormQueryWithoutAuth(code);
		let statusInfo = get(status, 'data.data', {});
		let visitData = get(visit, 'data.data', {});
		let testerInfo = get(tester, 'data.data', {});
		if (visitData) {
			setVisit(visitData)
		}
		if (statusInfo) {
			setTester(statusInfo);
		}
		if (testerInfo) {
			setTester({ ...statusInfo, ...testerInfo });
		}
		const {Status} = statusInfo;
		if (isCompleted(Status)) {
			setStatus('COMPLETED');
		}
	}

	useEffect(() => {
		getAdditionalInfo();
	}, []);
	return isEmpty(tester) ? <Spin /> : <Tabs defaultActiveKey={'INIT'} activeKey={status} className={'scheduleVisitTabs'}>
		<Tabs.TabPane key={'INIT'} tab={'init'}>
			<CancelAppComponent tester={tester} visit={visit} header={'Cancel appointment?'} setStatus={setStatus} />
		</Tabs.TabPane>
		<Tabs.TabPane key={'CANCELLED'} tab={'cancelled'}>
			<CancelAppFinishedComponent tester={tester} visit={visit} />
		</Tabs.TabPane>
		<Tabs.TabPane key={'COMPLETED'} tab={'completed'}>
			<CancelFinishedScheduledComponent tester={tester} visit={visit} />
		</Tabs.TabPane>
	</Tabs>
}
export default CancelAppContainer;