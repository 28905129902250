import {billingInfoFormMap} from "../../SignUp/constant/signUpForm";
import { Col, Form, Row} from "antd";
import CreditCardInputComponent from "./CreditCardInputComponent";
import React from "react";

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default (props)=>{
	const {  props:{ form },state,setInputValue,setCCError  } = props;
	const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form;
	// let { user } = props;
	let formItemList = [];
	// const disabled = hasErrors(getFieldsError());
	for(let [index,array] of Object.entries(billingInfoFormMap())) {
		let itemsInRow = [];
		if(Array.isArray(array)){
			for(let [colIndex,item] of Object.entries(array)) {
				let { rules,render,col,key,label,offset } = item;
				const error = isFieldTouched(key) && getFieldError(key);
				// let initialValue = get(user,FORMKEY_TO_USERKEY[key]);
				itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
					<Form.Item validateStatus={error ? 'error':''} help={error || ''}label={label} key={key}>{ getFieldDecorator(key,{ rules } )(render()) }</Form.Item>
				</Col>)
			}
		}
		formItemList.push(<Row key={index}>{itemsInRow}</Row>)
	}

	// return <Form className={'settingsForm'} layout={'horizontal'} onSubmit={this.handleSubmit}>
	// 	{formItemList}
	// 	<Form.Item>
	// 		<Button onClick={()=>this.onClickSave()}  style={{ marginTop:30 }} disabled={disabled}>Save</Button>
	// 	</Form.Item>
	// 	<div onClick={()=>this.setShowModal(true)} className={'changePWLink'}>Change Password</div>
	// </Form>
	return <div>
		{/*<Form className={'creditCardForm settingsForm'}>*/}
			<Row>
				<div className={'ant-col ant-form-item-label'}>Credit/debit card number</div>
				<div className={'ant-col ant-form-item-control-wrapper'}>
					<CreditCardInputComponent setInputValue={ setInputValue }
		                          setCCError={ setCCError }
		                          state={state}/>
				</div>
			</Row>
			{/*{formItemList}*/}
		{/*</Form>*/}
	</div>
}