import React from "react";
import moment  from 'moment-timezone';
import '../style/index.scss';
import {convertUTCToTimezone} from "../../lib/helpers";
// function convertUTCToTimezone(utcDt, utcDtFormat='YYYY-MM-DD hh:mm:ss A', timezone='UTC') {
// 	return moment.utc(utcDt, utcDtFormat).tz(timezone).format('hh:mm A');
// }
const AvailableSlotCardComponent = (props)=>{
	const { slot:{ timeSlot, timeSlotIndex, seatsAvailable },timezone={},setSelectedSlot,selectedSlot={} } = props;
	const parseSlotTime = (timeSlot)=> {
		let slot = convertUTCToTimezone(timeSlot,'YYYY-MM-DD hh:mm:ss A',timezone.value || timezone);
		return <div className={'time'} >{slot} </div>;
	};
	const isAvailable = seatsAvailable>0 && moment.utc(timeSlot).isAfter(moment.utc());
	const selected = timeSlotIndex === selectedSlot.timeSlotIndex;

	const handleClickCard = ()=>{
		if(isAvailable){
			setSelectedSlot(props.slot);
		}
	}
	return <div onClick={()=>handleClickCard()} className={`slotCard ` + (isAvailable ? '' : 'notAvailable' ) +(selected ? 'selected' : '')}>{ isAvailable ? parseSlotTime(timeSlot) : 'Not Available'}</div>
}
export default AvailableSlotCardComponent;
