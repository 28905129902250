import React, { useEffect, useState } from "react";
import { getAvailableSlots } from "../../API/query/schedule";
import AvailableSlotsComponent from "../component/AvailableSlotsComponent";
import { Button, DatePicker, Radio, Icon } from "antd";
import moment from "moment-timezone";
import '../style/index.scss';
import TimezoneSelect from 'react-timezone-select'
import RadioGroup from "antd/es/radio/group";
import { timer } from "redux-logger/src/helpers";
import { OFFICIAL_WEBSITE_URL } from "../../lib/constant";
import AppointmentDetailComponent from "../component/AppointmentDetailComponent";
import { isEmpty } from 'lodash';
import { isMobile } from "../../lib/helpers";
import DateFormattedInput from '../../Accessories/component/DateFormattedInput';
import { website} from "../../API/config/index";
import TechnicalRequirementsComponent from '../../Accessories/component/TechnicalRequirementsComponent';
import WhatToDoNextComponent from '../../Accessories/component/WhatToDoNextComponent';

const AvailableSlotsContainer = (props) => {
	// const [ slots,setSlots ] = useState([]);
	// const [selectedTimezone, setSelectedTimezone] = useState(  Intl.DateTimeFormat().resolvedOptions().timeZone)
	const [hasKit, setHasKit] = useState();
	// const [ selectedSlot, setSelectedSlot ] = useState();
	const { type, status, setStatus, selectedTimezone, setSelectedTimezone, selectedSlot, setSelectedSlot, startTime, setStartTime, slots, setSlots, visit, tester = {},preview } = props;
	const { ScheduledAt, TimezoneLabel, Timezone } = visit || {};
	const [dateErrorTip, setDateErrorTip] = useState();
	const [collapsed, setCollapsed] = useState(true);
	const disabledDates = (date) => {
		if (!date) {
			return false;
		}
		const str = date.format('MM/DD/yyyy');
		const ts_tz = moment.tz(str, 'UTC').unix() - selectedTimezone.offset * 3600;
		const date_tz = moment.tz(moment.unix(ts_tz), selectedTimezone.value);
		const now = moment.utc().tz(selectedTimezone.value);
		return date_tz.isBefore(now.startOf('day')) || date_tz.isAfter(now.startOf('day').add(30, 'day'));
	}

	const handleStartTimeMobile = (selectedTime) => {
		const select = moment(selectedTime);
		if (select.format('MM/DD/yyyy') !== selectedTime || disabledDates(select)) {
			setStartTime();
			setSelectedSlot();
			setSlots();
			setDateErrorTip('Please enter a date within one month in format MM/DD/YYYY')
			return;
		}
		setDateErrorTip('');
		handleStartTime(select);
	}

	const handleStartTime = (selectedTime) => {
		if (selectedTime && selectedTimezone) {
			const str = selectedTime.format('MM/DD/yyyy');
			const ts_tz = moment.tz(str, 'UTC').unix() - selectedTimezone.offset * 3600;
			const time = moment.tz(moment.unix(ts_tz), selectedTimezone.value);
			setStartTime(time);
			setSelectedSlot();
		}
	}
	const hasScheduled = () => {
		const {ticketNum=''} = props;
    const path = 'verifyTest/redeem'
		const link = `${website}/${path}/${ticketNum}`;
		const collapseIcon = !collapsed ? 'up-circle' : 'down-circle';
		const extraDisplay = !collapsed ? 'inline' : 'none';
		return !isEmpty(visit) && 
		<div className={'hasScheduledWrapper'}>
			<div className={'msg'}>Your current appointment is scheduled for the time and date shown below. <br /> If you need to reschedule, scroll down to select a new date and time.</div>
			<div className={'fromScheduleFormWrapper'}>
				<div className={'content'}>
					<div className="header">
						<div className={'title'}>My appointment</div>
						<p>Below is the link to join the appointment. Please mark the date & time in your calendar and join on time.</p>
						<a href={link} target="_blank">{link}</a>
					</div>
					<AppointmentDetailComponent {...tester} selectedSlot={{ timeSlot: ScheduledAt }} selectedTimezone={{ label: TimezoneLabel, offset: Timezone }} fromScheduleForm={true} />
					<div style={{display: extraDisplay}}>
							<div className={'techWrapper'}>
								<h3>Technical Requirements</h3>
								<TechnicalRequirementsComponent />
							</div>
							<WhatToDoNextComponent />
					</div> 
					<div className={'iconWrapper'}>
						<Icon type={collapseIcon} className={'collapseIcon'} onClick={()=>{
								const targetElem = collapsed ? '.iconWrapper' : '.fromScheduleFormWrapper';
								const elem = document.querySelector(targetElem);
								const rect = elem.getBoundingClientRect();
								window.scrollBy(0, rect.top);
								setCollapsed(!collapsed);
							}}/>
					</div>
				</div>
			</div>
		</div>
	}
	const renderHeader = () => <div className={'title'}>{preview? 'Check Appointment Availability' : 'Select a Date & Time'}</div>

	const renderMobileDateInput = () => {
		return <div style={{ margin: '10px 0 20px' }}>
			<DateFormattedInput
				onChange={handleStartTimeMobile}
				disabled={!selectedTimezone}
				className={'dateInput'}
			/>
			{!!dateErrorTip ? <div className={'errorDiv'}><span>{dateErrorTip}</span></div> : ''}
		</div>
	}

	const renderPicker = () => {
		return <div className={'timePickerWrapper'}>
			<span>Select the date you will be taking the test</span>
			{ isMobile() ? renderMobileDateInput() :
				<DatePicker dropdownClassName={'scheduleDatePicker'} defaultValue={startTime} value={startTime} disabledDate={disabledDates} disabled={!selectedTimezone} onChange={handleStartTime} allowClear={false} format={'MM/DD/yyyy'} showToday={false} />
			}
			<span style={{ maxWidth: 500 }}>Only the time slots in the following 30 days are open to schedule. If you wish to schedule your test more than 30 days away, please come back later!</span>
		</div>
	};

	const handleChangeTZ = (tz) => {
		setStartTime();
		setSlots();
		setSelectedSlot();
		setSelectedTimezone(tz);
	};

	const renderTZPicker = () => {
		return <div className="timezone--wrapper">
			<span>Select the time zone of the city where you will be taking the test</span>
			<TimezoneSelect onChange={(tz) => handleChangeTZ(tz)} value={selectedTimezone || ''} />
		</div>
	}
	const getSlots = async () => {
		if (selectedTimezone && startTime) {
			try {
				let startTimeStr = startTime.tz(selectedTimezone.value || selectedTimezone).startOf('day').valueOf();
				let endTimeStr = startTime.tz(selectedTimezone.value || selectedTimezone).endOf('day').valueOf();
				let res = await getAvailableSlots({ startTime: startTimeStr, endTime: endTimeStr });
				setSlots(res.data.data);
			} catch (e) {
				console.log(e);
			}
		}

	}
	useEffect(() => {
		getSlots()
	}, [selectedTimezone, startTime])

	const renderConfirmScheduleRadio = () => {
		return <div className={'testKitReadyRadio'}>
			<span style={{ maxWidth: 1200 }}>Please confirm that you will have an <strong>unopened iHealth COVID-19 Antigen Rapid Test Kit</strong> ready on the appointment date you selected.
				Test kits from other manufacturers, or iHealth test kits that are opened are NOT acceptable.  </span>
			<div><img src={'/image/Test_kits.png'} style={{ width: '25em', margin:'1em 0px', maxWidth: '90vw' }}/></div>
			<RadioGroup style={{ display: 'flex', flexDirection: 'column' }} defaultValue={hasKit} onChange={(e) => setHasKit(e.target.value)}>
				<Radio className={'radio'} value={true}>
					<div>Yes. I will have the test kit with me on the appointment date.</div>
				</Radio>
				<Radio className={'radio'} value={false}>
					<div>No. I am not sure if I will have a test kit ready</div>
				</Radio>
			</RadioGroup>
		</div>
	}
	const errorDiv = () => {
		const show = hasKit === false
		return show && <div className={'errorDiv'}>
			<span>
				Please make sure your test kit meet the requirments. If you need to purchase a new test kit from iHealth,  please purchase as soon as possible.
				<a href={OFFICIAL_WEBSITE_URL} target="_blank" style={{ color: 'black' }}>Purchase now</a>
			</span>
		</div>
	}
	const renderBtn = () => {
		const disabled = (hasKit !== true) || !selectedSlot;
		const onClick = () => {
			setStatus('USERINFO');
		}
		return <div className={'confirmBtn'}>
			<Button disabled={disabled} onClick={onClick}>Confirm date & time</Button>
		</div>
	}


	return <div className={'calendarWrapper'}>
		{hasScheduled()}
		{renderHeader()}
		{renderTZPicker()}
		{renderPicker()}
		{<AvailableSlotsComponent slots={slots} setStatus={setStatus} timezone={selectedTimezone} setSelectedSlot={setSelectedSlot} selectedSlot={selectedSlot} preview={preview} />}
		{ !preview && renderConfirmScheduleRadio()}
		{errorDiv()}
		{ !preview && renderBtn() }
	</div>
}
export default AvailableSlotsContainer;
