import React from "react";
import { Icon } from "antd";
import moment from "moment-timezone";
function convertUTCToTimezone(utcDt, utcDtFormat = 'YYYY-MM-DD hh:mm:ss A', timezone = 'UTC', offset = 0) {
	return offset != 0 ?
		moment.utc(utcDt, utcDtFormat).utcOffset(offset).format('dddd, MMM DD, YYYY, hh:mm A') :
		moment.utc(utcDt, utcDtFormat).tz(timezone).format('dddd, MMM DD, YYYY, hh:mm A');
}
const AppointmentDetailComponent = (props) => {
	const { selectedSlot: { timeSlot } = { timeSlot: '' }, selectedTimezone = {}, firstName, middleName, lastName, fromScheduleForm,className='' } = props;
	const offset = selectedTimezone.value ? moment.tz(timeSlot,selectedTimezone.value)._offset/60 : selectedTimezone.offset;
	const appointmentDetail = () => {
		return <div className={`appDetail ${fromScheduleForm ? ' fromScheduleForm' : ''} ${className}`}>
			<div>
				<div className='title'>iHealth Verified COVID Test Appointment</div>
				<div className={'item user'}><Icon type="user" />{firstName} {middleName} {lastName}</div>
				<div className={'item'}><Icon type="calendar" theme="filled" />{
					convertUTCToTimezone(timeSlot,null,selectedTimezone.value,offset)
				}</div>
				<div className={'item'}><Icon type="clock-circle" theme="filled" />30 min</div>
				<div className={'item'}><Icon type="global" />{selectedTimezone.label}</div>
			</div>
		</div>
	}

	return appointmentDetail();

}
export default AppointmentDetailComponent;