import React, {useEffect, useState} from "react";
import PaymentDetailsComponent from "../component/PaymentDetailsComponent";
import { estimateGroupOrderPriceDetails } from '../../API/query/Order';
import { get } from 'lodash';
import {Spin} from "antd";
const PaymentDetailsContainer = (props)=>{
	const { contactGroupId,orderCount,type,setType,setFreq,setStartDate ,setUnit,orderId,orderDetails} = props;
	const [ priceDetails,setPriceDetails ] = useState({});
	const [ loadingPrice,setLoadingPrice ] = useState(false);
	const [ error,setError ] = useState(null);
	const { status } = orderDetails||{};
	const getPriceBasedOnId = status !='UNPAID'
	useEffect(async ()=>{
		try{
			setLoadingPrice(true);
			let res = await estimateGroupOrderPriceDetails({ "objectType": type,id: getPriceBasedOnId&&orderId,contactGroupId,"quantity":parseInt(orderCount)});
			let bill = get(res,'data.data.bill',{});
			setPriceDetails(bill);
			setLoadingPrice(false);
		}
		catch (e) {
			const {response } = e;
			const errorMsg = get(response,'data.message');
			const errorDetail = get(response,'data.data.detail');
			setError(errorDetail);
		}

	},[type,contactGroupId,orderCount]);
	const contentMap = {
		subscription:<div className={'title'}>We’ll ship iHealth COVID-19 Antigen test kit(s) to the recipients at the frequency you choose until you cancel it, which you may do anytime via your account.</div>,
		order:		<div className={'title'}>This is a one-time purchase, <strong>thus no discount is applied.</strong> After this order, we will not continue to ship iHealth COVID-19 Antigen test kit(s) to your recipients.</div>
	}
	return <div className={'previewContent'}>
			{contentMap[type]}
			<div className={'subtitle'}>Below are the item price that will be charged in the service: </div>
				<PaymentDetailsComponent type={type}
			                          error={error}
			                          setFreq={setFreq}
			                          setUnit={setUnit}
			                          setStartDate={setStartDate}
			                          setType={setType}
		                              loadingPrice={loadingPrice}
			                          priceDetails={priceDetails}/>
		   </div>
}
export default PaymentDetailsContainer;