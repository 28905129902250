import React, {useEffect, useState} from "react";
import { Progress } from 'antd';
import { Button} from "antd";
import {startVisitMutation} from "../../API/query/redeem";
import useInterval from "../../lib/hook/useHook";
import * as TimeUnit from "spacetime";
import { testResultWaitMin } from "../config";

// import TimeUnit from 'TimeUnit';
const MS_IN_MIN = 60000;
const TimerComponent = (props)=>{
	const { state,setState,timeLeft } = props;
	const [ count,setCount ] = useState(timeLeft < 0 ? 0 : timeLeft);
	const [timerId,setTimerId ] = useState();

	const startTime = ()=>{
			if (count <= 0 && state == 'TIMER') {
				clearTimeout(timerId);
				setState('READY');
			} else {
				const nextCount = count-1000;
				setCount(nextCount < 0 ? 0 : nextCount);
			}
	}


	let timerIdFromHelper = useInterval(startTime,1000);
	if(timerIdFromHelper) {
		setTimerId(timerIdFromHelper);
	}
		// if(!timerId) {
		// 	startTime();
		// }
		// return ()=>{
		// 	clearInterval(timerId);
		// }


	const processCircleContainer = ()=>{

		function millisToMinutesAndSeconds(millis) {
			var minutes = Math.floor(millis / 60000);
			var seconds = ((millis % 60000) / 1000).toFixed(0);
			return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
		}
		return <div className={'processCircleContainer'}>
			<Progress percent={(count)/(testResultWaitMin*MS_IN_MIN)*100}  type="circle" format={percent =>millisToMinutesAndSeconds(count)} width={200} strokeColor="#2D3238"/>
		</div>
	}

	return processCircleContainer();
}

export default TimerComponent;