import React from "react";
import SettingComponent from "../component/SettingsComponent";
import '../style/index.scss';
import {getUserMutation} from "../../API/query/User";
import { getLoginUser } from '../../auth/helper/index';
import {get} from "lodash";
import {getUserInfo} from "../../auth/helper";
import { ShippingAddressElement } from "@stripe/react-stripe-js";
const SettingContainer = class extends React.Component{
	    constructor() {
		    super();
				const user = getUserInfo();
		    this.state = {
					user: user,
			    loading: true
		    }
	    }

	    componentDidMount() {
	    	const auth = getLoginUser();
				getUserMutation(auth).then(userRes=>{
					let userCode = get(userRes,'data.code');
					if(userCode === 200) {
						this.setUser(get(userRes,'data.data'));
						this.setState({
							loading: false
						})
					}
				})
	    }

		renderHeader = ()=>{
			return <div className={'header'}>Settings</div>
		}
		setUser = (user)=>{
			this.setState({
				user:{
					...this.state.user,
					...user
				}
			})
		}
		render() {
			const { renderHeader,setUser } = this;
			const { loading,user } = this.state;
			return <div className='settingsWrapper'>
					{ renderHeader() }
					{ !loading ? <SettingComponent user={user} setUser={setUser}/> :loading}
				   </div>
		}

}
export default SettingContainer;