import React from 'react';
import { Icon } from 'antd';
import '../style/technical.scss';

const TechnicalRequirementsComponent = () => {
    return <div className={'technicalRequirementsComponent'}>
        <p>The device you use to complete the appointment <strong>MUST</strong> meet the following requirements:</p>
        <div style={{ padding: '0' }}>
            <div className={'item'}>
                <Icon type="wifi" className={'icon'}/>
                <div>1. Have <strong>stable wifi access</strong> to ensure a smooth virtual meeting.</div>
            </div>
            <div className={'item'}>
                <Icon type="laptop" className={'icon'}/>
                <div>2. You must use a device that is an <strong>internet-enabled laptop</strong> or <strong>desktop computer</strong> ONLY. Tablets and smartphones are <strong>NOT</strong> supported.</div>
            </div>
            <div className={'item'}>
                <Icon type="video-camera" className={'icon'}/>
                <div>3. You must have a working <strong>microphone</strong> AND a <strong>front-facing camera</strong>.</div>
            </div>
            <div className={'item'}>
                <Icon type="chrome" className={'icon'}/>
                <div>4. Browser Requirement: <strong>Google Chrome ONLY</strong>.</div>
            </div>
        </div>
    </div>
}

export default TechnicalRequirementsComponent;
