import React from "react";
import { Upload, message, Button, Icon } from 'antd';
import '../style/index.scss';
import {uploadFile,associateContactsWithGroup} from "../../API/query/uploadFile";
import { createOrderMutation } from '../../API/query/Order';
import { get } from 'lodash';
import * as XLSX from 'xlsx';

import moment from "moment";
// const papa = require('papaparse');
// let array = fs.readFileSync('unshipped.csv',{encoding:'utf8', flag:'r'});

const UploadFileComponent = class extends React.Component{
	constructor(props) {
		super(props);
	}

	readAndParseFile = (file,id)=>{
		const { setPeopleList,setOrderId,initialGroupId,setFile } = this.props;

		var reader = new FileReader();
		reader.onload = function(e) {
			var data = e.target.result;
			var workbook = XLSX.read(data, {
				type: 'binary'
			});
			workbook.SheetNames.forEach(function(sheetName) {
				// Here is your object
				var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
				// var json_object = JSON.stringify(XL_row_object);
				setPeopleList(XL_row_object);
				if(id) {
					setOrderId(id);
				}

			})
		};

		reader.onerror = function(ex) {
			console.log(ex);
		};

		reader.readAsBinaryString(file);
	}


	setProps =  ()=>{
		const { setPeopleList,setOrderId,initialGroupId,setFile,setFileError } = this.props;
		const props = {
			accept:".xlsx",
			multiple: false,
			beforeUpload: (file) => {
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isLt5M) {
					message.error('file must be smaller than 5MB!');
				}
				if(initialGroupId) {
					setFile(file);
					(async ()=> {
						const res = await uploadFile(file);
						const { validations } = res;
						if(res.code ===400) {
							setFileError([{ lineNumber:0,warnings:{ Error: { error: res.message } }}]);
						}
						else if(validations && validations.length) {
							const errors = validations.map(v => {
								let {lineNumber, warnings} = v;
								return {
									lineNumber,warnings
								}
							})
							setFileError(errors);
						}
						else {
							this.readAndParseFile(file);
						}
					})();
				}else{
					(async ()=> {
						const res = await uploadFile(file);
						const { validations } = res;
						if(res.code ===400) {
							setFileError([{ lineNumber:-1,warnings:{ Error: { error: res.message } }}]);
						}
						else if(validations && validations.length) {
							const errors = validations.map(v => {
								let {lineNumber, warnings} = v;
								return {
									lineNumber,warnings
								}
							})
							setFileError(errors);
						}
						else {
							const id = get(res, 'id');
							this.readAndParseFile(file,id);
						}
					})();
				}
				setFile(file);
				return false;
			},
			onRemove:()=>{
				setPeopleList([]);
				if(setOrderId) setOrderId(null);
				setFile(null);
				setFileError([]);
			}
		};
		return props;
	}

	renderUploadButton = ()=>{
		let props = this.setProps();
		const { peopleList,file } = this.props;
		const disable = file
		return <Upload {...props}>
				<Button className={'uploadButton'} disabled={disable}>
					Upload recipient file
				</Button>
			   </Upload>
	}

	render() {
		return this.renderUploadButton();
	}
}

export default UploadFileComponent