export const actionTypes = {
    GET_GROUP_TEST_STATS : 'GET_GROUP_TEST_STATS',
    SET_GROUP_TEST_STATS : 'SET_GROUP_TEST_STATS',
    GET_TEST_GROUPS : 'GET_TEST_GROUPS',
    SET_TEST_GROUPS : 'SET_TEST_GROUPS',
    SET_SELECTED_GROUP : 'SET_SELECTED_GROUP',
    SET_FILTER : 'SET_FILTER',
    GET_GROUP_USER_LIST : 'GET_GROUP_USER_LIST',
    SET_GROUP_USER_LIST : 'SET_GROUP_USER_LIST',
    SET_DETAULT_DATE_RANGE : 'SET_DETAULT_DATE_RANGE',
    SET_START_END_TIME : 'SET_START_END_TIME',
    SET_IS_LOADING: 'SET_IS_LOADING',
    REMOVE_USER_FROM_GROUP: 'REMOVE_USER_FROM_GROUP',
    SET_TABLE_ROWS: 'SET_TABLE_ROWS',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE'
}

export const filterTypes = {
    ALL : 'all',
    POSITIVE: 'fail',
    NEGATIVE: 'pass',
    NORESULT: 'invalid'
}

