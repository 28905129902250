import { getLoginUser} from "../../auth/helper";
import { putRequest } from '../helper/index';
const SIGN_UP_URL ='/api/enterprise/user/profile';


const SIGN_UP_HEADERS = {
	Accept: '*/*',
	'Content-Type': 'application/json',
	"Authorization": `kit ${getLoginUser()}`,

}
export const userUpdateMutation =(parameter)=> putRequest(SIGN_UP_URL,{ headers:SIGN_UP_HEADERS, data:parameter });

