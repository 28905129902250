import React, {useEffect, useState} from 'react';
import { getResultReportQuery } from '../../API/query/redeem';
import AppointmentDetailComponent from './AppointmentDetailComponent';
import { get}  from "lodash";
const AppointmentFinishedComponent = (props) => {
    const { visit={},tester={},header='' } = props;
    const { ScheduledAt,TimezoneLabel,Timezone } = visit||{};
    const code = get(tester,'Code');
    return (
        <div className="appointmentFinishedWrapper">
            <h1>{header}</h1>
            <AppointmentDetailComponent {...tester} selectedSlot={{timeSlot:ScheduledAt}} selectedTimezone={{label:TimezoneLabel,offset:Timezone}}/>
            <div className="left-aligned">
                {/*<a href="#testReportImg" className={'testReportImg'}><img src={getResultReportQuery(code)} alt="Test Report" width={200}/></a>*/}
                {/*<a className="testReportImg" href="#testReportImg"><img className={'main'} src={getResultReportQuery(code)}  width="200px"*/}
                {/*                                            height="66px" border="0"/><span><img*/}
                {/*    src={getResultReportQuery(code)} width={200} /></span></a>*/}
                <img className={'testReportImg'} src={getResultReportQuery(code)}  width="200px"/>
            </div>
            <div style={{fontSize:16}}>
                <div style={{ marginBottom:20,marginTop:20 }}>(For laptop or desktop computer users: To save the test report, right-click the report and click Save Image. )</div>
                <div  style={{ marginBottom:20 }}>(For phone or tablet users: To save the test report, long-press the report and click Save Image.)</div>
                <div>If you have any questions regards the test service, please call our 24/7 Verified COVID Test hotline at 1-650-600-6313 (United States).</div>
            </div>
        </div>
    );
}

export default AppointmentFinishedComponent;