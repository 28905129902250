import {Icon, Row, Col, Button} from "antd";
import React from "react";
import MakeOrderButtonWrapperContainer from "../../Order/container/MakeOrderButtonWrapperContainer";

<Icon type="smile" theme="twoTone" />


const t1 = () => {
	return <p style={{width: 566 }} className={'t1'}>
		<div>Managing the periodic testing of your group can be as simple as 1-2-3.</div>
		<div style={{ marginTop:20 }}>You simply start a subscription with a roster of people you'd like the test done, and we will ship the test kits to them directly and promptly, guide them through signup and testing, and aggregate the group's test results for you to oversee.</div>
	</p>
}

const t2 = () => {
	return <div className="t2">
		<ol>
			<li>Tell us <span>whom</span> you want to order for.</li>
			<li>Subscribe and choose the <span>delivery schedule.</span></li>
			<li>Make payment.</li>
		</ol>
		<p>And we will take care of the rest.</p>
		</div>
}

const t3 = () => {
	return <Row type="flex" gutter={16} style={{maxWidth: 1100}} className={'t3'}>
		<Col md={6} lg={6}>Ship the COVID test kit(s) to <span>each recipient</span> based on the <span>delivery frequency</span> of your choice.</Col>
		<Col md={6} lg={6}>Send email instructions to guide them to <span>join your test group</span> through App.</Col>
		<Col md={6} lg={6}>Guide them to take the COVID test and submit the test result through the App.</Col>
		<Col md={6} lg={6}>Provide online procotoring services for testers to ensure test validity.</Col>
	</Row>
}

const t4 = () => {
	return <Row type="flex" gutter={20} style={{maxWidth: 900}} className={'t4'}>
		<Col md={8} lg={8}>We provide you with the <span>“Test Result” dashboard</span> to keep track of recipients’  (those who join the test group) test results.</Col>
		<Col md={8} lg={8}>No more hassle on test kit <span>regular ordering</span> and <span>distribution</span> logistics.</Col>
		<Col md={8} lg={8}>Everthing is under control to ensure the saftey of your community.</Col>
	</Row>
}

const t5=()=>{
	return <div className={'t5'}>
		Make an order now and get COVID test kit delivered sooner.
	</div>
}

export const POP_UP_CONFIG  = [{
		title:'Welcome to iHealth COVID Testing Solution. ',
		subTitle: t1(),
		prev: null,
		Next: 'Take a tour'
	},
	{
		title:'All you need to do is',
		subTitle: t2(),
		prev: 'Go back',
		Next: 'Next'
	},
	{
		title:'For your recipients, we will',
		subTitle: t3(),
		prev: 'Go back',
		Next: 'Next'
	},
	{
		title:'For you, as an organization HR, administrator, or safety officer',
		subTitle: t4(),
		prev: 'Go back',
		Next: 'Next'
	},
	{
		title:'Let’s get started now',
		subTitle: t5(),
		prev: 'Go back',
		Next: ({callback, fetchOrders})=><MakeOrderButtonWrapperContainer fetchOrders={fetchOrders} callback={callback} text={'Make an Order'} className={'createOrderBtn'}/>
	}
	]

