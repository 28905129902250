import { getRequest,postRequest } from "../helper";
import {getLoginUser} from "../../auth/helper";

const GET_RECORD_STATUS_URL =(code)=> `/api/proctoring/operators/recording-status?code=${code}`;
const START_RECORD_URL = (code)=>`/api/proctoring/operators/start-recording?code=${code}`;
const USER_HEADER =(auth)=>({
	"Authorization": `kit ${auth || getLoginUser()}`,
	"Accept": "*/*",
});
export const getRecordStatusQuery = (code)=>getRequest(GET_RECORD_STATUS_URL(code),{ headers:USER_HEADER() });
export const startRecordMutation = (code)=>postRequest(START_RECORD_URL(code),{ headers:USER_HEADER() })