import * as React from "react";
import { useAuth } from '../../auth';
import { Button } from 'antd';
import 'antd/lib/message/style/index.css';
import { get } from 'lodash';
import { getUserMutation} from '../../API/query/User';
import {setLoginToken,setUserInfo} from "../../auth/helper";
import { LOGIN_ERROR_MAP } from '../constant/errorMap'
import {useParams} from "react-router-dom";
const logInSucceed = (role)=>{
	const homepage = {
		cs:'/cs/tickets',
	}[role]
	window.location.href = homepage || '/';
}

export function LogInButton(props) {
	const { formValidation,disabled,setLogInError } = props;
	let auth = useAuth();
	let params = useParams();
	async function handleSubmit(e){
		e.preventDefault();
		try{
			let { username,password } = await formValidation();
			let res = await auth.signin({username,password});
			let code = res.data.code;
			if(code === 200) {
				const auth = get(res,'data.data.token');
				const userRes = await getUserMutation(auth);
				let userCode = get(userRes,'data.code');
				if(userCode === 200) {
					setLoginToken(auth);
					setUserInfo(get(userRes,'data.data'));
					logInSucceed(params.role);
				}
			}
		}
		catch(e){
			const code  = e.response.status;
			setLogInError(LOGIN_ERROR_MAP[code]);
		}
	}
	return <Button onClick={handleSubmit} type="primary" id={'loginButton'} disabled={disabled}>Login</Button>
}