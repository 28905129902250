import React from 'react';
import {Modal, Icon} from 'antd';

const SubmitSuccess = ({visible, close, footer}) => {


    return <Modal visible={visible} centered onCancel={close} footer={footer}>
        <Icon type="check-circle" theme="filled" />
        <h3>Your message has been sent!</h3>
        <p>Our support team will contact you ASAP.<br/>
Thank you for your patient!</p>
    </Modal>
}

export default SubmitSuccess;