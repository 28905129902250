import React, { useEffect, useState } from "react";
import TesterReportListComponent from "../component/TesterReportListComponent";
import ReportHistoryComponent from "../component/ReportHistoryComponent";
import SendReportButtonComponent from "../component/SendReportButtonComponent";
import {getReportList, getTesterInfoQuery} from '../../API/query/report';
import { get } from 'lodash';


const TesterReportListContainer = (props) => {
	const [reportList, setReportList] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [ticketNum, setTicketNum] = useState();
	const [testerInfo, setTesterInfo] = useState();
	const [modalStatus, setModalStatus] = useState('FINDTESTER');
	const fetchList = async (pagination) => {
		const { pageSize = 10, current = 1 }  = pagination;
		setLoading(true);
		try{
			let res = await getReportList({pageSize,page:current});
			let { totalCount } = res.data.summary;
			if(res.data.code==200) {
				let list = get(res,'data.data',[]);
				setReportList(list);
			}
			setTotal(totalCount);
		}catch (e) {

		}
		
		setLoading(false);
	}
	useEffect(() => {
		fetchList({});
	}, []);

	const getTesterInfo = async () => {
		const res = await getTesterInfoQuery(ticketNum);
		const testerInfo = get(res, 'data', {});
		setTesterInfo({...testerInfo, code:ticketNum});
		setModalStatus('SENDREPORT');
	}

	const renderHeader = () => <div className={'header'}>Manual Report</div>;
	return <div className='reportWrapper'>
		{renderHeader()}
		<SendReportButtonComponent code={ticketNum} setTicketNum={setTicketNum}
			getTesterInfo={getTesterInfo} testerInfo={testerInfo} modalStatus={modalStatus} setModalStatus={setModalStatus}></SendReportButtonComponent>
		<TesterReportListComponent reportList={reportList} total={total} loading={loading} fetchList={fetchList} />
	</div>
}
export default TesterReportListContainer;