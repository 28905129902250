import React from 'react';
import AppointmentDetailComponent from './AppointmentDetailComponent';
const OutOfRangeComponent = (props) => {

    return <div className="outOfRangeWrapper">
        <div className='outOfRangeContainer'>
            <p>Hi {props.firstName}</p>
            <p>You are seeing this page either because your join your appointment too early, or you missed the appointment. Below is your appointment information:</p>
            <AppointmentDetailComponent {...props} />
            <p>Please join at the scheduled date and time.</p>
            <p>If you want to re-schedule, no worries! We understand things change, and iHealth has made it easy to reschedule.  <a href={`/verifyTest/schedule/${props.code}`}>Rescheduled my appointment</a>.</p>
        </div>
    </div>
}
export default OutOfRangeComponent;