import React from 'react';
import Hero from '../component/HeroSection';
import CoverageSection from '../component/CoverageSection';
import GetStartedNowSection from '../component/GetStartedNowSection';
import StrengthSection from '../component/StrengthSection'
import HowItWorksSection from '../component/HowItWorksSection';
import GetStartedWithOffSection from '../component/GetStartedWithOffSection';
import Footer from '../../Accessories/component/Footer';
import FormSection from '../component/FormSection';


const HomeContainer = () => {
    return (
        <div className="home">
            <Hero />
            <CoverageSection />
            <GetStartedNowSection />
            <StrengthSection />
            <HowItWorksSection />
            <GetStartedWithOffSection />
            <FormSection />
            <Footer style={{ borderTop: '1px solid #B5B9BE' }} />
        </div>
    )
}

export default HomeContainer;
