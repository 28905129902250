import { Button, Input, Modal } from "antd";
import { useState } from "react";
import FindUserContent from "./FindUserContent";
import SendReportContent from "./SendReportContent";

const SendReportButtonComponent = (props) => {
  const [visible, setVisible] = useState(false);
  const { code, setTicketNum, testerInfo, getTesterInfo, modalStatus, setModalStatus } = props;
  const handleCancel = () => {
    setVisible(false);
    setTicketNum(null);
  };
  const onClick = () => {
    setModalStatus('FINDTESTER');
    setVisible(true);
  }
  const modalStatusMap = {
    FINDTESTER: <FindUserContent code={code} setTicketNum={setTicketNum}
      getTesterInfo={getTesterInfo}></FindUserContent>,
    SENDREPORT: <SendReportContent testerInfo={testerInfo}></SendReportContent>,
  }
  return <div>
    <div className={'sendReportButtonWrapper'}>
      <Button className={'ihealth'} onClick={onClick}>Send report</Button>
    </div>
    { visible && <Modal maskClosable={false} footer={null} visible={true} onCancel={handleCancel} centered width={800}>
      <div className={'modalWrapper'}>
        {modalStatusMap[modalStatus]}
      </div>
    </Modal>
    }
  </div>
}

export default SendReportButtonComponent;