import {getRequest, postRequest, putRequest} from '../helper/index';
import {getLoginUser} from "../../auth/helper";

const USER_GET_REPORT = '/api/proctoring/testers/fetch-report';

const HEADER_WITH_AUTH =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})

export const testerGetReportMutation = (data)=>postRequest(USER_GET_REPORT,{ data });