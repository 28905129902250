import React from 'react';
import QRCode from "react-qr-code";
import { Modal } from 'antd';

const QRCodeComponent = ({url, title, subtitle}) => {
    const [visible, setVisible] = React.useState(false);
    const showModal = () => {
        setVisible(true);
    };
    const onCancel = () => {
        setVisible(false);
    }

    return <div className={'qrCodeWrapper'}>
                <span onClick={showModal}>View Group QR code</span>
                <Modal className={'qrCodeModal'} visible={visible} onCancel={onCancel} footer={[]}>
                    <h1>{title}</h1>
                    <p>{subtitle}</p>
                    <QRCode value={url} size={155}/>
                </Modal>
            </div>
}

export default QRCodeComponent;