export const HIDE_LOGIN = [
	'/login',
	'/verifyTest',
]

export const HIDE_SIGNUP = [
	'/login',
	'/verifyTest',
	'/signup',
]

export const HIDE_SUPPORT_MENU = [
	'/verifyTest',
]

export const getType = (location) => {
	let typesPrefix = ['verifytest'];
	let type = 'enterprise';
	typesPrefix.forEach(t => {
		if (location.toLowerCase().includes(t)) {
			type = t;
		}
	})
	return type;
};

export const getThemeAndTitle = (location = '') => {
	let type = getType(location);
	let typeMap = {
		verifytest: { title: 'Verified COVID Test Service', theme: 'verifytest', link:'#', img:'/Logo/ihealth_logo_white.png', loginLink: '/verifyTest/proctorlogin' },
		enterprise: { title: 'COVID Testing Solution', theme: 'enterprise', link:'/', img:'/Logo/ihealth_logo.png', loginLink: '/login' }
	};
	return typeMap[type];

}
