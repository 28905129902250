import React from "react";

const TermAndPolicyComponent = class extends React.Component{
	render(){
		return <div style={{ display:'flex',justifyContent:'center'}}>
			<div style={{ width:300,textAlign:'center' }}>By signing up, you agree to our <a href='https://ihealthlabs.com/pages/terms-and-conditions' target="_blank">Terms and Conditions</a>, and
				<a href='https://ihealthlabs.com/pages/privacy-policy' target="_blank"> Privacy Policy</a>
			</div>
		</div>
	}
}

export default TermAndPolicyComponent