import React, {useEffect, useRef, useState} from "react";
import { Input,Icon} from "antd";
import SingleMessageComponent from '../component/SingleMessageComponent';
import '../style/index.scss';
const { TextArea } = Input
const ChatComponent = (props)=>{
	const { sendMessage,messages,showModal,role,setHasUnread,closeModalHandler } = props;
	const [ chatMsg,setChatMsg ] = useState('');
	const [ messageLen,setMessageLen ] = useState(0);
	const ref = useRef();
	const onEnter = (e)=>{
		e.preventDefault();
		sendMsg();
	}

	const inputBox = ()=>{
		return <div className={'inputBox'}>
			<TextArea  rows={4} placeholder={'Type messege here ...'} onChange={(e)=> setChatMsg(e.target.value)} value={chatMsg} onPressEnter={onEnter} />
			<span onClick={sendMsg} className={'sendIcon'}>
				<img src={'/image/send.png'} width={25} style={{ marginBottom:15,marginRight:15 }}/>
			</span>
		</div>
	}
	const sendMsg = ()=>{
		if(chatMsg&&chatMsg.trim()) {
			sendMessage(chatMsg);
			setChatMsg('');
		}
	}
	useEffect(()=>{
		scrollToBottom();
	},[messages.length]);
	useEffect(()=>{
		if(!showModal) {
			if(messageLen<messages.length) {
				setHasUnread(true);
			}
		}
	},[messages.length])
	useEffect(()=>{

	},[showModal]);
	const scrollToBottom = () => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	}
	const renderMsgHistory = ()=>{
		return messages.map((msg,index)=>{
			return <SingleMessageComponent msg={msg} key={index} {...props}/>
		})
	}
	return <div className={'chatbox ' + ((showModal ? 'show':'hide')+' '+ role)} >
			<div className={'chatboxHeader'}>
				<div onClick={closeModalHandler} className={'close'}>x</div>
			</div>
			<div className={'history'}>
				{ renderMsgHistory() }
				<div style={{ float:"left", clear: "both" }}
				     ref={ref}>
				</div>
			</div>
				{ inputBox() }
		  </div>
}

export default ChatComponent;