import React from 'react';
import { useSelector } from 'react-redux';
import OrderSuccessConfirmationComponent from './OrderSuccessConfirmationComponent';
import SubscriptionAfterResult from './SubscriptionAfterResult';

const OrderConfirmationComponent = (props) => {
    const {orderDetails} = useSelector(state => state.orderReducer);
    if( orderDetails.status==="PAID") {
        return orderDetails.orderType==='order' ? <OrderSuccessConfirmationComponent /> : <SubscriptionAfterResult />
    } else {
        return <div style={{minHeight: '100vh', textAlign:"center", marginTop: 35}}>Payment unsuccessful, please go to "settings" and verify your payment card information or try another card.</div>
    }
}

export default OrderConfirmationComponent;