import React from "react";
import {CardElement} from "@stripe/react-stripe-js";

const CardPaymentWrapperComponent = (props)=>{
	const { setCardInput } = props;

	return <CardElement onChange={(v,b)=> {
		setCardInput(v,b);
	} }/>
}

export default CardPaymentWrapperComponent