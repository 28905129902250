import React, {useState} from "react";
import { Table,Popconfirm } from "antd";
import '../style/index.scss';
import { useNavigate } from "react-router-dom";
import { upperFirst } from 'lodash'
import { deleteOrderMutation,closeOrderMutation } from "../../API/query/Order";
import OrderStatusComponent from "./OrderStatusComponent";
import MakeOrderButtonWrapperContainer from "../container/MakeOrderButtonWrapperContainer";
import {orderStatusMap} from "../constant/order";
import { get } from 'lodash';
const PaidOrderListComponent = (props)=>{
	const { totalCount,page,setPage,loading,fetchOrders,setStatus,setSort } = props;
	const navigate = useNavigate();
	const handleClick = (id)=>{
		navigate(`/orders/${id}`,{  state:{orderNumber:id } });
	}
	const parseTime = (time)=>{
		let v= time;
		if(time.split(' ')[1]){
			v = time.split(' ')[0];
		}
		const [year,month,day ] = v.split('-');
		return `${month}/${day}/${year}`;
	}

	const onCancel = (id)=>{
		closeOrderMutation(id).then(res=>{
			if(fetchOrders) fetchOrders();
		});
	}

	const renderTable = ()=>{
		const { orders } = props;
		
		const columns = [{
			key:'id',
			dataIndex:'id',
			title: 'Group Order Number',
			width: '180px',
		},{
			key:'name',
			dataIndex: 'name',
			title: 'Order Name',
			width: '25%'
		},{
			key:'subscriptionId',
			dataIndex: 'subscriptionId',
			title:'Type',
			width: '170px',
			render:(v)=>{
				if(v) return 'Subscription order'
				return 'One time-order'
			}
		},{
			key: 'created_At',
			dataIndex: 'createdAt',
			title:'Ordered On',
			sorter:true,
			width: '170px',
			render:(v)=>{
				return parseTime(v);
			}
		},
		{
			key: 'transactionDate',
			dataIndex: 'transactionDate',
			title:'Charged On',
			width: '170px',
			render:(v,doc)=>{
				const showChargedStatus = ['PAID'];
				const { status } = doc;
				if(showChargedStatus.includes(status)) {
					return parseTime(v);
				}
				return <div style={{ marginLeft:30 }}>--</div>;
			}
		},
		{
			key:'status',
			dataIndex: 'status',
			title: 'Status',
			width: '150px',
			// filters:orderStatusMap.map(({key,label,value})=>({text:label,value })),
			// filterMultiple:false,
			// onFilter: ()=>true,
			render:(value, record)=> <OrderStatusComponent record={record} status = {upperFirst(value.toLowerCase()) }/>
		},{
			key:'dummy',
			width:'200px',
			render:(doc,record)=>{
				let { status ,id,subscriptionId} = doc;
				status = !!subscriptionId && status=== 'UNPAID' ? 'Scheduled': status;
				if(status=='Scheduled') {
					return <div style={{ display:'flex', alignItems: 'center'}}>
							<div className={'viewOrderButton'} onClick={()=>handleClick(id)} style={{width:'inherit', display:'flex',justifyContent:'flex-start'}}>View</div>
							<Popconfirm title={'Canceled order will be deleted'} onConfirm={()=>onCancel(id)}
							            okText={'Yes,cancel order'}
								// icon={false}
								        cancelText={'Not now'}
							>

								<div className={'viewOrderButton'} style={{marginLeft: 15, whiteSpace:'nowrap' }}>Cancel order</div>
							</Popconfirm>

					</div>
				}
				else if(status!='PAID' && status!='REFUNDED') {
					const buttonText = status =='FAILED' ? 'Re-order' : status =='UNPAID' ?'Edit':'View';
					return <div style={{ display:'flex', alignItems: 'center', justifyContent:'space-between'}}>
							<MakeOrderButtonWrapperContainer unpaidOrderId={doc.id} key={id} fetchOrders={fetchOrders} className={'viewOrderButton'} text={buttonText}/>
							<Popconfirm title={'Are you sure to delete this order?'} onConfirm={()=>onCancel(id)}
							            okText={'Yes,delete order'}
							            cancelText={'Not now'}
										>
							{status=='UNPAID'? 
								<div className={'viewOrderButton'} style={{marginLeft: 15, whiteSpace:'nowrap',textDecoration:'underline', cursor:'pointer' }}>Delete</div>
								: ''}
							</Popconfirm>
						   </div>
				}
				else return <button className={'viewOrderButton'} onClick={()=>handleClick(id)} style={{width:'inherit', display:'flex',justifyContent:'flex-start'}}>View</button>
			}
		}]


		const pagination = {
			pageSize:10,
			total:totalCount,
			current: page,onChange:(p)=>setPage(p)
		}
		const orderMap = {
			'descend':'desc',
			'ascend':'asc'
		}
		return <Table columns={columns}  loading={loading} rowKey={record => record.id}
		              onChange={(p,f,s)=>{
		                setSort({sort:get(s,'columnKey','created_At'),order:orderMap[get(s,'order',null)]})
		              	setStatus(get(f,'status.0',''))}
		              }
		              dataSource={orders} pagination={pagination}/>
	}

	return <div className={'orderList'}>
			<div className={'header'}>My Orders</div>
			{ renderTable() }
		   </div>
}

export default PaidOrderListComponent;