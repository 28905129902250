import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import CreditCardElementComponent from "../../Order/component/CreditCardElementComponent";
import {getIntentId, getPaymentMethod, getStripePK} from "../../API/query/Stripe";
import { Row,Col } from 'antd';
import {get} from "lodash";
import {loadStripe} from "@stripe/stripe-js";
import OrderPreviewComponent from "./OrderPreviewComponent";
import { useSelector, useDispatch } from 'react-redux';

const MakePaymentComponent = (props)=>{
	const { setShowMakeOrder,setActiveKey,isActive,orderType,creationOrderInfo,orderDetails } = props;
	let [stripePromise,setStripePromise] = useState(null);
	let [options, setOptions ] = useState(null);
	let [payment,setPayment] = useState([]);
	let [saveCard,setSaveCard ] = useState(true);
	let [publicKey,setPublicKey ] = useState(null);
	let { subscription }= useSelector(state => state.orderReducer);

	let orderId = get(creationOrderInfo,orderType);
	const initialStripe = async()=>{
		try{
			const response = await getStripePK();
			const publicKey = get(response,'data.data.publicKey');
			const intentRes = await getIntentId();
			const clientSecret = get(intentRes,'data.data.setupIntentId');
			const paymentRes = await getPaymentMethod();
			const payment = get(paymentRes,'data.data');
			setPublicKey(publicKey);
			stripePromise = loadStripe(publicKey);
			setStripePromise(stripePromise);
			setOptions({clientSecret});
			setPayment(payment);
		}
		catch (e) {
			console.log(e);
		}
	}
	useEffect(()=>{

		initialStripe();

	},[publicKey]);
	if(stripePromise&&options) {
			return <Elements stripe={stripePromise} options={options}>
					<Row><Col><h2 style={{fontSize:24, textAlign: 'center', marginTop: 35}}>{`${orderType==='order'? 'Checkout' :'Add payment'}`}</h2></Col></Row>
					<Row type="flex" gutter={22} justify="center">
					<Col span={12} className={'creditCreditWrapper'}>
						<CreditCardElementComponent orderId={orderId} clientSecret={options.clientSecret}
						                            subscription={subscription}
					                            payment={payment} orderType={orderType}
					                                orderDetails={orderDetails}
					                                setShowMakeOrder={setShowMakeOrder}
					                            saveCard={saveCard}
					                                initialStripe={initialStripe}
					                                creationOrderInfo={ creationOrderInfo}
					                            setSaveCard={setSaveCard} setActiveKey={setActiveKey}/>
					</Col>
					<Col offset={1} span={8} className={'orderPreviewWrapper'}>
						{ isActive && <OrderPreviewComponent orderId={orderId} setActiveKey={setActiveKey} creationOrderInfo={ creationOrderInfo} orderType={orderType}/> }
					</Col>
					</Row>
			</Elements>

	}
	return <OrderPreviewComponent orderId={orderId} setActiveKey={setActiveKey}/>
}
export default MakePaymentComponent;