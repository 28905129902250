import React, {useEffect, useState} from "react";
import '../style/index.scss';
import { get,upperFirst } from 'lodash';
import {Row,Col} from "antd";
import {getOrderById, getOrderPriceDetails} from "../../API/query/Order";
import { getInvoice } from "../../API/query/invoice";
import OrderPriceComponent from '../component/OrderPriceComponent';
import { parseTime } from '../../lib/helpers/index';
import ProductContentComponent from "../../payment/component/ProductContentComponent";

const OrderInvoiceDetailComponent = (props)=>{
	const { setActiveKey,creationOrderInfo,orderId,hideMidCol,orderType } = props
	const [ priceDetails, setPriceDetails ] = useState({});
	const [ recipient, setRecipient ] = useState(0);
	const [ order,setOrder ] = useState({});
	useEffect(()=>{
		const doFetch = async ()=> {
			const fn = getOrderById ;
			if(orderId ||creationOrderInfo[orderType] ) {
				try {
					let res = await getOrderPriceDetails({"objectType":'order', id: parseInt(orderId)});
					let orderRes = await fn(parseInt(orderId));
					let bill = get(res, 'data.data.bill', {});
					setPriceDetails(bill);
					setOrder(get(orderRes, 'data'));
				} catch (e) {
					console.log(e);
				}
			}
		}
		doFetch();
	},[orderId]);

	// const handleDownloadInvoice = () => {
	// 	const {id } = order;
	// 	getInvoice(id)
	// 		.then((res) => {
	// 			const link = document.createElement("a");
	// 			link.target = "_blank";
	// 			link.download = "iHealth COVID Test Kit Solution Invoice.pdf"
	// 			link.href = URL.createObjectURL(new Blob([res.data]));
	// 			link.click();
	// 			link.remove()
	// 		});
	// };

	const otherOrderDetailedInfo = ()=>{
		const dummyDate = '0001-01-01';
		const { items=[{}] } = priceDetails;
		const { name,createdAt='2000-01-01',id,status='',transactionDate=dummyDate}= order;
		const { quantity } = items[0];
		const createdDateStr = createdAt.split(' ')[0];
		const [year, month, day] = createdDateStr.split('-');
		const formattedCreatedDateStr = `${month}/${day}/${year}`;
		const formattedTransactionDateStr = transactionDate!=dummyDate ? parseTime(transactionDate) : '--';
		// subscriptionId == 1 ? 'subscription' :'order';
		return <div className={'orderDetailedInfo'} style={{lineHeight: '30px',textAlign:'start' }}>
			<Row>
				<Col span={12} className={'title'}>Group order number:</Col>
				<Col span={6} className={'value'}>{id}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>OrderName:</Col>
				<Col span={6} className={'value'}>{name}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>Order Created:</Col>
				<Col span={6} className={'value'}>{formattedCreatedDateStr}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>Order Charged:</Col>
				<Col span={6} className={'value'}>{formattedTransactionDateStr}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>Status:</Col>
				<Col span={6} className={'value'}>{ upperFirst(status.toLowerCase()) }</Col>
			</Row>
			{/*<Row style={{ marginTop: 30 }}>*/}
			{/*	<Col span={12} style={{textDecoration:'underline'}}>*/}
			{/*		<div style={{width:'fit-content',cursor:'pointer',color: '#1890ff'}} onClick={()=>handleDownloadInvoice()}>View Invoice</div>*/}
			{/*	</Col>*/}
			{/*</Row>*/}
		</div>
	}

	const orderDetailsBottom = ()=>{
		const { items=[]} = priceDetails;
		const product = get(items,'0',{});
		return <div className={'orderBottomWrapper'} style={{lineHeight: '30px' }}>
			<div className={'productWrapper'}>
				<ProductContentComponent {...priceDetails} product={product}/>
			</div>
		</div>
	}

	return <Row style={{ padding:'30px 50px' }}>
		<Col><div style={{ fontSize:20, fontWeight: 600 }}>Order Details</div></Col>
		<Col span={8}>{ orderDetailsBottom() }</Col>
	    <Col span={8} style={{ visibility: hideMidCol ? 'hidden' : 'visible'}}>{ otherOrderDetailedInfo() }</Col>
		<Col span={8}>{ <OrderPriceComponent priceDetails={priceDetails}/> }</Col>
		{/*{otherOrderDetailedInfo()}*/}
	</Row>
}

export default OrderInvoiceDetailComponent