import React from 'react';
import { Row, Col } from 'antd';

const CoverageSection = () => {

    const columns = [{
        icon: '/image/subscription.png',
        icon_comment: '',
        title: 'COVID Test Kit Subscription',
        note: 'No more worrying about test kit supply.',
        description: "Options to subscribe to test kit, so you never run out. The subscription plan is customizable to meet your organization's needs."
    }, {
        icon: '/image/delivery.png',
        icon_comment: '',
        title: 'Direct Delivery to EACH Recipient',
        note: 'Hassle-free for test kits distribution.',
        description: 'Test kits are delivered to each recipient regularly based on the subscription plan.'
    }, {
        icon: '/image/result.png',
        icon_comment: '',
        title: 'Test Results Tracking and Acting',
        note: 'Know the test results in your community.',
        description: 'With the solution portal, you track test compliance, results and understand cases that need follow-up.'
    }
    // , {
    //     icon: '/image/verified.png',
    //     icon_comment: '',
    //     title: 'Verified COVID Test Service',
    //     note: "Verified tests meet the requirements of CDC’s order before travelling by air into the U.S.",
    //     description: 'Virtual test observation overseen by iHealth proctors to ensure test authenticity. (Available overseas.)'
    // }
    ];
    const renderColumns = columns.map((col, index) => {
        return (
            <Col xs={24} sm={24} md={7} lg={8} key={index} className="coverage-section-column">
                <div className="coverage-section-column-icon">
                    <img alt={col.title} src={col.icon} />
                    <span>{col.icon_comment}</span>
                </div>
                <div className="coverage-section-column-text">
                    <h3>{col.title}</h3>
                    <p className="subtitle">{col.note}</p>
                    <p className="details">{col.description}</p>
                </div>
            </Col>)
    })



    return <div className="coverageSection">
        <div className="sectionWrapper">
            <h2>3 Services Included in the Solution</h2>
            <p className="intro subtitle">The solution is HIPAA-compliant, eligible in the contiguous United States only (48 States and Washington, D.C.)</p>
            <p className="intro details">The COVID testing solution is designed for <strong>schools</strong>, <strong>companies</strong>, and <strong>organizations</strong> to launch a seamless and end-to end workflow to manage the COVID-19 test. </p>
            <Row gutter={[32, 32]} className="features">
                {renderColumns}
            </Row>
        </div>
    </div>
}

export default CoverageSection;
