import React, {useEffect, useState} from "react";
import {estimateGroupOrderPriceDetails, getOrderById, getOrderPriceDetails, getSubById} from "../../API/query/Order";
import {get} from "lodash";
import '../style/index.scss';
import {Row,Col, Divider} from "antd";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import {setOrderDetails} from "../../redux/actions/orderActions";
import OrderPriceDetailsComponent from "./OrderPriceDetailsComponent";
import ProductContentComponent from "../../payment/component/ProductContentComponent";

const OrderPreviewComponent = (props)=>{
	const { product } = useSelector(state=>state.orderReducer);
	const { setActiveKey,orderType,creationOrderInfo } = props
	const [ priceDetails, setPriceDetails ] = useState({});
	const [ order,setOrder ] = useState({});
	
	let { subscription }= useSelector(state => state.orderReducer);
	let orderId = get(creationOrderInfo,orderType);
	const dispatch = useDispatch();

	useEffect(()=>{
		const doFetch = async ()=> {
			const fn = orderType ==='order' ? getOrderById : getSubById;
			const priceFn = orderId ? estimateGroupOrderPriceDetails : getOrderPriceDetails;
			const priceArgs = !subscription ? {"objectType":orderType, id: parseInt(orderId)} : { "objectType": orderType, contactGroupId:subscription.contactGroupId,"quantity":parseInt(subscription.quantity) };
			if(subscription) {
				let res = await priceFn(priceArgs);
				let bill = get(res, 'data.data.bill', {});
				setPriceDetails(bill);
				updateOrderDetails({...bill, orderType});
			}
			if(orderId) {
				try {
					let res = await priceFn(priceArgs);
					let orderRes = await fn(parseInt(orderId));
					let bill = get(res, 'data.data.bill', {});
					setPriceDetails(bill);
					setOrder(get(orderRes, 'data'));
					updateOrderDetails({...bill, orderType});
				} catch (e) {
					console.log(e);
				}
			}
		}
		doFetch();
	},[orderId]);

	const updateOrderDetails = (data)=>{
		dispatch(setOrderDetails(data));
	}

	const parseStartDate = (startDate)=>{
		startDate = typeof startDate == 'string' ? startDate : moment(startDate).format('YY-MM-DD hh:mm');
		const createdDateStr = startDate.split(' ')[0];
		const [year, month, day] = createdDateStr.split('-');
		const formattedCreatedDateStr = `${month}/${day}/${year}`;
		return formattedCreatedDateStr
	}

	const otherOrderDetailedInfo = ()=>{
		const { taxDetails=[{}] } = subscription ? subscription : priceDetails;
		const { status } = order;
		const { name,startDate='2000-01-01',subscriptionId }= subscription ? subscription : order;
		const { quantity } = taxDetails[0];
		const formattedCreatedDateStr = parseStartDate(startDate);
		const purchaseType = orderType;
		const linkText = status ==='FAILED' ? 'View' :'Edit';
		return <div className={'orderDetailedInfo'}>
				<Row>
					<Col className={'title'}>Order name: {name}</Col>
				</Row>
				<Row>
					<Col className={'title'}>{`Qt: ${taxDetails.length * quantity} Packs (${taxDetails.length} recipients, ${quantity} packs/recipent)`}</Col>
				</Row>
				<Row>
					<Col className={'title'}>Purchase type: {purchaseType=='order'? 'One-time order' : 'subscription'}</Col>
				</Row>
				{purchaseType === 'order'? '':
				<Row>
					<Col className={'title'}>First ship date: {formattedCreatedDateStr}</Col>
					
				</Row>
				}
				<Row>
					<div className={'editOrderLink'} onClick={()=>setActiveKey('1')}>{linkText}</div>
				</Row>
		</div>
	}

	const orderDetailsBottom = ()=>{
		return <div className={'orderBottomWrapper'}>
				<div className={'header'}><label className={'title'}>Order Details</label></div>
				<div className={'productWrapper'}>
					<ProductContentComponent product={product} {...priceDetails} />
					<div>{otherOrderDetailedInfo()}</div>
				</div>
			   </div>
	}

	const showPriceDetails = ()=>{
		const {subTotal,total,total_tax,items=[],discount, serviceFee,serviceFeeDiscount } = priceDetails;
		return <div className={'priceDetails'}>
			<OrderPriceDetailsComponent {...priceDetails} />
			<Divider style={{borderTop: '1px solid #2D3238'}}/>
		</div>
	}

	return <div>
			{ showPriceDetails() }
			{ orderDetailsBottom() }
		   </div>
}
export default OrderPreviewComponent;