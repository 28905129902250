import {useState, useEffect, useRef} from 'react';
import  AgoraRTM, { createInstance } from 'agora-rtm-sdk';
import {IAgoraRTCClient} from "agora-rtc-sdk-ng";
import {useParams} from "react-router-dom";
export const useChat = (client,rtmToken,role,uid)=>{
	const [ channel,setChannel ]= useState();
	const [ chatStatus,setStatus ] = useState();
	const [ messages,setMessages ] = useState([]);
	const ref = useRef([]);
	let { id } = useParams();

	const getMsgRole = (pos,role)=>{
		switch (pos) {
			case 'SELF':
				return role.toUpperCase();
			case 'OTHER':
				return {
					'PROCTOR':"TESTER",
					'TESTER': 'PROCTOR'
				}[role.toUpperCase()]

		}
		return role;
	}
	const joinChannelAndSubscribe = async (channelName)=>{
		try {
			console.log(uid,rtmToken);
			await client.login({uid,token:rtmToken });
			let channel = await joinChannel(channelName);
			setChannel(channel);
			await subscribeClientEvents(channel);
			await subscribeChannelEvents(channel);
			setStatus(true);
			console.log(channel);
		}catch (e) {
			console.log(e);
		}
	}

	const sendMessage = async (msg)=>{
		try {
			await channel.sendMessage({text: msg});
			let msgObj = {
						messageType:'TEXT',
						text: msg,
						ts: new Date().getTime(),
						role:getMsgRole('SELF',role)
					}
			ref.current = [...ref.current,msgObj];
			setMessages(ref.current);
		}catch (e) {
			console.log(e);
		}
	};

	const subscribeChannelEvents = (channel)=>{
		channel.on('ChannelMessage',(...args)=>{
			let { serverReceivedTs } = args[2];
			let msgObj = { ...args[0],ts:serverReceivedTs,role:getMsgRole('OTHER',role) };
			ref.current = [...ref.current,msgObj];
			setMessages(ref.current);
		})
	}

	const joinChannel = async (channelName)=> {
		const channel = await client.createChannel(channelName)
		await channel.join()
		return channel;
	}

	const subscribeClientEvents =(channel)=> {
		const clientEvents = [
			'ConnectionStateChanged',
			'MessageFromPeer'
		]
		clientEvents.forEach((eventName) => {
			client.on(eventName, (...args) => {
				console.log(eventName,args);
			})
		})
	}
	const logOut = async ()=>{
		await client.logout();
		setStatus(false);

	}
	useState(()=>{
		if (!client) return;

	},[client])

	return {
		client,
		joinChannelAndSubscribe,
		sendMessage,
		chatStatus,
		logOut,
		messages,
		uid:id
	}
}