/* eslint-disable import/no-anonymous-default-export */
import React, {useEffect} from "react";
import { showSideMenu } from "../../auth/helper";
import { Layout,Menu,Icon } from "antd";
import { siderMenu} from "../constant/siderMenu";
import 'antd/lib/layout/style/index.css'
import 'antd/lib/icon/style/index.css';
import {Link, useLocation, useParams} from "react-router-dom";
import { includes } from 'lodash';
const { Sider } = Layout;

export default ()=>{
	const { pathname } = useLocation();
	useEffect(()=>{
		const ele = document.getElementById('sliderArrow');
		// ele[0].classList.add('slideIn');
		if(ele) {
			const sliderSel = document.getElementsByClassName('ant-layout-sider');
			const slider = sliderSel[0];
			if (slider) {
				const classList = slider.classList
				ele.addEventListener('click', (e) => {
					if (classList.contains('slideIn')) {
						classList.remove('slideIn');
						classList.add('slideOut');
					} else {
						classList.remove('slideOut');
						classList.add('slideIn');
					}
				})
			}
		}
	}, []);
	if(showSideMenu(pathname)&&siderMenu) {
		const selectedKeys = siderMenu.filter(item=> includes(pathname,item.key)).map(item=>item.key);
		return <Sider theme={'light'} className={'slideOut'} width='240'>
				<Menu defaultSelectedKeys={[siderMenu[0].key]} selectedKeys={selectedKeys}>
					{siderMenu.map(({key,icon,label,href}) =>
					<Menu.Item key={key} className={'menuItem'}>
						<Icon type={icon} style={{fontSize: 20}}/>
						<Link to={href} style={{whiteSpace: 'normal'}}>{label}</Link>
					</Menu.Item>)}
				</Menu>
				<div className={'arrow'} id={'sliderArrow'}/>
			</Sider>
	}
	else return null;
}