import React from 'react';
import { Button, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from '../../redux/actions/testResultActions';
import { filterTypes } from '../../redux/actionTypes/testResultActionTypes';
import QRCodeComponent from '../../Accessories/component/QRCode';
import { GROUP_QRCODE_BASE_URL } from '../../Accessories/constant/qrCode';
import {getAndSetGroupUserList} from '../helper/index';

const TestResultStatsFilters = (props) => {
    const dispatch = useDispatch();
    
    const { groupStats, filter, selectedTestGroup, startTime, endTime} = useSelector(state => state.testResultReducer);
    const { Count = {}, Members=0 } = groupStats;


    const onFilterChange = (filter) => {
        dispatch(setFilter(filter));
        const data = {
            GroupCode: selectedTestGroup.GroupCode,
            StartTime: startTime,
            EndTime: endTime,
            page: 1,
            pageSize: 10,
            ResultType: filter
        }

        getAndSetGroupUserList(dispatch, data);
    }

    return <div className={'statsButtonsWrapper'}>
        <Row type="flex" align="top" gutter={18}>
            <Col><Button className={filter===filterTypes.ALL ? 'active': ''} onClick={()=> onFilterChange(filterTypes.ALL)}><span className={'testStatus all'}>All members:</span><span>{Members}</span></Button>
            </Col>
            <Col><span style={{fontSize:27}}>:</span></Col>
            <Col>
                <Row type="flex" gutter={18}>
                    <Col><Button className={filter===filterTypes.NEGATIVE ? 'active': ''} onClick={()=> onFilterChange(filterTypes.NEGATIVE)}><span className={'testStatus negative'}>Negative:</span><span>{Count?.Negative || 0}</span></Button></Col>
                    <Col><Button className={filter===filterTypes.POSITIVE ? 'active': ''} onClick={()=> onFilterChange(filterTypes.POSITIVE)}><span className={'testStatus positive'}>Positive:</span><span>{Count?.Positive || 0}</span></Button></Col>
                    <Col><Button className={filter===filterTypes.NORESULT ? 'active': ''} onClick={()=> onFilterChange(filterTypes.NORESULT)}><span className={'testStatus noResult'}>No Result:</span><span>{Count?.Invalid || 0}</span></Button></Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col>
                    <span>(Based on members’ most recent test results within the selected time range)</span>
                    </Col>
                </Row>
            </Col>
            <QRCodeComponent title={selectedTestGroup.GroupName} subtitle={`Group Number: ${selectedTestGroup.GroupCode}`}  url={`${GROUP_QRCODE_BASE_URL}${selectedTestGroup.GroupCode}`}/>
        </Row>
    </div>
}


export default TestResultStatsFilters;