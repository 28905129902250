import { Button, Input } from "antd";
import { useState } from "react";
const FindUserContent = (props) => {
  const { setTicketNum, code, getTesterInfo } = props;
  const [disable, setDisable] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const onChangeInput = (value) => {
    setTicketNum(value);
  }
  const onClick = async () => {
    setDisable(true);
    try {
      await getTesterInfo();
    } catch (e) {
      setCodeError(true);
      setDisable(false);
    }
  }

  return <div className={'findUserContent'}>
    <Input placeholder={'Enter ticket number to find the tester'} value={code} onChange={(e) => onChangeInput(e.target.value)} className={codeError?'hasError':'normal'}></Input>
    <span className={'codeError'}>
      {codeError ? 'This is an invalid ticket number, please double check.' : ''}
    </span>
    <Button className={'ihealth'} disabled={!code || disable} onClick={onClick}>Find tester</Button>
  </div>
}

export default FindUserContent;