import {clearSession } from "./helper";
import { userLogInMutation, } from '../API/query/Login';
import { proctorLoginMutation  } from "../API/query/proctor";
import { redeemCodeLogInMutation } from '../API/query/redeem';
import { userSignUpMutation } from'../API/query/SignUp';

const fakeAuthProvider = {

   async signin({username,password,role,code}) {
       const roleMutationMap = {
           proctor: proctorLoginMutation,
           code: redeemCodeLogInMutation
       }
       let logInFn = roleMutationMap[role] ||  userLogInMutation
       return await logInFn({Email:username,Password:password,code});
        //    .then(res=>{
        //     if(res.data.code === 200) {
        //         const auth = get(res,'data.data.token');
        //         setLoginUser(auth)
        //         succeedCB();
        //     }
        //     else {
        //         return Promise.reject(res.data.sub_code);
        //     }
        // }).catch(e=>{
        //     return Promise.reject(e);
        // })

    },
   async signUp(values){
       return await userSignUpMutation(values);
    },
    signout() { clearSession() }
};

export { fakeAuthProvider };