import React from 'react';
import { Link} from "react-router-dom";
const LoginButtonWrapperComponent = class extends React.Component{
	signUpButton = ()=> <Link to={'/login'}>Sign In</Link>;
	render() {
		const { signUpButton } = this;
		return <div className='SignUpButtonWrapperComponent'>
			Already have an account ? { signUpButton() }
		</div>
	}
}

export default LoginButtonWrapperComponent