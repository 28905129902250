import React from "react";
import '../style/index.scss';
import ProvideTestGuidanceComponent from './ProvideTestGuidanceComponent';
import CollectTestResultGuidanceComponent from './CollectTestResultGuidanceComponent';
const TestGuidanceComponent = (props)=>{
	const {status} = props;
	let type = status.includes('PHASE_TWO') || status.includes('RESULT_SUBMITTED') ? 'PHASE_TWO' : 'PHASE_ONE';
	let typeToMsg = {
		PHASE_ONE:<ProvideTestGuidanceComponent />,
		PHASE_TWO:<CollectTestResultGuidanceComponent />
	}
	return typeToMsg[type]
}
export default TestGuidanceComponent;