import React, {useEffect, useState} from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import useAgora from '../hooks/useAgora';
import MediaPlayer from '../components/MediaPlayer';
import {startRecordMutation} from "../../API/query/video";
import {first,get} from "lodash";
import HttpLog from "../../utils/HttpLog";
import ChatContainer from "../../Chat/container/ChatContainer";
import {networkStatusMap} from "../constant/networkStatusMap";
import useInterval from "../../lib/hook/useHook";
import {Row, Col, Button} from 'antd';
// import './Call.css';
// import './Cwzzall.css';
import '../style/index.scss';
import {isMobile} from "../../lib/helpers";

AgoraRTC.enableLogUpload();
AgoraRTC.setLogLevel(1);
const client = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' });

export const Call = (props)=> {

	const { appId ,channel ,token, uid, rtmToken, testerUid } = props;
	const { role, recording, setProctorStatus, proctorStatus, setTesterStatus, testerStatus, showAgoraStreamEvent, wsclosed } = props;
	const [ testerConnected, setTesterConnected ] = useState(false);
	const [ remoteNetworkStatus,setRemoteNetworkStatus ]= useState({});
	const [ permissionDenied, setPermissionDenied ] = useState(false);
	const {
		localAudioTrack, localVideoTrack, leave, join, joinState, remoteUsers,controlAudio,uplinkNetworkQuality,downlinkNetworkQuality
	} = useAgora(client,role);

	const joinChannel = async ()=>{
		try {
			HttpLog.info({
				tag: 'AGORA-JOIN-START', appId, token, channel, uid, role,
			});
			await join(appId, channel, token,uid);
		} catch(err) {
			let errorCode = err.code;
			const deviceErro = ['PERMISSION_DENIED', 'NOT_SUPPORTED'];
			if(deviceErro.some(field => field === errorCode)) {
				setPermissionDenied(true);
			}
			HttpLog.error({
				tag: 'AGORA-JOIN-ERROR', channel, uid, role, err,
			});
		}
	}

	const getAndSetRemoteNetworkStatus = async ()=>{
		let res = await client.getRemoteNetworkQuality();
		setRemoteNetworkStatus(res);
	}
	useInterval(getAndSetRemoteNetworkStatus,2000);

	useEffect(()=>{
			joinChannel();
			return ()=>leave();
	},[]);
	const style = {
		gridGap: "10px",
		alignItems: "center",
		justifyItems: "center",
		gridTemplateRows: "repeat(12, auto)",
		gridTemplateColumns: "repeat(24, auto)",
	};

	useEffect(()=> {
		if (!!setProctorStatus && remoteUsers.length) {
			if(role==='TESTER') {
				const status = remoteUsers.length >= 1 ? 'ONLINE' :
					(proctorStatus !== 'CHANGING' && proctorStatus !== 'HUNGUP' ? 'OFFLINE' : proctorStatus);
				setProctorStatus(status);
			}
		}
		if(!!setProctorStatus && !remoteUsers.length) {
			if(role==='TESTER') {
				const status = proctorStatus !== 'CHANGING' && proctorStatus !== 'HUNGUP' ? 'OFFLINE' : proctorStatus;
				setProctorStatus(status);
			}
		}
		if(!!showAgoraStreamEvent && role === 'PROCTOR') {
			const testerConnectedNew = remoteUsers.length > 0 ? true : false;
			if (testerConnected !== testerConnectedNew) {
				const msg = testerConnectedNew ? 'The tester connected' : 'The tester disconnected';
				showAgoraStreamEvent(msg);
				setTesterConnected(testerConnectedNew);
			}
		}
	},[remoteUsers.length]);

	useEffect(()=> {
		if (!!setTesterStatus && (role === 'TESTER')) {
			if (joinState && testerStatus !== 'ONLINE') {
				setTesterStatus('ONLINE');
			}
			if (!joinState && testerStatus !== 'OFFLINE') {
				setTesterStatus('OFFLINE');
			}
		}
	},[joinState]);

	const startRecording = async () => {
		const { fetchRecordStatus, channel } = props;
		try {
			await startRecordMutation(channel);
			fetchRecordStatus(channel);
		} catch (e) {

		}
	}
	const showRecordingIcon = () => {
		if (role != 'PROCTOR') return '';
		return <div className={`recording ${recording ? 'recording' : 'notRecording'} `}>
			<div id="recordingCircle"></div>
			<span>{recording ? 'Recording ...' : <div>Recording fails<span style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 10 }} onClick={startRecording}>Record</span></div>}</span>
		</div>;
	}
	const audioControlBtn = ()=> {
	let muted = localAudioTrack && localAudioTrack.muted;
	return	<span
			onClick={()=>controlAudio()}
			style={{marginRight:30}}
			title="Enable/Disable Audio"
		>
		{!muted && <img className='ag-icon ag-icon-mic' src={'/image/microphone_on.png'} alt='on' width='40px'/>
		}
		{ muted && <img className='ag-icon ag-icon-mic-off' src={'/image/microphone_mute.png'} alt='mute' width='40px'/>
		}
        </span>;
	};

	const getLocalNetworkStatus = ()=>{
		let code = Math.max(downlinkNetworkQuality,uplinkNetworkQuality);
		const networkStatus = networkStatusMap(code);
		return networkStatus;
	}

	const getRemoteNetworkStatus = ()=>{
		let { downlinkNetworkQuality: remoteDownlinkNetworkQuality } = get(remoteNetworkStatus,`${testerUid}`,{});
		let { uplinkNetworkQuality: remoteUplinkNetworkQuality } = get(remoteNetworkStatus,`${testerUid}`,{});
		let code = Math.max(remoteDownlinkNetworkQuality,remoteUplinkNetworkQuality) || 0;
		const networkStatus = networkStatusMap(code);
		return networkStatus;
	}

	const showNetworkStatusTester = ()=>{
		let code = Math.max(downlinkNetworkQuality,uplinkNetworkQuality);
		const low = code > 4 || wsclosed;
		if(low) {
			return <div className={'testerNetworkStatus'}>
					<img alt='wifi unstable' height={17} src='/image/wifi_unstable.png'></img>
					<div className={'content'}>
						<div className={'title'}>Your internet connection is unstable.</div>
						<div className={'text'}>If you see this message constantly, please refresh your web page or try to change a device.</div>
					</div>
			</div>
		}
	}

	const permissionDeniedContent = ()=>{
		const imgStyle = {
			wrapper:{
				display:'flex'
			},
			img:{
				height: 190,
				width: 'auto',
				marginLeft: 'auto',
				marginRight: 'auto'
			}
		}

		return <div style={{ padding: '0px 15px',marginTop:'-30px' }}>
			<h2><strong style={{ fontWeight:900,fontSize:20 }}>Camera & Microphone Access Required</strong></h2>
			<span style={{fontSize: 16 }}>Please follow the instructions below to allow the access:</span>
			<ol style={{fontSize: 16 }}>
				<li>Click the “Try again” button below.</li>
				<li>Allow the broswer to grant <strong style={{fontWeight:900}}>camera & microphone</strong> permissions.</li>
			</ol>
			<div style={{fontSize: 16 }}>
				If the instructions above still don’t work, please click <a style={{color:'inherit',textDecoration:'underline'}} href={'https://ihealthlabs.com/pages/verified-test-access-instructions'} target={'_blank'}>here</a> to view instructions on how to grant access in Safari.
			</div>
			<div style={imgStyle.wrapper}>
				<img src={'/image/permission_warning.png'} style={imgStyle.img}/>
			</div>
			<div>
				<Button className={'ihealth'} style={{width:'100%',height:44,fontSize:18,marginTop:20 }} onClick={ ()=>window.location.reload() }>Try Again</Button>
			</div>
		</div>
	};

	const showNetworkStatusProctor = ()=>{
		const localStatus =  getLocalNetworkStatus();
		const remoteStatus = getRemoteNetworkStatus();
		let { text:localText, color:localColor } = localStatus;
		let { text:remoteText, color:remoteColor } = remoteStatus;
		const style = {
			display:'flex',justifyContent:'end'
		}
		return <div className={`networkStatus`}>
				<div style={style}>
					<div className={`networkIndicator ${localColor}`}/>
					<span className={'text'}>{'Your connection: '}{localText}</span>
				</div>
				<div style={style}>
					<div className={`networkIndicator ${remoteColor}`}/>
					<span className={'text'}>{'Tester’s connection: '}{remoteText}</span>
				</div>
		</div>
	}

	const chatIcon = ()=>{
		return role =='PROCTOR' ? <ChatContainer role={'PROCTOR'} agora={{ appId,channel,uid,rtmToken }}/> :''
	}
	const videoTrack = role =='TESTER' ? localVideoTrack : get(first(remoteUsers)||{},'videoTrack');
	const audioTrack = localAudioTrack;
	const chekcIfisMobile = isMobile();
	if(chekcIfisMobile && permissionDenied) {
		return 	permissionDeniedContent();
	}
	return <div id="ag-canvas" style={style}>
			{/*{ showRecordingIcon()}*/}
			<div className="ag-btn-group" style={{ width:'60%' }}>
				{ !permissionDenied && audioControlBtn()}
				{chatIcon()}
			</div>
				<MediaPlayer videoTrack={videoTrack} audioTrack={null}/>

				{   remoteUsers.map(user => (<div className='remote-player-wrapper' key={user.uid}>
			 				<MediaPlayer videoTrack={null} audioTrack={user.audioTrack}></MediaPlayer>
			 			</div>))
				}
				{role === 'TESTER' ? showNetworkStatusTester() :
					<Row className={'videoHeader'}>
						<Col span={10}>{ showRecordingIcon()}</Col>
						<Col span={14} className={`networkStatusWrapper ${role}`}>
							{ role=='PROCTOR' && showNetworkStatusProctor()}
						</Col>
					</Row>
				}
		  </div>

}
