import React from "react";
import { Row, Col } from 'antd';

const HowItWorksSection = () => {
    const columns = [
        {
            title: '1. Order test kits for recipients',
            description: 'Occupational Safety Officer selects subscription plan for your group. (Or place a one-time order.)',
            image: '/image/landing/order.png',
            imageAlt: 'order',
        },
        {
            title: '2. COVID test kit(s) will be delivered to EACH recipient',
            description: 'Each recipient receives the iHealth COVID-19 Antigen Rapid Test kit directly from iHealth.',
            image: '/image/landing/fulfillment.png',
            imageAlt: 'fulfillment',
        },
        {
            title: '3. Members join your \n COVID test group',
            description: 'Recipients will be invited to join your COVID test group.',
            image: '/image/landing/setupGroup.png',
            imageAlt: 'setup',
        },
        {
            title: '4. Recipients self-test at home',
            description: 'Recipients conduct self-test at home, get the results in 15 mins and submit the results through the iHealth COVID-19 Test App.',
            image: '/image/landing/test.png',
            imageAlt: 'test',
        },
        {
            title: '5. Manage group results via portal',
            description: "Managers, HRs, or safety teams can view and track group members' test results via the online portal.",
            image: '/image/landing/review.png',
            imageAlt: 'review',
        }

    ].map((column, index) => {
        return <Col key={index} span={8} className="hiwSectionColumn">
            <div className="hiwWrapper">
                <img src={column.image} alt={column.imageAlt} />
                <h3 style={{ whiteSpace: 'pre-line' }}>
                    {column.title}
                </h3>
                <p>{column.description}</p>
            </div>
        </Col>
    });
    return <div className="howItWorksSection">
        <div className="sectionWrapper">
            <h2>How it Works</h2>
            <Row type="flex" justify="center" gutter={[50, 34]} style={{ maxWidth: '1100px', marginLeft: 'auto', marginRight: 'auto', flexWrap: 'wrap' }}>
                {columns}
            </Row>
        </div>
    </div>
}

export default HowItWorksSection;

