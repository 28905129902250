let menuItemMap = {
	settings:{
		key: 'settings',
		icon: 'setting',
		label: 'Settings',
		href: 'settings'
	},
	orders:{
		key: 'orders',
		icon: 'gift',
		label: 'Orders',
		href: 'orders'
	},
	results:{
		key: 'results',
		icon: 'bar-chart',
		label: 'Test Results',
		href: 'results'
	},
	testSession:{
		key: 'testSession',
		icon: 'video-camera',
		label: 'Appointment',
		href: 'verifyTest/testSession'
	},
	proctorSettings:{
		key: 'settings',
		icon: 'setting',
		label: 'Settings',
		href: 'verifyTest/settings'
	},
	dashboard:{
		key: 'dashboard',
		icon: 'appstore',
		label: 'Dashboard',
		href: 'verifyTest/dashboard'
	},
	manualReport:{
		key: 'manualReport',
		icon: 'gift',
		label: 'Manual Report',
		href: 'verifyTest/manualReport'
	},
	solutionOrders:{
		key:'solutionOrders',
		icon:'appstore',
		label:'Solution Orders',
		href:'/cs/orders'
	},
	verifyTickets:{
		key:'verifyTickets',
		icon: 'video-camera',
		label:'Verified test tickets',
		href:'/cs/tickets'
	},
	timeSlot: {
		key:'timeslot',
		icon:'schedule',
		label:'Slot management',
		href:'/verifytest/timeslot'
	}
}

export const siderMenu =(()=>{
	try {
		let loginUser = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) || {};
		let { UserApplicationID } = loginUser;
		if(UserApplicationID) {
			return {
				9: [menuItemMap['orders'],menuItemMap["results"],menuItemMap['settings']],
				// 300:[menuItemMap['testSession'],menuItemMap['dashboard'],menuItemMap['manualReport'],menuItemMap['proctorSettings']],
				1000:[menuItemMap['solutionOrders'],menuItemMap['verifyTickets']],
				1002:[menuItemMap['solutionOrders'],menuItemMap['verifyTickets']],
				2000:[menuItemMap['timeSlot']],
				1001:[menuItemMap['solutionOrders'],menuItemMap['verifyTickets']],
				2001:[menuItemMap['testSession'],menuItemMap['dashboard'],menuItemMap['proctorSettings']],
				2002:[menuItemMap['testSession'],menuItemMap['dashboard'],menuItemMap['manualReport'],menuItemMap['proctorSettings']],
			}[UserApplicationID]
		}
		return [];
	} catch (error) {
		return [];
	}
})();