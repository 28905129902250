import React, {useEffect, useRef, useState} from "react";
import CustomerServiceHomeComponent from '../component/CustomerServiceHomeComponent';
import { customerServiceSuborderQuery,customerServiceOrderQuery } from '../../API/query/customerService';
import { getOrderById } from '../../API/query/Order';
import { Input,Row,Col } from 'antd';
import { get } from 'lodash'
import { TypeFormatFlags } from "typescript";
const { Search } = Input
const CustomerServiceHomeContainer = ()=>{
	const [ subOrderList,setSubOrderList ] = useState([]);
	const [ subOrderId,setSubOrderId ] = useState(null);
	const [ orderList,setOrderList ] = useState([]);
	const [ errorMsg,setErrorMsg ] = useState('');
	const [ orderId,setOrderId ] = useState(null);
	const ERROR_MSG = 'Order not found.Please enter a new order number';
	const [total, setTotal] = useState(0);
	let orderInputRef = useRef();
	let groupOrderIdRef = useRef();
	const fetchSubOrders = async (e)=>{
		//clean other fetch
		setOrderList([]);
		setOrderId(null);
		orderInputRef.current.input.state.value='';
		//fetch new data
		const groupId = (e.target.value);
		try {
			await getOrderById(groupId);
			let res = await customerServiceSuborderQuery(groupId);
			setSubOrderId(groupId);
			let subOrderList = get(res, 'data.data', []);
			setSubOrderList(subOrderList);
			setTotal(get(res, 'data.summary.totalCount', 0));
			if (subOrderList.length == 0) {
				setErrorMsg(ERROR_MSG);
			} else {
				setErrorMsg('');
			}
		}
		catch (e) {
			setErrorMsg(ERROR_MSG);
		}
	}

	const fetchOrder = async (e)=>{
		//clean other fetch
		setSubOrderId(null);
		setSubOrderList([]);
		groupOrderIdRef.current.input.state.value = '';
		//fetch new data
		const groupId = (e.target.value);
		try {
			let res = await customerServiceOrderQuery(groupId);
			let orderRes = get(res, 'data.data', []);
			setOrderList(orderRes);
			if (orderRes.length == 0) {
				setErrorMsg(ERROR_MSG);
			} else {
				setErrorMsg('');
			}
		}catch (e) {
			setErrorMsg(ERROR_MSG);
		}
	}

	const renderSearchByGroupId = ()=>{
		return <Search onPressEnter={(e)=>fetchSubOrders(e)} placeholder={'Search group order number'} ref={groupOrderIdRef}/>
	}
	const renderSearchByOrderId = ()=>{
		return <Search onPressEnter={(e)=>fetchOrder(e)} placeholder={'Search individual order number'} ref={orderInputRef}/>
	}
	return <div>
			<Row>
				<Col span={10} offset={1}>
					{ renderSearchByOrderId() }
				</Col>
				<Col span={10} offset={1}>
					{ renderSearchByGroupId() }
				</Col>
			</Row>
			<div>
				<CustomerServiceHomeComponent total={total} orderList={orderList} errorMsg={errorMsg} subOrderList={subOrderList} subOrderId={subOrderId} />
			</div>
		  </div>;
}
export default CustomerServiceHomeContainer;