import moment from 'moment';

export const parseTime = (time)=>{
	let v= time;
	if(time.split(' ')[1]){
		v = time.split(' ')[0];
	}
	const [year,month,day ] = v.split('-');
	return `${month}/${day}/${year}`;
}

export const getStartTimeEndTimeOfRecentNdays = (days=3) => {
    const today = moment().format('MM-DD-YYYY');
    const startTime = moment(today).subtract(days, 'd');
    const endTime = moment();
    return [startTime.format('X'), endTime.format('X')];
}

export const centsToDollar = (value)=>{
    if (typeof value === 'number' ){
        return (value/100).toLocaleString("en-US", {style:"currency", currency:"USD"});
    }
}

export const converDate = (number)=>{
    return moment.unix(number/1000).format('MM/DD/YYYY');
}

export const convertUTCToTimezone = (utcDt, utcDtFormat='YYYY-MM-DD hh:mm:ss A', timezone='UTC')=> {
	return moment.utc(utcDt, utcDtFormat).tz(timezone).format('hh:mm A');
}

export const isMobile  = ()=> {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i
	];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}
