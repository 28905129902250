import React from 'react';
import {Modal, Icon, Button} from 'antd';

const SubmitSuccess = ({visible, close, footer}) => {


    return <Modal visible={visible} centered onOk={close} onCancel={close} footer={footer}>
        <Icon type="check-circle" theme="filled" />
        <h3>Your form has been submitted successfully! </h3>
        <p>Thank you for your interest. We will contact you regards your requrest as soon as possible.</p>
        <Button type="primary" onClick={close}>Ok</Button>
    </Modal>
}

export default SubmitSuccess;
