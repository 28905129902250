import {actionTypes} from "../actionTypes/orderActionTypes";

const initialState = {
	product: {
        "id": 1,
		"name": "iHealth COVID-19 Antigen Rapid Test",
		"sku": "40687042953378",
		"price": 1798,
		"tax_code": "41116205A0005",
		"description": "Self-Test at Home. Result in 15 min.",
		"pack_num": 2,
		"service_fee": 0
    },
	discount: 10000,
	orderDetails: {},
	refresh: 0
}

export const orderReducer = (state = initialState, action) => {
		switch (action.type) {
			case actionTypes.SET_SUBSCRIPTION:
				return {
					...state,
					subscription: action.payload.subscription
				}

			case actionTypes.SET_DISCOUNT:
				return {
					...state,
					discount: action.payload
				}

			case actionTypes.SET_ORDER_DETAILS:
				return {
					...state,
					orderDetails: action.payload
				}

			case actionTypes.SET_PRODUCT_INFO:
				return {
					...state,
					product: action.payload
				}
			case actionTypes.SET_REFRESH:
				return {
					...state,
					refresh: state.refresh+1
				}
			default:
				return state;
		}
}
