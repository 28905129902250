import {actionTypes} from '../actionTypes/testResultActionTypes';

export const getGroupTestStats = data => {
    return {
        type: actionTypes.GET_GROUP_TEST_STATS,
        data
    }
}

export const setGroupTestStats = data => {
    return {
        type: actionTypes.SET_GROUP_TEST_STATS,
        data
    }
}

export const getTestGroups = data => {
    return {
        type: actionTypes.GET_TEST_GROUPS,
        data
    }
}

export const setTestGroups = data => {
    return {
        type: actionTypes.SET_TEST_GROUPS,
        data
    }
}

export const setSelectedGroup = data => {
    return {
        type: actionTypes.SET_SELECTED_GROUP,
        data
    }
}

export const setFilter = filter => {
    return {
        type: actionTypes.SET_FILTER,
        filter
    }
}


export const setGroupUserList = data => {
    return {
        type: actionTypes.SET_GROUP_USER_LIST,
        data
    }
}

export const setDefaultDateRange = data => {
    return {
        type: actionTypes.SET_DETAULT_DATE_RANGE,
        data
    }
}

export const setStartEndTime = (startTime, endTime) => {
    return {
        type: actionTypes.SET_START_END_TIME,
        startTime,
        endTime
    }
}

export const setIsLoading = isLoading => {
    return {
        type: actionTypes.SET_IS_LOADING,
        isLoading
    }
}

export const setTableRows = data => {
    return {
        type: actionTypes.SET_TABLE_ROWS,
        data
    }
}

export const setCurrentPage = currentPage => {
    return {
        type: actionTypes.SET_CURRENT_PAGE,
        currentPage
    }
}