import React from 'react';
import LinkButton from './LinkButton';

const GetStartedWithOffSection = () => {
    return <div className="getStartedWithOffSection sectionWrapper">
        <img src={'/image/receive.png'} alt="receive" />
        <p>
            Get started with 10% OFF on subscription order.
        </p>
        <LinkButton to="/signup" text="Get started" className='button linkButton' />
    </div>

}

export default GetStartedWithOffSection;
