import {Popover, Tooltip} from "antd";
import React from "react";
import { get} from 'lodash'
import moment from 'moment'
import Icon from "antd/es/icon";

let tooltipMsg = '15 min is the timer we set up for the test takers. \n' +
'\tThe time in the parentheses is the extra time\n' +
'\ttesters stay after time is up before clicking \n' +
'\tthe button to join the waitlist to start phase 2.  \n' +
'\tIf you see the time in the parentheses is over \n' +
'\t5 mins, you might want to contact the test taker.';
const TESTER_STATUS_MAP = {
	"PHASE_ONE_WAITING": "Waiting to start phase 1",
	"PHASE_TWO_WAITING": "Waiting to start phase 2",
	"PHASE_ONE_COMPLETED" :'PHASE_ONE_COMPLETED'
}

let FULL_NAME = {
	key:'fullName',
	dataIndex:'firstName',
	title:'Test taker',
	render:(i,doc)=>{
		const { birthday,phone,email,timezoneLabel,code,transactionId,firstName,lastName } = doc;
		const keyToDataIndex = [
			{
				key: 'birthday',
				label: 'DOB:',
				dataIndex:'birthday'
			},
			{
				key: 'phone',
				label: 'Phone:',
				dataIndex:'phone'
			},
			{
				key: 'email',
				label: 'Email:',
				dataIndex:'email'
			},
			{
				key: 'birthday',
				label: 'Time Zone:',
				dataIndex:'timezoneLabel'
			},
			{
				key: 'ticket',
				label: 'Ticket:',
				dataIndex:'code'
			},
			{
				key: 'transactionId',
				label: 'Shopify order#',
				dataIndex:'transactionId'
			},
			{
				key:'transactionPhone',
				label: "Shopify Phone Number:",
				dataIndex: 'transactionPhone'
			}
		]

		const content = <div>{keyToDataIndex.map(({label,dataIndex})=><div style={{display:'flex',marginBottom:5 }}>
			<div style={{marginRight:10}}>{label}</div>
			<div>{get(doc,dataIndex)}</div>
		</div>)}</div>
		return  <Popover title={`${firstName} ${lastName}`} placement={'bottomRight'} overlayClassName={'testerInfoPopover'} content={content}>{`${firstName} ${lastName}`}</Popover>
	}
}
let APP_TIME = {
	key:'scheduledAt',
	dataIndex: 'scheduledAt',
	title:'Appointment time',
	width:'20%',
	render: (i)=>moment(i).format('hh:mm A')
}
let TESTER_STATUS = {
	key:'status',
	title:'Status',
	dataIndex:'status',
	render:(i)=>TESTER_STATUS_MAP[i]
}
let TIME_PASSED = {
	key:'waitedAt',
	title:<div>
		Time passed
		<Tooltip title={tooltipMsg}><Icon style={{marginLeft:10}}  type="info-circle" /></Tooltip>
	</div>,
	dataIndex:'waitedAt',
	render:(i)=> {
		let waitTime = moment().diff(moment(i),'minutes');
		return waitTime >15 ?`15 mins (+ ${waitTime-15} mins)` : `${waitTime} mins`;
	}
}
let WAITING_TIME = {
	key:'waitedAt',
	title:'Waiting time',
	dataIndex:'waitedAt',
	render:(i)=> {
		let waitTime = moment().diff(moment(i),'minutes');
		return waitTime +' mins';
	}
}
let DROPPEDLINE_FOR = {
	key:'updatedAt',
	title:'Dropped connction for',
	dataIndex:'updatedAt',
	render:(i)=> {
		let waitTime = moment().diff(moment(i),'minutes');
		return waitTime +' mins';
	}
}
export const TESTER_STAT_TABLE_MAP = {
	WAITING: [FULL_NAME,APP_TIME,TESTER_STATUS,WAITING_TIME],
	TIMER:[FULL_NAME,APP_TIME,TIME_PASSED],
	DROPLINED: [FULL_NAME,APP_TIME,DROPPEDLINE_FOR]
}
