import React, {useEffect, useState} from "react";
import {confirmAndJoinVideo} from "../../API/query/proctor";
import UserInfoCardComponent from "./UserInfoCardComponent";
import {Button, Col, Icon, Row} from "antd";
import {getVisitQuery} from "../../API/query/schedule";
import {get} from "lodash";
import HttpLog from "../../utils/HttpLog";

const UpcomingTesterComponent = (props)=>{
	const { setState,tester,code,visit,setVisit,setAgoraInit } = props;
	const [ soundPlayed,setSoundPlayed ] = useState(false);
	const [ starting, setStarting ] = useState(false);
	const playSound = (audio)=>{
		audio.play();
		setSoundPlayed(true);
	}
	useEffect(()=>{
		let url = "/sound/insight.mp3";
		let audio = new Audio(url);
		getVisitQuery(code).then(res=>{
			let visitData = get(res,'data.data',{});
			setVisit(visitData);
		})
		if(!soundPlayed) {
			playSound(audio);
		}
	},[]);

	const handleStartBtn = async ()=>{
		try {
			setStarting(true);
			setAgoraInit();
			const agoraRes = await confirmAndJoinVideo(code);
			if(agoraRes.data.message='success') {
				const agoraCredential = get(agoraRes, 'data.summary.agoraCredential');
				if (!!agoraCredential) {
					setAgoraInit(agoraCredential);
					setState('STARTED');
				}
			}
		} catch (err) {
			HttpLog.error({tag: 'confirmAndJoinVideo', err});
		} finally {
			setStarting(false);
		}
	}

	const checkServiceType = (status)=>{
		let type = status.includes('PHASE_TWO') ? 'PHASE_TWO' : 'PHASE_ONE';
		let typeToMsg = {
			PHASE_ONE:'Check in and provide test guidance',
			PHASE_TWO:'Interpret and generate test report'
		}
		return typeToMsg[type];

	}

	return <div className={'upcomingWrapper'}>
			<div className={'title'}>Upcoming tester</div>
			<UserInfoCardComponent setState={setState} {...tester} {...visit}/>
			<Row>
				<Col span={24} className={'serviceType'}>
					<div>Service type: <strong>{checkServiceType(tester.Status||'')}</strong></div>
				</Col>
			</Row>
			<Row>
				<Col span={24} className={'startBtnWrapper'}>
					<Button className={'startBtn ihealth'} onClick={ handleStartBtn } disabled={starting}>
						<Icon type="arrow-right" />{starting ? 'Starting...' : 'Start the video meeting'}
					</Button>
				</Col>
			</Row>
		</div>
};

export default UpcomingTesterComponent;