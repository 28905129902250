import React, {useState} from "react";
import {Modal, Table} from "antd";
import moment from "moment";
import '../style/index.scss';
import SendReportContent from "./SendReportContent";
import {getReportById} from "../../API/query/report";
import { isEmpty } from 'lodash';
const TesterReportListComponent =(props)=>{
	const { reportList=[],total=0,loading,fetchList } = props;
	const [ testerInfo, setTesterInfo ] = useState({});

	const getColumns = ()=>{
		return [{
			key:'fullName',
			dataIndex:'firstName',
			title:'Tester',
			width:'20%',
			render:(o,doc)=>{
				return doc.firstName +' ' + doc.lastName
			}},
			{
				key:'ticketNum',
				dataIndex:'code',
				title:'Ticket number',
			},{
				key:'resultSent',
				dataIndex:'resultReported',
				title:'Result sent',
				render:(o)=>moment(o).format('hh:mm A MM/DD/YYYY')
			},{
				key:'sendBy',
				width:'20%',
				dataIndex:'sentBy',
				title:'Sent by',
			}]
	}

	const renderModal = ()=>{
		return !isEmpty(testerInfo)&&<Modal visible={testerInfo} footer={null} onCancel={()=>setTesterInfo({})}>
			<SendReportContent testerInfo={testerInfo} disableForm={true}/>
		</Modal>
	}
	const handleOnClick = async (record)=>{
		try{
			let res = await getReportById(record.Id)
			if(res.data.code==200) {
				setTesterInfo({...testerInfo,...record,...res.data.data})
			}
		}catch (e) {

		}
		
	}
	const onRow = (record, rowIndex)=>{
		return {
			onClick: async event => {
				console.log(record);
				await handleOnClick(record);
			}
		};
	}
	const renderTable = ()=>{
		return <Table columns={getColumns()}  onRow={onRow} loading={loading} dataSource={reportList} rowKey={'id'} onChange={(p,s,f)=>fetchList(p)} pagination={{ pageSize:10,total:total,hideOnSinglePage:true }}/>
	}

	return <div className={'testReportWrapper'}>
			<span style={{ float:'right',fontWeight:'bold',fontSize: 16,lineHeight:'30px' }}>Total:{total}</span>
			{renderTable()}
			{ renderModal() }
		   </div>
}
export default TesterReportListComponent;