import React, { useEffect, useState, useRef } from "react";
import UserInformationContainer from "../../BasicInfo/container/UserInformationContainer";
import { createWS } from "../../lib/socket/createWS";
import JoiningComponent from "./JoiningComponent";
import Meeting from '../../pages/meeting/index';
import { get, isEmpty } from 'lodash';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import * as Cookies from "js-cookie";
import { DEFAULT_WAITING_MSG } from "../constant";
import RatingComponent from "./RatingComponent";
import { Modal,Row,Col } from "antd";
import '../style/index.scss';
import TestVideoAndAudioComponent from "../../TestSession/component/TestVideoAndAudioComponent";
import ChatContainer from "../../Chat/container/ChatContainer";
import {CustomerSupportNumberIcon} from "./CustomerSupportNumberIcon";


const fetchAndSetStatus = async (getSessionStatus, setStatus, setLoading, setOperator, resetOperator=false, extraStates={}) => {
	let statusRes = await getSessionStatus();
	setLoading(true);
	if (statusRes.data.message == 'success') {
		let { Status, Code, Operator } = get(statusRes, 'data.data');
		setStatus(Status);
		if (Operator > 0 || resetOperator) {
			Cookies.set('proctorId', Operator);
			setOperator(Operator);
		}
		const {timer, setTimer, serverTime, setServerTime, agora, setAgora} = extraStates;
		let completedAtNew = get(statusRes, 'data.summary.completedAt', null);
		if (!!completedAtNew && isEmpty(timer) && !!setTimer) {
			setTimer(completedAtNew)
		}
		let serverTimeNew = get(statusRes,'data.summary.serverCurrent', null);
		if (!!serverTimeNew && isEmpty(serverTime) && !!setServerTime) {
			setServerTime(serverTimeNew)
		}
		let agoraNew = get(statusRes,'data.summary.agoraCredential', null);
		if (!!agoraNew && isEmpty(agora) && !!setAgora) {
			setAgora(agoraNew)
		}
		setLoading(false);
	}
}
const wsEventMap = (cmd, msg, props) => {

	const { agora, setAgora, setStatus, getSessionStatus, setTimer, setLoading, setOperator, setProctorStatus, setWaitingCount,setServerTime } = props;
	return {
		1: async () => {
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, false, {agora, setAgora});
		},
		1001: async () => {
			if (isEmpty(agora)) {
				setAgora(msg.agoraCredential);
			}
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator);
		},
		1002: async () => {
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator);
			setTimer(msg.completedAt);
			setServerTime(msg.serverCurrent);
		},
		1003: async () => {
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator);
		},
		1004: async () => {
			setProctorStatus('HUNGUP');
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, true);
		},
		1010: async () => {
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, false, {agora, setAgora});
			setWaitingCount(parseInt(msg));
		},
		1006: async () => {
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, true);
		},
		1009: async () => {
			await fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, false, {agora, setAgora});
		}
	}[cmd];
}
const TesterStatusComponent = (props) => {
	let initialAgora = get(props, 'summary.agoraCredential', null);
	let timer = get(props,'summary.completedAt',get(props,'timer',null));
	let serverTime = get(props,'summary.serverCurrent',get(props,'serverTime',null));
	const { status, code, getSessionStatus, setStatus, setTimer, operator, setServerTime, setOperator } = props;
	const [agora, setAgora] = useState(initialAgora);
	const [proctorId, setProctorId] = useState();
	const [loading, setLoading] = useState(false);
	const [wsclosed, setWsclosed] = useState(false);
	const [waitingMsg, setWaitingMsg] = useState(DEFAULT_WAITING_MSG);
	const [testerForm, setTesterForm] = useState({});
	//ONLINE, OFFLINE, CHANGING, HUNGUP
	const [proctorStatus, setProctorStatus] = useState('OFFLINE');
	//ONLINE, OFFLINE
	const [testerStatus, setTesterStatus ] = useState('OFFLINE');
	const [waitingCount, setWaitingCount] = useState(null);
	const [pingInterval, setPingInterval] = useState(null);
	const url = createWS();
	const {
		sendMessage,
		lastMessage,
		readyState,
	} = useWebSocket(url, {
		onReconnectStop: (numAttempted) => {
			console.log('ws onReconnectStop', numAttempted);
		},
		onError: (e) => {
			console.log('ws error', e);
		},
		onClose: (m) => {
			console.log('ws close', m);
			if (!!pingInterval) {
				clearInterval(pingInterval);
				setPingInterval(null);
			}
		},
		onOpen: () => {
			console.log('ws opened');
			if (!!pingInterval) {
				clearInterval(pingInterval);
			}
			const id = setInterval(pingServer, 30000);
			setPingInterval(id);
			fetchAndSetStatus(getSessionStatus, setStatus, setLoading, setOperator, false, { timer, setTimer, serverTime, setServerTime, agora, setAgora });
		},
		onMessage: (event) => {
			let res = JSON.parse(event.data);
			let cmd = JSON.parse(res.command);
			try {
				let msg = JSON.parse((typeof res.msg == 'string' && res.msg.length == 0) ? '{}' : res.msg);
				console.log("####----ws message cmd=", cmd);
				let action = wsEventMap(cmd, msg, { ...props, agora, setAgora, setTimer, timer, setServerTime,setLoading, setWaitingCount, setProctorStatus });
				action && action();
			}catch(e){
			}
		},
		//Will attempt to reconnect on all close events, such as server shutting down
		shouldReconnect: (closeEvent) => true,
		reconnectAttempts: 2000,
	}
	);

	useEffect(() => {
		const connectionStatus = {
			[ReadyState.CONNECTING]: 'Connecting',
			[ReadyState.OPEN]: 'Open',
			[ReadyState.CLOSING]: 'Closing',
			[ReadyState.CLOSED]: 'Closed',
			[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
		}[readyState];
		if (connectionStatus !== 'Open') {
			setWsclosed(true);
		} else {
			setWsclosed(false);
		}
  }, [readyState]);

	const pingServer = ()=>{
		try {
			sendMessage(JSON.stringify({command: 1000}));
		} catch(e){
			console.log(e);
		}
	}

	const meetingRef = () => get(agora,'appId') ? <Meeting waitingMsg={waitingMsg} fetchAndSetStatus={fetchAndSetStatus}
		setLoading={setLoading} code={code} operator={operator} status={status} {...agora} setServerTime={setServerTime}
		setTimer={setTimer} {...props} proctorId={proctorId} serverTime={serverTime} timer={timer}
		proctorStatus={proctorStatus} setProctorStatus={setProctorStatus} testerStatus={testerStatus} setTesterStatus={setTesterStatus}
		wsclosed={wsclosed}/>:<div></div>;
	const renderStatusContent = (status) => {
		const statsMap = {
			NEW: <UserInformationContainer code={code} getSessionStatus={getSessionStatus} setStatus={setStatus} />,
			FORM_SUBMITTED: <UserInformationContainer code={code} isEdit={status === 'FORM_SUBMITTED'} status={status} getSessionStatus={getSessionStatus} setStatus={setStatus} />,
			PHASE_ONE_WAITING: <JoiningComponent code={code} status={status} waitingCount={waitingCount} />,
			PHASE_ONE_ONGOING: meetingRef(),
			PHASE_ONE_COMPLETED: meetingRef(),
			PHASE_TWO_WAITING: meetingRef(),
			PHASE_TWO_ONGOING: meetingRef(),
			RESULT_SUBMITTED: meetingRef(),
			PHASE_TWO_COMPLETED: <RatingComponent code={code} />
		}
		return statsMap[status];
	}
	const showChatStatue = ['PHASE_ONE_ONGOING','PHASE_TWO_ONGOING','RESULT_SUBMITTED'];
	let proctorOnline = proctorStatus=='ONLINE';
	return <div className={status}>
			{renderStatusContent(status)}
			{ !isEmpty(agora)&&showChatStatue.includes(status)&&proctorOnline ? <ChatContainer role={'TESTER'} agora={agora}/> :''}
			<CustomerSupportNumberIcon/>

		   </div>
}
export default TesterStatusComponent;