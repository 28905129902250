import React from "react";
import { Result, Divider, Row, Col} from "antd";
import {useSelector} from "react-redux";
import { centsToDollar } from "../../lib/helpers";
import ProductContentComponent from "../../payment/component/ProductContentComponent";
const OrderSuccessConfirmationComponent = ()=>{
	const {orderDetails, product} = useSelector(state=>state.orderReducer);
	const { quantity } = orderDetails.taxDetails[0];
	const subTitle = <div className="subtitle">
		<p><strong>What's next:</strong></p>
		<ol>
			<li><span>1</span>We will send an order confirmation to your email.</li>
			<li><span>2</span>We will notify each recipient by email that you have ordered COVID-19 test kits for them.</li>
			<li><span>3</span>We will email each recipient an invitation to join the COVID Test Group you have set up for them. You will be able to view their test results when they accept the invitation and join your group. </li>
			<li><span>4</span>We will ship the test kit to each recipient within 1 business day.</li>
		</ol>
		<Divider />
		<p><strong>Below are your order details:</strong></p>
		<Row type="flex" className="orderDetails" gutter={8}>
			<Col className="productDetails">
				<p>Order number:<strong>{orderDetails.orderId}</strong>. </p>
				<ProductContentComponent product={product} discountRate={orderDetails.discountRate} discount={orderDetails.discount} />
			</Col>
			<Col offset={2} span={8} className="chargeDetails">
				<Row type="flex" justify="space-between">
					<Col>Quantity:</Col>
					<Col>{`${quantity*orderDetails.taxDetails.length} packs`} </Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col>Subtotal:</Col>
					<Col>{centsToDollar(orderDetails.subTotal)} </Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col>Discount:</Col>
					<Col>-{centsToDollar(orderDetails.discount)} </Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col>Shipping:</Col>
					<Col>Free</Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col>Tax:</Col>
					<Col>{centsToDollar(orderDetails.total_tax)}</Col>
				</Row>
				<Divider />
				<Row type="flex" justify="space-between" className="total">
					<Col>Total:</Col>
					<Col>{centsToDollar(orderDetails.total)}</Col>
				</Row>
			</Col>
			<Col>
			{`(${orderDetails.taxDetails.length} recipients x ${quantity} packs/recipient)`}
			</Col>
		</Row>
	</div>
	return <Result className="orderSuccess" status="success" title={'Thank you for your order!'} subTitle={subTitle}/>
}
export default OrderSuccessConfirmationComponent;