import { postRequest } from '../helper/index';
import { getLoginUser } from '../../auth/helper';


const HEADER =(auth)=>({
    "Authorization": `kit ${auth || getLoginUser()}`,
    "Content-Type": "application/x-www-form-urlencoded"

})

const STATS_URL ='/api/kit/group/statistics';
const GROUT_LIST_URL = '/api/kit/group/list';
const GROUP_USER_LIST_URL = '/api/kit/group/users';
const LEAVE_GROUP_URL = '/api/kit/group/leave';
const GROUP_USER_DETAILS_URL = '/api/kit/group/userdetails';


export const getGroupTestStats =(data)=> postRequest(STATS_URL,{ headers:HEADER(), data });
export const getTestGroups =()=> postRequest(GROUT_LIST_URL,{ headers:HEADER() });
export const getGroupUserList = (data)=> postRequest(GROUP_USER_LIST_URL,{ headers:HEADER(), data });
export const removeMemberFromGroup = (data)=> postRequest(LEAVE_GROUP_URL,{ headers:HEADER(), data });
export const getGroupUserDetails = (data)=> postRequest(GROUP_USER_DETAILS_URL,{ headers:HEADER(), data });