import { postRequest } from "../helper";
import { getLoginUser } from "../../auth/helper";

const UPDATE_PASSWORD_URL = '/api/kit/user/resetpassword';
const VERIFY_EMAIL_CODE_URL = '/api/kit/user/sendcode';
const FIND_PASSWORD_URL = '/api/kit/user/findpassword';
const USER_HEADER =(auth)=>({
	"Authorization": `kit ${auth || getLoginUser()}`,
	"Accept": "*/*",
})
export const updateUserPassword =(auth,data)=> postRequest(UPDATE_PASSWORD_URL,{ headers:USER_HEADER(auth),data });
export const verityEmailCodeMutation = (data)=>postRequest(VERIFY_EMAIL_CODE_URL,{ data });
export const findPasswordMutation = (data)=>postRequest(FIND_PASSWORD_URL,{ data });