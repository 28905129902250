import { getGroupUserList, getGroupUserDetails, getGroupTestStats} from '../../API/query/TestResults';
import { setGroupUserList, setTableRows, setGroupTestStats } from '../../redux/actions/testResultActions';

export const mergeResponseList = (response) => {
    if(response.data.message==="success" && response.data.data){
        const admin = response.data.data.Admin;
        if (admin) {
            return [...response.data.data.UserList, admin];
        }
        return response.data.data.UserList? response.data.data.UserList : [];
    }
    return [];
}

export const getAndSetGroupUserList = async (dispatch, data) => {
    // data = {GroupCode, StartTime, EndTime, Page, PageSize, ResultType}
    try{
        if(!data.GroupCode){
            return;
        }
        const response = await getGroupUserList(data);
        const userList = mergeResponseList(response);
        const ids = userList.map(user => user.UserID);
        dispatch(setGroupUserList(userList));
        if(ids.length>0){
            await getPaginatedUserList(dispatch, {...data, Users: ids});
        }
    } catch (error) {
        console.log('Error', error);
        
    } 

}

export const getPaginatedUserList = async (dispatch, data) => {
    // data = {GrouCode: string, StartTime: number, EndTime: number, Page: int, PageSize: int, ResultType: string, Users: [UserId]}
    try {
        const response = await getGroupUserDetails(data);
        if(response.data.message==="success" && response.data.data) {
            dispatch(setTableRows(response.data.data));
        } 
    } catch (error) {
        console.log('Error', error);
    } 

}

export const getGroupStats = async (dispatch, data) => {
    try {
        const response = await getGroupTestStats(data);
        if(response.data.message==="success" && response.data.data) {
            dispatch(setGroupTestStats(response.data.data));
        }
    } catch (error) {
        console.log('Error', error);
    } 
}