import React, { useEffect, useState } from "react";
import TimerComponent from "../components/TimerComponent";
import { startVisitMutation, getSessionStatusQuery } from "../../API/query/redeem";
import { Button, Progress } from "antd";
import { testResultWaitMin } from "../config";
import JoiningComponent from "../components/JoiningComponent";
import { get } from "lodash";

const MS_IN_MIN = 60000;

const TimerContainer = (props) => {
	const { completedAt = Number.MAX_VALUE, status, code, serverTime } = props;
	let timeLeft = ((completedAt * 1000 + testResultWaitMin * MS_IN_MIN) - (serverTime * 1000 || new Date().getTime()));
	const [state, setState] = useState(timeLeft <= 0 ? 'READY' : 'TIMER');
	const timesUpContent = () => {
		const sendVisit = async () => {
			try {
				await startVisitMutation();
			} catch (e) {
				const code = get(e, 'response.status');
				if (code === 400) {
					let status = await getSessionStatusQuery();
					let completeTime = get(status, 'data.summary.completedAt', null);
					let timenow = get(status, 'data.summary.serverCurrent', null);
					let timeServerLeft = ((completeTime * 1000 + testResultWaitMin * MS_IN_MIN) - (timenow * 1000 || new Date().getTime()));
					const wait = timeServerLeft > 0 ? timeServerLeft + 5000 : 5000;
					setTimeout(() => {
						sendVisit();
					}, wait);
				}
			}
		}
		const handleReadyBtn = async () => {
			setState('WAITING');
			sendVisit();
		}
		return <div className={'timesUpContainer'}>
			<div className={'title'}>{`${testResultWaitMin} min is Up!`}</div>
			<div className={'content'}>When you are ready to show your test result, click the button below and we will connect you with the iHealth proctor again to view your test result.</div>
			<div><Button onClick={handleReadyBtn} className={'ihealth'} style={{ height: 60 }}>I am ready to show my test result</Button></div>
		</div>
	}

	const timerCountDown = () => {
		return <div className={'timerCountDownContainer'}>
			{serverTime && <TimerComponent state={state} setState={setState} timeLeft={timeLeft} />}
			<p style={{textAlign: 'center'}}>Please wait for {`${testResultWaitMin}`} min for the test result.</p>
			{displayMonitoring()}
		</div>
	}

	const displayMonitoring = () => {
		return <div className={'displayMonitoringContainer'}>
			<img src="/assess/camera_black.svg" alt="camera" />
			<p>Video monitoring is in progress</p>
			<div className={'content'}>Please keep your camera facing the test card <strong>at all times</strong> while waiting for your result. Suspicious movements of the test card could invalidate your test result. </div>
			</div>
	}

	const waitingForEngage = () => {
		return <div className={'timesUpContainer'}>
			<JoiningComponent code={code} status={status} />
		</div>
	}

	const stateMap = {
		READY: timesUpContent(),
		TIMER: timerCountDown(),
		WAITING: waitingForEngage()
	}

	return stateMap[state];
}

export default TimerContainer;