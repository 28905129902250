import React from 'react';
import { Row, Col, Icon } from "antd";

const Step2Content = () => {
    return <div>
                <div className={'label'} style={{textAlign:'left',width: 600 }}>
	                <div style={{ marginTop:20 }}>To upload your team's shipping information. a) Click or tap the "Download Excel template" button below. b) Then, build and modify the shipping information file for each member. Fill out all the required columns and double-check the names, shipping addresses, and emails.</div>
	                <div style={{ margin:'20px 0px' }}>The correct information will help ensure that your team members are notified of inbound mail packages and receive their test kit(s) in time. (Max file size: 5 MB)</div>
                </div>
				{/*<div style={{padding: "11px 18px", background:'#E9F1FA', marginTop:15, marginBottom:25, borderRadius: '2px', textAlign:'left'}}>*/}
				{/*	<Row type="flex">*/}
				{/*		<Col>*/}
				{/*			<Icon type="exclamation-circle" style={{color: '#3177C9'}} theme="filled"/>*/}
				{/*		</Col>*/}
				{/*		<Col span={23}>*/}
				{/*			<ul>*/}
				{/*				<li><span>*/}
				{/*				We will <strong>ship the test kit directly to each recipient</strong> based on the <strong>addresses in the file</strong>. Please make sure they are accurate.*/}
				{/*				</span>*/}
				{/*				</li>*/}
				{/*				<li><span>*/}
				{/*				Email addresses will be used as <strong>contact information and iHealth COVID Test App login account</strong> for each recipient. Please make sure they are accurate.*/}
				{/*				</span>*/}
				{/*				</li>*/}
				{/*			</ul>*/}
				{/*		</Col>*/}
				{/*	</Row>*/}
				{/*</div>*/}
        </div>
}

export default Step2Content;