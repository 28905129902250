import React, {useState} from 'react';
import AppointmentDetailComponent from "./AppointmentDetailComponent";
import {get,isEmpty} from "lodash";
import { Input ,Button,message } from 'antd';
import {mutateCancelVisit} from "../../API/query/schedule";
import errorMsg from "../constant";
import {useNavigate} from "react-router-dom";
const CancelAppComponent = (props)=>{
	const { visit={},tester={},header='',setStatus } = props;
	const { ScheduledAt,TimezoneLabel,Timezone } = visit||{};
	const code = get(tester,'Code');
	const [ reason,setReason ] = useState();
	const navigate = useNavigate();
	const handleCancel = async ()=>{
		try {
			const res = await mutateCancelVisit({code,reason});
			if(res.data.code=='200') {
				setStatus('CANCELLED')
			}
		}catch (e) {
			let errorMessage = get(e,'response.data.message');
			if(errorMessage === "Invalid code status") {
				setStatus('COMPLETED')
			}
			message.error(errorMsg[errorMessage] || errorMessage);
		}
	}
	const renderButton = ()=>{
		return <Button className={'ihealth'} style={{ fontWeight: 700,width:230,height:44,fontSize:20 }} onClick={handleCancel}>Cancel Appointment</Button>
	}
	const scheduleButton = ()=>{
		return <Button className={'ihealth'} style={{ fontWeight: 700,width:300,marginTop:30,height:44,fontSize:20 }} onClick={()=>navigate(`/verifyTest/schedule/${code}`)}>Schedule an appointment</Button>

	}
	if(isEmpty(visit) ){
		return <div className="appointmentFinishedWrapper">
			<div style={{fontSize:16}}>Your appointment has already been canceled.
				To schedule a new appointment, please click the button below.</div>
			<div>{scheduleButton()}</div>
		</div>
	}
	return (
		<div className="appointmentFinishedWrapper">
			<h1>{header}</h1>
			<AppointmentDetailComponent {...tester} selectedSlot={{timeSlot:ScheduledAt}} selectedTimezone={{label:TimezoneLabel,offset:Timezone}}/>
			<div className={'cancelReasonWrapper'}>
				<div className={'title'}>Reason for canceling</div>
				<Input.TextArea rows={10} onChange={(e)=>setReason(e.target.value)}/>
			</div>
			<div style={{ alignSelf:'flex-start',marginTop:'10px',fontWeight: 700 }}>{renderButton()}</div>
		</div>
	);
}

export default CancelAppComponent