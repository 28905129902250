import React, {useEffect, useState} from "react";
import TestSessionVideoComponent from "../component/TestSessionVideoComponent";
import { get } from 'lodash';
import '../style/index.scss';
import {getRecordStatusQuery} from "../../API/query/video";
const TestSessionVideoContainer = (props)=>{
	const { fetchAgora,tester,code,agoraInit } = props
	const [ agora,setAgora ] = useState(agoraInit);
	const [ recording,setRecording ] = useState();
	const fetchRecordStatus = async (code)=>{
		try {
			await getRecordStatusQuery(code);
			setRecording(true);
		}catch (e) {
			const code = get(e,'response.data.code');
			if(code==400) {
				setRecording(false);
			}
		}
	}
	useEffect(()=>{
		if (!agora) {
			let code = get(tester,'Code');
			fetchAgora(code).then(res=>{
				setAgora(res.agoraCredential);
			});
		}
		fetchRecordStatus(code,setRecording);
	},[])
	return	agora ? <TestSessionVideoComponent {...agora}  fetchRecordStatus={fetchRecordStatus} code={code} tester={tester} {...props} recording={recording}/> :<span>Loading</span>

}
export default TestSessionVideoContainer