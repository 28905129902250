import { get } from 'lodash';
const lang = window.sessionStorage.getItem('lang') || 'en';

export default {
  get: (key) => {
    const MAP = require(`./en`).default;
    return get(MAP, key) || key;
  }

  // getFrom : (stringMap)=>(key)=>{
  //   const MAP = stringMap || {}
  //   return _.get(MAP, key) || key
  // }
};
