import React, {useState} from "react";
import {Button, InputNumber, Modal} from "antd";
import { reshipOrderMutation } from '../../API/query/customerService';
import { get,isInteger } from 'lodash'
const ReshipModalComponent = (props)=>{
	let [ shipCount,setShipCount ] = useState(1);
	const { orderRes,setShowReshipModal } = props;
	const { quantity,id } = orderRes;
	const handleConfirm = async ()=>{
		let res = await reshipOrderMutation(id,{ quantity: shipCount });
		let newOrderId = get(res,'data.data.id');
		setShowReshipModal(false);
		Modal.success({
			title:'Reship confirmed!',
			content:`New order Id is ${newOrderId}`
		})
	}
	const handleChangeInputNumber = (value)=>{
		if (!isInteger(value)) {
			return;
		}
		setShipCount(value)


	}
	return <div>
		<div style={{marginBottom:'20px'}}>Test kit quantity included in this order: {quantity} packs</div>
		<div style={{marginBottom:'20px',display:'flex',flexDirection:'column'}}>
			Enter quantity (number of packs) to reship to this customer
			<InputNumber min={1} max={quantity} onChange={handleChangeInputNumber} defaultValue={shipCount} style={{ marginTop:10 }} value={shipCount}/>
		</div>
		<div style={{marginBottom:'20px',textAlign:'center'}}>By clicking “Confirm reshipping”, we will place another order and ship this customer the iHealth COVID-19 Antigen Test Kit the quantity you entered.</div>
		<div style={{ textAlign:'center'}}><Button className={'ihealth'} onClick={()=>handleConfirm()}>Confirm reshipping</Button></div>
	</div>
}

export default ReshipModalComponent;