import React, {useState, useEffect} from "react";
import '../style/index.scss';
import { Button,Icon,Row,Col } from 'antd';
import TestSessionVideoContainer from "../container/TestSessionVideoContainer";
import DispatchingComponent from "./DispatchingComponent";
import { get } from 'lodash'
import UpcomingTesterComponent from '../component/UpcomingTesterComponent';
const TestSessionComponent = (props)=>{
	const { state,setState } = props;
	const { tester,setTester } = props;
	const { updateProctorStatus } = props;
	const { agoraInit, setAgoraInit, fetchAgora, wsclosed } = props;
	const [ visit,setVisit ] = useState({});
	const [ available, setAvailable ] = useState();
	let code = get(tester,'Code');
	useEffect(() => {
		if (!!state) {
			let data = {
				WAITING: 'NOT_AVAILABLE',
				DISPATCHING: 'AVAILABLE',
				UPCOMING: 'NOT_AVAILABLE',
				STARTED: 'NOT_AVAILABLE',
			}[state];
			if (!!data && available !== data) {
				setAvailable(data);
				!!updateProctorStatus && updateProctorStatus(data);
			}
		}
	}, [state]);
	const dispatchingContent = ()=>{
		const { getNextTester,getOnGoingTester } = props;
		const handleExitBtn = ()=>{
			setState('WAITING');
		}
		return <React.Fragment>
					<div style={{textAlign:'right', width:'100%',paddingRight:30 }}>
						<Button style={{ marginTop:10 }} onClick={handleExitBtn}>Exit</Button>
					</div>
					<DispatchingComponent setState={setState}
		                                             setTester={setTester} tester={tester} getOnGoingTester={getOnGoingTester} getNextTester={getNextTester}/>
			   </React.Fragment>
	}
	const waitingContent = ()=>{
		return <div className={'waitingContent'}>
			<div className={'message'}>
				Each session will take around <strong>5 min</strong>, and <strong>no quit is allowed.</strong> Please click the button below when you are ready, and the system will dispatch a tester to you.
			</div>
			<div>
				{ startSessionBtn() }
			</div>
		</div>
	}
	const testVideoContainer= ()=>{
		return <TestSessionVideoContainer agoraInit={agoraInit} fetchAgora={fetchAgora} tester={tester} code={code} setState={setState} state={state} {...visit} wsclosed={wsclosed}/>
	}
	const startDispatch = async ()=>{
		const ele = document.getElementsByClassName('ant-layout-sider');
		if(ele[0]) {
			const classList = ele[0].classList;
			if (classList.contains('slideOut')) {
				classList.remove('slideOut');
			}
			classList.add('slideIn');
		}
		setState('DISPATCHING');

	}
	const startSessionBtn = ()=>{
		return <div>
			<Button className={'ihealth'} onClick={startDispatch}>
				<Icon type="arrow-right" />
				I am available to start a session
			</Button>
		</div>
	}
	const stateMap = {
		WAITING: waitingContent(),
		DISPATCHING: dispatchingContent(),
		UPCOMING: <UpcomingTesterComponent tester={tester} setState={setState} code={code} setVisit={setVisit} visit={visit} setAgoraInit={setAgoraInit}/>,
		STARTED: testVideoContainer()
	}
	return <div className={'testSessionWrapper'} style={{height:'100%'}}>
				{ stateMap[state] }
		   </div>
}
export default TestSessionComponent;