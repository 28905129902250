import React, { useState } from "react";
import { Button, Input, message,Radio } from "antd";
import { endSessionMutation, sendResultMutation } from '../../API/query/proctor';
import { get, isEmpty } from 'lodash';
import { getSessionStatusQuery } from "../../API/query/redeem";
import RadioGroup from "antd/es/radio/group";
const SubmitTestResultComponent = (props) => {
	const [result, setResult] = useState();
	const { code, status, email, setStatus } = props;
	const [ sending, setSending ] = useState(false);
	const sendResult = async () => {
		try {
			setSending(true);
			await sendResultMutation(code, { result,email });
			setStatus('RESULT_SUBMITTED');
			message.success('Test report sent!');
			setSending(false);
		} catch (e) {
			setSending(false);
		}
	}
	const resultContent = () => {
		const resultArray = [{
			key: 'NEGATIVE',
			value: 'NEGATIVE',
			label: 'Negative'
		}, {
			key: 'POSITIVE',
			value: 'POSITIVE',
			label: 'Positive'
		},{
			key:'INVALID',
			value:'INVALID',
			label: 'Invalid'
		}
		];
		return <div className={'buttonGroup'}>
				<label>Test result:</label><RadioGroup style={{ marginLeft:50 }} defaultValue={result} onChange={(e)=>setResult(e.target.value)}>
				{ resultArray.map(({value,label,key})=><Radio value={value} key={key}>{label}</Radio>)}
			</RadioGroup>
		</div>
	}
	const emailInput = () => {
		const disabled = isEmpty(email) || !result || sending;
		const btnText = sending? 'sending...' : status === 'RESULT_SUBMITTED' ? 'Resend' : 'Send to email'
		const sent = status === 'RESULT_SUBMITTED';
		return <div className={'emailDiv '}>
			<div className={'input'}>
				{/*<label>Email:</label>*/}
				{/*<div>*/}
				{/*	<Input defaultValue={email} onChange={(e) => setEmail(e.target.value)} />*/}
				{/*	<div className={'message'}>The test resport will be sent to this email, please confirm with the tester.</div>*/}
				{/*</div>*/}
				{ !sent ? <Button onClick={sendResult} disabled={disabled} className={'ihealth'}>{btnText}</Button> :
						  <a  onClick={sendResult} disabled={disabled} className={'send'}>{btnText}</a> }
				{/*<Button onClick={sendResult} disabled={disabled} className={`ihealth ${sent ? 'sent' :'' }`}>{btnText}</Button>*/}
			</div>
		</div>
	}

	return <div>
		{resultContent()}
		{emailInput()}
	</div>
}
export default SubmitTestResultComponent;