import React, {Fragment, useEffect, useState} from 'react';
import {Dropdown, Menu, Icon, Button} from 'antd';
import OnboardingPopUpComponent from '../../Onboarding/container/OnboardingPopUpComponent';
import CustomerSupportContainer from '../../CustomerSupport/container/CustomerSupportContainer';
import {getOnboardingStatus} from '../../API/query/Onboarding';
import {get,isEmpty} from 'lodash';
import {getUserInfo} from "../../auth/helper";

const SupportMenu = ()=>{
    const [ visibility, setVisibility ] = useState(false);
    const [ visible, setVisible ] = useState(false);
    const [status, setStatus] = useState(0);
    const user = getUserInfo()|| {};

    useEffect(()=>{
      const doFetch = async ()=> {
        try {
          const res = await getOnboardingStatus();
          const status = get(res, 'data.data.onboarding');
          if(status !== "1") {
            setVisibility(true);
          } else {
            setStatus(status);
          }
        } catch (e) {
          
        }
      }
      doFetch();
    }, []);

    const menu = (
        <Menu className="supportMenu">
          <Menu.Item key="0">
            <Button type="link" onClick={()=>setVisible(true)}>Contact support</Button>
          </Menu.Item>
          <Menu.Item key="1">
            <Button type="link" onClick={()=>setVisibility(true)}>Onboarding</Button>
          </Menu.Item>
        </Menu>
      );
    if(isEmpty(user)){
        return <div></div>
    }
    return <Fragment>
            <Dropdown overlay={menu} trigger={['click']} className="supportDropdown" placement='bottomRight'>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <Icon type="question-circle" />
                </a>
            </Dropdown>
            <OnboardingPopUpComponent status={status} setStatus={setStatus} visibility={visibility} setVisibility={setVisibility.bind(this)}/>
            <CustomerSupportContainer visible={visible} setVisible={setVisible.bind(this)} />
        </Fragment> 
}

export default SupportMenu;