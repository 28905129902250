import React from 'react';


const ProvideTestGuidanceComponent = (props)=>{
    return <div className={'testGuidanceWrapper'}>
        <div className="serviceTypeLabel">Service type:</div>
        <div className="serviceType">Checkin and provide test guidance</div>
        <div className="instructions">
            <div className="instruction">
                <h4>1. Introduce yourself.</h4>
                <p><i>Script:  Thank you for joining the iHealth Verified COVID test appointment. My name is [Name], and I will be your proctor instructing and guiding you through your COVID test.</i></p>
            </div>
            <div className="instruction">
                <h4>2. Verify tester's identity.</h4>
                <p>Have the tester to place their photo ID in front of the camera. </p>
                <p>Check and make sure the <strong>name</strong>, <strong>date of birth</strong> and <strong>photo ID</strong> match this person.</p>
            </div>
            <div className="instruction">
                <h4>3.  Guide the tester to collect sample.</h4>
                <p>Guide the tester to unpack the test kit contents, and collect the sample. </p>
            </div>
        </div>

    </div>
}

export default ProvideTestGuidanceComponent;