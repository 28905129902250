import React from 'react';
import { Link} from "react-router-dom";

const SignUpButtonWrapperComponent = class extends React.Component{
	signUpButton = ()=> <Link to={'/signup'}>Sign Up</Link>;
	render() {
		const { signUpButton } = this;
		return <div className='SignUpButtonWrapperComponent'>
			Don’t have an account? { signUpButton() }
		</div>
	}
}

export default SignUpButtonWrapperComponent