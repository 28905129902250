import React from 'react';
import { Checkbox, DatePicker, Row, Col} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setStartEndTime } from '../../redux/actions/testResultActions';
import {getStartTimeEndTimeOfRecentNdays} from '../../lib/helpers';
import { getAndSetGroupUserList, getGroupStats } from '../helper';
const { RangePicker } = DatePicker;

const TestResultDatePickerController = () => {
    const dispatch = useDispatch();
    const { isLoading, selectedTestGroup, filter } = useSelector(state => state.testResultReducer);
    const GroupCode = selectedTestGroup.GroupCode;
    const [checked, setChecked] = React.useState(true);

    const onCheckboxChange = async (e) => {
        const checked = e.target.checked;
        setChecked(checked);
        if (checked) {
            const [StartTime, EndTime] = getStartTimeEndTimeOfRecentNdays();
            dispatch(setStartEndTime(StartTime, EndTime));
            const data = {
                GroupCode,
                StartTime,
                EndTime,
                Page: 1,
                PageSize: 10,
                ResultType: filter
            };
            getGroupStats(dispatch, {GroupCode, StartTime, EndTime});
            getAndSetGroupUserList(dispatch, data);
        } 
    }

    const onDatePickerChange = async (e) => {
        const [start, end] = e;
        const StartTime = start.format("X");
        const EndTime = end.format("X");
        dispatch(setStartEndTime(StartTime, EndTime));
        const data = {
            GroupCode,
            StartTime,
            EndTime,
            Page: 1,
            PageSize: 10,
            ResultType: filter
        };
        getGroupStats(dispatch, {GroupCode, StartTime, EndTime});
        getAndSetGroupUserList(dispatch, data);
    }

    const disabledDate = (current) => {
        return current && current.valueOf() > Date.now();
    }

    return <Row gutter={16} type="flex" align="middle" justify="space-between" style={{marginBottom: '35px'}} >
            <Col>
                <div className={'testResultHeaderWrapper'}>
                    <h1>Test Results</h1>
                </div>
            </Col>
            <Col className="gutter-row" >
                    <RangePicker disabled={checked||isLoading} id="datePicker" onChange={onDatePickerChange} separator={'-'} disabledDate={disabledDate} />
                    <Checkbox onChange={onCheckboxChange} id="recentOneWeek" checked={checked} disabled={isLoading}>
                        View recent 3 days
                    </Checkbox>
        
            </Col>
        </Row>
    
}


export default TestResultDatePickerController;
