import React from "react";
import LoginFormComponent from "../component/LoginFormComponent";
import '../style/index.scss';
import SignUpWrapperComponent from "../../SignUp/component/SignUpButtonWrapperComponent";
import { ForgotPasswordContainer } from "../../Password/container/ForgotPasswordContainer";

const LoginFormContainer = class extends React.Component {
	constructor() {
		super();
		this.state = {
			showRestPwd: false
		}
	}
	renderHeader = () => <h1 className={'formHeader'}>iHealth COVID Testing Solution</h1>
	renderSubheader = ()=><div className={'formSubHeader'}>We offer the all-in-one solution for COVID test kit subscription, delivery and COVID test result management</div>
	renderFooter =()=><div className={'formFooter'} onClick={()=>this.setShowRestPwd(true)}
	                       style={{textDecoration:'underline',cursor:'pointer',width:'fit-content',height:'fit-content',marginTop:20}}>
						Forget Password
				      </div>

	setShowRestPwd = (showRestPwd)=>{
		this.setState({
			showRestPwd
		})
	}
	render() {
		const { renderSubheader,renderFooter,setShowRestPwd, renderHeader } = this;
		const { showRestPwd } = this.state;
		return <div className='loginFormWrapper'>
				<div id={'loginContainer'}>
					{ renderHeader() }
					{ renderSubheader() }
					<LoginFormComponent/>
					{ renderFooter() }
				</div>
				<SignUpWrapperComponent/>
				 { showRestPwd && <ForgotPasswordContainer setShowRestPwd={setShowRestPwd} showRestPwd={showRestPwd}/>}
              </div>
	}

}


export default LoginFormContainer