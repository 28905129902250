import React from "react";
import { Icon, Select,InputNumber } from "antd";
import { isInteger } from 'lodash'
const { Option } = Select;
export const orderStatusMap = [{
	key:'paid',
	label:'Paid',
	value:'paid'
},{
	key:'failed',
	label:'Failed',
	value:'failed'
}, {
	key:'unpaid',
	label:'Unpaid',
	value:'unpaid'
}]
export const FREQUENCY_UNIT = [{
	label:'week',
	value:'1-week',
	key:'week'
},{
	label:'2 weeks',
	value:'2-week',
	key:'2weeks'
},{
	label:'month',
	value:'1-month',
	key:'month'
}]

const icon = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ fontSize:20 }}/>;
export const SUBSCRIPTION_PLAN_MAP =({setUnit,setFreq,setOrderCount,frequency, frequencyUnit,orderCount,selectedCard})=>{
	const onChangeCustomizePacks = (v)=>{
		if(!isInteger(v)) {
			return;
		}
		setOrderCount(v);
	}
	const onChangeCustomizeFreq = (v)=>{
		const [freq,freqUnit ] = v.split('-');
		setUnit(freqUnit);
		setFreq(parseInt(freq));
	}

	return {
		'2 Packs /Week':{
			frequency:1,
			key:1,
			count:2,
			frequencyUnit:'week',
			label:'2 packs per week',
			// subTitle:<div className={'subtitle'}>
			// 	<span>Best for people who need to be on-site every day.</span>
			// 	<strong style={{fontWeight:'bold',color:'black'}}>Ship weekly</strong>
			// </div>,
			extra:icon
		},
		'1 Pack / Week':{
			frequency: 1,
			frequencyUnit: 'week',
			label:'1 pack per week',
			count:1,
			key:2,
			// subTitle: <div className={'subtitle'}>
			// 	<span>Ensures 2 tests / week / person.</span>
			// 	<strong style={{fontWeight:'bold',color:'black'}}>Ship weekly</strong>
			// </div>,
			extra: icon
		},
		'1 Pack / 2 Weeks':{
			frequency: 2,
			frequencyUnit: 'week',
			label:'1 pack every 2 weeks',
			key:3,
			count:1,
			// subTitle: <div className={'subtitle'}>
			// 	<span>Ensure people have test kit as needed.</span>
			// 	<strong style={{fontWeight:'bold',color:'black'}}>Ship bi-weekly</strong>
			// </div>,
			extra: icon
		},
		'1 Pack / Month':{
			key:4,
			frequency: 1,
			frequencyUnit: 'month',
			label: '1 pack per month',
			count:1,
			// subTitle:<div className={'subtitle'}>
			// 	<span>Ensure people have test kit occasionally.</span>
			// 	<strong style={{fontWeight:'bold',color:'black'}}>Ship Monthly</strong>
			// </div>,
			extra: icon
		},
		'Customize plan': {
			key:5,
			frequency: 1,
			frequencyUnit: null,
			label: 'Customize your plan',
			className:'customize',
			count: null,
			extra: icon,
			subTitle:<React.Fragment>
				<InputNumber onChange={(e)=>onChangeCustomizePacks(e)} value={selectedCard=='5' ? orderCount : null} min={1} max={10}/>
				<span style={{ lingHeight: '22px',fontSize:20,color: '#2D3238',fontWeight:'bold',margin:'0 5px'}}>packs</span>
				<span style={{ lingHeight: '22px',fontSize:20,color: '#2D3238',fontWeight:'bold',margin:'0 5px'}}>/</span>
				<Select onChange={(e)=>onChangeCustomizeFreq(e)} style={{ width:'100px',minWidth:'100px' }} value={selectedCard=='5'&&frequencyUnit ? frequency+'-'+frequencyUnit : null}>
					{FREQUENCY_UNIT.map(({value,label,key})=><Option value={value} key={key}>{label}</Option>)}
				</Select>
			</React.Fragment>
		}
	}
}

export const ORDER_PLAN_MAP = {
	'One time order':{
		key:6,
		frequency: 1,
		cunt:1,
		frequencyUnit: 'week',
		className:'onetime',
		label: 'One time order',
		// subTitle:<div className={'subtitle'}>
		// 	<span>Meet your one-time needs.</span>
		// </div>,
		extra: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
	}
}