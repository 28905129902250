import React, {useEffect, useState} from "react";
import '../style/index.scss';
import {Button, Radio} from "antd";
import RadioGroup from "antd/es/radio/group";
import { sum} from 'lodash'

const DEVIDE_COUNT = 3;
const TestVideoAndAudioComponent = (props)=>{
	var video = document.getElementById('testVideoAndAudio');
	const { setShowConfirmVideoAndAudio } = props;
// Get access to the camera!
	const [ loadingVideo,setLoadingVideo ] = useState(true);
	const [ audioPlaying, setAudioPlaying ] = useState(false);
	const [ audio,setAudio ] = useState(null);
	const [ devicesEnabled, setDevicesEnabled ] = useState({});
	const checkMicrophone = (stream)=>{
		const audioContext = new AudioContext();
		const analyser = audioContext.createAnalyser();
		const microphone = audioContext.createMediaStreamSource(stream);
		const scriptProcessor = audioContext.createScriptProcessor(2048, 0, 1);
		analyser.smoothingTimeConstant = 0.8;
		analyser.fftSize = 1024;

		microphone.connect(analyser);
		analyser.connect(scriptProcessor);
		scriptProcessor.connect(audioContext.destination);
		scriptProcessor.onaudioprocess = function() {
			const array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			const arraySum = array.reduce((a, value) => a + value, 0);
			const average = arraySum / array.length;
			colorPids(average);
		};
	}
	const initAudio = ()=> {
		let initAudio = new Audio('/sound/insight.mp3');
		setAudio(initAudio);
	}
	const playAudio = async ()=>{
		if(audio) {
			audio.play();
		}
	}
	if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		if(loadingVideo) {
			navigator.mediaDevices.getUserMedia({video: true, audio: true})
				.catch(function (e) {
					console.log(e);
				})
				.then(async function (stream) {
					try {
						if (video) {
							checkMicrophone(stream);
							video.srcObject = stream;
							video.play().then(res => setLoadingVideo(false));
						}
					} catch (e) {
						console.log(e);
					}
				});
		}
	}
	useEffect(()=>{
				initAudio();
			}
	,[])
	function colorPids(vol) {
		const allPids = [...document.querySelectorAll('.pid')];
		const numberOfPidsToColor = Math.round(vol / 10);
		const pidsToColor = allPids.slice(0, numberOfPidsToColor);
		for (const pid of allPids) {
			pid.style.backgroundColor = "#e6e7e8";
		}
		for (const pid of pidsToColor) {
			pid.style.backgroundColor = "#69ce2b";
		}
	}
	const radioWrapper = (key)=>{
		return <div className={'radioWrapper'} onChange={(e)=>checkDeviceEnabled(key,e.target.value)}>
			<RadioGroup>
				<Radio value={1}>Yes</Radio>
				<Radio value={0}>No</Radio>
			</RadioGroup>
		</div>
	}

	const checkDeviceEnabled = (key,value)=>{
		const diff = parseInt(value);
		setDevicesEnabled({
			...devicesEnabled,
			[key]:diff
		});
	}
	const disableBtn = ()=>{
		const values = Object.values(devicesEnabled);
		return sum(values) !=DEVIDE_COUNT;

	}
	const infoDiv = ()=>{
		const values = Object.values(devicesEnabled);
		let hasNo = values.some(k=>k==0);
		return hasNo ? <div className={'infoDiv'}>
			It seems like your device is not ready yet. Please click
			<a href={'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop'} target="_blank"> here </a>
			for setup instructions. If you need more help, please contact us <a href={'https://ihealthlabs.com/pages/contact-us'} target="_blank">here</a>.
		</div>: <div></div>
	}
	return <div className="pids-wrapper">
			<div style={{display:'flex',marginTop:20 }}>
				<div>
					<div className={'title'}>Camera:</div>
					<div className={'subtitle'}>Can you see yourself in the video above?</div>
				</div>
				{ radioWrapper('camera') }
			</div>
			<div style={{display:'flex',marginTop:20 }}>
				<div>
					<div className={'title'}>Microphone:</div>
					<div className={'subtitle'}>Say “Verified COVID Test”. Do you see the volumn bar goes up?</div>
				</div>
				{ radioWrapper('microphone') }
			</div>
		<div style={{ marginTop:10 }}>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
			<div className="pid"></div>
		</div>
		<div style={{display:'flex',marginTop:20 }}>
			<div>
				<div className={'title'}>Audio Output:</div>
				<div className={'subtitle'}>Please click the “Play” button below. Can you hear the sound?</div>
			</div>
			{ radioWrapper('audio') }
		</div>
		<div>
			<button type="button" value={audioPlaying ? 'Playing': `PLAY`} className={'playBtn'} onClick={playAudio} disabled={audioPlaying}>Play</button>
		</div>
		{ infoDiv()}
		<div style={{textAlign:'center',marginTop:35}}>
			<Button className={'ihealth'} disabled={  disableBtn() } onClick={()=>setShowConfirmVideoAndAudio(false)}>
				Continue
			</Button>
		</div>
	</div>;
}
export default TestVideoAndAudioComponent;