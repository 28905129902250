import React from "react";
import { BASIC_INFO_FORM, USER_FORM_HEADER, QUESTION_FORM, CONSENT_CHECK_FORM, CONSENT_BODY, TESTER_FORM_EDIT_EXCLUDED_FIELDS } from '../constant/userForm';
import {Form, Col, Row, Button, message} from "antd";
import { get, merge, reduce, set } from 'lodash'
import '../style/index.scss';
import TechnicalRequirementsComponent from '../../Accessories/component/TechnicalRequirementsComponent';
import { questions } from "../../I18N/en/questions";
import { startVisitMutation } from '../../API/query/redeem';
import {verityEmailCodeMutation} from "../../API/query/password";
import {isMobile} from "../../lib/helpers";
import moment from "moment";

const { Item } = Form;
let BasicInformationComponent = class extends React.Component {
	constructor(props) {
		let testerForm = get(props, 'testerForm');
		let testFormFlatted = merge(testerForm, testerForm.questions || {});
		let questionKeys = Object.keys(questions);
		let questionValues = reduce(questionKeys, (acc, key) => {
			set(acc, key, testFormFlatted[key] || null);
			return acc;
		}, {});
		super(props);
		this.state = {
			hasTestKit: null,
			hasId: null,
			handSanitized: null,
			firstTest: null,
			fileList: [],
			verifyCode:null,
			...questionValues,
			submitForm: false,
			submitting: false,
		}
	}
	setStateByKey = (key, value) => {
		this.setState({
			[key]: value
		})
	}

	componentDidMount() {
		this.props.form.validateFields();
	}

	sendCode = async ()=>{
		const { testerForm } = this.props;
		const { email='' } = testerForm;
		let res = await verityEmailCodeMutation({Email:email,Type:1});
		if(res.data.code ===40004){
			message.info(' This email is not registered');
		}else {
			message.info(`A password reset link with instruction about how to reset you password was sent to: ${email}`)
		}
	}

	renderForm = (formConfig) => {
		const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;
		const { testerForm, isEdit } = this.props;
		const { submitForm } = this.state;
		let formItemList = [];
		let testFormFlatted = merge(testerForm, testerForm.questions || {});
		for (let [index, array] of Object.entries(formConfig(this))) {
			let itemsInRow = [];
			if (Array.isArray(array)) {
				for (let [colIndex, item] of Object.entries(array)) {
					let { rules, render, col, key, label, offset, extra, dataTransfer, valuePropName, className, extraItemTopContent } = item;
					if (isEdit && TESTER_FORM_EDIT_EXCLUDED_FIELDS.includes(key)) continue;
					let data = get(testFormFlatted, key, '');
					let initialValue = dataTransfer ? dataTransfer(data) : data;
					const error = (submitForm || !!isFieldTouched(key)) && getFieldError(key);
					const decorator = !valuePropName ? { initialValue, rules } : { initialValue, rules, valuePropName }
					itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
						<Item className={className} label={label} key={key} validateStatus={error ? 'error' : ''} help={error || ''}>
							<div>{extraItemTopContent && extraItemTopContent()}</div>
							{getFieldDecorator(key, decorator)(render())}
						</Item>
						<div>{extra && extra()}</div>
					</Col>)
				}
			}
			formItemList.push(<Row key={index}>{itemsInRow}</Row>)
		}
		return formItemList;
	}
	renderTesterForm = () => {
		return <div>
			<label>Who will be taking the test</label>
			{this.renderForm(BASIC_INFO_FORM)}
		</div>
	}

	renderQuestionForm = () => {
		return this.renderForm(QUESTION_FORM);
	}

	renderConsentCheckForm = () => {
		return <div className={'checkboxContainer'}>
			{this.renderForm(CONSENT_CHECK_FORM)}
		</div>
	}

	renderTitle = () => {
		return <div className={'title'}>The verified test will take around 25 mins</div>
	}

	renderHeader = (header) => {
		return <div className={'header'}>{USER_FORM_HEADER[header]}</div>
	}

	renderSubtitle = () => {
		return <div className="subtitle">
			<p className={'tips'} style={{ fontWeight: '900' }}>Please be mindful of the technical requirements:</p>
			<TechnicalRequirementsComponent />
			<p className={'tips'} >Please fill out the form below.</p>
		</div>
	}
	readURL = file => {
		return new Promise((res, rej) => {
			const reader = new FileReader();
			reader.onload = e => res(e.target.result);
			reader.onerror = e => rej(e);
			reader.readAsDataURL(file);
		});
	};

	renderConsentForm = () => {
		return <div className={'consentDiv'}>
			<div className={'consentContent'}>
				<div className={'title'}>Consent form</div>
				<div className={'body'}>{CONSENT_BODY}</div>
			</div>
		</div>
	}

	cameraNotice = () => {
		return <div className={'cameraNotice'}>
			<img src="/assess/camera_black.svg" />
			The whole process will be monitored in order to keep the authenticity of the test process.
		</div>
	}




	joinSession = () => {
		const { form, submitTesterForm, getSessionStatus, setStatus } = this.props;
		this.setState({submitForm:true}, ()=>{
			form.validateFieldsAndScroll(async (error, res) => {
				if (!error) {
					let variables = {
						...res,
					};
					try {
						this.setState({submitting:true});
						let formRes = await submitTesterForm(variables);
						if (get(formRes, 'data.message') == 'success') {
							let startVideoRes = await startVisitMutation();
							if (get(startVideoRes, 'data.message') == 'success') {
								let statusRes = await getSessionStatus();
								setStatus(get(statusRes, 'data.data.Status'));
							}
						}
					} catch (e) {
					} finally {
						this.setState({submitting:false});
					}
				}
			});
		});
	}

	render() {
		const { renderTesterForm, renderTitle, renderSubtitle, renderHeader, renderQuestionForm, renderConsentForm,
			renderConsentCheckForm, cameraNotice, joinSession } = this;
		const { isEdit } = this.props;
		const { submitting } = this.state;
		const mobile = isMobile();
		return <Row>
			<Form layout={'horizontal'} className={`userInfoForm ${isEdit ? 'isEdit' : ''}`}>
				<Col span={22} offset={1}>
					{renderTitle()}
					{renderSubtitle()}
					{renderHeader('PART1')}
					{renderTesterForm()}
					{renderHeader('PART2')}
					{renderQuestionForm()}
					{!isEdit && <React.Fragment>
						{renderConsentForm()}
						{renderConsentCheckForm()}
					</React.Fragment>
					}
				</Col>
				<Col span={mobile? 22 : 12} offset={mobile? 1 : 6} style={{ justifyContent: 'space-evenly', display: 'flex' }}>
					{cameraNotice()}
				</Col>
				<Col span={mobile? 22 : 12} offset={mobile? 1 : 6} style={{ justifyContent: 'space-evenly', display: 'flex', marginTop: '30px', marginBottom: '30px' }}>
					<Button className={'ihealth button'} disabled={submitting} onClick={() => joinSession()}>
					{submitting ? 'Joining...' : 'Join test session'}
					</Button>
				</Col>
			</Form>
		</Row>
	}
}

BasicInformationComponent = Form.create()(BasicInformationComponent);

export default BasicInformationComponent;
