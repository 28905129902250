import {getRequest, postRequest, putRequest} from '../helper/index';
import {getLoginUser} from "../../auth/helper";
import axios from "axios";
import { host} from "../config";

const LOG_IN_URL ='/api/proctoring/operators/sign-in';
const NEXT_TESTER_URL='/api/proctoring/operators/next-tester';

const ON_GOING_URL = '/api/proctoring/operators/ongoing-session';
const TEST_FORM_URL = (code)=>`/api/proctoring/operators/tester-form?code=${code}`;
const CONFIRM_AND_JOIN_URL = (code)=>`/api/proctoring/operators/confirm-tester?code=${code}`;
const END_SESSION_URL = (code)=>`/api/proctoring/operators/complete-session?code=${code}`;
const SEND_RESULT_URL = (code)=>`/api/proctoring/operators/submit-result?code=${code}`;
const CHANGE_PROCTOR_URL = (operatorId)=>`/api/proctoring/testers/change-operator?operator=${operatorId}`;
const CHANGE_RESPONSE_URL = (code)=>`/api/proctoring/operators/response-change?code=${code}`;
const HANG_TESTER_URL = (code)=>`/api/proctoring/operators/hangup-tester?code=${code}`;
const PROCTOR_CHANGE_FORM_URL = (code)=>`/api/proctoring/operators/tester-form?code=${code}`;
const PROCTOR_STATUS_URL = '/api/proctoring/operators/status';
const PROCTOR_STATS_URL = '/api/proctoring/operators/operator-stats';
const TESTER_STATS_URL = '/api/proctoring/operators/tester-stats';
const APPOINTMENT_STATS_URL = '/api/proctoring/operators/appointment-stats';
const TESTER_QUEUE_STATS_URL = `/api/proctoring/operators/tester-queue-stats`;
const TESTER_QUEUE_DETAILS = `/api/proctoring/operators/tester-queue`;
const HEADER_WITH_AUTH =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})
const LOG_IN_HEADERS = {
	Accept: '*/*',
	'Content-Type': 'application/x-www-form-urlencoded'
}

const HEADER_WITH_COOKIE = ()=>{
	return {
		...HEADER_WITH_AUTH(),
		// Cookie:document.cookie
	}
}
export const proctorLoginMutation =(data)=> postRequest(LOG_IN_URL,{ headers:LOG_IN_HEADERS, data });
export const getNextTesterQuery = (data)=>getRequest(NEXT_TESTER_URL,{ headers: HEADER_WITH_AUTH() });
export const getOnGoingTesterQuery = (data)=>getRequest(ON_GOING_URL,{ headers: HEADER_WITH_AUTH() });
export const testerFormInfo = (code)=>getRequest(TEST_FORM_URL(code),{ headers:HEADER_WITH_COOKIE()})
export const confirmAndJoinVideo = (code)=>postRequest(CONFIRM_AND_JOIN_URL(code),{ headers:HEADER_WITH_AUTH() });
export const endSessionMutation = (code)=>postRequest(END_SESSION_URL(code),{ headers:HEADER_WITH_AUTH() });
export const sendResultMutation = (code,data)=>postRequest(SEND_RESULT_URL(code),{ headers: HEADER_WITH_AUTH(),data })
export const changeProctorMutation = (operatorId)=>postRequest(CHANGE_PROCTOR_URL(operatorId),{ headers:HEADER_WITH_AUTH() });
export const changeResponseMutation = (code,data)=>postRequest(CHANGE_RESPONSE_URL(code),{ headers: HEADER_WITH_AUTH(),data });
export const hangTesterMutation = (code,data)=>postRequest(HANG_TESTER_URL(code),{ headers: HEADER_WITH_AUTH(),data });
export const proctorChangeFormMutation=(code,data)=>postRequest(PROCTOR_CHANGE_FORM_URL(code),{ headers:HEADER_WITH_AUTH(),data});
export const updateProctorStatusMutation=(data)=>putRequest(PROCTOR_STATUS_URL,{ headers:HEADER_WITH_AUTH(),data});
export const getProctorStatsQuery=()=>getRequest(PROCTOR_STATS_URL,{ headers:HEADER_WITH_AUTH()});
export const getTesterStatsQuery=()=>getRequest(TESTER_STATS_URL,{ headers:HEADER_WITH_AUTH()});
export const getAppointmentStatsQuery=(data)=>postRequest(APPOINTMENT_STATS_URL,{ headers:HEADER_WITH_AUTH(),data});

export const getTestersQueueStats = ()=>getRequest(TESTER_QUEUE_STATS_URL,{ headers: HEADER_WITH_AUTH() });
export const getTestersQueueDetails = ()=>getRequest(TESTER_QUEUE_DETAILS,{ headers:HEADER_WITH_AUTH()});