import React from "react";
import {Button, Col, Icon, Row, Form, message} from "antd";
import {get, merge, omit, pick} from 'lodash';
import {PROCTORING_USER_FORM} from "../../BasicInfo/constant/userForm";
import {proctorChangeFormMutation} from "../../API/query/proctor";
import StringFormat from "../../utils/StringFormat";
function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}
let UserInfoCardComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {
			editMode:false
		}
	}

	additionalInfo = () => {
		const { TransactionId, TimezoneLabel, Code, phone,TransactionPhone} = this.props;

		return <div className={'additionalInfo'}>
			<div className={'info'}>
				<span className={'key'}>Ticket#</span>
				<span className={'value'}>{Code}</span>
			</div>
			<div className={'info'}>
				<span className={'key'}>Time zone</span>
				<span className={'value'}>{TimezoneLabel}</span>
			</div>
			<div className={'info'}>
				<span className={'key'}>Shopify order #</span>
				<span className={'value'}>{TransactionId}</span>
			</div>
			<div className={'info'}>
				<span className={'key'}>Shopify phone #</span>
				<span className={'value'}>{TransactionPhone}</span>
			</div>
		</div>
	}
	saveForm = () => {
		const { form,getSessionStatus, questions,code,setEmail } = this.props;
			form.validateFields(async (error, res) => {
			if (!error) {
				let birthday = StringFormat.parseDOB(res.birthday)
				let variables = {
					...res,
					questions,
					birthday,
				};
				try {
					await proctorChangeFormMutation(code,variables);
					setEmail(res.email);
					this.setState({
						editMode:false
					})
					message.success('Changes Saved!');
				} catch (e) {
					console.log(e);
				}
			}
		});
	}
	renderForm = ()=>{
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
		const { editMode } = this.state;
		let formItemList = [];
		for(let [index,array] of Object.entries(PROCTORING_USER_FORM(this))) {
			let itemsInRow = [];
			if(Array.isArray(array)){
				for(let [colIndex,item] of Object.entries(array)) {
					let { rules,render,col,key,label,offset,dataTransfer } = item;
					const error = getFieldError(key);
					let data = get(this.props, key, '');

					let initialValue = dataTransfer ? dataTransfer(data) : data;

					itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
						<Form.Item validateStatus={error ? 'error':''} help={error || '' }label={label}>{ getFieldDecorator(key,{initialValue, rules } )
						(render({disabled:!editMode})) }
						</Form.Item>
					</Col>)
				}
			}
			formItemList.push(<Row key={index}>{itemsInRow}</Row>)
		}
		const buttonHandler = ()=>{
			editMode ? this.saveForm() : this.setState({editMode:true})
		}
		return <React.Fragment>
			<div style={{textAlign:'end'}}>
				<button onClick={buttonHandler} style={{ marginBottom:10,cursor:'pointer',border:'unset',textDecoration:'underline',backgroundColor:'inherit',color:editMode ? '#3177C9':'inherit'}}>
					{editMode ? 'Save':'Edit'}
				</button>
			</div>
			<Form layout={'inline'}>
			{formItemList}
		</Form></React.Fragment>;
	}
	renderBasicInfo = ()=>{
		const {firstName, lastName, birthday, email,  phone,questions} = this.props;
		return <Row>
			<Col span={24}>
				<div className={'info'}>
					<span className={'key'}>Name</span>
					<span className={'value'}>{firstName + ' ' + lastName}</span>
				</div>
				<div className={'info'}>
					<span className={'key'}>DOB</span>
					<span className={'value'}>{birthday}</span>
				</div>
				<div className={'info'}>
					<span className={'key'}>Phone</span>
					<span className={'value'}>{phone || '---'}</span>
				</div>
				<div className={'info'}>
					<span className={'key'}>Email</span>
					<span className={'value'}>{email}</span>
				</div>
			</Col>
		</Row>
	}
	render() {
		const {setState, firstName, lastName, birthday, questions, Email, TransactionId, TimezoneLabel, Code, phone,editable = false} = this.props;
		const familiarWithTestRes = get(questions, 'FAMILIAR_WITH_TEST') === 'YES' ? '' : 'NOT';
		const { additionalInfo } = this;
		return <div className={'upcomingWrapper'}>
			<Row className={'upcomingCard'}>
				<Col span={24}>{ editable ? this.renderForm() :this.renderBasicInfo()}</Col>
				<Col span={24}>{additionalInfo()}</Col>
				<div className={'familiarMsg'}>This tester is <strong>{familiarWithTestRes}</strong> familiar with using iHealth test kit</div>
			</Row>

		</div>
	}
}
UserInfoCardComponent = Form.create()(UserInfoCardComponent);
export default UserInfoCardComponent