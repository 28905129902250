import React from "react";
import { Table ,Form,Input,Popconfirm,InputNumber } from 'antd';
import 'antd/lib/table/style/index.css';
import '../style/index.scss';
import { getUserInfo } from "../../auth/helper";


const EditableContext = React.createContext();

class EditableCell extends React.Component {
	getInput = (index) => {
		if (this.props.inputType === 'number') {
			return <InputNumber />;
		}
		return <Input />;
	};

	renderCell = ({ getFieldDecorator }) => {
		const {
			editing,
			dataIndex,
			title,
			inputType,
			record,
			index,
			children,
			...restProps
		} = this.props;
		return (
			<td {...restProps}>
				{editing ? (
					<Form.Item style={{ margin: 0 }}>
						{getFieldDecorator(dataIndex, {
							rules: [
								{
									required: true,
									message: `Please Input ${title}!`,
								},
							],
							initialValue: record[dataIndex],
						})(this.getInput(index))}
					</Form.Item>
				) : (
					children
				)}
			</td>
		);
	};

	render() {
		return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
	}
}

let PeopleListComponent = class extends React.Component{
	constructor(props) {
		super(props);
		this.state ={
			dataSource: props.peopleList.map((v,key)=>({...v,key:key.toString(),editable: true})),
			editingKey: ''
		}
	}
	isEditing = record => {
		return record.key === this.state.editingKey;
	}

	cancel = () => {
		this.setState({ editingKey: '' });
	};

	save(form, key) {
		form.validateFields((error, row) => {
			if (error) {
				return;
			}
			const newData = [...this.state.dataSource];
			const index = newData.findIndex(item => key === item.key);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				this.setState({ dataSource: newData, editingKey: '' });
			} else {
				newData.push(row);
				this.setState({ dataSource: newData, editingKey: '' });
			}
		});
	}

	edit(key) {
		this.setState({ editingKey: key });
	}
	handleDelete = key => {
		const dataSource = [...this.state.dataSource];
		this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
	};
	tableConfigs = ()=>{
		const { dataSource } = this.state;
		const { peopleList } = this.props;
		const ObjectKeys = Object.keys(peopleList[0]||{});
		const keys = [
			"First_name",
			"Last_name",
			"Email",
			"Phone_number",
			"Address",
			"Address 2",
			"City",
			"State",
			"Zipcode"
		]
		let dataKeyToFileKey = {
			'firstName':'First_name',
			'lastName':'Last_name',
			'email':'Email',
			'phoneNumber':'Phone_number',
			'address':'Address',
			'address 2':'Address2',
			'city':'City',
			'state':'State',
			'zipcode':'Zipcode'
		}

		let columns = ObjectKeys.filter(k=>keys.includes(dataKeyToFileKey[k] ? dataKeyToFileKey[k] : k)).map(key=>({
			key,
			editable: false,
			title:dataKeyToFileKey[key] ? dataKeyToFileKey[key] : key,
			dataIndex: key

		}))
		// columns.push( {
		// 	title: 'operation',
		// 	dataIndex: 'operation',
		// 	render: (text, record) =>
		// 		dataSource.length >= 1 ? (
		// 			<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
		// 				<a>Delete</a>
		// 			</Popconfirm>
		// 		) : null,
		// });
		// columns.push( {
		// 	title: 'operation',
		// 	dataIndex: 'operation',
		// 	render: (text, record) => {
		// 		const { editingKey } = this.state;
		// 		const editable = this.isEditing(record);
		// 		return editable ? (
		// 			<span>
        //       <EditableContext.Consumer>
        //         {form => (
	    //             <a
		//                 onClick={() => this.save(form, record.key)}
		//                 style={{ marginRight: 8 }}
	    //             >
		//                 Save
	    //             </a>
        //         )}
        //       </EditableContext.Consumer>
        //       <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
        //         <a>Cancel</a>
        //       </Popconfirm>
        //     </span>
		// 		) : (
		// 			<React.Fragment>
		// 			<a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
		// 				Edit
		// 			</a>
		// 			<a  onClick={() => this.handleDelete(record.key)} style={{ marginLeft: 10 }}>
		// 				Delete
		// 			</a>
		// 			</React.Fragment>
		// 		);
		// 	},
		// });
		return columns;
	}

	renderInvitationNotice = ()=>{
		const userInfo = getUserInfo();
		const { CompanyName } = userInfo;
		if(this.props.activeKey=='1') {
			return <div></div>;
		} else {
			return <div className={'invitationWrapper'}>
				<div className={'title'}>{ `Recipients will be invited to join your organization COVID Test Group: ${ CompanyName } COVID Test Group `}</div>
				<div className={'context'}>Once the order is paid, an invitation email with instructions to join the COVID Test Group will be sent to the recipients. You can view the recipient’s test results when the recipients accept the invitation and join the group. </div>
			</div>
		}
	}
	renderPeopleTable = ()=>{
		const { peopleList } = this.props;
		const { dataSource } = this.state;
		const components = {
			body: {
				cell: EditableCell,
			},
		};
		const columns = this.tableConfigs().map(col => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: record => ({
					record,
					inputType: col.dataIndex === 'age' ? 'number' : 'text',
					dataIndex: col.dataIndex,
					title: col.title,
					editing: this.isEditing(record),
				}),
			};
		});
		return <EditableContext.Provider value={this.props.form}>
				<div style={{ marginLeft: 50,textAlign:'start',color:'#2D3238',fontWeight:'bold' }}>{ peopleList.length ? <div >Recipients: {peopleList.length}</div>:'' }</div>
				<Table dataSource={peopleList} components={components} bordered columns={columns} className={'peopleTable'} pagination={false}/>
				{ this.renderInvitationNotice() }
			   </EditableContext.Provider>
	}
	render() {
		return this.renderPeopleTable() ;
	}
}
PeopleListComponent = Form.create()(PeopleListComponent);

export default PeopleListComponent;