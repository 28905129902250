import React from "react";
import {Button, Col, Form, Row,message} from 'antd';
import {confirmChangePwdForm} from "../../SignUp/constant/signUpForm";
import { findPasswordMutation } from '../../API/query/password';
function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}
let ConfirmChangePwdComponent = class extends React.Component{
	constructor() {
		super();
		this.state={
			errors: null,
		}
	}

	componentDidMount() {
		this.props.form.validateFields();
	}

	onClick = (e)=>{
		e.preventDefault();
		const { setShowRestPwd } = this.props;

		this.props.form.validateFields(async (error,values)=>{
			let res = await findPasswordMutation({
				Password:values.password,
				Email:this.props.email,
				Code: values.verifyCode
			})
			if(res.data.code===40004 || res.data.code===40002){
				message.info('Invalid code');
			}
			if(res.data.code==200){
				message.info('Password reset');
				setShowRestPwd(false);
			}
		})
	}
	renderForm = ()=>{
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
		let formItemList = [];
		const disabled =  hasErrors(getFieldsError());
		for(let [index,array] of Object.entries(confirmChangePwdForm(this))) {
			let itemsInRow = [];
			if(Array.isArray(array)){
				for(let [colIndex,item] of Object.entries(array)) {
					let { rules,render,col,key,label,offset } = item;
					const error = !!isFieldTouched(key) && getFieldError(key);
					itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
						<Form.Item validateStatus={error ? 'error':''} help={error || '' }label={label}>{ getFieldDecorator(key,{ rules } )(render()) }</Form.Item>
					</Col>)
				}
			}
			formItemList.push(<Row key={index}>{itemsInRow}</Row>)
		}

		return <Form layout={'horizontal'} onSubmit={ this.onClick }>
				{formItemList}
				<Form.Item>
					<Button className={'ihealth'} disabled={disabled} htmlType="submit">Reset password</Button>
				</Form.Item>
		</Form>;
	}
	render() {
		return this.renderForm();
	}
}

ConfirmChangePwdComponent = Form.create()(ConfirmChangePwdComponent);
export default ConfirmChangePwdComponent