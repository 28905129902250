import React from "react";
import '../../Settings/style/index.scss';
const SavedCreditCardComponent = (props)=>{
	const { last4,brand,expMonth,expYear } = props.payment[0] || {};
	const monthTwoDigit = String(expMonth).padStart(2, '0')
	return <div className={`credit-card ${brand} selectable`}>
		<div className="credit-card-last4">
			{ last4 }
		</div>
		<div className="card__space-25">
			<span className="card__label">Expires</span>
			<p className="card__info">{`${monthTwoDigit}/${expYear}`}</p>
		</div>
	</div>
}

export default SavedCreditCardComponent;