import React from 'react';
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    message,
    Spin
} from 'antd';
import axios from 'axios';
import SubmitSuccess from './SubmitSuccess';
import { frequencies, employees, industries } from '../constants/constants';

const { TextArea } = Input;
const { Option } = Select;

class FormComponent extends React.Component {
    state = {
      confirmDirty: false,
      autoCompleteResult: [],
      checked: true,
      loading: false,
      visible: false,
    };

    handleConfirm = (e) => {
        this.setState({ visible: false});
    }

    submitForm = async (data) => {
         return await axios.post('https://form-services.ihealth-eng.com/forms/covid_test_solution', {...data});
    }


  
    handleSubmit = async (e) => {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            this.setState({ loading: true });
            const res = await this.submitForm(values);
            if(res.data.result===1 && res.data.message==='success'){
                this.props.form.resetFields();
                this.setState({loading: false, visible: true});
            } else {
                message.error(res.data.message, 5);
                this.setState({loading: false});
            }
          }
            catch (error) {
                console.log(error);
                this.setState({loading: false});
                message.error('An error occurred. Please try again later.');
            }
        } else {
            message.error('Please fill in all the required fields.', 5);
        }
      });
    };
  
    handleConfirmBlur = e => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  
    handleWebsiteChange = value => {
      let autoCompleteResult;
      if (!value) {
        autoCompleteResult = [];
      } else {
        autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
      }
      this.setState({ autoCompleteResult });
    };
  
    render() {
      const { getFieldDecorator } = this.props.form;
      const industryOptions = industries.map(val => <Option key={val}>{val}</Option>);
      const frequencyOptions = frequencies.map(val => <Option key={val}>{val}</Option>);
      const employeeOptions = employees.map(val => <Option key={val}>{val}</Option>);
      return (<div id="form">
        <Form layout={'vertical'} onSubmit={this.handleSubmit}>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="First name" >
                        {getFieldDecorator('firstName', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your first name!',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Last name">
                        {getFieldDecorator('lastName', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your last name!',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="Email">
            {getFieldDecorator('email', {
                rules: [
                {
                    type: 'email',
                    message: 'The input is not valid email!',
                },
                {
                    required: true,
                    message: 'Please input your email!',
                },
                ],
            })(<Input />)}
            </Form.Item>
          <Form.Item label="Phone number">
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: 'Please input your phone number!' }],
            })(<Input style={{ width: '100%' }} />)}
          </Form.Item>
          <Form.Item label="Job title" >
            {getFieldDecorator('title', {
                rules: [
                    {
                        required: true,
                        message: 'Please input your title!',
                    },
                ],
            })(<Input />)}
            </Form.Item>
            <Form.Item label="Organization name" >
            {getFieldDecorator('orgName', {
                rules: [
                    {
                        required: true,
                        message: 'Please input your organization name!',
                    },
                ],
            })(<Input />)}
            </Form.Item>
            <Form.Item label="Number of employees" >
            {getFieldDecorator('numberOfEmployees', {
                rules: [
                    {
                        required: true,
                        message: 'Please input the number of employees!',
                    },
                ],
            })(<Select style={{width: '100%'}}>{
                employeeOptions
            }</Select>)}
            </Form.Item>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="City" >
                        {getFieldDecorator('city', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your city!',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="State">
                        {getFieldDecorator('state', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your state!',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="Industry">
                {
                    getFieldDecorator('industry', {initialValue: '', rules:[
                    {
                            required: true,
                            message: 'Please select your industry!',
                    }
                ]})(
                        <Select>
                            {industryOptions}
                        </Select>
                )}
            </Form.Item>
            <Form.Item label="Desired service start time" >
                {getFieldDecorator('serviceTime', {
                    rules: [
                        {
                            required: true,
                            message: 'Please input your time of service!',
                        },
                    ],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="Estimated COVID test kit reorder frequency" >
                {getFieldDecorator('frequency', {initialValue: '',
                    rules: [
                        {
                            required: true,
                            message: 'Please input a requency!',
                        },
                    ],
                })(<Select>
                    {frequencyOptions}
                </Select>)}
            </Form.Item>
            <Form.Item label="Message" style={{marginBottom: '40px'}}>
                {getFieldDecorator('message', {
                    rules: [
                        {
                            required: true,
                            message: 'Please leave a message',
                        },
                    ],
                })(<TextArea placeholder={'Any questions? or other needs you hope to get from our service? Please let us know'} style={{minHeight: '110px', fontSize: '18px'}}>
                </TextArea>)}
            </Form.Item>

          <Form.Item style={{textAlign: 'left', marginBottom: '40px', fontSize: '16px'}}>
            {getFieldDecorator('receiveUpdates', {
              valuePropName: 'checked',
              initialValue: true
            })(
              <Checkbox style={{fontSize: '16px'}}>
                I would like to receive updates and products information via Email from iHealth Labs.
              </Checkbox>,
            )}
          </Form.Item>
          <Form.Item >
            {
                this.state.loading? <Spin tip="Loading...">
              </Spin>
              : 
              <Button disabled={this.state.loading} className="submit" type="primary" htmlType="submit">
                Submit
              </Button>
            }
            
          </Form.Item>
        </Form>
        <SubmitSuccess visible={this.state.visible} close={this.handleConfirm} footer={null} />
        </div>
      );
    }
}

const WrappedRegistrationFor = Form.create({ name: 'signup' })(FormComponent);

export default WrappedRegistrationFor;
