import React, {useState} from 'react';
import UploadFileComponent from "./UploadFileComponent";
import { getContactListByGroupId } from '../../API/query/Order';
import { associateContactsWithGroup } from '../../API/query/uploadFile';
import {Button, Col, Icon, message, Row} from "antd";
import { get } from 'lodash'
import PeopleListComponent from "./PeopleListComponent";
import Step2Content from './Step2';

const AddPeopleComponent = (props)=>{
	const { subscription: { contactGroupId },setContactList,setShowAddPeople } = props;
	const [file,setFile] = useState();
	const [peopleList,setPeopleList] = useState([]);
	let [ fileError,setFileError ] = useState([]);
	const handleAddPeople = async ()=>{

		try {
			const res = await associateContactsWithGroup(file, contactGroupId);
			message.info('Contacts Added');
			const response = await getContactListByGroupId(contactGroupId, 1);
			const contacts = get(response,'data.data');
			setContactList(contacts)
			setShowAddPeople(false)
		}catch (e) {
			console.log(e);
		}
	}

	const renderTable = ()=>{
		return !!peopleList.length && <PeopleListComponent peopleList={peopleList}/>
	}
	const renderFileErrors = ()=>{
		if(!fileError.length) return <div></div>
		fileError = fileError.sort((a,b)=>a['lineNumber']-b['lineNumber']);
		return <div>
			<div className="fileError" style={{ color:'red',width:750,paddingLeft:50,marginBottom:20,display:'flex'}}>
				<div><Icon type={'info-circle'} style={{marginRight: 10}}/></div>
				In your uploaded file, there are several places that the format is invalid.
				Please refer to the list below,  modify the information and upload again.
			</div>
			{fileError.map(e=>{
				const { lineNumber, warnings } = e;
				return <Row style={{ paddingLeft: 50,marginBottom:10,color:'red' }}>
					<Col span={4}>
						line number:{lineNumber+1}
					</Col>
					<Col span={10}>
						{
							Object.keys(warnings).map(w=>{
								return <div>{`${w}: ${warnings[w].error}`}</div>
							})
						}
					</Col>
				</Row>
			})}</div>

	}
	const stepTwo = ()=>{
		const disableButton = !!(!peopleList.length);
		return <div className={'step'}>
				<Step2Content/>
				<div style={{display:'flex'}}>
					<UploadFileComponent initialGroupId={contactGroupId} setPeopleList={setPeopleList}  setFile={setFile}
					                     file={file}
					                     setFileError={setFileError}/>
					<Button className={'downloadBtn'}><a href="/files/template.xlsx" download="template.xlsx">Download file template</a></Button>
				</div>
				<div>{ renderTable() }</div>
				{ renderFileErrors() }
				<div style={{textAlign: 'center'}}>
					<Button className={'ihealth'} onClick={()=>handleAddPeople()} disabled={disableButton}>Add</Button>
				</div>
			</div>
	}

	return <div>
			{ stepTwo() }
	 </div>
}
export default AddPeopleComponent;