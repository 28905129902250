import React from "react";
import ProctorLoginComponent from "../component/ProctorLoginComponent";
import '../../Login/style/index.scss';
import { ForgotPasswordContainer } from "../../Password/container/ForgotPasswordContainer";
import { Button } from "antd";
const ProctorLoginContainer = class extends React.Component {
	constructor() {
		super();
		this.state = {
			showRestPwd: false
		}
	}
	renderSubheader = ()=><div className={'formSubHeader'}>Proctor Login</div>
	renderFooter =()=><div className={'formFooter'} onClick={()=>this.setShowRestPwd(true)}
	                       style={{textDecoration:'underline',cursor:'pointer',width:'fit-content',height:'fit-content',marginTop:20}}>
		Forget Password
	</div>

	setShowRestPwd = (showRestPwd)=>{
		this.setState({
			showRestPwd
		})
	}
	render() {
		const { renderSubheader,renderFooter,setShowRestPwd, renderHeader } = this;
		const { showRestPwd } = this.state;
		return <div className='loginFormWrapper'>
			<div id={'loginContainer'}>
				{ renderSubheader() }
				<ProctorLoginComponent/>
				<Button className={'forgotPassword'} onClick={()=>setShowRestPwd(true)}>Forgot password?</Button>
			</div>
			{ showRestPwd && <ForgotPasswordContainer setShowRestPwd={setShowRestPwd} showRestPwd={showRestPwd}/>}
		</div>
	}

}


export default ProctorLoginContainer