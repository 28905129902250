import React from 'react';
import '../style/index.scss';
import LinkButton from './LinkButton'

export default function HeroSection() {
    return (
        <div className="hero">
            <div className='hero-header'>
                <h1>
                    Enjoy 10% off on subscription orders.
                </h1>
            </div>
            <div className='hero-body'>
                <div className="heroContent">
                    <h1 className="heroTitle">
                        Take the hassle out of the COVID testing management at your organization
                    </h1>
                    <LinkButton to="/signup" text='Get started' />
                </div>
                <img src={'/image/persons.jpg'} alt={'persons'}/>
            </div>
        </div>
    )
}
