import React, {useState} from "react";
import '../style/index.scss';
const ConnectingPageComponent = ()=>{
	const [state,setState] = useState('JOINING');

	const contentMap = {
		CONNECTING:<div className={'waitingDiv'}>
					<span>We are connecting you with a COVID test proctor, please wait ...</span>
				   </div>,
		JOINING:<div className={'joiningDiv'}>
					<div className={'title'}>You are the next one! </div>
					<span>Thank you for being patient. Our proctor [First name, last name] will meet you just a moment! </span>
				</div>
	}
	return contentMap[state];
}

export default ConnectingPageComponent;