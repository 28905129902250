import React from 'react';
import FormComponent from './FormComponent';

const FormSection = () => {

    return <div className="formWrapper" id="signup">
        <div className="sectionWrapper">
            <h2>Any questions? Please contact us.</h2>
            <p>
                If you have any other questions, please fill out the form, and we will get back to you as soon as possible.
            </p>

            <FormComponent />

        </div>
    </div>
}

export default FormSection;
