import React, {useEffect, useState} from "react";
import {Icon, Input, Col, Row, Button, message} from 'antd';
import moment from "moment-timezone";
import 'antd/lib/input/style/index.css';
import 'antd/lib/form/style/index.css';
import { reserveVisitMutation } from '../../API/query/redeem';
import  AppointmentDetailComponent  from './AppointmentDetailComponent';
import { get } from 'lodash'
import {isMobile} from "../../lib/helpers";
const CreateAppStepTwoComponent = (props)=>{
	const [submitting, setSubmitting] = useState(false);
	const { selectedSlot={}, selectedTimezone,setStatus,firstName,setFirstName,middleName,setMiddleName,lastName,setLastName,email,setEmail,setValidEmail,validEmail,ticketNum,status } = props;
	const renderHeader = ()=><div className={'title'}>Add Test Taker</div>;
	const appointmentDetail = ()=>{
		return <AppointmentDetailComponent selectedSlot={selectedSlot} selectedTimezone={selectedTimezone}/>
	}
	useEffect(()=>{
		window.scrollTo(0, 0)
	},[status]);
	const backBtn = ()=>{
		return <a style={{ marginLeft:20 ,fontSize:16,color:'black' }} onClick={()=>setStatus('SLOT')}><Icon type="arrow-left" style={{ marginRight: 15 }} />Go back</a>
	}
	const onChangeEmailInput = (email)=>{
		setValidEmail(validateEmail(email));
		setEmail(email);
	}

	const nameInput = ()=>{
		const mobile = isMobile();
		return 	<Row className={'nameInputContainer'}>
			<Col span={mobile ? 24 : 7}><Input placeholder={'First Name'} value={firstName} onChange={(e)=>setFirstName(e.target.value)}/></Col>
			<Col span={mobile ? 24 : 8} offset={mobile ? 0 : 1}><Input placeholder={'Middle Name (optional)'} value={middleName} onChange={(e)=>setMiddleName(e.target.value)}/></Col>
			<Col span={mobile ? 24 : 7} offset={mobile ? 0 : 1}><Input placeholder={'Last Name'} value={lastName} onChange={(e)=>setLastName(e.target.value)}/></Col>
		</Row>
	}

	const formDiv = ()=>{
		return <Row style={{ maxWidth:'50em',display:'flex',flexDirection:'column',marginTop:30 }}>
			<Row style={{ display:'flex' }}><span>Who will be taking the test</span></Row>
			{nameInput()}
			<Row style={{ marginTop:30 }}>
				<Col style={{ display:'flex' }}><span>Email:</span></Col>
				<Col span={24} className={'ant-form-item-control'+(validEmail?'':' has-error')}>
					<Input onChange={(e)=>onChangeEmailInput(e.target.value)}/>
					{ !validEmail&& <div className="ant-form-explain">Email is invalid.</div> }
				</Col>
			</Row>
			<Row>
				{messageContent()}
			</Row>
		</Row>
	}
	const validateEmail = (email) => {
		if(email) {
			let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regex.test(email);
		}
		return true;
	};

	const messageContent = ()=>{
		return <div style={{marginTop:20,display:'flex',fontSize:16 }}>
				<Icon type="info-circle" theme={'filled'} style={{ margin:'5px 10px 5px 0px'}}/>
				<div><strong>Important:</strong> We will send you an appointment confirmation to this email that contains
					<strong> a link to join the appointment.</strong> Please make sure the email is correct.</div>
		</div>
	}

	const renderBtn = ()=>{
		let offset = moment.tz(selectedSlot.timeSlot,selectedTimezone.value)._offset/60;
		const handleBtn = async ()=>{
			try{
					setSubmitting(true);
					await reserveVisitMutation({startTime:selectedSlot.timeSlot ,firstName,middleName,lastName,email,code:ticketNum,
					timezone:offset,timezoneLabel:selectedTimezone.label });
					message.info('Appointment scheduled.');
					setStatus('SCHEDULED');
			}
			catch (e) {
				const errorMsg = get(e,'response.data.message');
				if(errorMsg ==='Invalid code'){
					message.error('Appointment already started, cannot be rescheduled.');
				}
			} finally {
				setSubmitting(false);
			}
		}
		const disabled = submitting || !validEmail || (!firstName|| firstName.trim().length==0) || (!lastName || (lastName.trim().length==0)) || (!email || (email.trim().length==0))
		return <div style={{ marginTop:50 }}>
			<Button className={'ihealth'} type='primary' onClick={ ()=>handleBtn() } disabled={disabled}>
				{submitting ? 'Appointing...' : 'Confirm appointment'}
			</Button>
		</div>
	}

	return <React.Fragment>
			{backBtn()}
			<div className={'appTwoDiv'}>
				{ renderHeader() }
				{ appointmentDetail() }
				{ formDiv() }
				{ renderBtn() }
			</div>
	</React.Fragment>
}
export default CreateAppStepTwoComponent;