import React, { useEffect, useState } from "react";
import BasicInformationComponent from "../component/UserInformationComponent";
import { questions } from "../../I18N/en/questions";
import { submitUserFormMutation, getTesterFormQuery } from '../../API/query/redeem';
import { pick, omit, get } from 'lodash';
import {Col, Modal, Row} from "antd";
import TestVideoAndAudioComponent from "../../TestSession/component/TestVideoAndAudioComponent";
import {isMobile} from "../../lib/helpers";
import StringFormat from "../../utils/StringFormat";
const BasicInformation = (props) => {
	const { code, isEdit, status, getSessionStatus, setStatus } = props;
	const [testerForm, setTesterForm] = useState({});
	const [loading, setLoading] = useState(false);
	const [ showConfirmVideoAndAudio, setShowConfirmVideoAndAudio ] = useState(true);

	// const [ confirmVideoAndAudio, setConfirmVideoAndAudio ] = useState();
	const getTesterForm = async () => {
		setLoading(true);
		let response = await getTesterFormQuery(code);
		if (response.data.code == 200) {
			setTesterForm(get(response, 'data.data',) || {});
			setLoading(false);
		}
	};

	useEffect(() => {
		getTesterForm();
	}, []);

	const showLocalCameraTest = ()=>{
		if(isMobile()) return <div></div>;
		return <Modal visible={showConfirmVideoAndAudio} width={800} closeIcon={null} maskClosable={false} className={'testVideoModal'} footer={null}>
			<Row style={{display:'flex',flexDirection:'column'}}>
				<Col span={24} style={{ textAlign:'center',marginBottom:20 }}>
					<h4 style={{fontSize:24,fontWeight:700 }}>Please make sure your device is ready </h4>
					<div style={{ fontSize:16}}>Please allow the browser to grant camera & microphone permissions </div>
				</Col>
				<Col span={24}>
					<div style={{ width:270,height:160,border:'solid 1px',margin:'auto'}}>
						<video id={'testVideoAndAudio'} autoPlay={true} muted={true} width={270} height={160}/>
					</div>
				</Col>
				<Col span={24}>
					<TestVideoAndAudioComponent setShowConfirmVideoAndAudio={setShowConfirmVideoAndAudio}/>
				</Col>
			</Row>
		</Modal>
	}
	const submitTesterForm = (variables) => {
		let { birthday } = variables;
		let questionsKey = Object.keys(questions);
		birthday = StringFormat.parseDOB(birthday);
		let params = {
			...omit(variables, questionsKey),
			birthday,
			questions: {
				...pick(variables, questionsKey),
			}
		}
		return submitUserFormMutation(params);
	}
	// if(!confirmVideoAndAudio) {
	// 	return <Modal visible={true}>
	//
	// 	</Modal>
	// }

	return <div> { loading ? 'loading' : <BasicInformationComponent submitTesterForm={submitTesterForm} getSessionStatus={getSessionStatus}
	                                                                setStatus={setStatus}
	                                                                testerForm={testerForm} isEdit={isEdit} status={status} /> }
		{showLocalCameraTest()}
	</div>
}

export default BasicInformation;
