import { actionTypes} from "../actionTypes/orderActionTypes";

export const setSubscription = subscription=>{
	return {
		type: actionTypes.SET_SUBSCRIPTION,
		payload: subscription
	}
}

export const setOrderDetails = orderDetails=>{
	return {
		type: actionTypes.SET_ORDER_DETAILS,
		payload: orderDetails
	}
}

export const setProductInfo = productInfo=>{
	return {
		type: actionTypes.SET_PRODUCT_INFO,
		payload: productInfo
	}
}

export const setRefresh = ()=> {
	return {
		type: actionTypes.SET_REFRESH
	}
}