import React from "react";
import { Table } from 'antd';
import '../style/index.scss';
import { upperFirst } from 'lodash'
const SingleOrderDetailComponent =  (props)=>{
	const { orderList=[], total=0, page, setPage } = props;
	const renderTable = ()=>{
		const columns = [{
			key:'fullName',
			dataIndex:'fullName',
			title: 'Full Name',
			render:(val,doc)=>{
				return doc.firstName + ' ' + doc.lastName;
			}
		},{
			key:'email',
			dataIndex: 'email',
			title: 'Email'
		},{
			key:'address',
			dataIndex: 'address',
			title:'Address'
		},{
			key:'orderNumber',
			dataIndex: 'id',
			title: 'Individual Order Number'
		},{
			key:'status',
			dataIndex: 'status',
			title:'Status',
			render:(v)=> upperFirst(v.toLowerCase())
		},{
			key:'trackingCompany',
			dataIndex: 'trackingCompany',
			title:'Carrier'
		},{
			key:'trackingNumber',
			dataIndex: 'trackingNumber',
			title: 'Tracking Number',
			render:(v,doc)=>{
				return !!v.length && <a href={doc.trackingUrl} target="_blank">{v}</a>
			}
		}]
		
		const pagination = {
			pageSize:10,
			total:total,
			current: page,
			onChange:(p)=>setPage(p)
		}
		return <Table columns={columns} dataSource={orderList} rowKey={record => record.id} pagination={pagination}/>
	}
	const tableWrapper = ()=>{
		return <div className={'orderDetailTableWrapper'}>
			<div className={'header'} style={{ display:'flex',justifyContent:'space-between'}}>
				<div className={'title'}>Delivery Details</div>
				<div className={'subtitle'}>Total:{total}</div>
			</div>
			{ renderTable() }
		</div>
	}

	return tableWrapper();
}

export default SingleOrderDetailComponent;