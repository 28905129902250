import { getRequest, postRequest } from "../helper";
import { getLoginUser} from "../../auth/helper";

const ONBOARDING_URL = '/api/enterprise/settings';

const HEADER =(auth)=>({
    "Authorization": `kit ${auth || getLoginUser()}`,
    "Content-Type": "application/x-www-form-urlencoded"

})
export const getOnboardingStatus =()=> getRequest(ONBOARDING_URL,{ headers:HEADER() });
export const setOnboardingStatus =(data)=> postRequest(ONBOARDING_URL,{ headers:HEADER(), data });
