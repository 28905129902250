import React from "react";
import { Modal } from 'antd';
import '../style/index.scss';
import CustomerSupportForm from '../component/CustomerSupportForm';
import SuccessModal from '../component/SuccessModal';

const CustomerSupportContainer = ({visible, setVisible})=>{
	const [submit, setSubmit] = React.useState(false);


	const showModal = ()=>{
		return <Modal visible={visible} onCancel={ ()=>setVisible(false)} footer={null} className={'onboardingPopUp'} width={800}>
					<CustomerSupportForm onCancel={()=> setVisible(false)} setSubmit={setSubmit.bind(this)}/>
			   </Modal>
	}

	if(submit) {
		return <SuccessModal visible={submit} close={()=>setSubmit(false)} footer={null}/>
	}
	return 	<React.Fragment>
				{ showModal() }
		    </React.Fragment>

}

export default CustomerSupportContainer;