import React from "react";
import { Button, Col, Icon, message, Modal, Popover, Row } from "antd";
import AgoraVideoCall from "../../Video/components";
import UserInfoCardComponent from "./UserInfoCardComponent";
import TestGuidanceComponent from "./TestGuidanceComponent";
import { endSessionMutation, hangTesterMutation } from '../../API/query/proctor';
import { get } from 'lodash';
import SubmitTestResultComponent from "./SubmitTestResultComponent";
import {Call} from "../../Video/components/Testcall";
const TestSessionVideoComponent = class extends React.Component {
	constructor(props) {
		super(props);
		const { appId, uid, code, token, tester, rtmToken } = props;
		this.videoProfile = "vp8";
		this.channel = code.toLowerCase();
		this.transcode = "vp9";
		this.attendeeMode = "audio-only";

		this.baseMode = "avc";
		this.appId = appId;
		this.uid = uid;
		this.token = token;
		this.status = get(tester, 'Status');
		this.state = {
			status: get(tester, 'Status'),
			popVisible: false,
			email: get(tester, 'email'),
		}
		if (!this.appId) {
			return alert("Get App ID first!");
		}
	}
	phaseOneOnGoing = () => {
		return <Row style={{ height: 'inherit' }}>
			<Col span={24} className={'bottom'}>
				<div className={'message'}>When the tester finishes collecting the sample, you can start the timer and leave.
					<ul style={{ paddingLeft: '20px', marginBottom: '0px' }}>
						<li>The tester will see a 15 min timer on their screen.</li>
						<li><strong>Remind the tester to keep the camera facing the test card at all times while waiting for the result.</strong></li>
					</ul>
					After the time is up, the interface will prompt the tester to call us, and the system will dispatch a new proctor to record the test result.
				</div>
			</Col>
		</Row>
	}
	setStatus = (status) => {
		this.setState({
			status
		})
	}
	bottomContent = () => {
		const { setStatus } = this;
		const { status, email } = this.state;
		const { code, tester, setState } = this.props;
		const statusMap = {
			PHASE_ONE_ONGOING: this.phaseOneOnGoing(),
			PHASE_TWO_ONGOING: <SubmitTestResultComponent code={code} setStatus={setStatus} tester={tester} status={status} setState={setState} email={email}/>,
			RESULT_SUBMITTED: <SubmitTestResultComponent code={code} setStatus={setStatus} tester={tester} status={status} setState={setState} email={email}/>,

		}
		return statusMap[status];
	}

	handleLeave = async () => {
		const { setState } = this.props;
		let res = await endSessionMutation(this.channel);
		setState('WAITING');
		message.info('The session has been finished! Thank you for your hard work!');
	}

	changeTester = async () => {
		const { setState } = this.props;
		this.onPopVisibleChange(false);
		Modal.confirm({
			title: 'Are you sure to change a tester',
			okText: 'Yes Change',
			onOk: async () => {
				try{
					await hangTesterMutation(this.channel, {});
				}
				catch (e) {
					console.log(e);
				}
				finally {
					setState('WAITING');
				}
			},
			okButtonProps: {
				type: 'primary',
				className: 'ihealth'
			},
			cancelText: 'Never mind',
		})

	}

	onPopVisibleChange = (popVisible) => {
		this.setState({ popVisible });
	};

	popContent = () => {
		const content = (
			<Button onClick={() => this.changeTester()}>Dispatch me a new Tester</Button>
		);
		return <div className={'help'} >
			<Popover content={content} title={null} trigger="click" overlayClassName={'changeTester'}
					visible={this.state.popVisible} onVisibleChange={this.onPopVisibleChange}>
				<Icon type="question-circle" />
			</Popover>
		</div>
	}

	endSessionBtn = () => {
		const { setState, code } = this.props;
		const { status } = this.state;
		const endSession = async () => {
			await endSessionMutation(code);
			setState('WAITING');
			message.info('The session has been finished! Thank you for your hard work!');
		}
		return <Button className={'ihealth leave'} onClick={endSession} disabled={status !== 'RESULT_SUBMITTED'}>End the appointment</Button>
	}

	leaveBtn = () => {
		return <Button className={'ihealth leave'} onClick={this.handleLeave}>
			<Icon type="arrow-right" /> Start the timer and leave
		</Button>
	}

	finishButton = () => {
		const { status } = this.state;
		const statusMap = {
			PHASE_ONE_ONGOING: this.leaveBtn(),
			PHASE_TWO_ONGOING: this.endSessionBtn(),
			RESULT_SUBMITTED: this.endSessionBtn(),

		}
		return <div className="leaveButtonContainer">
			{statusMap[status]}
		</div>
	}

	showAgoraStreamEvent = (event) => {
		message.info(event);
	}

	render() {
		const { props } = this;
		const { tester, recording, fetchRecordStatus, wsclosed} = this.props;
		const status = get(this,'props.tester.Status');
		return <div className={'testSessionVideoWrapper'}>
			<Row style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
				<Col span={6} style={{ height: '60vh' }}>
					<TestGuidanceComponent status={status}/>
				</Col>
				<Col span={12} style={{ height: '100%', overflow: 'hidden', background: '#6B7178' }}>
					<Call appId={this.appId}
					      token={this.token}
					      channel={this.channel}
					      uid={this.uid}
				        recording={recording}
				        rtmToken={this.props.rtmToken}
								testerUid={this.props.testerUid}
					      role={'PROCTOR'}
					      fetchRecordStatus={fetchRecordStatus}
						  	showAgoraStreamEvent={this.showAgoraStreamEvent}
								wsclosed={wsclosed}
					/>
					<div id={'audioPlayer'}></div>
					<Row style={{ height: '100%', position: 'absolute', width: '100%', background: '#E5E5E5', paddingTop: '30px' }}>
						<Col span={24} className={'videoBottom'}>{this.bottomContent()}</Col>
					</Row>
				</Col>
				<Col span={6}>
					<div className="testerInfo">
						<div className={'title'}>Tester</div>
						<UserInfoCardComponent {...tester} {...props} editable={true} email={this.state.email} setEmail={(email)=>this.setState({email})}/>
					</div>
					<div className={'changeTesterWrapper'}> {this.popContent()}</div>
					{this.finishButton()}
				</Col>
			</Row>

		</div>;
	}
}

export default TestSessionVideoComponent;