import React from "react";

const AudioPlayerComponent = class extends React.Component{
	constructor() {
		super();
		this.audio = null;
	}
	async componentDidMount() {
		const { audioSrc } = this.props;
		this.audio = new Audio(audioSrc);
		this.audio.loop = true;
		await this.audio.play();
	}

	componentWillUnmount() {
		if( this.audio ) {
			this.audio.pause();
		}
	}

	render() {
		return (
			<div></div>
		);
	}

}
export default AudioPlayerComponent;