import React from "react";
import { Input, Icon, Radio, Checkbox,Button } from "antd";
import I18N from '../../I18N/'
import moment from "moment";
import {isMobile} from "../../lib/helpers";
import DateFormattedInput from '../../Accessories/component/DateFormattedInput';
// remove CTKS-421
const REMOVE_421 = true;
const formItemLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 12 },
};
const requiredRule = (required) => {
	return {
		required: typeof required === 'boolean' ? required : true,
		message: `This is a required field`,
	}
}
const requiredInput = (key, message, label, { col, offset } = { col: 24 }, required, placeholder, className='') => {
	return {
		key,
		col,
		label,
		offset,
		formItemLayout,
		rules: [requiredRule(required)],
		className: className,
		render: ({disabled}={}) => <Input placeholder={placeholder} disabled={disabled}  />
	}
}

function disabledDate(current) {
	return current && current > moment().endOf('day');
}
const fontStyle = {
	fontWeight: 'normal',
	fontSize: 16,
	lineHeight: '22px',
	color: '#2D3238',
	// marginTop:'-15px'
}
const mobile = isMobile();
const dobValidator = (rule, value, callback) => {
	if (!!value && (moment(value).format('MM/DD/yyyy') !== value || disabledDate(moment(value)))){
		return callback('Please input a valid birthday.');
	}
	callback()
};
const dobDataTransfer = (val) => {
	if(!val) return '';
	return moment(val).format('yyyy-MM-DD') === val ? moment(val).format('MM/DD/yyyy') : val;
};
export const BASIC_INFO_FORM = ({sendCode}) => [[
	requiredInput('firstName', 'firstName', '', { col: mobile? 24 : 4 }, true, 'First name', 'noMarginBottom'),
	requiredInput('middleName', 'middleName', '', { col: mobile? 24 : 5, offset: mobile? 0 : 1 }, false, 'Middle name (optional)', 'noMarginBottom'),
	requiredInput('lastName', 'lastName', '', { col: mobile? 24 : 4 , offset: mobile? 0 : 1 }, true, 'Last name', 'noMarginBottom'),
	{
		key:'text',
		render:()=><div style={fontStyle}>The name will be displayed on the test report. Please enter your legal name that matches your ID.</div>,
		col:24
	},
], [
	{	
		key: 'birthday',
		label: 'Date of birth',
		col: mobile? 24 : 15,
		rules: [requiredRule(true), {validator: dobValidator}],
		className: 'noMarginBottom',
		render: (props) => {
			return <DateFormattedInput />
		},
		dataTransfer: dobDataTransfer,
	},
	{
		key:'text',
		render:()=><div style={fontStyle}>The DOB will be displayed on the test report. Please make sure it matches your ID.</div>,
		col: 24,
	},
],
	[
		{
			key: 'phone',
			label: 'Phone (optional)',
			col: mobile? 24 : 15,
			className: 'noMarginBottom',
			render: () =>{ return <Input /> },
			extra: () => {
				return <div className={'emailInputExtra'}>
					<div style={{marginBottom:20}}>We'll contact you on this number if we cannot connect with you during the test process.</div>
				</div>
			}
		}],
[
	{
		key: 'email',
		label: 'Email',
		col: mobile? 24 : 15,
		className: 'noMarginBottom',
		rules: [requiredRule(true), { type: 'email' }],
		render: () => {
			return REMOVE_421? 
			<Input /> :
			<Input className={'emailInput'} suffix={<Button type={'primary'} onClick={sendCode}>Send verification code</Button>}/>
		},
		extra: () => {
			return REMOVE_421? <div className={'emailInputExtra'}>
				<Icon type="info-circle" theme={'filled'} style={{ margin: '5px 10px 5px 0px' }} />
				<div><strong>Important:</strong> The COVID test report will be send to the email you provided. <br />Please doulbe check and make sure it’s correct!</div>
			</div> : 
			<div className={'emailInputExtra'}>
				{/*<Icon type="info-circle" theme={'filled'} style={{ margin: '5px 10px 5px 0px' }} />*/}
				<div style={{marginBottom:20}}>We will send your verified test report to this email.</div>
				{/*<div style={{marginBottom:20}}><strong>Important:</strong> The COVID test report will be send to the email you provided. <br />Please doulbe check and make sure it’s correct!</div>*/}
			</div>
		}
	}],

	REMOVE_421 ? [] : [ 	requiredInput('verifyCode','verifyCode','Verification code',{ col: 15 },true) ]
]

export const QUESTION_FORM = (localThis) => {
	const changeRadio = (key, value) => {
		localThis.setStateByKey(key, value);
	}
	const getStateValueByKey = (key) => {
		return localThis.state[key];
	};
	const questionErrorTip = (content) => {
		return <span><Icon type="info-circle" theme={'filled'} style={{ marginRight: '8px' }} />{content}</span>
	}
	const radioQuestions = [{
		key: 'HAS_TEST_KIT',
		mustYes: true,
		extraItemTopContent:()=>{
			return <div><img src={'/image/Test_kits.png'} style={{ width: '25em', margin:'1em 0px', maxWidth: '90vw' }}/></div>
		}
	}, {
		key: 'ID_READY',
		mustYes: true,
	}, {
		key: 'HAND_SANITIZED',
		mustYes: true,
	}, {
		key: 'FAMILIAR_WITH_TEST',
		mustYes: false,
	}];
	const radioQuestionArray = radioQuestions.map((item) => {
		const { key, mustYes, extraItemTopContent } = item;
		return {
			key: key,
			rules: [requiredRule(true), mustYes ? { pattern: 'YES', message: ' ' } : {}],
			label: I18N.get(`questions.${key}`),
			render: () => {
				return <Radio.Group onChange={(e) => changeRadio(key, e.target.value)}>
					<Radio className={'radio'} value={'YES'}>
						Yes
					</Radio>
					<Radio className={'radio'} value={'NO'}>
						No
					</Radio>
				</Radio.Group>
			},
			extra: () => {
				let showExtra = mustYes && getStateValueByKey(key) === 'NO';
				return showExtra && <div className={'errorBox'}>
					{questionErrorTip(I18N.get(`questionsTip.${key}`))}
				</div>
			},
			extraItemTopContent: extraItemTopContent,
		}
	});
	return [radioQuestionArray];
}

export const CONSENT_CHECK_FORM = (localThis) => {
	const changeCheckBox = (key, value) => {
		localThis.setStateByKey(key, value);
	}
	const checkBoxMap = [{
		key: 'consentAgreed',
	}, {
		key: 'agreementAgreed',
	}];
	return [checkBoxMap.map((item) => {
		const {key} = item;
		return {
			key: key,
			rules: [{
				validator:(rule, value, callback) => {
					if (value !== true) {
						return callback('This is a required field');
					}
					callback()
				}
			}],
			render: () => {
				return <Checkbox onChange={(e) => changeCheckBox(key, e.target.checked)}>
					<div className="checkboxText">{CHECKBOX_BODY[key]}</div>
				</Checkbox>
			},
			valuePropName: 'checked',
			dataTransfer: (val = null) => !!val
		}
	})];
}
export const PROCTORING_USER_FORM = ()=>{

	return [[{...requiredInput('firstName', 'firstName', 'First Name:', { col: 24 }, true, 'First name')}],
		[{...requiredInput('middleName', 'middleName', 'MidName', { col: 24 }, false, 'Middle name (optional)')}],
		[{...requiredInput('lastName', 'lastName', 'Last Name', { col: 24 }, true, 'Last name')}],
        [{
			key: 'birthday',
			label: 'Date of birth',
			col: 24,
			rules: [requiredRule(true), {validator: dobValidator}],
			render: ({disabled}) => {
				return <DateFormattedInput disabled={disabled}/>
			},
			dataTransfer: dobDataTransfer,
		}],
		[{
			key: 'phone',
			label: 'Phone',
			col: 24,
			render: ({disabled}) =>{ return <Input disabled={disabled}/> },
		}],
		[{
			key: 'email',
			label: 'Email',
			col: 24,
			rules: [requiredRule(true), {type: 'email'}],
			render: ({disabled}) => {
				return <Input disabled={disabled}/>
			}
		}]
	]
}

export const REPORT_USER_FORM = ()=>{
	return [[{...requiredInput('firstName', 'firstName', 'First Name:', { col: 7 }, true, 'First name')},
		{...requiredInput('middleName', 'middleName', 'Middle Name:', { col: 7,offset:1 }, false, 'Middle name (optional)')},
		{...requiredInput('lastName', 'lastName', 'Last Name', { col: 7,offset:1 }, true, 'Last name')}],
        [{
			key: 'birthday',
			label: 'Date of birth',
			col: 24,
			rules: [requiredRule(true), {validator: dobValidator}],
			render: ({disabled}) => {
				return <DateFormattedInput disabled={disabled}/>
			},
			dataTransfer: dobDataTransfer,
		}],
		[{
			key: 'email',
			label: 'Email',
			col: 24,
			rules: [requiredRule(true), {type: 'email'}],
			render: ({disabled}) => {
				return <Input disabled={disabled}/>
			}
		}]
	]
}

export const USER_FORM_HEADER = {
	'PART1': 'Part 1: Test taker information.',
	'PART2': 'Part 2: Get ready for the test session.'
}
const ADDRESS = '150C Charcot Ave, San Jose, CA 95131';

export const CONSENT_BODY = 'I authorize iHealth Labs Inc (“iHealth”), to use and disclose my COVID-19 test results and any other of my protected health information held by iHealth.\n\n' +
	'I understand that I am authorizing iHealth to use and disclose this information to: (1) provide me with my test results; (2) engage in marketing and to conduct health care operations, including conducting quality assessment and improvement activities, data aggregation, care coordination, population health activities, and other business and management activities.\n\n' +
	'I understand that this authorization will expire in three (3) years unless I revoke it prior to that date.\n\n' +
	'I understand that I will receive no remuneration for the disclosure of my protected health information, and that iHealth will not receive remuneration for the disclosure of my protected health information.\n\n' +
	'I understand that once the protected health information is disclosed pursuant to this authorization, it may be redisclosed and no longer protected by federal privacy regulations. However, California law may prohibit iHealth from making further disclosure of my PHI that I have not approved in this form, unless another authorization for such disclosure is obtained from me or unless such disclosure is specifically required or permitted by law.\n\n' +
	'I understand that I do not have to execute this authorization and that my refusal to execute it will not affect my ability to obtain treatment from iHealth, nor will it affect my eligibility to obtain payment for such health care. I may request a copy of this authorization.\n\n' +
	`I understand that I have the right to revoke this authorization at any time. To revoke this authorization, I may contact iHealth Labs Inc, at ${ADDRESS}. I understand that the revocation will not apply to information that has already been released in response to this authorization.\n`

export const CHECKBOX_BODY = {
	'consentAgreed': 'I affirm that I understand the content of this authorization, I reached my decision free from pressure and coercion, and I authorize the release of my protected health information as indicated above.',
	'agreementAgreed': <div>I affirm that I understand and agree to the iHealth <a href="https://ihealthlabs.com/pages/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Use</a> and <a href="https://ihealthlabs.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</div>
}
export const TESTER_FORM_EDIT_EXCLUDED_FIELDS = ['verifyCode']
