import React, {useEffect, useState} from "react";
import {Input, Button, Tabs, Result, Icon, Tooltip} from "antd";
import '../../main/style/index.scss';
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../auth";
import {get} from "lodash";
import CreateAppStepTwoComponent from "../component/CreateAppStepTwoComponent";
import AvailableSlotsContainer from "./AvailableSlotsContainer";
import AppointmentScheduledComponent from "../component/AppointmentScheduledComponent";
import { getVisitQuery,getTesterFormQueryWithoutAuth,getSessionStatusQuery } from '../../API/query/schedule';
import Spin from "antd/es/spin";
import {isMobile} from "../../lib/helpers";
import DateFormattedInput from "../../Accessories/component/DateFormattedInput";
import {testerGetReportMutation} from "../../API/query/tester";
import TesterCheckReportComponent from "../../testerReport/component/TesterCheckReportComponent";
import {isCompleted} from "../constant/CheckStatus"

const RedeemAndScheduleContainer = (props)=>{
	const params = useParams();
	const { code } = params;
	const { preview } = props;
	const initialStatus = code || preview ? 'SLOT' : "INIT";
	const [ ticketNum,setTicketNum ] = useState(code||'');
	const [ codeError, setCodeError ] = useState();
	const [ checkReportError, setCheckReportError ] = useState(false);
	const [ birthday, setBirthday ] = useState('');
	const [ verified, setVerified ] = useState(false);
	const [ status,setStatus ] = useState(initialStatus);
	const [selectedTimezone, setSelectedTimezone] = useState()
	const [ selectedSlot, setSelectedSlot ] = useState();
	const [ startTime,setStartTime ]  = useState();
	const [ firstName,setFirstName ] = useState();
	const [ lastName,setLastName ] = useState();
	const [ middleName,setMiddleName ] = useState("");
	const [ email,setEmail ] = useState();
	const [ validEmail,setValidEmail ] = useState(true);
	const [ slots,setSlots ] = useState([]);
	const [ tester,setTester ] = useState({});
	const [ visit,setVisit ] = useState({});
	const [loading,setLoading] = useState(true);
	// const [ summary,setSummary ] = useState();
	// const [ timer,setTimer ] = useState();
	let auth = useAuth();
	const navigate = useNavigate();
	const renderTitle = ()=>{
		return <div className={'title'}>
			<div style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
				<span>Schedule/View My Appointment</span>
			</div>
		</div>
	}

	const renderSubtitle =()=>{
		return <div className={'subtitle'}>
			<span>Please enter your ticket number</span>
		</div>
	}
	const onChangeInput = (ticketNum)=>{
		setCodeError();
		setCheckReportError();
		setTicketNum(ticketNum);
	}
	const onChangeBirthday = (value) => {
		setCheckReportError();
		setBirthday(value);
	}
	const renderInput = ()=>{
		const placement = isMobile() ? 'bottomRight': 'topRight';
		return <div style={{ display:'flex',flexDirection:'row', paddingTop:5 }}>
			<div style={{ display:'flex', flexDirection:'column', flex:1}}>
				<Input value={ticketNum} onChange={(e)=>onChangeInput(e.target.value)} className={codeError &&'hasError'} style={{ height: 44}}/>
				<div className={'codeError'}>{ codeError }</div>
				{ renderBtn() }
			</div>
			<div style={{marginLeft: 10, height: 44, display:'flex', alignItems:'center'}}>
				<Tooltip placement={placement}
				title={'The ticket number is the code we \n' +
				'sent to you in your service purchase \n' +
				'confirmation email. '} overlayClassName={'codeTooltip'}>
					<Icon type="question-circle"  theme='filled' style={{ fontSize:20 }}/>
				</Tooltip>
			</div>
		</div>
	}

	const getReport = async ()=>{
		const [month,day,year] = birthday.split('/');
		try {
			setLoading(true);
			await testerGetReportMutation({code:ticketNum, birthday: `${year}-${month}-${day}`});
			setVerified(true);
		}catch (e) {
			setCheckReportError(true);
		}
		finally {
			setLoading(false);
		}
	}

	const showCheckReportError = ()=>{
		return <div className={'errorBox'} style={{marginTop:'50px'}}>
				<img src={'/image/red_info.png'} width={14} style={{marginRight:10,alignSelf:'flex-start',marginTop:5 }}/>
				<span style={{lineHeight:'20px'}}>  Sorry, the ticket number or DOB is incorrect. Please try again.</span>
		</div>
	}

	const renderCheckReportBtn = ()=>{
		const disabled = !ticketNum.length || !birthday.length;
		return <Button style={{ marginTop:'50px' }} className={'ihealth'} disabled={disabled} loading={loading} onClick={getReport}>Confirm</Button>
	}

	const renderInputCheckReport = ()=>{
		const placement = isMobile() ? 'bottomRight': 'topRight';
		return <div style={{ display:'flex',flexDirection:'row', paddingTop:5 }}>
			<div style={{ display:'flex', flexDirection:'column', flex:1}}>
				<Input value={ticketNum} onChange={(e)=>onChangeInput(e.target.value)} style={{ height: 44}}/>
				<div style={{ margin:'23px 0', fontSize:'16px' }}>Your appointment has been finished, and a test report has been attached to it. To protect your personal information, please verify the test taker’s DOB.</div>
				<div className={'subtitle'}>DOB</div>
				<DateFormattedInput onChange={onChangeBirthday} style={{ height: 44}}/>
				{checkReportError && showCheckReportError()}
				{ renderCheckReportBtn() }
			</div>
			<div style={{marginLeft: 10, height: 44, display:'flex', alignItems:'center'}}>
				<Tooltip placement={placement}
				title={'The ticket number is the code we \n' +
				'sent to you in your service purchase \n' +
				'confirmation email. '} overlayClassName={'codeTooltip'}>
					<Icon type="question-circle"  theme='filled' style={{ fontSize:20 }}/>
				</Tooltip>
			</div>
		</div>
	}

	const getAdditionalInfo = async ()=>{
		let status = await getSessionStatusQuery(ticketNum);
		let visit = await getVisitQuery(ticketNum);
		let tester = await getTesterFormQueryWithoutAuth(ticketNum);
		let statusInfo = get(status,'data.data',{});
		let visitData = get(visit,'data.data',{});
		let testerInfo = get(tester,'data.data',{});
		if(statusInfo) {
			setTester(statusInfo);
		}
		if(testerInfo) {
			setTester({...statusInfo,...testerInfo});
		}
		if(visitData) {
			setVisit(visitData)
		}
		const {Status} = statusInfo;
		if (isCompleted(Status)) {
			setStatus('CHECKREPORT');
		} else {
			setStatus('SLOT');
		}
	}

	const getInitVisitStatus = async ()=>{
		try {
			if(ticketNum) {
				await auth.signin({code: ticketNum, role: 'code'});
				await getAdditionalInfo();
			}
		}catch (e) {
			const code = get(e,'response.status');
			if(code ===401) {
				setCodeError('Sorry, this ticket number is invalid. Please double check that you\'ve copied it correctly and there are no spaces.');
				setStatus('INIT');
			}
			if(code===403) {
				await getAdditionalInfo();
			}
		} finally {
			setLoading(false);
		}
	}
	useEffect(()=>{
		getInitVisitStatus();
	},[]);

	const handleOnClick = ()=>{
		getInitVisitStatus();
	}
	const restStates =()=>{
		setFirstName();
		setMiddleName();
		setLastName();
		setEmail();
		setStartTime();
		setSelectedTimezone();
		setTicketNum('');
		setSlots([]);
		setStatus('INIT');
	}
	const renderBtn =()=>{
		const disabled = !ticketNum.length;
		return <div className={'buttonContainer'} style={{ textAlign:'center' }}>
			<Button className={'ihealth'} disabled={disabled} onClick={handleOnClick}>Confirm</Button>
		</div>
	}

	const initStatueDiv = ()=>{
		return <div className={'ticketNumWrapper'}>
			<div className={'content'}>
				{ renderTitle() }
				<div className={'optContainer'}>
					{ renderSubtitle() }
					{ renderInput() }
				</div>
			</div>
		</div>
	}
	const checkReportDiv = ()=>{
		return <div className={'ticketNumWrapper'}>
			<div className={'content'}>
				{ renderTitle() }
				<div className={'optContainer'}>
					{ renderSubtitle() }
					{ renderInputCheckReport() }
				</div>
			</div>
		</div>
	}
	const handleBackbtn = ()=>{
		window.location.href = '/verifyTest/schedule'
	}
	if(loading && status !== 'CHECKREPORT') return <Spin/>
	if(verified){
		return <TesterCheckReportComponent code={ticketNum}/>
	}
	if(status){
		return <Tabs defaultActiveKey={'INIT'} activeKey={status} className={'scheduleVisitTabs'}>
			<Tabs.TabPane key={'INIT'} tab={'init'}>
				{initStatueDiv()}
			</Tabs.TabPane>
			<Tabs.TabPane key={'CHECKREPORT'} tab={'checkreport'}>
				{checkReportDiv()}
			</Tabs.TabPane>
			<Tabs.TabPane key={'SLOT'} tab={'slot'}>
				<AvailableSlotsContainer setStatus={setStatus} status={status} selectedSlot={selectedSlot} setSelectedSlot={setSelectedSlot}
				                         startTime={startTime} setStartTime={setStartTime} slots={slots} setSlots={setSlots}
				                         ticketNum={ticketNum} visit={visit} tester={tester} preview={preview}
				                         selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone}/>,
			</Tabs.TabPane>
			<Tabs.TabPane key={'USERINFO'} tab={'userinfo'}>
				<CreateAppStepTwoComponent selectedSlot={selectedSlot} startTime={startTime} selectedTimezone={selectedTimezone}
				                           firstName={firstName} middleName={middleName} lastName={lastName} setStatus={setStatus}
				                           setValidEmail={setValidEmail} validEmail={validEmail} ticketNum={ticketNum}
				                           setFirstName={setFirstName} setMiddleName={setMiddleName} setLastName={setLastName}
											email={email} setEmail={setEmail}
                                           status={status}
				/>
			</Tabs.TabPane>
			<Tabs.TabPane key={'SCHEDULED'} tab={'SCHEDULED'}>
				<div className={'appTwoDiv success'}>
					<div style={{marginLeft:'auto'}}><button className={'backBtn'} onClick={handleBackbtn}>Back</button></div>
						<AppointmentScheduledComponent code={ticketNum} selectedSlot={selectedSlot} startTime={startTime} selectedTimezone={selectedTimezone} status={status}
						                               firstName={firstName} middleName={middleName} lastName={lastName} />
				</div>
			</Tabs.TabPane>
		</Tabs>
	}
	return <div></div>


}
export default RedeemAndScheduleContainer;