import {getRequest, postRequest} from '../helper/index';
import {getLoginUser} from "../../auth/helper";

const TEST_INFO_URL = (code)=>`/api/proctoring/operators/tester-info?code=${code}`;
const SEND_REPORT_URL = (code)=>`/api/proctoring/operators/send-report?code=${code}`;
const REPORT_LIST_URL= ({pageSize,page})=>`/api/proctoring/operators/reports?page=${page}&pageSize=${pageSize}`
const REPORT_BY_ID_URL = (id)=>`/api/proctoring/operators/reports/${id}`;
const REPORT_HISTORY_BY_CODE_URL = (code)=>`/api/proctoring/operators/reports/testers?code=${code}`;
const HEADER_WITH_AUTH =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})

const HEADER_WITH_COOKIE = ()=>{
	return {
		...HEADER_WITH_AUTH(),
	}
}

export const getTesterInfoQuery = (code)=>getRequest(TEST_INFO_URL(code),{ headers:HEADER_WITH_COOKIE()});
export const sendReportMutation = (code, data)=>postRequest(SEND_REPORT_URL(code),{ headers:HEADER_WITH_COOKIE(), data});
export const getReportList = (pagination)=>getRequest(REPORT_LIST_URL(pagination),{ headers:HEADER_WITH_COOKIE() });
export const getReportById = (id)=>getRequest(REPORT_BY_ID_URL(id),{ headers:HEADER_WITH_COOKIE() });
export const getReportHistoryByCode = (code)=>getRequest(REPORT_HISTORY_BY_CODE_URL(code),{ headers:HEADER_WITH_COOKIE() });