import React from 'react';
import LinkButton from './LinkButton';

const StrengthSection = () => {
    const itemFeatrues = [
        { text: 'Free shipping' },
        { text: 'Direct delivery to each recipient' },
        { text: 'Support subscription order (10% OFF)' },
        { text: 'Group test result management web portal' },
    ];

    const itemsSolution = [
        true,
        true,
        true,
        true,
    ];

    const itemsPurchase = [
        true,
        false,
        false,
        false,
    ];

    const renderFeatrues = (items) => itemFeatrues.map((featrue, index) => {
        const check = items[index];
        const src = check ? '/assess/check.svg' : '/assess/no.svg';
        const alt = check ? 'true' : 'false';
        const textClassName = check ? 'spanTrue' : 'spanFalse';
        return (
            <div key={index} className='featrues'>
                <img alt={alt} src={src} />
                <span className={textClassName}>{featrue.text}</span>
            </div>
        )
    })

    return <div className="strengthSection">
        <div className="sectionWrapper">
            <div className='strength-header'>
                <h2>iHealth COVID Testing Solution VS. Enterprise bulk Purchase</h2>
            </div>
        </div>
        <div className='strength-body'>
            <div className="strengthContent">
                <div className="contentCard">
                    <div className='cardTitle'>
                        <h3>iHealth COVID Testing Solution</h3>
                        <img src={'/image/hot.png'} />
                    </div>
                    <img className='img_kit' src={'/image/test_kit.png'} />
                    {renderFeatrues(itemsSolution)}
                    <LinkButton to="/signup" text="Get started" className='button linkButton' />
                    <p>*There will be a $ 1/pack service fee. Service fees offset the costs of curating and making your test results accessible to you anytime, anywhere.</p>
                </div>
                <div className="contentCard">
                    <div className='cardTitle' style={{ justifyContent: 'flex-start' }}>
                        <h3>Enterprise Bulk Purchase</h3>
                    </div>
                    <img className='img_kit' src={'/image/test_kit.png'} />
                    {renderFeatrues(itemsPurchase)}
                    <LinkButton to="https://ihealthlabs.com" text="Enterprise purchase" className='buttonPurhase button linkButton' target="_blank" rel="noopener noreferrer" />
                </div>
            </div>
            <img className='content_bg' src={'/image/strength_bg.png'} />
        </div>
    </div>

}

export default StrengthSection;
