import React, {useEffect, useRef, useState} from "react";
import '../style/index.scss';
import AudioPlayerComponent from './AudioPlayerComponent';
const JoiningComponent = (props)=>{
	const { waitingCount } = props;
	const { status } = props;
	const waitingTextTheme = {
		PHASE_TWO_WAITING:'phaseTwo',
		PHASE_ONE_WAITING:'phaseOne'
	}
	const waitingTextMsg = {
		PHASE_TWO_WAITING:'',
		PHASE_ONE_WAITING:''
	}
	const theme = waitingTextTheme[status];
	return <div className={`waitingDiv ${theme}`}>
		<div>
			<span>We are connecting you with a COVID test proctor, please wait ...</span>
			<img src={'/image/hourglass.gif'}/>
			<AudioPlayerComponent audioSrc={'/sound/waiting.m4a'}/>
		</div>
		{ typeof waitingCount=='number' ? <div className={'waitingCount'}>People ahead of you: {waitingCount + (waitingCount==0 ? 1 : 0)}</div> :'' }
	</div>
}

export default JoiningComponent;