import React from "react";
import { Card,Row,Col } from 'antd';
import moment from "moment";
import '../style/index.scss';
import {useNavigate} from "react-router-dom";
const SubscriptionCardComponent = (props)=>{
	const navigation = useNavigate();

	const handleClick = (id)=>{
		navigation(`/subscription/${id}`);
	}

	const renderCards = ()=>{
		const { subscriptions=[] } = props;
		return <Row gutter={[6,2]}>{ subscriptions.map((sub, k)=><Col key={k} span={6} style={{width: 300}} > { singleCard(sub) } </Col>)}</Row>
	}

	const singleCard = (sub={})=>{
		const { name,nextDate,contactGroupId,id } = sub;
		const nextOrderDate = moment(nextDate).format('MM/DD/YYYY');
		return <Card style={{ height:200}} onClick={()=>handleClick(id)}
		             className={'singleSubscription'} key={contactGroupId}>
			<div className={'header'}>{name}</div>
			<div>
				<div className={'logo'}><img src={'/image/test_kit.png'} width={75}/></div>
			</div>
			<div style={{ display:'flex',justifyContent:'space-between' }}>
				<div>Next ship date</div>
				<div style={{ fontWeight:'bold' }}>{nextOrderDate}</div>
			</div>
		</Card>
	}

	return renderCards();
}
export default SubscriptionCardComponent;