import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import {Col, Row} from "antd";
import {convertUTCToTimezone} from "../../lib/helpers";

const AppTodayComponent = (props)=>{
	const timeSlotAndSchedule = (a)=>{
		return convertUTCToTimezone(a.timeSlot,null,moment.tz.guess());
	}
	const renderEachSlot = (a)=>{
		const timeslot = timeSlotAndSchedule(a);
		const { seatsOccupied } = a;
		const isWholeHour = timeslot.includes(':00') ? 'whole' : '';
		return <Row className={`slot ${isWholeHour}`}>
			<Col span={12}>{timeslot}</Col>
			<Col span={12} style={{ textAlign:'center' }}>{seatsOccupied}</Col>
		</Row>
	}
	const renderAppointment = () => {
		const {appointments} = props;
		const totalApp = appointments.reduce((res,a)=>res + a.seatsOccupied,0);
		return <div className={'appointmentWrapper'}>
			<div className={'mainHeader'}>Appointments in the following 24 hrs: <span>{totalApp}</span></div>
			<Row className={'subheader'}>
				<Col span={12}>Time </Col>
				<Col span={12}># of appointments</Col>
			</Row>
			<div className={'slotList'}>
				{appointments.map(a=>renderEachSlot(a))}
			</div>

		</div>
	}
	return renderAppointment();
}
export default AppTodayComponent;
