import * as React from "react";
import {
		Link,
		useNavigate,
		Navigate,
} from "react-router-dom";
import { fakeAuthProvider } from "./authProvider";
import { isLoggedIn } from "./helper";

function Layout() {
	return (
		<div>
			<AuthStatus />

			<ul>
				<li>
					<Link to="/">Public Page</Link>
				</li>
				<li>
					<Link to="/protected">Protected Page</Link>
				</li>
			</ul>
		</div>
	);
}

interface AuthContextType {
	user:Object,
	signin: (user, callback) => void;
	signout: (callback) => void;
}

let AuthContext = React.createContext({});

export function AuthProvider({ children }) {
	let [user, setUser] = React.useState({ });

	let signin = (newUser, succeedCB,failCB) => {
		return fakeAuthProvider.signin(newUser,succeedCB,failCB);
	};

	let signout = (callback) => {
		return fakeAuthProvider.signout(callback);
	};

	let signUp = (calllback) => {
		return fakeAuthProvider.signUp(calllback);
	}
	let value = { user, signin, signout,signUp };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
	return React.useContext(AuthContext);
}

function AuthStatus() {
	let auth = useAuth();
	let navigate = useNavigate();
	if (!auth.user) {
		return <p>You are not logged in.</p>;
	}

	return (
		<p>
			Welcome {auth.user}!{" "}
			<button
				onClick={() => {
					auth.signout(() => navigate("/"));
				}}
			>
				Sign out
			</button>
		</p>
	);
}

export function RequireAuth({ path="/login", children }) {
	if (!isLoggedIn()) {
		return <Navigate to={path}/>;
	}
	return children;
}

// function LoginPage() {
// 	let navigate = useNavigate();
// 	let location = useLocation();
// 	let auth = useAuth();
//
// 	// @ts-ignore
// 	let from = location.state?.from?.pathname || "/";
//
// 	function handleSubmit(event) {
// 		// event.preventDefault();
// 		//
// 		// let formData = new FormData(event.currentTarget);
// 		// let username = formData.get("username");
//
// 		auth.signin(username, () => {
// 			// Send them back to the page they tried to visit when they were
// 			// redirected to the login page. Use { replace: true } so we don't create
// 			// another entry in the history stack for the login page.  This means that
// 			// when they get to the protected page and click the back button, they
// 			// won't end up back on the login page, which is also really nice for the
// 			// user experience.
// 			navigate(from, { replace: true });
// 		});
// 	}
//
// 	return (
// 		<div>
// 			<p>You must log in to view the page at {from}</p>
//
// 			<form onSubmit={handleSubmit}>
// 				<label>
// 					Username: <input name="username" type="text" />
// 				</label>{" "}
// 				<button type="submit">Login</button>
// 			</form>
// 		</div>
// 	);
// }