import React, { useState } from 'react';
import { Button, Row, Col, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedGroup } from '../../redux/actions/testResultActions';
import { getAndSetGroupUserList, getGroupStats } from '../helper/index';

const TestGroups = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // let throttle = false;
    let { startTime, filter, endTime, selectedTestGroup, testGroups }= useSelector(state => state.testResultReducer);
    
    const handleClick = async (group) => {
        // API has time limit, so we need to throttle the request
        if(loading) {
            message.info('Changing test group too frequently, please wait for a while');
            return;
        }
        const data = {
            GroupCode: group.GroupCode,
            StartTime: startTime,
            EndTime: endTime,
            Page: 1,
            PageSize: 10,
            ResultType: filter
        }
        
        setLoading(true);
        dispatch(setSelectedGroup(group));
        await getGroupStats(dispatch, {GroupCode: group.GroupCode, StartTime: startTime, EndTime: endTime});
        await getAndSetGroupUserList(dispatch, data);
        setLoading(false);
    }

    const renderTestGroups = () => {

        if(testGroups.length === 1) {
            return <span className={'testGroupName'}><img className={'testGroupIcon'} alt="Test Group" src={'/assess/TestGroupIcon.svg'} /><strong>{ ` ${testGroups[0].GroupName}` }</strong></span>
        }
        return testGroups.map((group, index) => {
            const selected = selectedTestGroup.GroupCode === group.GroupCode ? 'selected' : '';
            return <Col key={index} className="gutter-row">
                    <Button type="flex" align="middle" className={`testGroupButton ${selected}`} onClick={()=>handleClick(group)}>
                        <img className={'testGroupIcon'} alt="Test Group" src={'/assess/TestGroupIcon.svg'} />
                        <span className={'testGroupName'}>{ group.GroupName }</span>
                    </Button>
                </Col>
        });
    }


    return <Row gutter={[8, 5]} className={'testResultControlWrapper'} type="flex" align="middle">
        {renderTestGroups()}
    </Row>
}

export default TestGroups;