import React, {useEffect} from "react";
import { Table, Icon, Popover, Button, Menu, Dropdown, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { removeMemberFromGroup } from "../../API/query/TestResults";
import { setIsLoading } from "../../redux/actions/testResultActions";
import moment from 'moment';
import { setCurrentPage } from "../../redux/actions/testResultActions";
import {filterTypes} from '../../redux/actionTypes/testResultActionTypes';
import {
    getAndSetGroupUserList,
    getGroupStats
} from '../helper/index';

const TestResultTableComponent = () => {
    const dispatch = useDispatch();

    const { groupStats, tableRowsData, selectedTestGroup, userList, startTime, endTime, currentPage, filter } = useSelector(state => state.testResultReducer);
    const total = filter===filterTypes.ALL? groupStats.Members : filter===filterTypes.POSITIVE? groupStats.Count.Positive: filter===filterTypes.NEGATIVE? groupStats.Count.Negative: filter===filterTypes.NORESULT? groupStats.Count.Invalid : 0;

    const data = userList.map(user => {
        const obj = {...user};
        obj.results = tableRowsData[user.UserID] || [];
        for(let record of obj.results) {
            record.status = record.Result === "pass" ? "Negative" :  record.Result === "fail" ? "Positive" : "No Result";
        }
        return obj;
    })

    const doFetch = async () => {
        const data = {GroupCode: selectedTestGroup.GroupCode, StartTime: startTime, EndTime: endTime, Page: currentPage, PageSize:10,  ResultType: filter};
        try {
            dispatch(setIsLoading(true));
            await getGroupStats(dispatch, data);
            await getAndSetGroupUserList(dispatch, data);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setIsLoading(false));
         }
    }

    useEffect(() => {
        doFetch();
    }, [currentPage]);

    const removeFromGroupByUserId = async (userId) => {
        try {
            const response = await removeMemberFromGroup({GroupCode: selectedTestGroup.GroupCode, UserID: userId});
            if(response.data.code === 200 && response.data.message==="success"){
                // update data
                message.success("User removed from group successfully");
                await doFetch();
            } else {
                message.error("Failed to remove from group");
            }

        } catch (error) {
            console.log(error);
            message.error("An error occurred. Unable to remove user from group");
        }
        
    }

    const popOverContent = (userId) => {
        return (
            <div>
                <Button onClick={()=>removeFromGroupByUserId(userId)}> Remove from group </Button>
            </div>
        )
    }

    const createSettigsPopover = (userId) => {
        if(userId===groupStats.UserID && groupStats.IsAdmin===1){
            return <span>Group Admin</span>;
        }
        return <Popover className={'tablePopOver'} placement="bottomLeft" content={popOverContent(userId)}><Icon type="more" id={userId} /></Popover>
    }

    const createDropdown = (row) => {
        const items = row.results.map((record, k) => {
            const t = moment(record.MeasureTS*1000).format('MM/DD/YYYY');
            const c = record.status.toLowerCase().replace(' ', '');
            return <Menu.Item key={k}><span className="testStatusText">{t}</span><span className={`testStatus ${c}`}>{record.status}</span></Menu.Item>
        });

        
        const menu = (
            <Menu className="statusList">
              {items}
            </Menu>
          );

        return row.results.length > 1 ?
        <Dropdown overlay={menu} trœigger={['click']}>
            <span className="statusMenuHeader">{row.results.length}</span>
      </Dropdown>
      :
      <span>{row.results.length}</span>
    }

    const namePopOverContent = (row) => {
        return (
            <div className="namePopOver">
                <p><strong>{`${row.FirstName} ${row.LastName}`}</strong></p>
                <p>{`Address: ${row.Address}`}</p>
                <p>{`Contact Email: ${row.Email}`}</p>
                <p>{`phone Number: ${row.Mobile}`}</p>
                <p>{`DOB: ${moment(row.Brith*1000).format('MM/DD/YYYY')}`}</p>
            </div>
        )
    }

    const createNamePopOver = (row) => {
        return <Popover className={'tablePopOver'} placement="bottomLeft" content={namePopOverContent(row)}>{`${row.FirstName} ${row.LastName}`}</Popover>
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'key',
            key: '1',
            // sorter: (a, b) => a.name.length - b.name.length,
            width: '15%',
            render: (text, record) => createNamePopOver(record),
        },
        {
            title: '# of Test',
            dataIndex: 'results',
            key: '2',
            // sorter: (a, b) => a.results.length - b.results.length,
            width: '15%',
            render: (text, record) => createDropdown(record)
        },
        {
            title: 'Most recent test status',
            dataIndex: 'results',
            key: '3',
            width: '15%',
            render: (text, record) => {
                const c = record.results.length ? record.results[0].status.toLowerCase().replace(' ', '') :'noResult';
                const t = record.results.length ? record.results[0].status : 'No Result';
                return <span className={`testStatus ${c}`}>{t}</span>
            }
        },
        {
            title: 'Most recent test date',
            dataIndex: 'MeasureTS',
            key: '4',
            width: '15%',
            render: (text) => {
                const t = text > 0 ? moment(parseInt(text)*1000).format('MM/DD/YYYY') : '';
                return <span>{t}</span>
            }
        },
        {
            title: '',
            dataIndex: 'UserID',
            key: 'UserID',
            width: '7%',
            render: (text) => createSettigsPopover(text),
            align: 'right'
        }
    ]
    
    const pagination = {
        pageSize:10,
        total: total,
        current: currentPage,
        onChange:(p)=>dispatch(setCurrentPage(p))
    }

    return <div>
            <Table columns={columns} dataSource={data} rowKey={record => record.UserID} size="small" pagination={pagination}></Table>
        </div>;
}

export default TestResultTableComponent;