import React, {useEffect} from "react";
import { upperFirst} from 'lodash';
import {parseTime} from "../../lib/helpers";
import moment from "moment";

const ReportHistoryComponent = (props)=>{
	const { res } = props;
	useEffect(()=>{

	},[res]);
	const singleReport = (report={})=>{
		const { result,firstName,lastName,midName='',birthday,email,resultReported,Id,sentBy,method } = report;
		const fullName = firstName+' '+midName+' '+lastName;
		const parsedDOB = parseTime(birthday);
		const parseSentAt = moment(resultReported).format('hh:mm A MM/DD/YYYY');
		let sentMethod = {
			ON_PORTAL: 'In phase 2',
			MANUALLY: 'Manually sent'
		}[method] || 'Unknown';
		return <div className={'singleReport'} key={Id}>
			<div className={'item result ' + result}>{upperFirst(result.toLowerCase())}</div>
			<div className={'item fullName'}>{fullName}</div>
			<div className={'item birthday'}>{parsedDOB}</div>
			<div className={'item email'}> {email} </div>
			<div className={'item sentAt'}>{`Sent at: ${parseSentAt}`}</div>
			<div className={'item sentAt'}>{`Sent by: ${sentBy}`}</div>
			<div className={'item sentAt'}>{`Sent method: ${sentMethod}`}</div>
		</div>
	}
	return <div className={'reportHistory'}>
			{ res.map(r=>singleReport(r)) }
		   </div>
}
export default ReportHistoryComponent;