import React from "react";
import { useEffect } from 'react';
import { testerFormInfo } from '../../API/query/proctor';
import { get,isEmpty } from 'lodash';
import useInterval from "../../lib/hook/useHook";

const DispatchingComponent = (props)=>{
	const { tester,setTester,getOnGoingTester,getNextTester,setState } = props;
	let timer = null;
	let fetchTester = async ()=>{
		try {
			let nextOnGoingTester = await getOnGoingTester();
			if (!nextOnGoingTester) {
				const nextTester = await getNextTester();
				if (nextTester) {
					nextOnGoingTester = await getOnGoingTester();
				}
			}
			if(!isEmpty(nextOnGoingTester)) {
				let { Code } = nextOnGoingTester;
				let testerForm = await testerFormInfo(Code);
				let testerInfo = get(testerForm,'data.data',{});
				setTester({...nextOnGoingTester,...testerInfo});
				clearInterval(timer);
				setState('UPCOMING');
			}
		}catch (e) {
		}
	}
	useInterval(fetchTester, 10000, true);
	return <div className={'dispatchMsg'}>Please wait... We are dispatching a tester to you.</div>

}
export default DispatchingComponent;