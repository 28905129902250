import React, {useEffect, useState} from "react";
import {Rate, Input, Button, Spin, message} from 'antd';
import '../style/index.scss';
import {getTesterFormQuery,rateServiceMutation} from "../../API/query/redeem";
import { useNavigate } from "react-router-dom";
import {get} from "lodash";
const { TextArea } = Input;
const RatingComponent = (props)=>{
	const [testerForm, setTesterForm] = useState({});
	const [loading, setLoading] = useState(false);
	const [ rating,setRating ] = useState();
	const [comment,setComment ] = useState('');
	const getTesterForm = async () => {
		setLoading(true);
		let response = await getTesterFormQuery(props.code);
		if (response.data.code == 200) {
			setTesterForm(get(response, 'data.data',) || {});
			setLoading(false);
		}
	};
	useEffect(() => {
		getTesterForm();
	}, []);
  const navigate = useNavigate();
	let email = get(testerForm, 'email');
	if (loading) return <Spin />;
	const renderHeader = ()=><div className={'title'}>Thank you for choosing iHealth Verified COVID Test Service</div>;
	const renderBody = ()=><div className={'body'}>{`Your verified test report has been sent to your email: `}<strong> {email} </strong>Please check!
Have a good day!</div>;
	const rateService = async ()=>{
		try {
			await rateServiceMutation({rating, comment});
      message.success('Thank you for your response!');
      navigate('/verifyTest/schedule')
		}catch (e) {
			console.log(e);
		}
	}
	const ratingBlock = ()=>{
		return <div className={'ratingDiv'}>
			<span>How would you rate our service?</span>
			<Rate style={{fontSize:24}} onChange={setRating}/>
			<TextArea rows={6} placeholder={'We’d like to hear more feedback from you.'} onChange={(e)=>setComment(e.target.value)}
			          style={{ marginTop:'2em'}}/>
			<Button className={'ihealth'} onClick={ rateService } disabled={!rating}>Submit</Button>
		</div>
	}
	return <div className={'ratingWrap'}>
			{ renderHeader() }
			{ renderBody() }
			{ ratingBlock() }
	</div>
}
export default RatingComponent;
