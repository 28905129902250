import {Tooltip, Row, Popover} from "antd";
import React, { useEffect } from "react";
import useInterval from "../../lib/hook/useHook";
import {convertUTCToTimezone} from "../../lib/helpers";
import moment from "moment-timezone";
import AppTodayComponent from "./AppTodayComponent";
import TesterStateTableComponent from "./TesterStatTableComponent";
import 'antd/lib/tooltip/style/index.css';
const ProctorDashboardComponent = (props) => {
  const { fetchStatsData, loading, proctorInService,appointments,statInfo,testerInfo,setStatus,servingProfiles} = props;
  const renderProctorStat = () => {
    const statMap = [{
      tip: 'These proctors are serving testers currently.',
      content: 'Proctors in-service:',
      count: proctorInService,
    }]
    return <div className={'proctorStatWrapper'}>
      {statMap.map(item =>
        <div className={'proctorStat'}>
          <Tooltip placement={'bottom'}
            title={item.tip}>
            <label>{item.content}</label>
          </Tooltip>
          <Popover overlayClassName={'inServiceProctorCount'} placement={'bottomLeft'}
                   content={servingProfiles.map(({FirstName,LastName})=><div style={{marginBottom:12}}>{`${FirstName} ${LastName}`}</div>)}>
            <span className={'count'}>{item.count}</span>
          </Popover>
        </div>)
      }
    </div>
  }

  const renderAppointment = () => {
    return <AppTodayComponent appointments={appointments}/>
  }
  const historyIcon = ()=>{
    return <Popover content={renderAppointment()} placement={'bottomLeft'}
    overlayClassName={'todayAppPopup'}><img src={'/image/schedule.png'} style={{ marginLeft:'auto',marginTop:20 }} width={40}/></Popover>
  }
  return <div className={'dashboardContent'}>
    {renderProctorStat()}
    <div style={{ display:'flex' }}>{ historyIcon() }</div>
    <div>
      <Row style={{ display:'flex',flexWrap:'wrap' }} gutter={24}>
        <TesterStateTableComponent statInfo={statInfo} testerInfo={testerInfo} setStatus={setStatus} loading={loading}/>
      </Row>
    </div>
  </div>
}
export default ProctorDashboardComponent;