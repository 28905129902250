import React, { useEffect, useRef, useState} from "react";
import TestSessionComponent from "../component/TestSessionComponent";
import { getNextTesterQuery,getOnGoingTesterQuery,confirmAndJoinVideo } from '../../API/query/proctor';
import { get } from 'lodash';
import {createWS} from "../../lib/socket/createWS";
import useWebSocket, { ReadyState }  from "react-use-websocket";
import { updateProctorStatusMutation } from '../../API/query/proctor';

const wsEventMap = (cmd, msg,props)=>{
	const { setState } = props;
	const code = get(props,'tester.Code');
	// const { agora,setAgora,setStatus,getSessionStatus,setTimer,setLoading } = props;
	return {
		1005:async ()=> {
		},
		1006:async ()=>{
			setState('WAITING');
		}

	}[cmd];
}

const TestSessionContainer = ()=>{
	const [ state,setState ] = useState('WAITING');
	const [ tester,setTester ] = useState({});
	const [pingInterval, setPingInterval] = useState(null);
	const [wsclosed, setWsclosed] = useState(false);
	const [agoraInit, setAgoraInit] = useState();
	const ws = useRef(null);
	const url = createWS();
	const fetchAgora = async (code)=>{
		let agoraRes = await confirmAndJoinVideo(code);
		if(agoraRes.data.message='success') {
			return agoraRes.data.summary;
		}
	}
	const {
		sendMessage,
		lastMessage,
		readyState,
	} = useWebSocket(url, {
			onReconnectStop: (numAttempted) => {
				console.log('ws onReconnectStop', numAttempted);
			},
			onError: (e) => {
				console.log('ws error', e);
			},
			onClose: (m) => {
				console.log('ws close', m);
				if (!!pingInterval) {
					clearInterval(pingInterval);
					setPingInterval(null);
				}
			},
			onOpen: () => {
				console.log('ws opened');
				if (!!pingInterval) {
					clearInterval(pingInterval);
				}
				const id = setInterval(pingServer, 30000);
				setPingInterval(id);
			},
			onMessage:(event)=>{
				let res = JSON.parse(event.data);
				let cmd = JSON.parse(res.command);
				try {
					let msg = JSON.parse((typeof res.msg=='string' && res.msg.length==0) ? '{}': res.msg);
					let action = wsEventMap(cmd,msg,{ tester,setState });
					action && action();
				}catch(e){
				}
			},
			//Will attempt to reconnect on all close events, such as server shutting down
			shouldReconnect: (closeEvent) => true,
			reconnectAttempts: 2000,
		}
	);

	useEffect(() => {
		const connectionStatus = {
			[ReadyState.CONNECTING]: 'Connecting',
			[ReadyState.OPEN]: 'Open',
			[ReadyState.CLOSING]: 'Closing',
			[ReadyState.CLOSED]: 'Closed',
			[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
		}[readyState];
		if (connectionStatus !== 'Open') {
			setWsclosed(true);
		} else {
			setWsclosed(false);
		}
  }, [readyState]);
	const pingServer = ()=>{
		try {
			sendMessage(JSON.stringify({command: 1000}));
		} catch(e){
			console.log(e);
		}
	}

	const getNextTester = async ()=>{
		const res = await getNextTesterQuery();
		return get(res,'data.data');
	}
	const getOnGoingTester = async ()=>{
		const res = await getOnGoingTesterQuery();
		return get(res,'data.data');

	}
	const updateProctorStatus = async (status)=>{
		try {
			await updateProctorStatusMutation({status});
		} catch (e){
			console.log(e);
		}
	}

	return <TestSessionComponent state={state} setState={setState} getNextTester={getNextTester}  setTester={setTester} tester={tester}
															 fetchAgora={fetchAgora} agoraInit={agoraInit} setAgoraInit={setAgoraInit} getOnGoingTester={getOnGoingTester}
															 updateProctorStatus={updateProctorStatus} wsclosed={wsclosed}/>
}
export default TestSessionContainer;