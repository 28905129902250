import React from "react";
import { merge } from "lodash";
import AgoraRTC from "agora-rtc-sdk-ng";
import "./canvas.scss";
import "../../assets/fonts/css/icons.css";
import { startRecordMutation } from '../../API/query/video';
import HttpLog from "../../utils/HttpLog";

const tile_canvas = {
  "1": ["span 12/span 24"],
  "2": ["span 12/span 12/13/25", "span 12/span 12/13/13"],
  "3": ["span 6/span 12", "span 6/span 12", "span 6/span 12/7/19"],
  "4": [
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12/7/13",
  ],
  "5": [
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 9/span 16/10/21",
  ],
  "6": [
    "span 3/span 4/13/7",
    "span 3/span 4/13/11",
    "span 3/span 4/13/15",
    "span 3/span 4/13/19",
    "span 3/span 4/13/23",
    "span 9/span 16/10/21",
  ],
  "7": [
    "span 3/span 4/13/5",
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 3/span 4/13/25",
    "span 9/span 16/10/21",
  ],
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */
class AgoraCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.client = {};
    this.localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    this.state = {
      displayMode: "pip",
      streamList: [],
      readyState: false,
      stateSharing: false,
    };
  }

  componentWillMount() {
    let $ = this.props;
    // init AgoraRTC local client
    HttpLog.info({
      tag: 'AGORA-CREATE-CLIENT', appId: $.appId, token: $.token, channel: $.channel,
      uid: $.uid, mode: $.transcode, attendeeMode: $.attendeeMode, videoProfile: $.videoProfile
    });
    console.log($);
    this.client = AgoraRTC.createClient({ codec: $.transcode,mode:'rtc' });
    this.client.init($.appId, () => {
      console.log("AgoraRTC client initialized");
      this.subscribeStreamEvents();
      console.log($.token);
      this.client.join($.token, $.channel, $.uid, (uid) => {
        this.state.uid = uid;
        console.log("User " + uid + " join channel successfully");
        console.log("At " + new Date().toLocaleTimeString());
        // create local stream
        // It is not recommended to setState in function addStream
        this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile);
        this.localStream.init(
          () => {
            if ($.attendeeMode !== "audience") {
              this.addStream(this.localStream, true);
              this.client.publish(this.localStream, (err) => {
                console.log("Publish local stream error: ", err);
                HttpLog.error({ tag: 'AGORA-PUBLISH-STREAM', err });
              });
            }
            this.setState({ readyState: true });
          },
          (err) => {
            console.log("getUserMedia failed", err);
            this.setState({ readyState: true });
            HttpLog.error({ tag: 'AGORA-INIT-STREAM', err });
          }
        );
      },
        (err) => {
          console.log("client join failed", err);
          HttpLog.error({ tag: 'AGORA-JOIN-CLIENT', err });
        }
      );
    },
      (err) => {
        console.log("client init failed", err);
        HttpLog.error({ tag: 'AGORA-INIT-CLIENT', err });
      }
    );
  }

  componentDidMount() {
    // add listener to control btn group
    let canvas = document.querySelector("#ag-canvas");
    let btnGroup = document.querySelector(".ag-btn-group");
    btnGroup.classList.add("active");
    // canvas.addEventListener("mousemove", () => {
    //   if (global._toolbarToggle) {
    //     clearTimeout(global._toolbarToggle);
    //   }
    //   btnGroup.classList.add("active");
    //   global._toolbarToggle = setTimeout(function () {
    //     btnGroup.classList.remove("active");
    //   }, 2000);
    // });
  }
  setVideo = (role) => {

  }
  setAgoraCanvas = (role, item, index) => {
    let canvas = document.querySelector("#ag-canvas");
    const roleMap = {
      'PROCTOR_SELF': () => {
        let id = item.getId();
        let container = document.querySelector('#audioPlayer');
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          container.style.visibility = 'hidden';
          container.appendChild(dom);
          item.play("ag-item-" + id);
          // (async()=>await item.play("ag-item-" + id))();
        }

      },
      'PROCTOR_OTHER': () => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          item.play("ag-item-" + id);

          // (async()=>await item.play("ag-item-" + id))();
          dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);

        }
      },
      'TESTER_SELF': () => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          // (async()=>await item.play("ag-item-" + id))();
          item.play("ag-item-" + id);

        }

        // dom.setAttribute(
        //   "style",
        //   `grid-area: span 3/span 4/${4 + 3 * index}/25;
        //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
        // );
        dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
      },
      'TESTER_OTHER': () => {
        let id = item.getId();
        let container = document.querySelector('#audioPlayer');
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          container.style.visibility = 'visible';
          container.appendChild(dom);
          // (async()=>await item.play("ag-item-" + id))();
          item.play("ag-item-" + id);
        }
      }

      // dom.setAttribute(
      //   "style",
      //   `grid-area: span 3/span 4/${4 + 3 * index}/25;
      //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
      // );
      // dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);

    }
    return roleMap[role]();
  }
  // componentWillUnmount () {
  //     // remove listener
  //     let canvas = document.querySelector('#ag-canvas')
  //     canvas.removeEventListener('mousemove')
  // }
  //if tester always show video in large           dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
  //if doctor always show video in large           dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
  startRecording = async () => {
    const { fetchRecordStatus, channel } = this.props;
    try {
      await startRecordMutation(channel);
      fetchRecordStatus(channel);
    } catch (e) {

    }
  }
  showRecordingIcon = () => {
    const { role, recording } = this.props;
    if (role != 'PROCTOR') return '';
    return <div className={`recording ${recording ? 'recording' : 'notRecording'} `}>
      <div id="recordingCircle"></div>
      <span>{recording ? 'Recording ...' : <div>Recording fails<span style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 10 }} onClick={this.startRecording}>Record</span></div>}</span>
    </div>;
  }
  componentDidUpdate(prevProps, prevState) {
    // rerendering
    let canvas = document.querySelector("#ag-canvas");
    let { role, setProctorStatus, proctorStatus } = this.props;
    let { setAgoraCanvas } = this;
    if (!!setProctorStatus && this.state.streamList.length !== prevState.streamList.length) {
      const status = this.state.streamList.length > 1 ? 'ONLINE' :
        (proctorStatus !== 'CHANGING' && proctorStatus !== 'HUNGUP' ? 'OFFLINE' : proctorStatus);
      setProctorStatus(status);
    }
    // pip mode (can only use when less than 4 people in channel)
    if (this.state.displayMode === "pip") {
      let no = this.state.streamList.length;
      if (no > 4) {
        this.setState({ displayMode: "tile" });
        return;
      }
      this.state.streamList.map(async (item, index) => {
        // let id = item.getId();
        // let dom = document.querySelector("#ag-item-" + id);
        // if (!dom) {
        //   dom = document.createElement("section");
        //   dom.setAttribute("id", "ag-item-" + id);
        //   dom.setAttribute("class", "ag-item");
        //   canvas.appendChild(dom);
        //   item.play("ag-item-" + id);
        // }
        if (index === no - 1) {
          setAgoraCanvas(role + '_SELF', item, index);
          // let id = item.getId();
          // let dom = document.querySelector("#ag-item-" + id);
          // if (!dom) {
          //   dom = document.createElement("section");
          //   dom.setAttribute("id", "ag-item-" + id);
          //   dom.setAttribute("class", "ag-item");
          //   canvas.appendChild(dom);
          //   await item.play("ag-item-" + id);
          // }
          //
          // // dom.setAttribute(
          // //   "style",
          // //   `grid-area: span 3/span 4/${4 + 3 * index}/25;
          // //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          // // );
          // dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
        } else {
          setAgoraCanvas(role + '_OTHER', item, index);
          // let id = item.getId();
          // let container = document.querySelector('#audioPlayer');
          // let dom = document.querySelector("#ag-item-" + id);
          // if (!dom) {
          //   dom = document.createElement("section");
          //   dom.setAttribute("id", "ag-item-" + id);
          //   dom.setAttribute("class", "ag-item");
          //   container.style.visibility = 'visible';
          //   container.appendChild(dom);
          //   await item.play("ag-item-" + id);
          // }
          // // dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
          // dom.setAttribute(
          //   "style",
          //   `grid-area: span 3/span 4/${4 + 3 * index}/25;
          //           z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          // );
        }
        item.player.resize && item.player.resize();

      });
    }
    // tile mode
    else if (this.state.displayMode === "tile") {
      let no = this.state.streamList.length;
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          item.play("ag-item-" + id);
        }
        dom.setAttribute("style", `grid-area: ${tile_canvas[no][index]}`);
        // item.player.resize && item.player.resize();
      });
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === "share") {
    }
  }

  componentWillUnmount() {
    this.client && this.client.unpublish(this.localStream);
    this.localStream && this.localStream.close();
    if (this.state.stateSharing) {
      this.shareClient && this.shareClient.unpublish(this.shareStream);
      this.shareStream && this.shareStream.close();
    }
    this.client &&
      this.client.leave(
        () => {
          console.log("Client succeed to leave.");
        },
        () => {
          console.log("Client failed to leave.");
        }
      );
  }

  streamInit = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false,
    };

    switch (attendeeMode) {
      case "audio-only":
        defaultConfig.video = false;
        defaultConfig.audio = true;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };

  subscribeStreamEvents = () => {
    let rt = this;
    rt.client.on("stream-added", function (evt) {
      let stream = evt.stream;
      console.log("New stream added: " + stream.getId());
      console.log("At " + new Date().toLocaleTimeString());
      console.log("Subscribe ", stream);
      rt.client.subscribe(stream, function (err) {
        console.log("Subscribe stream failed", err);
      });
    });

    rt.client.on("peer-leave", function (evt) {
      console.log("Peer has left: " + evt.uid);
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      rt.removeStream(evt.uid);
    });

    rt.client.on("stream-subscribed", function (evt) {
      let stream = evt.stream;
      console.log("Got stream-subscribed event");
      console.log(new Date().toLocaleTimeString());
      console.log("Subscribe remote stream successfully: " + stream.getId());
      console.log(evt);
      rt.addStream(stream);
    });

    rt.client.on("stream-removed", function (evt) {
      let stream = evt.stream;
      console.log("Stream removed: " + stream.getId());
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      rt.removeStream(stream.getId());
    });
  };

  removeStream = (uid) => {
    this.state.streamList.map((item, index) => {
      if (item.getId() === uid) {
        item.close();
        let element = document.querySelector("#ag-item-" + uid);
        if (element) {
          element.parentNode.removeChild(element);
        }
        let tempList = [...this.state.streamList];
        tempList.splice(index, 1);
        this.setState({
          streamList: tempList,
        });
      }
    });
  };

  addStream = (stream, push = false) => {
    let repeatition = this.state.streamList.some((item) => {
      return item.getId() === stream.getId();
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        streamList: this.state.streamList.concat([stream]),
      });
    } else {
      this.setState({
        streamList: [stream].concat(this.state.streamList),
      });
    }
  };

  handleCamera = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isVideoOn()
      ? this.localStream.disableVideo()
      : this.localStream.enableVideo();
  };

  handleMic = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isAudioOn()
      ? this.localStream.disableAudio()
      : this.localStream.enableAudio();
  };

  switchDisplay = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    if (this.state.displayMode === "pip") {
      this.setState({ displayMode: "tile" });
    } else if (this.state.displayMode === "tile") {
      this.setState({ displayMode: "pip" });
    } else if (this.state.displayMode === "share") {
      // do nothing or alert, tbd
    } else {
      console.error("Display Mode can only be tile/pip/share");
    }
  };

  hideRemote = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(
      document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
    );
    list.map((item) => {
      if (item.style.display !== "none") {
        item.style.display = "none";
      } else {
        item.style.display = "block";
      }
    });
  };

  handleExit = (e) => {
    if (e.currentTarget.classList.contains("disabled")) {
      return;
    }
    try {
      this.client && this.client.unpublish(this.localStream);
      this.localStream && this.localStream.close();
      if (this.state.stateSharing) {
        this.shareClient && this.shareClient.unpublish(this.shareStream);
        this.shareStream && this.shareStream.close();
      }
      this.client &&
        this.client.leave(
          () => {
            console.log("Client succeed to leave.");
          },
          () => {
            console.log("Client failed to leave.");
          }
        );
    } finally {
      this.setState({ readyState: false });
      this.client = null;
      this.localStream = null;
      // redirect to index
      window.location.hash = "";
    }
  };

  // sharingScreen = (e) => {
  //   if (this.state.stateSharing) {
  //     this.shareClient && this.shareClient.unpublish(this.shareStream);
  //     this.shareStream && this.shareStream.close();
  //     this.state.stateSharing = false;
  //   } else {
  //     this.state.stateSharing = true;
  //     let $ = this.props;
  //     // init AgoraRTC local client
  //     this.shareClient = AgoraRTC.createClient({ mode: $.transcode });
  //     this.shareClient.init($.appId, () => {
  //       console.log("AgoraRTC client initialized");
  //       this.subscribeStreamEvents();
  //       this.shareClient.join($.appId, $.channel, $.uid, (uid) => {
  //         this.state.uid = uid;
  //         console.log("User " + uid + " join channel successfully");
  //         console.log("At " + new Date().toLocaleTimeString());
  //         // create local stream
  //         // It is not recommended to setState in function addStream
  //         this.shareStream = this.streamInitSharing(
  //           uid,
  //           $.attendeeMode,
  //           $.videoProfile
  //         );
  //         this.shareStream.init(
  //           () => {
  //             if ($.attendeeMode !== "audience") {
  //               this.addStream(this.shareStream, true);
  //               this.shareClient.publish(this.shareStream, (err) => {
  //                 console.log("Publish local stream error: " + err);
  //               });
  //             }
  //             this.setState({ readyState: true });
  //           },
  //           (err) => {
  //             console.log("getUserMedia failed", err);
  //             this.setState({ readyState: true });
  //           }
  //         );
  //       });
  //     });
  //   }
  // };

  // streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
  //   let defaultConfig = {
  //     streamID: uid,
  //     audio: true,
  //     video: false,
  //     screen: true,
  //   };
  //
  //   switch (attendeeMode) {
  //     case "audio-only":
  //       defaultConfig.video = false;
  //       break;
  //     case "audience":
  //       defaultConfig.video = false;
  //       defaultConfig.audio = false;
  //       break;
  //     default:
  //     case "video":
  //       break;
  //   }
  //
  //   let stream = AgoraRTC.createStream(merge(defaultConfig, config));
  //   stream.setVideoProfile(videoProfile);
  //   return stream;
  // };

  render() {
    const style = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
    };
    // const videoControlBtn =
    //   this.props.attendeeMode === "video" ? (
    //     <span
    //       onClick={this.handleCamera}
    //       className="ag-btn videoControlBtn"
    //       title="Enable/Disable Video"
    //     >
    //       <i className="ag-icon ag-icon-camera"></i>
    //       <i className="ag-icon ag-icon-camera-off"></i>
    //     </span>
    //   ) : (
    //     ""
    //   );
    //
    const audioControlBtn =
      this.props.attendeeMode !== "audience" ? (
        <span
          onClick={this.handleMic}
          className="ag-btn audioControlBtn"
          title="Enable/Disable Audio"
        >
          <img className='ag-icon ag-icon-mic' src={'/image/microphone_on.png'} alt='on' width='80px' style={{ padding: '20px' }} />
          <img className='ag-icon ag-icon-mic-off' src={'/image/microphone_mute.png'} alt='mute' width='80px' style={{ padding: '20px' }} />
        </span>
      ) : (
        ""
      );
    let isTester = this.props.role === 'TESTER';
    return (
      <div id="ag-canvas" style={style}>
        {this.showRecordingIcon()}
        <div className="ag-btn-group">
          {audioControlBtn}
        </div>
      </div>
    );
  }
}

export default AgoraCanvas;
