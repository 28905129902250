import React from "react";
import {Form, Input, Tabs, Button, Col, Row, Modal, message} from 'antd';
import {profileFormMap, proctorProfileFormMap} from "../../SignUp/constant/signUpForm";
import ChangePwdLinkComponent from "./ChangePwdLinkComponent";
import { userUpdateMutation } from '../../API/query/UpdateProfile';
import BillingInfoComponent from '../component/BillingInfoComponent';
import CreditCardInputComponent from '../component/CreditCardInputComponent';
import { FORMKEY_TO_USERKEY,roleMap } from '../constant/index'
import { get } from 'lodash';
import 'antd/lib/message/style/index.css'
import {getUserRole } from '../../auth/helper/index';
const { TabPane } = Tabs;

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}
let SettingComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {
			showModal: false,
			showCCModal: false,
			activeKey:'1'
		}
	}
	componentDidMount() {
		this.props.form.validateFields();
	}

	setInputValue=(type,value)=>{
		this.setState({
			[type]:value
		})
	}

	setCCError = (error)=>{
		this.setState({
			CCError: error
		})
	}

	onClickSave=()=>{
		const { setUser } = this.props
		this.props.form.validateFields((err, values) => {
			if(!err){
				userUpdateMutation(values)
					.then(res=>{
						let parsedObj = {};
						for( let [key,value] of Object.entries(values)) {
							parsedObj[FORMKEY_TO_USERKEY[key]] = value
						}
						setUser(parsedObj);
						message.info('Setting Updated')
					})
			}
		});
	}

	companyProfileForm = ()=>{
			const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
			let { user } = this.props;
			const companynameError = isFieldTouched('companyName') && getFieldError('companyName');
			const disabled = hasErrors(getFieldsError());
			return (
				<Form onSubmit={this.handleSubmit} className={'settingsForm'}>
					<Form.Item validateStatus={companynameError ? 'error' : ''} help={companynameError || ''} label="Company Name">
						{getFieldDecorator('companyName', {
							initialValue:get(user,FORMKEY_TO_USERKEY['companyName']),
							rules: [{ required: true, message: 'Please input your Company Name!' }],
						})(
							<Input />,
						)}
					</Form.Item>
					<Form.Item label='Company Website'>
						{getFieldDecorator('companyWebsite',{initialValue:get(user,FORMKEY_TO_USERKEY['companyWebsite'])})(<Input />)}
					</Form.Item>
					<Form.Item>
						<Button disabled={disabled} onClick={()=>this.onClickSave()}>Save</Button>
					</Form.Item>
				</Form>
			);

	}

	userProfileForm =(disabledForm)=>{
			const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
			let { user } = this.props;
			const role = getUserRole();
			const fromMap = roleMap[role] === 'proctor'? proctorProfileFormMap : profileFormMap;
			let formItemList = [];
			const disabled = hasErrors(getFieldsError());
			for(let [index,array] of Object.entries(fromMap(this))) {
				let itemsInRow = [];
				if(Array.isArray(array)){
					for(let [colIndex,item] of Object.entries(array)) {
						let { rules,render,col,key,label,offset } = item;
						const error = isFieldTouched(key) && getFieldError(key);
						let initialValue = get(user,FORMKEY_TO_USERKEY[key]);
						itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
							<Form.Item validateStatus={error ? 'error':''}
							           help={error || ''}label={label} key={key}>{ getFieldDecorator(key,{ rules,initialValue } )(render({disabled:disabledForm})) }</Form.Item>
						</Col>)
					}
				}
				formItemList.push(<Row key={index}>{itemsInRow}</Row>)
			}

			return <Form className={'settingsForm'} layout={'horizontal'} onSubmit={this.handleSubmit} >
				{formItemList}
					<Form.Item>
					{disabledForm ? '' : <Button onClick={()=>this.onClickSave()}  style={{ marginTop:30 }} disabled={disabled}>Save</Button>}
					</Form.Item>
				<div onClick={()=>this.setShowModal(true)} className={'changePWLink'}>Change Password</div>
			</Form>
	}

	setShowModal = (showModal)=>{
		this.setState({
			showModal
		})
	}

	renderModal = ()=>{
		const { prop,state,setShowModal } = this;
		const { showModal } = state;
		return <Modal visible={showModal} footer={null} onCancel={()=>setShowModal(false)}
		              width={900} title={'Change Password'}
		              maskClosable={false} keyboard={false}>
					<div className={'formWrapper'}>
						<ChangePwdLinkComponent setShowModal={ this.setShowModal} showModal={this.state.showModal}/>
					</div>
			   </Modal>;
	}

	// billingInfoForm = ()=>{
	// 	const {  state  } = this;
	// 	const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
	// 	let { user } = this.props;
	// 	let formItemList = [];
	// 	const disabled = hasErrors(getFieldsError());
	// 	for(let [index,array] of Object.entries(profileFormMap(this))) {
	// 		let itemsInRow = [];
	// 		if(Array.isArray(array)){
	// 			for(let [colIndex,item] of Object.entries(array)) {
	// 				let { rules,render,col,key,label,offset } = item;
	// 				const error = isFieldTouched(key) && getFieldError(key);
	// 				let initialValue = get(user,FORMKEY_TO_USERKEY[key]);
	// 				itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
	// 					<Form.Item validateStatus={error ? 'error':''} help={error || ''}label={label} key={key}>{ getFieldDecorator(key,{ rules,initialValue } )(render()) }</Form.Item>
	// 				</Col>)
	// 			}
	// 		}
	// 		formItemList.push(<Row key={index}>{itemsInRow}</Row>)
	// 	}
	//
	// 	return <Form className={'settingsForm'} layout={'horizontal'} onSubmit={this.handleSubmit}>
	// 		{formItemList}
	// 		<Form.Item>
	// 			<Button onClick={()=>this.onClickSave()}  style={{ marginTop:30 }} disabled={disabled}>Save</Button>
	// 		</Form.Item>
	// 		<div onClick={()=>this.setShowModal(true)} className={'changePWLink'}>Change Password</div>
	// 	</Form>
	// 	return <div>
	// 			<Form>
	// 				<CreditCardInputComponent setInputValue={this.setInputValue}
	// 					                          setCCError={this.setCCError}
	// 					                          state={state}/>
	// 				{formItemList}
	// 				<Form.Item>
	// 					<Button onClick={()=>this.onClickSave()}  style={{ marginTop:30 }} disabled={disabled}>Save</Button>
	// 				</Form.Item>
	// 			</Form>
	// 	</div>
	// }
	setShowCCModal = (showCCModal)=>{
		this.setState({
			showCCModal
		})
	}

	renderShowCCModal = ()=>{
		const { prop,state,setShowCCModal } = this;
		const { showCCModal } = state;
		return <Modal visible={showCCModal} footer={null} onCancel={()=>setShowCCModal(false)}
		              width={720} title={'Card Information'}
		              maskClosable={false} keyboard={false}>
				{ showCCModal&&<BillingInfoComponent {...this} /> }
              </Modal>;
	}

	renderTabs = ()=>{
		const { companyProfileForm,userProfileForm,renderShowCCModal,setShowCCModal } = this;
		const { activeKey } = this.state;
		let role = getUserRole();
		let companyProfile = {
			tab:'Company Profile',
			render:()=>{
				return companyProfileForm();
			}
		}
		let myProfile =(disableForm, tab='My Profile')=> ({
			tab,
			render:()=>userProfileForm(disableForm)
		})
		let paymentInformation = {
			tab:'Payment Information',
			render:()=>{
				return <React.Fragment>
					<CreditCardInputComponent/>
				</React.Fragment>
			}
		}
		let tabsByRole = (role)=>{
			switch (role) {
				case 'proctor':
					return [ myProfile(true, 'My Account') ]
				default:
					return [ companyProfile,myProfile(), paymentInformation ]
			}
		}
		const tabsPaneMap = tabsByRole(roleMap[role]).map((t,key)=>({
			key:1+key+'',
			...t
		}))
		return <Tabs defaultActiveKey={activeKey}  className={`settingTabs ${roleMap[role]}`} activeKey={activeKey}
		             onChange={(activeKey)=>this.setState({activeKey})}>
				{ tabsPaneMap.map(({key,render,tab})=>{
					return <TabPane tab={tab} key={key}>
						{ key===activeKey ? render() :'' }
					</TabPane>
				})}
			  </Tabs>
	}
	render() {
		const { renderTabs,renderModal } = this;
		const { showModal } = this.state;
		return <div>
				{ showModal && renderModal()}
				{ renderTabs() }
			   </div>
	}
}

SettingComponent = Form.create()(SettingComponent);
export default SettingComponent;