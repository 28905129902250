import React, {useEffect, useState} from "react";

import {CardElement, useElements, useStripe,PaymentElement} from "@stripe/react-stripe-js";
import {message, Input, Row, Col, Checkbox, Button,Form} from "antd";
import {checkoutDirectly, checkOutWithPaymentId} from '../../API/query/Order';
import SavedCreditCardComponent from "./SavedCreditCardComponent";
import CardPaymentWrapperComponent from "../../payment/component/CardPaymentWrapperComponent";
import PayForOrderButtonComponent from "../../payment/component/PayForOrderButtonComponent";
import { get } from 'lodash';
function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}

let CreditCardComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {
			saveCard: true,
			cardInput: { }
		}
	}

	setCardInput =(cardInput)=>{
		this.setState({
			cardInput
		})
	}

	onSubmit = (event)=>{
		const { saveCard  } = this.props;
		event.preventDefault();
	}
	orderPaidSucceed = ()=>{
		message.info('Order Paid Successfully')
	}
	componentDidMount() {
		this.props.form.validateFields();
	}

	setSaveCard = (saveCard)=>{
		this.setState({
			saveCard
		})
	}
	render(){
		const { clientSecret,setSaveCard,orderId,payment,form,setShowMakeOrder,orderType,creationOrderInfo,initialStripe,orderDetails,subscription,setActiveKey } = this.props;
		const { saveCard,cardInput } = this.state;
		const { complete } = cardInput
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = form;
		const firstNameError = !!isFieldTouched('firstName') && getFieldError('firstName');
		const lastNameError = !!isFieldTouched('lastName') && getFieldError('lastName');
		const streetError = !!isFieldTouched('street') && getFieldError('street');
		const cityError = !!isFieldTouched('city') && getFieldError('city');
		const stateError = !!isFieldTouched('state') && getFieldError('state');
		const zipCodeError = !!isFieldTouched('zipcode') && getFieldError('zipcode');
		const ccError = get(cardInput,'error.message');
		const disabled = hasErrors(getFieldsError()) || !complete;

		if(payment.length){
			return <div>
				<div className={'title'}>Saved Card:</div>
				<SavedCreditCardComponent payment={payment}/>
				<div className="space"></div>
				<PayForOrderButtonComponent form={form} initialStripe={initialStripe}
				                            orderDetails={orderDetails}
				                            subscription={subscription}
				                            haveCC={true}  creationOrderInfo={creationOrderInfo} orderType={ orderType } saveCard={saveCard} orderId={orderId} setShowMakeOrder={setShowMakeOrder} setActiveKey={setActiveKey}/>
			</div>
		}
		return (
			<Form>
				<Row>
					<Col span={24}>
						<h3>Card Information</h3>
						<Row>
							<Col span={10}>
								<Form.Item label={'First Name'} validateStatus={firstNameError ? 'error' : ''} help={firstNameError || ''}>
								{ getFieldDecorator('firstName', {rules: [{ required: true, message: 'Please enter your Firs tName!' }] })(
									<Input placeholder={'First Name'}/>,
								)}
							</Form.Item>
							</Col>
							<Col span={12} offset={1}>
								<Form.Item label={'Last Name'} validateStatus={lastNameError ? 'error' : ''} help={lastNameError || ''}>
								{ getFieldDecorator('lastName', {rules: [{ required: true, message: 'Please enter your Last Name!' }] })(
									<Input placeholder={'Last Name'}/>,
								)}
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item label={'Credit Card'} validateStatus={ccError ? 'error' : ''} help={ccError || ''}>
									{/*<Row><div className={'ant-col ant-form-item-label title'}><label className={'ant-form-item-required'}>Card Info:</label></div></Row>*/}
									{/*<CardPaymentWrapperComponent clientSecret={clientSecret} saveCard={ saveCard} setSaveCard={setSaveCard} orderId={orderId}/>*/}
									{ getFieldDecorator('creditCard')(
										<CardPaymentWrapperComponent clientSecret={clientSecret} saveCard={ saveCard} setSaveCard={setSaveCard} orderId={orderId} setCardInput={ this.setCardInput}/>
									)}
									{/*{ccError}*/}
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<h3>Billing Information</h3>
					<Col span={24}>
						<Row>
							<Col span={23}>
								<Form.Item label={'Country'}>
									{ getFieldDecorator('country', { initialValue:'United State',rules: [{ required: true, message: 'Please enter country!' }] })(
										<Input placeholder={'Country'} disabled={true}/>,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={23}>
								<Form.Item label={'Street'} validateStatus={streetError ? 'error' : ''} help={streetError || ''}>
									{ getFieldDecorator('street', {rules: [{ required: true, message: 'Please enter street!' }] })(
										<Input placeholder={'Street'}/>,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={23}>
								<Form.Item label={'Apt, Suite, Building (Optional)'}>
									{ getFieldDecorator('apt')(
										<Input placeholder={'Apt, Suite, Building (Optional)'}/>,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={7}>
								<Form.Item label={'City'} validateStatus={cityError ? 'error' : ''} help={cityError || ''}>
									{ getFieldDecorator('city', {rules: [{ required: true, message: 'Please enter city!' }] })(
										<Input placeholder={'City'}/>,
									)}
								</Form.Item>
							</Col>
							<Col span={7} offset={1}>
								<Form.Item label={'State'} validateStatus={stateError ? 'error' : ''} help={stateError || ''}>
									{ getFieldDecorator('state', {rules: [{ required: true, message: 'Please enter state!' }] })(
										<Input placeholder={'State'} />,
									)}
								</Form.Item>
							</Col>
							<Col span={7} offset={1}>
								<Form.Item label={'Zipcode'} validateStatus={zipCodeError ? 'error' : ''} help={zipCodeError || ''}>
									{ getFieldDecorator('zipcode', {rules: [{ required: true, message: 'Please enter zipcode!' }] })(
										<Input placeholder={'zipcode'}/>,
									)}
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
				<Form.Item>
					<PayForOrderButtonComponent form={form} saveCard={saveCard}  creationOrderInfo={creationOrderInfo}
					                            orderDetails={orderDetails} subscription={subscription}
					                            disabled={disabled} orderType={ orderType } initialStripe={initialStripe}
					                            haveCC={false} clientSecret={clientSecret} orderId={orderId} setShowMakeOrder={setShowMakeOrder} setActiveKey={setActiveKey}/>
				</Form.Item>
			</Form>
		);
	}
}
CreditCardComponent = Form.create()(CreditCardComponent);
export default CreditCardComponent;