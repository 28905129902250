import React from "react";
import {get} from "lodash";
import {Col, Row} from "antd";
import {centsToDollar} from "../../lib/helpers";

const OrderPriceComponent = (props)=>{
	const { priceDetails } = props;
	const showPriceDetails = ()=>{
		const { subTotal,total,total_tax,items=[],discount,taxDetails =[], serviceFee,serviceFeeDiscount} = priceDetails;
		let peopleCount = taxDetails.length;
		let qtPerPerson = get(taxDetails,'0.quantity',0);
		let totalCount = peopleCount * qtPerPerson;
		return <div className={'orderDetailedInfo'} style={{lineHeight: '30px',textAlign:'start' }}>
			<Row>
				<Col span={12} className={'title'}>Qt:</Col>
				<Col span={12}><div className="totals-value" id="cart-subtotal">{totalCount} Pack(s) ({peopleCount} recipients, {qtPerPerson} packs/recipient)</div></Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>Subtotal:</Col>
				<Col span={12} className={'value'}>{centsToDollar(subTotal)}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>Discount:</Col>
				<Col span={12} className={'value'}>-{centsToDollar(discount)}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'}>Shipping:</Col>
				<Col span={12} className={'value'}>Free</Col>
			</Row>
			{centsToDollar(serviceFee-serviceFeeDiscount) > 0 &&
				<Row>
					<Col span={12} className={'title'}>Service Fee:</Col>
					<Col span={12} className={'value'}>{centsToDollar(serviceFee-serviceFeeDiscount)}</Col>
				</Row>
			}
			<Row>
				<Col span={12} className={'title'}>Tax:</Col>
				<Col span={12} className={'value'}>{centsToDollar(total_tax)}</Col>
			</Row>
			<Row>
				<Col span={12} className={'title'} style={{ fontWeight:900 }}>Order Total:</Col>
				<Col span={12} className={'value'} style={{ fontWeight:900 }}>{centsToDollar(total)}</Col>
			</Row>

			{/*<div className="totals-item">*/}
			{/*	<label>Tax</label>*/}
			{/*	<div className="totals-value" id="cart-tax">{centsToDollar(total_tax)}</div>*/}
			{/*</div>*/}
			{/*<div className="totals-item totals-item-total">*/}
			{/*	<label>Total</label>*/}
			{/*	<div className="totals-value" id="cart-total">{centsToDollar(total)}</div>*/}
			{/*</div>*/}
		</div>
	}
	return showPriceDetails();
}
export default OrderPriceComponent