import {deleteRequest, getRequest} from "../helper";
import { postRequest } from "../helper";
import { putRequest } from "../helper";
import {getLoginUser} from "../../auth/helper";

const HEADER =()=>({
	"Authorization": `kit ${getLoginUser()}`,
	"Accept": "*/*",
	"Content-Type":'application/json'
})

const CREATE_ORDER_URL = '/api/enterprise/orders';
const CREATE_SUBSCRIPTION_URL ='/api/enterprise/user/subscriptions';
const GET_ORDER_URL = (page=1,size=10,status='', {sort='created_At',order='desc'})=> `/api/enterprise/orders?page=${page}&size=${size}&status=${status}&sort=${sort}&order=${order}`;
const GET_SUBSCRIPTIONS_URL = '/api/enterprise/user/subscriptions?status=created';
const GET_SUBSCRIPTIONBYID_URL = (id)=>`/api/enterprise/user/subscriptions/${id}`
const GET_ORDER_BY_ID = (id)=>`/api/enterprise/orders/${id}`
const DELETE_ORDER =(id)=>`/api/enterprise/orders/${id}`;
const CONTACT_GROUP_BY_ID = (id, page)=>`/api/enterprise/user/contact-groups/${id}/list?size=10&page=${page}`;
const DISSOCIATE_USER_FROM_GROUP =(id)=> `api/enterprise/user/contact-groups/${id}/dissociate`
const ORDER_PRICE_DETAILS = `/api/enterprise/orders/price-details`;
const GROUP_ORDER_DETAILS = `api/enterprise/orders/price-details`;
const SUB_ORDER_LIST = (id, page)=>`/api/enterprise/orders/sub-orders?orderId=${id}&size=10&page=${page}`
const CHECK_OUT_ORDER_WITH_PAYMENT = (orderId,paymentId)=>`/api/enterprise/orders/${orderId}/checkout?paymentMethod=${paymentId}`;
const CHECK_OUT_ORDER_DIRECTLY = (orderId)=>`/api/enterprise/orders/${orderId}/checkout`;
const DELETE_PAYMENT_METHOD = (id)=>`/api/enterprise/payment/stripe/payment-methods/${id}`;
const UPDATE_ORDER_URL = (id)=>`/api/enterprise/orders/${id}`
const UPDATE_SUBSCRIPTION_URL = (id)=>`/api/enterprise/user/subscriptions/${id}`;
const CLOSE_ORDER_URL = (id)=>`/api/enterprise/orders/${id}/close`;
const REMAKE_ORDER_URL = (id)=>`/api/enterprise/orders/${id}/remake`;
const MAKE_ORDER_ON_SUBSCRIPTION = (id)=>`/api/enterprise/subscriptions/${id}/make-order`;
const SKU_LIST_URL = '/api/enterprise/sku';

export const createOrderMutation = (data)=>postRequest(CREATE_ORDER_URL,{ headers:HEADER(),data });
export const createSubscriptionMutation = (data)=>postRequest(CREATE_SUBSCRIPTION_URL,{ headers:HEADER(), data })
export const getOrdersQuery = (page,size,status,sort)=>getRequest(GET_ORDER_URL(page,size,status,sort),{ headers:HEADER() } );
export const getOrderById = (id)=>getRequest(GET_ORDER_BY_ID(id),{ headers: HEADER() });
export const getSubscriptions =()=> getRequest(GET_SUBSCRIPTIONS_URL,{ headers: HEADER() })
export const getSubById = (id)=>getRequest(GET_SUBSCRIPTIONBYID_URL(id),{ headers: HEADER() });
export const getContactListByGroupId = (id, page)=>getRequest(CONTACT_GROUP_BY_ID(id, page),{ headers: HEADER() });
export const stopSubscription = (id)=> deleteRequest(GET_SUBSCRIPTIONBYID_URL(id),{ headers: HEADER() });
export const dissociateContacts = (id,data)=> postRequest(DISSOCIATE_USER_FROM_GROUP(id),{ headers:HEADER(),data })
export const getOrderPriceDetails = (data)=>postRequest(ORDER_PRICE_DETAILS,{ headers: HEADER(), data});
export const estimateGroupOrderPriceDetails = (data)=>postRequest(GROUP_ORDER_DETAILS,{ headers:HEADER(), data});
export const getSubOrderList = (id, page)=>getRequest(SUB_ORDER_LIST(id, page),{ headers:HEADER()});
export const checkOutWithPaymentId = (orderId,paymentId)=>postRequest(CHECK_OUT_ORDER_WITH_PAYMENT(orderId,paymentId),{ headers:HEADER() });
export const checkoutDirectly = (orderId)=>postRequest(CHECK_OUT_ORDER_DIRECTLY(orderId),{ headers:HEADER() })
export const deleteCard = (id)=>deleteRequest(DELETE_PAYMENT_METHOD(id),{ headers: HEADER() })
export const getSkuList =()=> getRequest(SKU_LIST_URL,{ headers: HEADER() });
export const updateOrderMutation = (id,data)=>putRequest(UPDATE_ORDER_URL(id),{ headers: HEADER(),data });
export const updateSubscriptionMutation = (id,data)=>putRequest(UPDATE_SUBSCRIPTION_URL(id),{ headers:HEADER(),data })


export const deleteOrderMutation = (id)=>deleteRequest(DELETE_ORDER(id),{ headers: HEADER() })
export const closeOrderMutation= id =>postRequest(CLOSE_ORDER_URL(id),{ headers: HEADER()  });

export const remakeOrderMutation = id=>postRequest(REMAKE_ORDER_URL(id),{ headers:HEADER() });
export const makeOrderBasedOnSubscription = id=>postRequest(MAKE_ORDER_ON_SUBSCRIPTION(id),{ headers:HEADER() });