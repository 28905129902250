import React from 'react';
import { Icon } from 'antd';

const WhatToDoNextComponent = () => {
    return <div style={{border: '1px solid #2D3238', margin:0}}>
        <h3 style={{textAlign: 'center', background: '#2D3238', color: '#fff', padding: '19px 0', margin:0, fontWeight: 700}}>What to do next?</h3>
        <div style={{padding: '2em'}}>
            <h4><span style={{display:'inline-block', borderRadius:'50%', background: '#2D3238', width: 30, height: 30, textAlign: 'center', color: '#fff',verticalAlign: 'middle', lineHeight: '30px', marginRight: '15px'}}>1</span> Join the appointment</h4>
            <div style={{borderLeft:'1px solid #2D3238', padding: '1.5rem 0.5rem', margin: '-1.5rem 15px'}}>
                <ul>
                    <li style={{marginBottom: '1rem'}}><p>
                        At the scheduled time, have these 2 things ready for your appointment: 
                        </p>
                        <div style={{display: 'flex', alignItems: 'stretch', gap: '2rem', flexWrap: 'wrap'}}>
                        <div style={{maxWidth: 220, textAlign: 'center', background: '#F4F6F8', padding: '1.5rem', border: '1px solid #B5B9BE', borderRadius: '4px'}}>
                                <img src="https://cdn.shopify.com/s/files/1/0428/9406/5826/files/testkit.png" alt="iHealth COVID-19 Antigen Rapid Test Kit" width="160" height="120"/>
                                <p style={{fontSize:'14px', lineHeight:'20px', marginTop:'20px', marginBottom: 0}}>An iHealth COVID-19 <br />Antigen Rapid Test Kit</p>
                            </div>
                            <div style={{maxWidth: 220, textAlign: 'center', background: '#F4F6F8', padding: '1.5rem', border: '1px solid #B5B9BE', borderRadius: '4px'}}>
                                <img src="https://cdn.shopify.com/s/files/1/0428/9406/5826/files/id.png" alt="Photo ID" height="104" style={{marginTop:'8px'}}/>
                                <p style={{fontSize:'14px', lineHeight: '20px', marginTop:'20px', marginBottom: 0}}>Valid Photo ID<br/>
                                    (ID should contain: Photo,<br/> First Name, Last name, and<br/>Date of birth.)</p>
                            </div>
                        </div>
                        <div  style={{display: 'flex', paddingTop: 30}}>
                            <img src="https://cdn.shopify.com/s/files/1/0428/9406/5826/files/Attention.png" alt="warning" width="20" height="20" />
                            <p style={{color: '#DA6453', margin:'0 8px'}}>Please note that test kits <strong>from other manufacturers</strong>, or iHealth test kits that are <strong>opened</strong> are <strong>NOT</strong> acceptable. </p>
                        </div>
                    </li>
                    <li style={{marginBottom: "1rem"}}>Click the <strong>link</strong> to join the meeting.</li>
                    <li style={{marginBottom: "1rem"}}>The whole appointment is via a virtual meeting that takes around 25 minutes.</li>
                    <li>Our iHealth proctor will guide you to collect the sample, perform the test, and interpret your test result.</li>
                </ul>
            </div>
            <h4><span style={{display:'inline-block', borderRadius: '50%', background: '#2D3238', width: '30px', height: '30px', textAlign: 'center', color: '#fff', verticalAlign: 'middle', lineHeight: '30px', marginRight: 15}}>2</span> After the appointment</h4>
            <ul style={{marginLeft: '1.5rem'}}>
                <li style={{marginBottom: "1rem"}}>The test report will be verified by iHealth, and your proof of a certified test result will be sent to your email within 5 minutes after the appointment.</li>
                <li>If your test result is negative, you can take this report with you to board your flight into the U.S. The clinically verified test meets the CDC's requirements when traveling to the U.S.</li>
            </ul>
        </div>
      </div>
}

export default WhatToDoNextComponent;
