/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import AddPeopleComponent from '../component/AddPeopleComponent';
import {useNavigate, useParams} from 'react-router-dom';
import {
	getSubById,
	getContactListByGroupId,
	stopSubscription,
	dissociateContacts
} from "../../API/query/Order";
import {Icon, Table, Modal, Button, message,Popconfirm,Row,Col} from "antd";
import '../style/index.scss';
import {get} from "lodash";
import ProductContentComponent from '../../payment/component/ProductContentComponent';
import { useSelector } from 'react-redux';


const SingleSubscriptionContainer = ()=>{
	const {product} = useSelector(state => state.orderReducer);
	const navigate = useNavigate();
	const [subscription, setSubscription] = React.useState({});
	const [ contactList,setContactList ] = React.useState([]);
	const [ showAddPeople,setShowAddPeople ] = React.useState(false);
	const [ contactsToRemove,setContactsToRemove ] = React.useState([]);
	const [ selectedRows, setSelectedRows ] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [total, setTotal] = React.useState(0);
	const { id } = useParams();
	const { contactGroupId } = subscription;

	const useFetch = (url, options) => {
		React.useEffect(async () => {
			let ordersResponse = await getSubById(id);
			let code = ordersResponse.status;
			if(code==200) {
				setSubscription(ordersResponse.data);
				const id = ordersResponse.data.contactGroupId;
				let contactListResponse = await getContactListByGroupId(id, page);
				let { data } = contactListResponse;
				let { code }  = data;
				let { totalCount=0 } = data.summary;
				if( code == 200 ){
					setContactList(data.data);
					setTotal(totalCount);
				}
			}
		},[page]);
	};

	useFetch();
	

	const renderNextOrderInfo = ()=>{
			if(subscription) {
				const {nextDate = ' ', frequency, frequencyUnit,quantity,name} = subscription;
				const nextDateStr = nextDate.split(' ')[0];
				const [year, month, day] = nextDateStr.split('-');
				return <div className={'nextOrderInfoWrapper'}>
						<Row>
							<Col><div style={{ fontSize:20, fontWeight: 600 }}>Subscription Details</div></Col>
							<Col span={8}>
								{orderDetailsBottom()}
							</Col>
							<Col span={8} style={{ borderLeft: '2px solid #E7EAED',paddingLeft:40 }}>
								<Row>
									<Col span={10} className={'title'}>Subscription Name:</Col>
									<Col span={14} className={'value'}>{name}</Col>
								</Row>
								<Row>
									<Col span={10} className={'title'}>Subscription plan:</Col>
									<Col span={14} className={'value'}>{`${quantity} packs/${frequency > 1? frequency: ''} ${frequencyUnit}`}</Col>
								</Row>
								<Row>
									<Col span={10} className={'title'}>Recipient:</Col>
									<Col span={14} className={'value'}>{total}</Col>
								</Row>
							</Col>
							<Col span={8}>
								<Row>
									<Col span={10} className={'title'}>Next ship date:</Col>
									<Col span={14} className={'value'}>{`${month}/${day}/${year}`}</Col>
								</Row>
							</Col>
						</Row>
						<Row><Col span={24}>{ subscriptionSummary() }</Col></Row>
				</div>
			}
			return <div></div>
	}

	const backBtn = ()=>{
		return <div style={{ display:'flex',alignItems:'center'}}>
				<Icon type="left-square" onClick={()=>navigate('/orders')} style={{ fontSize:28,alignSelf:'start',marginRight:20 }}/>
				<span style={{ fontWeight: 900 }}>My Subscription</span>
			   </div>
	}

	const cancelSubscription = (id)=>{
		stopSubscription(id)
			.then(res=>{
				message.info('Subscription Canceled');
				navigate('/orders')
			});
	}

	const subscriptionSummary = ()=>{
		const { id } = subscription;
		return <div className={'subscriptionSummary'} >
			<Popconfirm title={'Canceled subscription will be deleted.'} onConfirm={()=>cancelSubscription(id)} cancelText={'Not now'}
				okText={'Yes, cancel subscription'}
				>
				
				<div style={{ textDecoration:'underline',cursor:'pointer' }}>Cancel Subscription</div>
			</Popconfirm>
		</div>
	}

	const handleRemoveContacts = async ()=>{

		try{
			let res = await dissociateContacts(contactGroupId,contactsToRemove);
			const response = await getContactListByGroupId(contactGroupId, 1);
			const contacts = get(response,'data.data');
			setContactList(contacts)
			setContactsToRemove([]);
			setSelectedRows([]);
		}catch (e) {
			console.log(e);
		}
	}

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			let localContactsToRemove = [];
			selectedRowKeys.forEach(i=>{
				localContactsToRemove.push(contactList[i].id);
			})
			setContactsToRemove(localContactsToRemove);
			setSelectedRows(selectedRowKeys);
		},
		selectedRowKeys:selectedRows
	};

	const contactsTable = ()=>{
		const disabled = contactsToRemove.length == 0 ;
		let columns = [{
			key:'firstName',
			dataIndex:'firstName',
			title:'Name',
			sorter: true,
			render:(value,doc)=>{
				return doc.firstName+ ' ' + doc.lastName;
			}
		},{
			key:'email',
			dataIndex: 'email',
			title:'Email'
		},{
			key:'address',
			dataIndex: 'address',
			title: 'Address'
		},{
			key: 'phoneNumber',
			dataIndex: 'phoneNumber',
			title: 'Phone Number'
		},{
			key: 'zipCode',
			dataIndex: 'zipCode',
			title:'Zip code'
		}]

		const pagination = {
			pageSize:10,
			total:total,
			current: page,
			onChange:(p)=>setPage(p)
		}


		return <div className={'contactListWrapper'}>
				<Popconfirm title={'Are you sure to remove selected people from the subscription recipient list ?'}
				            disabled={disabled}
				            onConfirm={ handleRemoveContacts }
				>
					<Button disabled={disabled} style={{ margin:10 }}>Remove from Subscription</Button>
				</Popconfirm>
					<Table  rowSelection={rowSelection} dataSource={contactList} columns={columns} total={total} pagination={pagination}/>
			   </div>
	}
	const addPeopleBtn = ()=>{
		return <div className={'addPeopleWrapper'}>
			<div style={{marginRight:10 }}>Order for: {total} people</div>
			<Button onClick={()=>setShowAddPeople(true)}>Add recipients in subscription</Button>
		</div>
	}
	const orderDetailsBottom = ()=>{
		return <div className={'orderBottomWrapper'} style={{lineHeight: '30px' }}>
			<div className={'productWrapper'}>
				<ProductContentComponent product={product} discountRate={10000} discount={true} />
			</div>
		</div>
	}
	const renderAddPeople = ()=>{
		return showAddPeople && <Modal visible={showAddPeople} onCancel={()=>setShowAddPeople(false)} footer={false} width={1100}>
			<AddPeopleComponent setShowAddPeople={setShowAddPeople} setContactList={setContactList} subscription={subscription}/>
		</Modal>
	}

	return <div>
			<div className={'singleSubscriptionWrapper'}>
				{ backBtn() }
				{ renderNextOrderInfo() }
				{ addPeopleBtn() }
				{ contactsTable() }
				{ renderAddPeople() }
			</div>
			{/* <SingleSubscriptionComponent subscription={subscription}/> */}
		   </div>
}

export default (SingleSubscriptionContainer);