import React, {useEffect, useState} from "react";
import ImportPeopleComponent from "../component/ImportPeopleComponent";
import { getOrderById,getSkuList } from "../../API/query/Order";
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import { Row, Col } from "antd";
import { centsToDollar } from "../../lib/helpers";

const ImportPeopleContainer = (props)=> {
	const [ skuList,setSkuList ] = useState([]);
	const {product} = useSelector(state=>state.orderReducer);

	useEffect( () => {
		const fetchData = async () => {
			let res = await getSkuList();
			setSkuList(get(res,'data.data',[]))
		};
		fetchData();
	},[])

	const renderMainHeader = ()=>{

		return <div className={'mainHeader'} style={{ height: 400,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center' }}>
				<div style={{ fontSize:24,lineHeight:'29px',fontWeight:'900',marginBottom:30,color:'#2D3238' }}>Make an order</div>
				<Row type="flex" align="middle" gutter={50}>
					<Col><img src={'/image/test_kit.png'} width={300}/>
					</Col>
					<Col>
					<Row>
						<Col>
							<div className={'title'}>
								<div style={{fontSize:'20px'}}>{product.name}</div>
								<div className={'productDescription'}>{product.description}</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={'title price'}>{`${centsToDollar(product.price)}/Pack`} <sub style={{fontWeight: 'normal'}}>({product.packaging})</sub></div>
							<p className={'fastDelivery'} ><img src={'/assess/fast-delivery-icon.png'} width={20}/>Free shipping to each recipient</p>
						</Col>
					</Row>
					<Row>
						<Col>
						
						</Col>
					</Row>
					</Col>
				</Row>
		</div>
	}
	return skuList.length && <ImportPeopleComponent {...props } skuList={skuList}/>
}

export default ImportPeopleContainer;