import React from 'react';
import {Button, Input, Form, message} from "antd";
import { verityEmailCodeMutation } from '../../API/query/password';
 let SendResetPwdCodeComponent = class extends React.Component {
	componentDidMount() {
		this.props.form.validateFields();
	}

	 onClick = ()=>{
		const { setEmail ,email,setActiveKey } = this.props;
		this.props.form.validateFields(async (error,values)=>{
			if(!error){
				let res = await verityEmailCodeMutation({Email:email,Type:1});
				if(res.data.code ===40004){
					message.info(' This email is not registered');
				}else {
					setActiveKey('2');
					message.info(`A password reset link with instruction about how to reset you password was sent to: ${email}`)
				}
			}
		})
	}
	renderHeader = ()=>{
		return<div style={{ display:'flex',flexDirection:'column',textAlign:'start',marginBottom:20 }}>
			<span>Don’t worry. We get you covered to reset your password!</span>
			<span>Please enter your account email and we’ll send you a verification code to reset your password.</span>
		</div>
	}
	render() {
		const { setActiveKey,setEmail,email,form } = this.props;
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
		const disabled = getFieldError('email');
		const error = isFieldTouched('email') && getFieldError('email');
		return <div>
			{ this.renderHeader() }
			<Form>
				<Form.Item validateStatus={error ? 'error':''} help={error || '' }>
					{getFieldDecorator('email',{ rules:[{required:true},{type:'email'} ]})(<Input onChange={(e)=>setEmail(e.target.value)}/>)}
				</Form.Item>
				<Button className={'ihealth'} disabled={disabled} style={{ marginTop:20 }} onClick={this.onClick}>Send Reset Code</Button>
			</Form>
		</div>
	}
};
SendResetPwdCodeComponent = Form.create()(SendResetPwdCodeComponent);

export default SendResetPwdCodeComponent;