import React from "react";
import {changePwdFormMap} from "../../SignUp/constant/signUpForm";
import {Col, Form, Row, Button, message} from "antd";
import {updateUserPassword} from "../../API/query/password";
import {setLoginToken} from "../../auth/helper";

function hasErrors(fieldsError) {
	return Object.keys(fieldsError).some(field => fieldsError[field]);
}
let ChangePwdLinkComponent = class extends React.Component{
	constructor() {
		super();
		this.state = {
			showModal: false,
			hasErrors:false
		}
	}

	componentDidMount() {
		this.props.form.validateFields(['password','curPassword']);
	}

	formValidation = async() => {
		let { curPassword,password } = this.props.form.getFieldsValue();
		const { setShowModal } = this.props;

		let res = await updateUserPassword(null,{ OldPassWord:curPassword, newPassWord: password });
		if([40004,40002].includes(res.data.code)) {
			message.error('Current Password incorrect');
		}else if(res.data.data.token) {
			message.info('Password Updated');
			setLoginToken(res.data.data.token);
			setShowModal(false);
		}

	};
	renderForm = ()=>{
		const { setShowModal } = this.props;
		const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
		let formItemList = [];
		const disabled = hasErrors(getFieldsError());

		for(let [index,array] of Object.entries(changePwdFormMap(this))) {
			let itemsInRow = [];
			if(Array.isArray(array)){
				for(let [colIndex,item] of Object.entries(array)) {
					let { rules,render,col,key,label,offset } = item;
					const error = isFieldTouched(key)&&getFieldError(key);
					itemsInRow.push(<Col span={col} key={colIndex} offset={offset}>
						<Form.Item validateStatus={error ? 'error':''} help={error || ''}label={label}>{ getFieldDecorator(key,{ rules:rules } )(render()) }</Form.Item>
					</Col>)
				}
			}
			formItemList.push(<Row key={index}>{itemsInRow}</Row>)
		}

		return <Form className={'settingsForm'} layout={'horizontal'}>
			{formItemList}
			<Form.Item>
				<div className={'footer'}>
					<Button className={'cancel'} onClick={()=>setShowModal(false)}>Cancel</Button>
					<Button className={'save'} disabled={ disabled } onClick={()=>this.formValidation()}>Save</Button>
				</div>
			</Form.Item>
		</Form>

	}

	render() {
		return <div>
			{this.renderForm()}
		</div>
	}
}
ChangePwdLinkComponent = Form.create()(ChangePwdLinkComponent);
export default ChangePwdLinkComponent