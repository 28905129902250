import React from 'react';
import LoginButtonWrapperComponent from '../../Login/component/LoginButtonWrapperComponent';
import SignUpFormComponent from "../component/SignUpFormComponent";
const SignUpFormContainer = class extends React.Component{

	render() {
		return <div className={'signUpFormWrapper'}>
					<span className={'signupFormHeader'}>Get Started with iHealth COVID Testing Solution</span>
					<span className={'singuoFormSubheader'}>We offer the all-in-one solution for COVID test kit subscription, delivery and COVID test result management</span>
					<SignUpFormComponent/>
					<LoginButtonWrapperComponent/>
			   </div>
	}
}

export default SignUpFormContainer;