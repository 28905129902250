import React from "react";
import {Row, Col, Divider} from 'antd';
import AvailableSlotCardComponent from "./AvailableSlotCardComponent";
import moment from "moment-timezone";
import {convertUTCToTimezone} from "../../lib/helpers";

const AvailableSlotsComponent = (props)=>{
	let { slots=[],timezone ,setSelectedSlot,selectedSlot,setStatus, preview } = props;
	const isAvailable =({seatsAvailable,timeSlot})=>seatsAvailable>0 && moment.utc(timeSlot).isAfter(moment.utc());
	const hasAvailableSlot =()=>slots.some( slot=>isAvailable(slot));
	const checkIfAM = ({seatsAvailable,timeSlot})=>{
		let localTimeStr = convertUTCToTimezone(timeSlot,null,timezone.value || timezone);
		return localTimeStr.includes('AM');
	}
	const filterOutUnavailableSlots = slots.filter(s=>isAvailable(s));
	const AMSlots = filterOutUnavailableSlots.filter(s=>checkIfAM(s));
	const PMSlots = filterOutUnavailableSlots.filter(s=>!checkIfAM(s));


	const renderSlots = ()=>{
		const slotsDiv = !hasAvailableSlot ? 'There is not available slot, please select another day.' :
					<React.Fragment>
					<div className={'slotCardContainer'} style={{ display:'flex',flexDirection:'row',flexWrap:'wrap' }}>
						{ AMSlots.map(s=><Col  offset={1}>
							<AvailableSlotCardComponent setStatus={setStatus} selectedSlot={selectedSlot}setSelectedSlot={setSelectedSlot} slot={s} timezone={timezone}/>
						</Col>) }
						{ !!AMSlots.length && <Divider style={{ background:'#B5B9BE'}}/> }
					</div>
					<div className={'slotCardContainer'} style={{ display:'flex',flexDirection:'row',flexWrap:'wrap' }}>
						{ PMSlots.map(s=><Col offset={1}>
							<AvailableSlotCardComponent setStatus={setStatus} selectedSlot={selectedSlot}setSelectedSlot={setSelectedSlot} slot={s} timezone={timezone}/>
						</Col>) }
					</div>
					</React.Fragment>
		return	<Row className={`availableSlotsWrapper ${preview? 'notClickable' : ''}`}>
				<div className={'title'}>Available Slots </div>
				<div style={{ marginTop:20 }}>The time slots are based on the local time in the selected time zone.</div>
				{ slotsDiv }
			</Row>
	}

	return <div>{renderSlots()}</div>
}
export default AvailableSlotsComponent;