import React, {useEffect, useState} from "react";
import EnterTicketNumberComponent from "../component/EnterTicketNumberComponent";
import { getSessionStatusQuery } from '../../API/query/redeem';
import {message, Modal, Spin} from "antd";
import { get } from 'lodash'
import {isCompleted} from "../../schedule/constant/CheckStatus";
import {useParams} from "react-router-dom";
import {setLoginToken} from "../../auth/helper";
import {useAuth} from "../../auth";
import { useNavigate } from "react-router-dom";
import * as Cookies from "js-cookie";
import { getVisitQuery, getTesterFormQueryWithoutAuth, getSessionStatusQuery as getSessionStatusQueryWithoutAuth } from "../../API/query/schedule";

const EnterTicketNumberContainer = ()=>{
	const [ codeError,setCodeError ] = useState(null);
	const [ summary,setSummary ] = useState();
	const [ timer,setTimer ] = useState();
	const [ serverTime,setServerTime ] = useState();
	const [ status,setStatus ] = useState();
	const [ loading,setLoading ] = useState(true);
	const [ operator,setOperator ] = useState();
	const [ improperTimeVisit, setImproperTimeVisit ] = useState();
	const [ tester, setTester ] = useState();
	const params = useParams();
	let auth = useAuth();
	const navigate = useNavigate();

	useEffect(()=>{
		const { code } = params;
		if(code) {
			redeemCodeMutation(code);
		} else {
			setLoading(false);
		}
		return Cookies.remove('proctorId');
	},[]);

	const redeemCodeMutation = async (ticketNum)=>{
		setLoading(true);
		await processTesterCode(ticketNum);
		setLoading(false);
	}
	const getRedeemCode = async (code)=>{
		try{
			let res = await redeemCodeMutation({code});
			return get(res,'data.data.isValid');

		}catch (e) {
			message.error(e.message);
		}
	}
	const getSessionStatus = async ()=>{
		let status = await getSessionStatusQuery();
		return status;
	}

	const showTicketNumberInvalid = ()=>{
		setCodeError('Sorry, this ticket number is invalid. Please double check.');
		message.error('Sorry, this ticket number is invalid. Please double check.');
	}

	const showNeedSchedule = ()=>{
		setCodeError('Please schedule a verified COVID test appointment first.');
		message.error('Please schedule a verified COVID test appointment first.');
	}

	const processImproperTimeVisit = async (ticketNum)=>{
		try {
			let statusRes = await getSessionStatusQueryWithoutAuth(ticketNum);
			if(statusRes.data.code==200){
				let { Status } = get(statusRes,'data.data');
				if(isCompleted(Status)){
					navigate(`/verifyTest/schedule/${ticketNum}`);
					return;
				}
			}
			let visit = await getVisitQuery(ticketNum);
			let visitData = get(visit,'data.data',{});
			if(visitData) {
				setImproperTimeVisit(visitData);
				let tester = await getTesterFormQueryWithoutAuth(ticketNum);
				let testerInfo = get(tester,'data.data',{});
				if(testerInfo) {
					setTester({...testerInfo});
				}
			} else {
				showNeedSchedule();
			}
		} catch(e){
			showTicketNumberInvalid();
		}
	}

	const processTesterCode = async (ticketNum)=>{
		try{
			let res = await auth.signin({code:ticketNum,role:'code'});
			if(!res){
				showTicketNumberInvalid();
			}else{
				let code = res.data.code;
				if(code === 200) {
					const auth = get(res,'data.data.token');
					setLoginToken(auth);
				}
				let statusRes = await getSessionStatus();
				if(statusRes.data.code==200){
					let { Status, Operator } = get(statusRes,'data.data');
					if(isCompleted(Status)){
						navigate(`/verifyTest/schedule/${ticketNum}`);
						return;
					}
					let summary = get(statusRes,'data.summary');
					setSummary(summary);
					setStatus(Status);
					if(Operator>0) {
						Cookies.set('proctorId',Operator);
						setOperator(Operator);
					}
					setTimer(get(statusRes,'data.summary.completedAt'));
				}
			}
		} catch(e) {
			const code = get(e, 'response.status');
			code === 403 ? await processImproperTimeVisit(ticketNum) : showTicketNumberInvalid();
		}
	}

	return loading ? <Spin/> : <EnterTicketNumberComponent redeemCode={getRedeemCode} getSessionStatus={getSessionStatus}
	                                   summary={summary} setSummary={setSummary} code={params.code}
                                     operator={operator} setOperator={setOperator}
                                       serverTime={serverTime} setServerTime={setServerTime}
	                                   timer={timer} setTimer={setTimer} status={status} setStatus={setStatus}
	                                   codeError={codeError} setCodeError={setCodeError} processTesterCode={processTesterCode}
																		 improperTimeVisit={improperTimeVisit} tester={tester}/>;
}
export default EnterTicketNumberContainer;